import { call, put, takeEvery } from "redux-saga/effects";

import { userInfo } from "../../services/projectService";
import { closeLoader, openLoader } from "../loader/loaderSlice";

import { getUserDataStart, getUserDataSuccess } from "./currentUserSlice";

function* getCurrentUserDetailsSaga(action) {
  try {
    yield put(openLoader());
    const { data } = yield call(userInfo);
    sessionStorage.setItem("userInfo", JSON.stringify(data));
    yield put(getUserDataSuccess(data));
  } catch (error) {
    console.error({ error });
  } finally {
    yield put(closeLoader());
  }
}

export function* watchUserSaga() {
  yield takeEvery(getUserDataStart, getCurrentUserDetailsSaga);
}
