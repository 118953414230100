import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // weightFormByRuleId
  // [ruleId]: {
  //   data: rowDetails,
  //   weightValue: newValue,
  // }
  weightFormByRuleId: {},
  // [rule_name]: {
  //   data: rowDetails,
  //   weightValue: newValue,
  // }
  patternRecognitionFormById: {},
  thresholdScore: 0,
  isNextTriggered: false,
  previewRedirectNextStep: false,
  initApiExecutedData: [],
  initApiLoading: false,
  initApiError: false,

  genaiResponse: [],
  genAiApiStatus: null,
  isGenAiApiFetchedAndCompleted: false,

  respondentCount: 0,
  firstCalculateButtonClicked: false,
  debouncedSearchQuery: "",
};

const previewWeightsSlice = createSlice({
  name: "previewWeights",
  initialState,
  reducers: {
    setDebouncedSearchQuery(state, action) {
      state.debouncedSearchQuery = action.payload;
    },
    setGenaiResponse(state, action) {
      state.genaiResponse = action.payload;
    },
    setGenaiApiStatus(state, action) {
      state.genAiApiStatus = action.payload;
    },
    setGenAiFetchedAndCompleted(state) {
      state.isGenAiApiFetchedAndCompleted = true;
    },
    resetGenAiFetchedAndCompleted(state) {
      state.isGenAiApiFetchedAndCompleted = false;
    },
    fetchInitPreviewApiStart(state) {
      state.initApiLoading = true;
      state.initApiError = null;
    },
    initPreviewApiSuccess(state, action) {
      state.initApiLoading = false;
      state.initApiExecutedData = action.payload;
    },
    initPreviewApiError(state, action) {
      state.initApiLoading = false;
      state.initApiError = action.payload;
    },

    setWeightFormByRuleId(state, action) {
      state.weightFormByRuleId = action.payload;
    },
    setPatternRecognitionFormById(state, action) {
      state.patternRecognitionFormById = action.payload;
    },
    resetPreviewWeights(state) {
      state.weightFormByRuleId = {};
      state.patternRecognitionFormById = {};
    },

    setThresholdScore(state, action) {
      state.thresholdScore = action.payload;
    },
    resetThresholdScore(state) {
      state.thresholdScore = 0;
      state.firstCalculateButtonClicked = false;
    },

    setPreviewCalculateAgain(state) {
      state.previewCalculateAgain = true;
    },
    resetPreviewCalculateAgain(state) {
      state.previewCalculateAgain = false;
    },

    setPreviewIsNextTriggered(state) {
      state.isNextTriggered = true;
    },
    resetPreviewIsNextTriggered(state) {
      state.isNextTriggered = false;
    },
    setPreviewRedirectNextStep(state) {
      state.previewRedirectNextStep = true;
    },
    resetPreviewRedirectNextStep(state) {
      state.previewRedirectNextStep = false;
    },

    setRespondentCount(state, action) {
      state.respondentCount = action?.payload ?? 0;
    },
    resetAllState(state) {
      return initialState;
    },

    setFirstPreviewCalculateBtnClicked(state) {
      state.firstCalculateButtonClicked = true;
    },
  },
});

export const {
  setDebouncedSearchQuery,
  resetAllState,

  setGenaiResponse,
  setGenaiApiStatus,

  setGenAiFetchedAndCompleted,
  resetGenAiFetchedAndCompleted,

  fetchInitPreviewApiStart,
  initPreviewApiSuccess,
  initPreviewApiError,

  setWeightFormByRuleId,
  setPatternRecognitionFormById,
  resetPreviewWeights,
  setThresholdScore,
  resetThresholdScore,

  setPreviewCalculateAgain,
  resetPreviewCalculateAgain,

  setJokerIsNextTriggered,
  setPreviewIsNextTriggered,
  resetPreviewIsNextTriggered,
  setPreviewRedirectNextStep,
  resetPreviewRedirectNextStep,

  setRespondentCount,
  setFirstPreviewCalculateBtnClicked,
} = previewWeightsSlice.actions;

export default previewWeightsSlice.reducer;
