import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import React from "react";

import "./LockSwitch.css";
import { Fade, Tooltip } from "@mui/material";

import LockSwitchIcon from "../../assets/images/SwitchLock.svg";

const IOSSwitch = styled((props) => (
  <div className="lock-switch__container">
    {props?.checked && <img src={LockSwitchIcon} className="lock-switch__lock-icon" />}
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  </div>
))(({ theme }) => ({
  width: 46,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.MuiSwitch-thumb": {
      color: "white",
      border: "none",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    color: "white",
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "var(--secondary-color)",
    left: "10px !important",
  },
}));

const LockSwitch = ({ isChecked, onClick, toolTipTitle = null }) => (
  <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title={toolTipTitle}>
    <FormGroup>
      <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} defaultChecked checked={isChecked} onClick={onClick} />} />
    </FormGroup>
  </Tooltip>
);

export default LockSwitch;
