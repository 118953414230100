import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questions: [],
  selectedQuestionId: {
    id: "",
    questionId: "",
    meta: {},
    optionsMeta: {},
    ifGrid: {},
  },
  adverseSelectedQuestionId: {
    id: "",
    questionId: "",
    meta: {},
    optionsMeta: {},
    ifGrid: [],
  },
  accordionIndex: "",
  prevQuestionId: null,
  AdverseprevQuestionId: null,
  options: [],
  loading: false,
  error: null,
  openQuestionModal: false,
  ifGrid: false,
};

const questionSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setIsgrid(state, action) {
      state.ifGrid = action.payload;
    },
    fetchQuestionsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchQuestionsSuccess(state, action) {
      state.loading = false;
      state.questions = action.payload;
    },
    fetchQuestionsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setSelectedQuestionId(state, action) {
      state.selectedQuestionId = action.payload;
    },
    setAdverseSelectedQuestionId(state, action) {
      state.adverseSelectedQuestionId = action.payload;
    },
    setOpenQuestionModal(state, action) {
      state.openQuestionModal = action.payload;
    },
    setOptions(state, action) {
      state.options = action.payload;
    },
    setPreviousQuestionId(state, action) {
      state.prevQuestionId = action?.payload ?? null;
    },
    setAdversePreviousQuestionId(state, action) {
      state.AdverseprevQuestionId = action?.payload ?? null;
    },
    setAccordionIndex(state, action) {
      // New reducer to set accordionIndex
      state.accordionIndex = action.payload;
    },
    resetAllQuestionState(state) {
      return initialState;
    },
  },
});

export const {
  resetAllQuestionState,
  setIsgrid,
  fetchQuestionsStart,
  fetchQuestionsSuccess,
  fetchQuestionsFailure,
  setSelectedQuestionId,
  setOpenQuestionModal,
  setOptions,
  setAccordionIndex,
  setAdverseSelectedQuestionId,
  setAdversePreviousQuestionId,
  setPreviousQuestionId: setQuestionLibraryPreviousId,
} = questionSlice.actions;

export default questionSlice.reducer;
