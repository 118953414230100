import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";

import SelectDropdown from "../../SelectDropdown/SelectDropdown";
import SavedFileTable from "../../Tables/SavedFileTable/SavedFileTable";
import { createProjectStart, filterFiles } from "../../../features/createProject/createProductSlice";
import { SNACKBAR_TYPES, fileTypeOptions } from "../../../assets/constants/constant";
import { activateFile } from "../../../services/projectService";
import { openSnackbar } from "../../../features/snackbar/snackbarslice";

import "./SavedFileModal.css";
import { closeLoader, openLoader } from "../../../features/loader/loaderSlice";
import ButtonComponent from "../../Button/ButtonComponent";

const SavedFileModal = ({ openModal, setOpenModal, setFileType, fileType, projectDetails, projectId }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [validateFileType, setValidateFileType] = useState(null);
  const dispatch = useDispatch();
  const { createProjectDetails } = useSelector((state) => state);
  const uploadSavedFiles = async () => {
    const reqObj = {
      raw_file_id: selectedRow.rawDataId,
      data_map_file_id: selectedRow.mapDataId,
    };
    try {
      dispatch(openLoader());
      await activateFile(projectId, reqObj);
      dispatch(closeLoader());
      dispatch(
        openSnackbar({
          message: `File activation successfull!!`,
          type: SNACKBAR_TYPES.SUCCESS,
        })
      );
      dispatch(createProjectStart(projectId));
      setOpenModal(false);
    } catch (error) {
      dispatch(closeLoader());
      dispatch(
        openSnackbar({
          message: `File activation failed!!`,
          type: SNACKBAR_TYPES.ERROR,
        })
      );
    }
    // dispatch(createProjectStart(projectId));
  };
  const styles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  useEffect(() => {
    if (fileType?.file_type) {
      setValidateFileType(fileType?.file_type);
      filterValidateFiles(fileType?.file_type);
    }
  }, [fileType.file_type]);

  const filterValidateFiles = (e) => {
    setValidateFileType(e);
    const { value } = e;
    // const filteredFiles = createProjectDetails?.filterFiles?.filter(
    //   (ele) => ele.dataFileType === value
    // );
    const filteredFiles = createProjectDetails?.filterFiles;
    dispatch(filterFiles(filteredFiles));
  };
  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="saved-file-modal" onClick={(e) => e.stopPropagation()}>
        <Button
          className="closeModalBtn sizeLarge"
          onClick={(e) => {
            e.preventDefault();
            setOpenModal(false);
          }}
        >
          <IoClose fontSize="28px" width="24px" height="24px" color="#4D4D4D" />
        </Button>
        <div className="file_header">
          <div className="attach">Attachments</div>
          <div className="header_right d-flex align-items-baseline gap-3">
            <label htmlFor="inputPassword4">Data file type</label>
            <SelectDropdown value={validateFileType} options={fileTypeOptions} className={"file_select"} onChange={(e) => filterValidateFiles(e)} />

            {/* <Select
              labelId="select-dropdown-label"
              onChange={(e) =>
                // (e) => // console.log(e)
                setSavedDataFileType({
                  ...savedDataFileType,
                  savedDataFileType: e?.target?.value,
                })
              }
              id="select-dropdown"
              value={savedDataFileType}
              className="single-select"
              classNamePrefix="select"
              styles={styles}
            >
              {fileTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select> */}
          </div>
        </div>
        <div className="saved-file-table w-100 d-flex flex-column align-items-center justify-content-center p-3">
          <SavedFileTable setSelectedRow={setSelectedRow} />
        </div>
        <div className="w-100 d-flex justify-content-end gap-2 p-2">
          <ButtonComponent
            onClick={(e) => {
              e.preventDefault();
              setOpenModal(false);
            }}
            variant="outlined"
            btnType="error"
            text="Close"
            className="btnDanger"
          />
          <ButtonComponent
            onClick={() => uploadSavedFiles()}
            variant="contained"
            btnType="primary"
            text="Upload Files"
            className="btnPrimary"
            disabled={!selectedRow}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default SavedFileModal;
