export const formatDate = (dateString) => {
  if (!dateString) return ""; // Return empty string if dateString is falsy

  const date = new Date(dateString);

  // Extract date components
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  let hour = date.getHours();
  const minute = date.getMinutes();
  let period = "AM";

  // Convert hour to 12-hour format and determine AM/PM
  if (hour === 0) {
    hour = 12;
  } else if (hour === 12) {
    period = "PM";
  } else if (hour > 12) {
    hour -= 12;
    period = "PM";
  }

  // Format the output string
  const formattedDate = `${day} ${month} ${year} ${hour}:${minute.toString().padStart(2, "0")} ${period}`;

  return formattedDate;
};

export function capitalize(str) {
  if (str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
  } else {
    return "";
  }
}
// Function to check rawdata and datamap validity based on code
export const validationFileError = (rawData = [], datamap = [], code) => {
  let isValid = true;
  if (rawData?.length > 0) {
    const validation = rawData.find((item) => item.validation.code === code);
    if (validation.is_valid) {
      isValid = true;
    } else {
      isValid = false;
    }
  }
  if (datamap?.length) {
    const validation = datamap.find((item) => item.validation.code === code);
    if (validation.is_valid) {
      isValid = true;
    } else {
      isValid = false;
    }
  }
  return isValid;
};
export const convertToKb = (bytes) => (bytes / 1024).toFixed(2);
export const convertToMb = (bytes) => (bytes / (1024 * 1024)).toFixed(2);
// Function to check rawdata and datamap validity based
export const allValidationFileError = (rawData, datamap) => {
  let isValid = true;
  let isError = false;
  if (rawData.status === "LOADED" || rawData.status === "MERGED" || rawData.status === "ERROR") {
    if (rawData.status === "ERROR") {
      isError = true;
    }

    for (const raw of rawData.file_validations) {
      if (!raw?.is_valid) {
        isValid = false;
      }
    }
  } else {
    isValid = false;
  }
  if (rawData.status === "LOADED" || rawData.status === "MERGED" || rawData.status === "ERROR") {
    if (rawData.status === "ERROR") {
      isError = true;
    }

    for (const data of datamap.file_validations) {
      if (!data?.is_valid) {
        isValid = false;
      }
    }
  } else {
    isValid = false;
  }
  return { isValid, isError };
};
