import { Radio } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";

import Minus from "../../../../assets/images/minus.svg";
import Plus from "../../../../assets/images/plus.svg";

const QuestionTableQuestionRow = ({
  showRow = true,
  rowClassName,
  showExpandIcon,
  isRowExpanded,
  showRadioButton,
  selectedRow,
  handleExpandClick,
  displayQuestionId,
  displayQuestionLabel,
  displayQuestionType,
  rowMetaDataOptions,
  rowMetaDataRadioInputAriaLabel,
  handleRadioChange,
}) => (
  <>
    {showRow && (
      <TableRow className={rowClassName}>
        <TableCell className="question-expand-icon">
          {showExpandIcon && (
            <IconButton aria-label="expand row" size="small" onClick={handleExpandClick}>
              <img src={isRowExpanded ? Minus : Plus} alt={isRowExpanded ? "Collapse" : "Expand"} />
            </IconButton>
          )}
          {showRadioButton && (
            <Radio
              checked={selectedRow == displayQuestionId}
              onChange={() => handleRadioChange(displayQuestionId, rowMetaDataOptions, displayQuestionLabel, displayQuestionType)}
              inputProps={{ "aria-label": rowMetaDataRadioInputAriaLabel }}
              name="radio-buttons"
              sx={{
                marginLeft: "-10px",
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
              }}
            />
          )}
        </TableCell>
        <TableCell />
        <TableCell className="questionTable-id" component="th" scope="row">
          {displayQuestionId}
        </TableCell>
        <TableCell align="left" className="questionTable_question">
          <Typography
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: displayQuestionLabel?.length <= 100 ? "flex" : "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              fontSize: "14px",
              height: "40px",
              lineHeight: "19px",
              alignItems: "center",
            }}
            title={displayQuestionLabel}
          >
            {displayQuestionLabel}
          </Typography>
        </TableCell>
        <TableCell align="left" className="questionTable-type">
          {displayQuestionType}
        </TableCell>
      </TableRow>
    )}
  </>
);

export default QuestionTableQuestionRow;
