import { call, put, takeEvery } from "redux-saga/effects";

import { saveProjectJokersApi } from "../../../services/previewService";
import { closeLoader, openLoader } from "../../loader/loaderSlice";
import { openSnackbar } from "../../snackbar/snackbarslice";
import { setPreviewRedirectNextStep } from "../previewWeights/previewWeightsSlice";
import { SNACKBAR_TYPES } from "../../../assets/constants/constant";

import { saveProjectJokersError, saveProjectJokersStart, saveProjectJokersSuccess } from "./projectJokersSlice";

function* saveProjectJokersStartSaga(action) {
  yield put(openLoader());
  try {
    const mappedData = {
      projectId: action?.payload?.projectId ?? null,
      wave: action?.payload?.wave ?? 1,
      respondentIds: action?.payload?.respondentIds ?? [],
      username: action?.payload?.username ?? "",
      respondentFileId: action?.payload?.respondentFileId ?? null,
    };

    const { data } = yield call(() => saveProjectJokersApi(mappedData.projectId, mappedData.wave, mappedData.respondentIds, mappedData.username));

    yield put(saveProjectJokersSuccess(data));
    // yield put(openSnackbar({ message: "Project jokers saved successfully", type: SNACKBAR_TYPES.SUCCESS }));

    if (action?.payload?.triggerPreviewRedirect) {
      yield put(setPreviewRedirectNextStep());
    }
  } catch (error) {
    yield put(saveProjectJokersError(error));
    yield put(openSnackbar({ message: "Failed to save project jokers", type: SNACKBAR_TYPES.ERROR }));
  } finally {
    yield put(closeLoader());
  }
}

export function* watchProjectJokersSaga() {
  yield takeEvery(saveProjectJokersStart, saveProjectJokersStartSaga);
}
