function* patternRecognitionStartSaga(action) {
  // const mappedData = {
  //   mergeFile: action?.payload?.merge_file ?? "string",
  //   type: action?.payload?.type ?? "alternative",
  // };
  // mergeFile: action?.payload?.merge_file ?? "string",
  const { merge_file, type } = action.payload;
  try {
    // let data;
    // if (type === 'alternative') {
    //   data = yield call(alternativePatternApi,merge_file);
    // } else if (type === 'stepped') {
    //   // data = yield call(steppedPatternApi,merge_file);
    // } else if (type === 'repetitive') {
    //   data = yield call(repetitivePatternApi,merge_file);
    // }
    // yield put(patternRecognitionSuccess(data));
  } catch (error) {
    console.error("Pattern recognition failed", error);
    // yield put(patternRecognitionError(error.message));
  }
}

export function* watchPatternRecognitionSaga() {
  // yield takeEvery(patternRecognitionStart.type, patternRecognitionStartSaga);
}
