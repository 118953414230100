import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableSortLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import PaginationComponent from "../../PaginationComponent/PaginationComponent";
import ButtonComponent from "../../Button/ButtonComponent";
import "./QuestionTable.css";
import {
  setAdverseSelectedQuestionId,
  setOpenQuestionModal,
  setOptions,
  setSelectedQuestionId,
} from "../../../features/designQcRule/questionlibrary/questionlibrarySlice";

import { nanoid } from "@reduxjs/toolkit";

import { openSnackbar } from "../../../features/snackbar/snackbarslice";
import { updateAdverseRulequestiontype } from "../../../features/adverseEvent/RuleBuilder/RuleBuilder";
import { updaterulequestiontype } from "../../../features/designQcRule/BuildRule/BuildRuleslice";
import { QUESTION_TYPES } from "../../modals/QuestionLibrary/questionLibrary.constant";
import { SNACKBAR_TYPES } from "../../../assets/constants/constant";

import { isOpenTextSingleRow, questionTableColumns } from "./helper/questionTableHelper";
import QuestionTableQuestionRow from "./helper/QuestionTableQuestionRow";
import QuestionTableRenderSubDetails from "./helper/QuestionTableRenderSubDetails";

export default function QuestionTable({
  selectedType,
  questionList,
  setQuestionList,
  limit,
  offset,
  totalCount,
  handlePageChange,
  order,
  orderBy,
  handleRequestSort,
}) {
  const [selectedOptions, setSelectedOptions] = React.useState({});
  const [openIndexes, setOpenIndexes] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState("");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10);
  const [selectQuestionId, setSelectQuestionId] = React.useState("");
  const [selectQuestionType, setSelectQuestionType] = React.useState("");

  const [selectedQuestionMetaData, setSelectedQuestionMetaData] = useState();
  const [selectedOptionsData, setSelectedOptionsData] = useState();
  const [selectedOptionsMappedData, setSelectedOptionsMappedData] = useState();

  const [selectedOptionsArray, setSelectedOptionsArray] = React.useState([]);
  const selectedQuestionId = useSelector((state) => state.questions.selectedQuestionId);
  const snackbar = useSelector((state) => state.snackbar);
  const textRule = useSelector((state) => state?.text?.textRule);
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);

  React.useEffect(() => {
    const initOpenIndexes = [];

    if (questionList) {
      let index = 0;
      for (const question of questionList) {
        if (isOpenTextSingleRow(question)) {
          initOpenIndexes.push(index);
        }

        index = index + 1;
      }
    }

    setOpenIndexes(initOpenIndexes);
  }, [questionList]);

  const handleToggleSubQuestions = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };
  const handleCloseModal = () => {
    dispatch(setOpenQuestionModal(false));
  };

  const handleSave = (questionId, questionMetaData, type, selectedOptionsMappedData) => {
    if (!questionId) return;

    const optionsMetaData = getOptionMappedObj(questionId, selectedOptionsMappedData);

    if (questionId) {
      if (selectedHorizontalTab === "Adverse Events") {
        dispatch(
          setAdverseSelectedQuestionId({
            id: nanoid(),
            questionId: questionId,
            meta: questionMetaData,
            optionsMeta: optionsMetaData,
            gridRule: type,
          })
        );
        const obj = { rule: questionId, type: type };
        dispatch(updateAdverseRulequestiontype(obj));
      } else {
        dispatch(
          setSelectedQuestionId({
            id: nanoid(),
            questionId: questionId,
            meta: questionMetaData,
            optionsMeta: optionsMetaData,
            gridRule: type,
          })
        );
        const obj = { rule: questionId, type: type };
        dispatch(updaterulequestiontype(obj));
      }
      handleCloseModal();
      // dispatch(openSnackbar({ message: "Question added successfully", type: SNACKBAR_TYPES.SUCCESS }));
    } else {
      dispatch(openSnackbar({ message: "Please select a question to save", type: SNACKBAR_TYPES.ERROR }));
    }
  };

  const getOptionMappedObj = (questionId, selectedOptionsMappedData) => {
    const Obj = {};

    let i = 1;
    for (const option of selectedOptionsMappedData) {
      Obj[option.option_id] = option?.option_label;
      i = i + 1;
    }

    return Obj;
  };

  const optionsDataMapping = (options, questionId, type) => {
    const optionsArray = JSON.parse(JSON.stringify(options));

    if (type == QUESTION_TYPES.SINGLE || type == QUESTION_TYPES.GRID) {
      let i = 0;
      for (const option of options) {
        optionsArray[i] = { ...optionsArray[i] };
        optionsArray[i].option_id = `${questionId}__${option?.answer_code ?? ""}`;
        i = i + 1;
      }
    } else if (type == QUESTION_TYPES.MULTI) {
      let i = 0;
      for (const option of options) {
        optionsArray[i] = { ...optionsArray[i] };
        optionsArray[i].option_id = `${questionId}__${option?.variable_id ?? ""}`;
        i = i + 1;
      }
    } else {
      let i = 0;
      for (const option of options) {
        optionsArray[i] = { ...optionsArray[i] };
        optionsArray[i].option_id = `${questionId}__${i + 1}`;
        i = i + 1;
      }
    }

    return optionsArray;
  };

  //   const projectId = 284;
  //   const fileId = "5045_Data_File_V0 (3).csv";
  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);
  const fileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);

  React.useEffect(() => {
    const getquestions = async () => {
      try {
      } catch (error) {
        console.error(error.message);
        dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
      }
    };
    getquestions();
  }, [selectedType]);

  // handle radio button selection

  const handleRadioChange = (questionId, options = [], question, type) => {
    setSelectedRow(questionId);
    setSelectQuestionId(questionId);
    setSelectedQuestionMetaData({ label: question, type: type });
    setSelectQuestionType(type);

    setSelectedOptionsData(options);
    setSelectedOptionsMappedData(optionsDataMapping(options, questionId, type));

    if (!selectedRow[questionId]) {
      dispatch(setOptions(optionsDataMapping(options, questionId, type)));
    } else {
      dispatch(setOptions([]));
    }
  };

  const handleOptionSelect = (optionKey, isChecked) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [optionKey]: isChecked,
    }));
  };

  return (
    <div className="question-modal">
      <TableContainer component={Paper} className="QuestionTable_container" sx={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.12)", overflowX: "hidden" }}>
        <Table
          aria-label="collapsible table"
          sx={{
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
          <TableHead sx={{ zIndex: 1 }} className="tablehead" classes={"tablehead"}>
            <TableRow>
              <TableCell sx={{ width: "55px", padding: "0" }} />
              <TableCell align="left" sx={{ padding: "0px !important", width: "25px" }}></TableCell>
              <TableCell
                align="left"
                sx={{ padding: "0px !important", cursor: "pointer", width: "150px" }}
                className="id-question-table"
                onClick={() => handleRequestSort(questionTableColumns.ID)}
              >
                ID
                <TableSortLabel active={orderBy === questionTableColumns.ID} direction={orderBy === questionTableColumns.ID ? order : "asc"} />
              </TableCell>
              <TableCell
                align="left"
                sx={{ padding: "0px !important", cursor: "pointer", width: "320px" }}
                onClick={() => handleRequestSort(questionTableColumns.QUESTION)}
              >
                Question
                <TableSortLabel active={orderBy === questionTableColumns.QUESTION} direction={orderBy === questionTableColumns.QUESTION ? order : "asc"} />
              </TableCell>
              <TableCell
                align="left"
                sx={{ padding: "0px !important", cursor: "pointer", width: "125px" }}
                onClick={() => handleRequestSort(questionTableColumns.QUESTION_TYPE)}
              >
                Type
                <TableSortLabel
                  active={orderBy === questionTableColumns.QUESTION_TYPE}
                  direction={orderBy === questionTableColumns.QUESTION_TYPE ? order : "asc"}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionList &&
              questionList?.map((question, index) => {
                const questionId = question?.question_id;
                const originalQuestionObject = question?.[questionId];
                const showQuestionSubDetails = openIndexes?.includes(index);
                const otherQuestions = Object.keys(question).filter((key) => key !== questionId && key !== "question_id") ?? [];

                return (
                  originalQuestionObject?.question !== null &&
                  originalQuestionObject?.question !== undefined && (
                    <React.Fragment key={index}>
                      <QuestionTableQuestionRow
                        showRow={!isOpenTextSingleRow(question)}
                        rowClassName={`questionRow ${
                          openIndexes.includes(index) ? "question-table__highlighted-row question-table__highlighted-row--top-row" : ""
                        }`}
                        showExpandIcon={
                          otherQuestions?.length > 0 ||
                          originalQuestionObject?.sub_questions?.length > 0 ||
                          (originalQuestionObject?.type === QUESTION_TYPES.RANKING && originalQuestionObject?.options?.length > 0)
                        }
                        isRowExpanded={openIndexes.includes(index)}
                        showRadioButton={
                          otherQuestions?.length == 0 &&
                          originalQuestionObject?.type &&
                          originalQuestionObject?.type !== QUESTION_TYPES.GRID &&
                          originalQuestionObject?.type !== QUESTION_TYPES.NUMERIC_LIST &&
                          originalQuestionObject?.type !== QUESTION_TYPES.RANKING &&
                          originalQuestionObject?.type !== QUESTION_TYPES.OPEN_TEXT &&
                          originalQuestionObject?.type !== QUESTION_TYPES.OPEN_TEXT_LIST
                        }
                        selectedRow={selectedRow}
                        handleExpandClick={() => handleToggleSubQuestions(index)}
                        displayQuestionId={question?.question_id}
                        displayQuestionLabel={originalQuestionObject?.question}
                        displayQuestionType={originalQuestionObject?.type}
                        rowMetaDataOptions={originalQuestionObject?.options}
                        rowMetaDataRadioInputAriaLabel={`Select question ${index}`}
                        handleRadioChange={handleRadioChange}
                      />

                      {showQuestionSubDetails && (
                        <QuestionTableRenderSubDetails
                          overallQuestionObj={question}
                          originalQuestionObject={originalQuestionObject}
                          questionId={questionId}
                          index={index}
                          handleRadioChange={handleRadioChange}
                          selectedRow={selectedRow}
                          openIndexes={openIndexes}
                          showHighlightedRows={!isOpenTextSingleRow(question)}
                          radioOnFirstColumn={isOpenTextSingleRow(question)}
                        />
                      )}
                    </React.Fragment>
                  )
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: "absolute", bottom: "24px", left: "24px", right: "24px", display: "flex", justifyContent: "space-between" }}>
        <PaginationComponent className="custom-pagination" limit={limit} offset={offset} totalCount={totalCount} onPageChange={handlePageChange} />
        <ButtonComponent
          variant="contained"
          btnType="primary"
          text="Save"
          onClick={() => handleSave(selectQuestionId, selectedQuestionMetaData, selectQuestionType, selectedOptionsMappedData)}
          className="btnPrimary"
          sx={{ width: "140px" }}
        />
      </Box>
    </div>
  );
}
