import { call, put, select, takeEvery } from "redux-saga/effects";

import { createRule } from "../../../services/projectService";
import { closeLoader, openLoader } from "../../loader/loaderSlice";
import { openSnackbar } from "../../snackbar/snackbarslice";
import { setIsRuleModified, setIsSwitchButtonPositionLeft } from "../Rule/ruleSlice";
import { checkEvaluation } from "../../../utils/evaluation";
import { RULE_TYPE, SNACKBAR_TYPES } from "../../../assets/constants/constant";
import { updateAdverseRuleIds } from "../../adverseEvent/RuleBuilder/RuleBuilder";
import { setIsAdverseRuleModified } from "../../adverseEvent/AdverseRule/AdverseRule";

import { startSaveJokerRules, startSaveRulesAndNext, updateRuleIds } from "./BuildRuleslice";

export function* saveRulesSagaFunction(ruleType) {
  let isSuccess = true;

  try {
    yield put(openLoader());

    let rule_Rule_name;

    if (ruleType === RULE_TYPE.JOKER_ANALYSIS) {
      rule_Rule_name = yield select((state) => state?.text?.textRules);
    } else if (ruleType === RULE_TYPE.ADVERSE_EVENT) {
      rule_Rule_name = yield select((state) => state?.adversetext?.textRules);
    }

    const projectId = yield select((state) => state?.createProjectDetails?.data?.id);
    const username = yield select((state) => state?.user?.userInfo?.email);

    let allValid = true;
    let invalidMessage = "";

    for (const ruleObj of rule_Rule_name) {
      const { rule, rule_name, questionsMeta, optionsMeta } = ruleObj;
      const [preProcessedResult, customValidationMessage] = checkEvaluation(rule, questionsMeta, optionsMeta);

      if (!preProcessedResult) {
        isSuccess = false;
        allValid = false;

        if (customValidationMessage) {
          invalidMessage = `Invalid rule: ${rule_name}. ${customValidationMessage}`;
        } else {
          invalidMessage = `Invalid rule: "${rule_name}". Please enter a valid expression.`;
        }

        break;
      }
    }

    const isEmptyRuleName = rule_Rule_name.some((ruleObj) => !ruleObj?.rule_name || ruleObj?.rule_name.trim() === "");
    const isEmptyRule = rule_Rule_name.some((ruleObj) => !ruleObj?.rule || ruleObj?.rule.trim() === "");

    if (isEmptyRuleName) {
      isSuccess = false;
      yield put(openSnackbar({ message: "Rule name is empty. Please fill rule name.", type: SNACKBAR_TYPES.ERROR }));
    } else if (isEmptyRule) {
      isSuccess = false;
      yield put(openSnackbar({ message: "Rule is empty. Please fill in the rule.", type: SNACKBAR_TYPES.ERROR }));
    } else if (!allValid) {
      isSuccess = false;
      if (invalidMessage) {
        yield put(openSnackbar({ message: invalidMessage, type: SNACKBAR_TYPES.ERROR }));
      } else {
        yield put(openSnackbar({ message: "Rule expression is invalid. Please enter a valid expression.", type: SNACKBAR_TYPES.ERROR }));
      }
    } else {
      const newRulesData = rule_Rule_name.map((ruleObj) => ({
        id: ruleObj?.id ?? null,
        project_id: projectId,
        rule_name: ruleObj.rule_name.trim(),
        rule_type: ruleType,
        rule: ruleObj.rule.trim(),
        optionsMeta: ruleObj?.optionsMeta ?? {},
        questionsMeta: ruleObj?.questionsMeta ?? {},
        weight: "0",
        is_rule_active: false,
      }));

      const newRuleData = {
        rules: newRulesData,
        updated_by: username,
      };

      const response = yield call(() => createRule(newRuleData));
      const ids = response?.data?.map((rule) => rule.id);

      if (ruleType === RULE_TYPE.JOKER_ANALYSIS) {
        yield put(updateRuleIds({ ids }));
        yield put(setIsRuleModified(false));
      } else if (ruleType === RULE_TYPE.ADVERSE_EVENT) {
        yield put(updateAdverseRuleIds({ ids }));
        yield put(setIsAdverseRuleModified(false));
      }

      yield put(openSnackbar({ message: "Rule created successfully!", type: SNACKBAR_TYPES.SUCCESS }));
    }
  } catch (error) {
    isSuccess = false;

    if (error?.response?.data?.errorCode === "E011") {
      yield put(openSnackbar({ message: "Duplicate rule name received from Joker Analysis or Adverse Event", type: SNACKBAR_TYPES.ERROR }));
    } else {
      yield put(openSnackbar({ message: error?.response?.data?.message, type: SNACKBAR_TYPES.ERROR }));
    }
  } finally {
    yield put(closeLoader());
  }

  return isSuccess;
}

function* startSaveJokerRulesSaga(action) {
  try {
    yield call(() => saveRulesSagaFunction(RULE_TYPE.JOKER_ANALYSIS));
  } catch (error) {}
}

function* startSaveRulesAndNextSaga(action) {
  try {
    const ruleType = action.payload?.ruleType ?? RULE_TYPE.JOKER_ANALYSIS;

    const isSuccess = yield call(() => saveRulesSagaFunction(ruleType));

    if (isSuccess) {
      yield put(setIsSwitchButtonPositionLeft(false));
    }
  } catch (error) {}
}

export function* watchBuildRuleSaga() {
  yield takeEvery(startSaveJokerRules, startSaveJokerRulesSaga);
  yield takeEvery(startSaveRulesAndNext, startSaveRulesAndNextSaga);
}
