import React, { useMemo } from "react";

import "./GenaiTable.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { LuRefreshCcw } from "react-icons/lu";

import { GENAI_RATIONALE, GENAI_SCORE, GEN_AI_COLUMNS_OBJECT, PATTERN_RECOGNITION_FLAT_COLUMN_OBJECT } from "../constants/preview-constants";
import { ReactComponent as LeftArrowIcon } from "../../../../../assets/images/arrowIcons/leftArrowIcon.svg";
import { ReactComponent as RightArrowIcon } from "../../../../../assets/images/arrowIcons/rightArrowIcon.svg";

import { JokerTableColumn } from "./helper/jokerTableHelper";

function GenAiTable({
  isGenAiLoading,
  sortedRows,
  RESPONDENT_COUNT,
  handleRequestSort,
  dense,
  selected,
  rules,
  order,
  orderBy,
  captureScrollPosition,
  isSelected,
  selectedHorizontalTab,
  handleOnSwitchChange,
  showGenAiColumns,
  setShowGenAiColumns,
  getGenAiData,
}) {
  function handleToggleGenAiColumns() {
    setShowGenAiColumns((prev) => !prev);
    captureScrollPosition();
  }

  return (
    <div className="preview-genAi__container">
      <div className="preview-genAi-table__expand-btn-container">
        <button
          className={`preview-genAi-table__expand-btn ${isGenAiLoading ? "preview-genAi-table__expand-btn--disabled" : ""}`}
          disabled={isGenAiLoading}
          onClick={handleToggleGenAiColumns}
        >
          {showGenAiColumns ? <RightArrowIcon /> : <LeftArrowIcon />}
        </button>
      </div>
      <>
        {!isGenAiLoading && showGenAiColumns ? (
          <>
            {/* {isGenAiLoading ? (
              <div className="preview-genAi__loading">
                <Rings visible={true} height="80" width="80" color="#4f46e595" ariaLabel="rings-loading" wrapperStyle={{}} wrapperClass="" />
              </div>
            ) : ( */}
            <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"} className="jocker-recognition-table">
              <>
                <GenAiTableHeader
                  numSelected={selected.length}
                  onSelectAllClick={() => {}}
                  rowCount={RESPONDENT_COUNT}
                  fontFamily="Montserrat"
                  rules={rules}
                  onRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                  captureScrollPosition={captureScrollPosition}
                />
                <TableBody
                  style={{
                    height: "100px",
                    overflow: "auto",
                  }}
                >
                  {sortedRows &&
                    sortedRows?.map((rowObj) => {
                      const respondentId = parseInt(rowObj?.[JokerTableColumn.RESP_ID]);
                      const isItemSelected = isSelected(respondentId);
                      const labelId = `enhanced-table-checkbox-${respondentId}`;
                      const showCheck = !!rowObj?.[JokerTableColumn.EXCLUDED];

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={respondentId}
                          selected={isItemSelected}
                          className="jocker_table-row"
                          sx={{
                            cursor: "pointer",
                            height: "75px",
                            fontFamily: "Montserrat, sans-serif",
                            fontSize: "16px",
                          }}
                        >
                          {renderGenAiCells(rowObj ?? {})}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </>
            </Table>
            {/* )} */}
          </>
        ) : (
          <div className="preview-genAi-table__banner">
            <div className="preview-genAi-table__banner-rotated-container">
              GenAi Driven {isGenAiLoading && <LuRefreshCcw className="preview-genAi-table__banner-refresh-icon" onClick={() => getGenAiData(true)} />}
            </div>
          </div>
        )}
      </>
    </div>
  );
}

const GenAiTableHeader = ({ onRequestSort, rules, order, orderBy, captureScrollPosition }) => {
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);

  const createSortHandler = (property) => (event) => {
    captureScrollPosition();
    onRequestSort(event, property);
  };

  const columnsToRender = useMemo(() => GEN_AI_COLUMNS_OBJECT, [selectedHorizontalTab]);

  return (
    <TableHead className="jocker-table-head" sx={{ position: "sticky", top: 0, zIndex: 1 }}>
      <TableRow sx={{ backgroundColor: "#E3E4FD", width: "15px" }}>
        <TableCell
          className="jocker_table-head-cell preview-genAi__table-head-cell"
          align="center"
          // sx={{
          //   borderBottom: "3px solid white",
          // }}
          colSpan={5}
        >
          GenAi Driven
        </TableCell>
      </TableRow>

      <TableRow sx={{ backgroundColor: "#E3E4FD" }}>
        {columnsToRender &&
          Object.keys(columnsToRender).map((columnKey, index) => {
            const columnName = columnsToRender[columnKey];

            const isLast = index === Object.keys(columnsToRender).length - 1;

            return (
              <TableCell
                className={`jocker_table-head-cell preview-genAi__table-sub-head-cell preview-genAi__table-head-cell--${columnKey}`}
                align="center"
                sx={{ borderRight: !isLast ? "1px solid white" : "", cursor: "pointer" }}
                onClick={createSortHandler(PATTERN_RECOGNITION_FLAT_COLUMN_OBJECT?.[columnKey])}
              >
                {columnName ?? ""}
                <TableSortLabel
                  active={orderBy === PATTERN_RECOGNITION_FLAT_COLUMN_OBJECT?.[columnKey]}
                  direction={orderBy === PATTERN_RECOGNITION_FLAT_COLUMN_OBJECT?.[columnKey] ? order : "asc"}
                />
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
};

function renderGenAiCells(patternRecognitionDetail) {
  return (
    <>
      <TableCell style={{ height: "45px", padding: "0px" }} align="center">
        {renderGenAiScoreCells(patternRecognitionDetail?.[GENAI_SCORE])}
      </TableCell>
      <TableCell
        style={{
          height: "45px",
          padding: "8px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "30px",
        }}
        align="center"
      >
        <Tooltip title={patternRecognitionDetail?.[GENAI_RATIONALE]} placement="bottom-start">
          {patternRecognitionDetail?.[GENAI_RATIONALE] ?? "NA"}
        </Tooltip>
      </TableCell>
    </>
  );
}

function renderGenAiScoreCells(genAiScore) {
  if (typeof genAiScore !== "number") return "NA";

  genAiScore = genAiScore * 10;

  if (genAiScore <= 40) {
    return <span className="preview-genAi-table__score preview-genAi-table__score--red">{genAiScore}</span>;
  } else if (genAiScore <= 70) {
    return <span className="preview-genAi-table__score preview-genAi-table__score--yellow">{genAiScore}</span>;
  } else {
    return <span className="preview-genAi-table__score preview-genAi-table__score--green">{genAiScore}</span>;
  }
  return;
}

export default GenAiTable;
