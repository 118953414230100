// src/features/counterSaga.js

import { call, put, takeEvery } from "redux-saga/effects";

import { fetchProjects } from "../../services/projectService";
import { closeLoader, openLoader } from "../loader/loaderSlice";

import {
  getArchivedprojectStart,
  getArchivedprojectSuccess,
  getProjectById,
  getProjectError,
  getProjectsByIdStart,
  getProjectsStart,
  getProjectsSuccess,
} from "./projectSlice";

function* getProductsSaga(action) {
  try {
    yield put(openLoader());
    const { data } = yield call(() => fetchProjects(true, action.payload));
    yield put(getProjectsSuccess(data));
    yield put(closeLoader());
  } catch (error) {
    yield put(getProjectError(error));
    yield put(closeLoader());
  }
}

function* getProjectByIdSaga(action) {
  try {
    yield put(openLoader());
    // const data = yield call(() => fetchProjectsById(action.payload));
    yield put(getProjectById(action.payload));
    yield put(closeLoader());
  } catch (error) {
    yield put(getProjectError(error));
    yield put(closeLoader());
  }
}

function* getArchivedProjectsSaga(action) {
  try {
    yield put(openLoader());
    const { data } = yield call(() => fetchProjects(false, action.payload));
    yield put(getArchivedprojectSuccess(data));
    yield put(closeLoader());
  } catch (error) {
    yield put(getProjectError(error));
    yield put(closeLoader());
  }
}

export function* watchProjectSaga() {
  yield takeEvery(getProjectsStart, getProductsSaga);
  yield takeEvery(getArchivedprojectStart, getArchivedProjectsSaga);
  yield takeEvery(getProjectsByIdStart, getProjectByIdSaga);
}
