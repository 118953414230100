import { call, put, select } from "redux-saga/effects";

import { closeLoader, openLoader } from "../../loader/loaderSlice";
import { openSnackbar } from "../../snackbar/snackbarslice";
import { bulkUpsertAdverseRules } from "../../../services/adverseEventService";
import { getAllRulesByProjectId } from "../../../services/projectService";
import { SNACKBAR_TYPES } from "../../../assets/constants/constant";

import {
  fetchAdverseRuleSuccessWithInitCheckedRulesReduxState,
  fetchAdverseRulesFailure,
  fetchAdverseRulesSuccess,
  updatedAdverseSelectedAndActiveRules,
} from "./AdverseRule";

const checkedRulesInRuleTable = (state) => state?.adverseRule?.adversecheckedRulesInRuleTable;
const usernameState = (state) => state?.user?.userInfo?.email || "harsh.chauhan@inizioadvisory.onmicrosoft.com";
const allRulesState = (state) => state?.adverseRule?.adverserules;

export function* fetchGetAllAdverseRulesStartSaga(action) {
  yield put(openLoader());
  try {
    const mappedData = {
      projectId: action?.payload ?? "",
    };

    const { data } = yield call(() => getAllRulesByProjectId(mappedData.projectId, "ADVERSE_EVENT"));

    yield put(fetchAdverseRuleSuccessWithInitCheckedRulesReduxState(data?.items));
    yield put(fetchAdverseRulesSuccess(data?.items ?? []));
  } catch (error) {
    yield put(fetchAdverseRulesFailure(error.message));
  } finally {
    yield put(closeLoader());
  }
}

// useSelector((state) => state?.adverseRule?.adversecheckedRulesInRuleTable)
export function* handleAdverseActiveRulesUpdates() {
  try {
    yield put(openLoader());
    // Get the username from the state
    const selectedRulesIds = yield select(checkedRulesInRuleTable);
    const username = yield select(usernameState);
    const rules = yield select(allRulesState);

    const upsertObj = rules.map((rule) => {
      const { created_by, updated_by, created_at, updated_at, rule_version, is_valid, raw_data_file_id, data_map_file_id, rawFile, dataMapFile, ...data } =
        rule;

      if (selectedRulesIds.includes(rule.id)) {
        data.is_rule_active = true;
      } else {
        data.is_rule_active = false;
      }

      return data;
    });

    const data = yield call(() => bulkUpsertAdverseRules(upsertObj, username));

    yield put(updatedAdverseSelectedAndActiveRules(data.data));
    // yield put(resetPreviewWeights());
  } catch (error) {
    yield put(openSnackbar({ message: "Couldn't save active rules!", type: SNACKBAR_TYPES.ERROR }));
    throw error;
  } finally {
    yield put(closeLoader());
  }
}

//   export function* watchFetchAdverseRules() {
//     yield takeLatest(handleActiveRulesUpdates);
//   }
