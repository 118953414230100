import React, { useEffect, useState } from "react";
import "./PreviewScreen.css";
import { useDispatch, useSelector } from "react-redux";
import { Box, Paper, TableContainer, styled } from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";

import { saveProjectJokersStart } from "../../../../features/designQcRule/projectJokers/projectJokersSlice";
import { setDesignQcHorizontalSelectedTab, setDesignQcSelectedTab } from "../../../../features/designQcRule/designQc/DesignQcSlice";
import { DESIGN_QC_HORIZONTAL_TABS, DESIGN_QC_TABS } from "../../DesignQc.constants";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import { saveProjectJokersApi } from "../../../../services/previewService";
import {
  fetchInitPreviewApiStart,
  resetPreviewIsNextTriggered,
  resetPreviewRedirectNextStep,
  resetThresholdScore,
  setDebouncedSearchQuery,
  setPreviewRedirectNextStep,
} from "../../../../features/designQcRule/previewWeights/previewWeightsSlice";
import { closeLoader, openLoader } from "../../../../features/loader/loaderSlice";
import { openSnackbar } from "../../../../features/snackbar/snackbarslice";
import SearchComponent from "../../../../components/SearchBox/SearchBox";
import LabelIcon from "../../../../components/Label/label";
import { SNACKBAR_TYPES } from "../../../../assets/constants/constant";

import ThresholdBar from "./thresholdBar/ThresholdBar";
import JokerDatabaseAndSummary from "./JokerDatabaseAndSummary/JokerDatabaseAndSummary";
import JokerWeightInput from "./JokerWeightInput/JokerWeightInput";

const StyledTableContainer = styled(TableContainer)({
  padding: "0px",
});

function PreviewScreen() {
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({
    Weight: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const username = useSelector((state) => state?.user?.userInfo?.email) ?? "";
  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);
  const [isCalculationInit, setIsCalculationInit] = useState(false);
  const [isInitRuleApiDone, setIsInitRuleApiDone] = useState(false);
  const isNextClicked = useSelector((state) => state?.previewWeights?.isNextTriggered) ?? undefined;
  const redirectFromPreview = useSelector((state) => state?.previewWeights?.previewRedirectNextStep) ?? undefined;
  const [calculatedRespondentsObject, setCalculatedRespondentsObject] = useState({});
  const [mergedData, setMergedData] = useState([]);
  const respondentCount = useSelector((state) => state?.previewWeights?.respondentCount) ?? undefined;

  const isRuleFetched = useSelector((state) => state?.rules?.loading === false && state?.rules?.rules !== undefined) ?? false;

  const dataMapFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);
  const dataMapFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.id);
  // const rawFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeRawDataFile?.name);
  const rawFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeRawDataFile?.id);
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  const DEBOUNCED_SEARCH_TIME = 700;
  const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCED_SEARCH_TIME);

  useEffect(() => {
    async function fetchUpdatedSearchTerm() {
      // await handleFetchKeywords(offset, debouncedSearchQuery);
      // setOffset(0);
    }

    fetchUpdatedSearchTerm();
  }, [debouncedSearchQuery]);

  dispatch(setDebouncedSearchQuery(debouncedSearchQuery));

  const firstCalculateButtonClicked = useSelector((state) => state?.previewWeights?.firstCalculateButtonClicked);

  // const isApiCalled = useSelector((state) => state?.patternRecognition?.isApiCalled);
  // const tableDataMap = useSelector((state) => state?.patternRecognition?.tableDataMap);

  const limit = 10;
  const [offset, setOffset] = useState(0);

  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getRulesFunction = async () => {
    dispatch(openLoader());

    try {
      dispatch(resetThresholdScore());
      dispatch(
        fetchInitPreviewApiStart({
          projectId: projectId,
          questionFileId: dataMapFileId,
          respondentFileId: rawFileId,
          username: username,
          dataMapFileName: dataMapFileName,
          selectedHorizontalTab: selectedHorizontalTab,
        })
      );

      setIsInitRuleApiDone(true);
      dispatch(openLoader());
    } catch (error) {
      // todo: show toast error
    }
  };

  useEffect(() => {
    getRulesFunction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    function saveProjectJokers() {
      if (isNextClicked === true && isCalculationInit) {
        try {
          // handleSaveProjectJokers(true);
          dispatch(setPreviewRedirectNextStep());
        } catch (error) {}
      }
    }

    saveProjectJokers();
    dispatch(resetPreviewIsNextTriggered());
  }, [isNextClicked]);

  if (redirectFromPreview) {
    if (isCalculationInit) {
      // navigate("/create-project/preview-qc-joker-score/3");
      dispatch(setDesignQcHorizontalSelectedTab(DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS));
      dispatch(setDesignQcSelectedTab(DESIGN_QC_TABS.BUILD_QC_RULE));
      // selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS
    }

    dispatch(resetPreviewRedirectNextStep());
  }

  const handleSaveProjectJokers = (triggerPreviewRedirect) => {
    try {
      const finalJokers = getJokerRespondentIds();
      const payload = {
        projectId: projectId ?? null,
        respondentIds: finalJokers,
        username: username,
        triggerPreviewRedirect: triggerPreviewRedirect,
        respondentFileId: rawFileId,
      };

      dispatch(saveProjectJokersStart({ ...payload }));
    } catch (error) {
      throw error;
    }
  };

  const saveProjectJokers = async () => {
    try {
      const finalJokers = getJokerRespondentIds();
      const payload = {
        projectId: projectId ?? null,
        respondentIds: finalJokers,
        username: username,
        wave: "NA",
        respondentFileId: rawFileId,
      };
      dispatch(openLoader());
      try {
        const adversedata = await saveProjectJokersApi(payload.projectId, payload.wave, payload.respondentIds, payload.username, payload.respondentFileId);
      } catch (err) {
        dispatch(openSnackbar({ message: err.message, type: SNACKBAR_TYPES.ERROR }));
      } finally {
        dispatch(closeLoader());
      }
    } catch (error) {
      throw error;
    }
  };

  const getJokerRespondentIds = () => {
    if (!firstCalculateButtonClicked) {
      return [];
    }

    const finalJokers = [];
    Object.keys(calculatedRespondentsObject).forEach((key) => {
      if (calculatedRespondentsObject[key]?.exclude !== true) {
        finalJokers.push(parseInt(key));
      }
    });

    return finalJokers;
  };
  const databaseHeadingStyle = {
    paddingBottom: selectedHorizontalTab === "Adverse Events" ? "20px" : "0px",
  };
  const handleSearchInputChange = async (event) => {
    const newSearchValue = event.target.value;
    setSearchQuery(newSearchValue);
  };
  return (
    <>
      <div className="preview_table joker_preview_table">
        <StyledTableContainer className="previewcontainer">
          <Box sx={{ width: "100%", padding: "0px" }}>
            <Paper className="preview_paper" sx={{ width: "100%", mb: 2 }}>
              <JokerWeightInput
                formErrors={formErrors}
                calculatedRespondentsObject={calculatedRespondentsObject}
                setCalculatedRespondentsObject={setCalculatedRespondentsObject}
                mergedData={mergedData}
                setMergedData={setMergedData}
                setIsCalculationInit={setIsCalculationInit}
                isRuleFetched={isRuleFetched}
                isInitRuleApiDone={isInitRuleApiDone}
                limit={limit}
                offset={offset}
                setOffset={setOffset}
              />
              <div className="grid_search">
                {selectedHorizontalTab === "Joker Analysis" && (
                  <div className="database_heading" style={databaseHeadingStyle}>
                    <div className="maindatabseheding">
                      <div>
                        <p
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Database and summary
                        </p>
                      </div>

                      <div className="horizontal_line_heading"></div>
                    </div>
                    {/* {selectedHorizontalTab === "Joker Analysis" && ( */}
                    <div className="ruletablesearch">
                      <SearchComponent value={searchQuery} onChange={handleSearchInputChange} className="open_text_search" width="244px" />
                    </div>
                    {/* )} */}
                    {/* <div className="true_false">
                  <div className="true">
                    <GreenCircleIcon />
                    <p style={{ fontSize: "12px" }}>True</p>
                  </div>
                  <div className="false">
                    <RedCircleIcon />

                    <p style={{ fontSize: "12px" }}>False</p>
                  </div>
                </div> */}
                    <LabelIcon />
                    {selectedHorizontalTab === "Joker Analysis" && (
                      <ButtonComponent
                        className="file_upload_btn downloadbtn"
                        btnType="secondary"
                        text="Confirm Changes"
                        onClick={saveProjectJokers}
                        sx={{
                          width: "140px",
                          padding: "13px 13px 13px 30px",
                          height: "32px !important",
                          padding: "0px !important",
                          // marginRight: "10px",
                          marginLeft: "15px",
                        }}
                        style={{ marginRight: "10px" }}
                      />
                    )}
                  </div>
                )}
                {selectedHorizontalTab === "Joker Analysis" && (
                  <ThresholdBar respondentCount={respondentCount} formErrors={formErrors} calculatedRespondentsObject={calculatedRespondentsObject} />
                )}
                <JokerDatabaseAndSummary
                  debouncedSearchQuery={debouncedSearchQuery}
                  projectId={projectId}
                  calculatedRespondentsObject={calculatedRespondentsObject}
                  setCalculatedRespondentsObject={setCalculatedRespondentsObject}
                  initApiFunction={getRulesFunction}
                  limit={limit}
                  offset={offset}
                  setOffset={setOffset}
                />
              </div>
            </Paper>
          </Box>
        </StyledTableContainer>
      </div>
    </>
  );
}

export default PreviewScreen;
