import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { BiError } from "react-icons/bi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdWarningAmber } from "react-icons/md";

import CloseIconSVG from "../../assets/images/CloseIconSVG";
import { closeSnackbar } from "../../features/snackbar/snackbarslice";

import "./Snackbar.css";

const SnackbarComponent = () => {
  const { snackbar } = useSelector((state) => state);
  const dispatch = useDispatch();

  const getIconAndColor = () => {
    switch (snackbar.type) {
      case "error":
        return { icon: <BiError color="#EF0707" fontSize={18} />, color: "#EF0707", title: "Error" };
      case "warning":
        return { icon: <MdWarningAmber color="#FFB665" fontSize={18} />, color: "#FFB665", title: "Warning" };
      case "success":
      default:
        return { icon: <IoMdCheckmarkCircleOutline color="#00CA9F" fontSize={18} />, color: "#00CA9F", title: "Success" };
    }
  };

  return (
    <>
      <Snackbar
        className={`snackbar snackbarMain${snackbar.type.charAt(0).toUpperCase() + snackbar.type.slice(1)}`}
        open={snackbar.open}
        message={snackbar.message}
        autoHideDuration={snackbar.autoHideDuration}
        anchorOrigin={snackbar.anchorOrigin}
        onClose={() => dispatch(closeSnackbar())}
        action={
          <React.Fragment>
            <div className="titleContent">
              <div className="messageTitle">
                {getIconAndColor().icon}
                <h3>{getIconAndColor().title}</h3>
              </div>
              <IconButton aria-label="close" color="#ffffff" sx={{ p: 0.5 }} onClick={() => dispatch(closeSnackbar())}>
                <CloseIconSVG color="#A0A4A8" />
              </IconButton>
            </div>
          </React.Fragment>
        }
      />
    </>
  );
};

export default SnackbarComponent;
