import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import CloseIconSvg from "../../../assets/images/CloseIconSVG";
import AsynSelect from "../../AsyncSelect";
import { openSnackbar } from "../../../features/snackbar/snackbarslice";
import { appendProjectCollaborators, getUsers } from "../../../services/projectService";
import { getProjectsStart } from "../../../features/product/projectSlice";
import "./AssignUserModal.css";
import { SNACKBAR_TYPES } from "../../../assets/constants/constant";

const AssignUserModal = ({
  openModal,
  setOpenModal,
  collaboratorDropDown,
  setCollaboratorDropdown,
  assignProject,
  assigned,
  setAssigned,
  currentProjectOwnerEmail,
  userEmail,
}) => {
  const dispatch = useDispatch();

  const handleLoadOptions = async (inputValue) => {
    try {
      const data = await getUsers();
      return data
        ?.filter((user) => user?.email?.toLowerCase().includes(inputValue.toLowerCase()))
        .map((user) => ({
          label: user.email,
          value: parseInt(user.id),
        }));
    } catch (error) {
      if (error?.response?.data?.data?.[0]) {
        dispatch(
          openSnackbar({
            message: error?.response?.data?.data?.[0] || "Something went wrong",
            type: SNACKBAR_TYPES.ERROR,
          })
        );
      } else {
        dispatch(
          openSnackbar({
            message: error.message || "Something went wrong",
            type: SNACKBAR_TYPES.ERROR,
          })
        );
      }
    }
  };

  const handleCloseModel = () => {
    setAssigned([]);
    setOpenModal(false);
  };

  const handleProjectAssign = async () => {
    try {
      await appendProjectCollaborators({
        project_id: assignProject,
        user_ids: assigned.map((assignee) => assignee.value),
      });
      dispatch(
        openSnackbar({
          message: "Collaborators are added",
          type: SNACKBAR_TYPES.SUCCESS,
        })
      );
      handleCloseModel();
      dispatch(getProjectsStart());
    } catch (error) {
      if (error?.response?.data?.data?.[0]) {
        dispatch(
          openSnackbar({
            message: error?.response?.data?.data?.[0] || "Something went wrong",
            type: SNACKBAR_TYPES.ERROR,
          })
        );
      } else {
        dispatch(
          openSnackbar({
            message: error.message || "Something went wrong",
            type: SNACKBAR_TYPES.ERROR,
          })
        );
      }
    }
  };
  // Custom styles for AsyncSelect
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      height: "120px", // Adjust this value to show three options at a time
      overflowY: "auto",
    }),
  };
  const [contentBgBoxHeight, setContentBgBoxHeight] = useState("145px");
  const [contentBgBoxOverflow, setContentBgBoxOverflow] = useState("hidden");

  useEffect(() => {
    if (assigned.length > 3) {
      setContentBgBoxHeight("232px");
      setContentBgBoxOverflow("auto");
    } else {
      let newHeight;
      switch (assigned.length) {
        case 1:
          newHeight = "145px";
          break;
        case 2:
          newHeight = "186px";
          break;
        case 3:
          newHeight = "232px";
          break;
        default:
          newHeight = "145px";
      }
      setContentBgBoxHeight(newHeight);
      setContentBgBoxOverflow("hidden");
    }
  }, [assigned]);

  return (
    <Modal open={openModal} onClose={handleCloseModel} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="fileUploadModal assignUserModal">
        <div className="file_header">
          <div className="attach">Assign project </div>
          <Button onClick={handleCloseModel} className="closeIconBtn mx-2">
            <CloseIconSvg fillColor="#ffffff" />
          </Button>
        </div>
        <div className="DropBox w-100 d-flex flex-column align-items-center justify-content-center p-4">
          <div className="contentBgBox" style={{ height: contentBgBoxHeight }}>
            <h4 className="contentTitle">Username/Email-ID</h4>
            <AsynSelect value={assigned} onChange={setAssigned} loadOptions={handleLoadOptions} />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-end px-4 pt-2 pb-4 gap-4">
          <Button onClick={handleCloseModel} className="close_btn">
            Cancel
          </Button>
          <Button onClick={handleProjectAssign} className="validate_btn_enabled" disabled={userEmail !== currentProjectOwnerEmail}>
            Apply
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AssignUserModal;
