import { Box, Button, Modal } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";
import "./ConfirmationModal.css";

const ConfirmationModal = ({ confirmationTitle, confirmationDescription, openConfirmation = false, setOpenConfirmation, onConfirm }) => (
  <Modal
    open={openConfirmation}
    onClose={() => setOpenConfirmation(false)}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
  >
    <Box className="ConfirmationModal">
      <Button className="closeModalBtn" onClick={() => setOpenConfirmation(false)}>
        <IoClose className="closeIcon" fontSize="18px" color="#4D4D4D" />
      </Button>
      <center className="p-4">
        <img src="./images/Delete.png" alt="Delete" />
        <h4>{confirmationTitle}</h4>
        <p>{confirmationDescription}</p>
        <div className="w-100 ">
          <Button className="btnPrimary w-100" onClick={onConfirm} variant="contained" color="primary" disableRipple>
            Yes, confirm
          </Button>
        </div>
        <div className="w-100 mt-3">
          <Button className="cancelBtn w-100" variant="outlined" color="primary" onClick={() => setOpenConfirmation(false)} disableRipple>
            No, cancel
          </Button>
        </div>
      </center>
    </Box>
  </Modal>
);

export default ConfirmationModal;
