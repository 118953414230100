import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";

import Counter from "../../../../../components/Counter/Counter";
import { formatToPrecision } from "../../../../../utils/helper";
import { setThresholdScore } from "../../../../../features/designQcRule/previewWeights/previewWeightsSlice";

const ThresholdBar = ({ respondentCount, formErrors, calculatedRespondentsObject }) => {
  // const respondentDetailsArray = useSelector((state) => state?.ruleExecution?.ruleExecutionResponse) ?? undefined;

  // const groupedRespondentDetails = _.groupBy(respondentDetailsArray, "respondent_id");
  const thresholdValue = useSelector((state) => state?.previewWeights?.thresholdScore);
  const firstCalculateButtonClicked = useSelector((state) => state?.previewWeights?.firstCalculateButtonClicked);

  const dispatch = useDispatch();
  const totalRespondentCounts = respondentCount;

  const jokersCounts = !firstCalculateButtonClicked
    ? 0
    : Object.values(calculatedRespondentsObject).reduce((sum, resObj) => sum + (resObj.exclude === true ? 0 : 1), 0);

  const calculateJokerPercentage = (jokersCounts, totalRespondentCounts) => {
    const percentage = (jokersCounts / totalRespondentCounts) * 100;
    return isNaN(percentage) ? 0 : percentage;
  };

  const jokerPercentage = calculateJokerPercentage(jokersCounts, totalRespondentCounts);

  const handleOnCounterChange = (newThresholdValue) => {
    dispatch(setThresholdScore(newThresholdValue));
  };

  return (
    <div className="thresholdscore_input">
      <div className="thresholdbtn">
        <p style={{ paddingRight: "14px", fontSize: "14px", fontWeight: "500", fontFamily: "Montserrat,sans-serif" }}>Threshold score {">="}</p>
        <div className="countbtn">
          <Counter minValue={0} maxValue={999} handleOnCounterChange={handleOnCounterChange} count={thresholdValue} />
        </div>
      </div>
      <div className="respondent_container">
        <p style={{ paddingRight: "14px" }}>Total respondents</p>
        <div className="respondent_input">
          <TextField
            type={"text"}
            autoComplete="off"
            className={`respondent_textfield` + ` ${formErrors.name && "error-border"}`}
            name="name"
            value={totalRespondentCounts}
          />
          {formErrors.name && <p className="error-font text-danger">{formErrors.name}</p>}
        </div>
      </div>

      <div className="jockercnt_container">
        <p style={{ paddingRight: "14px" }}>Joker count</p>
        <div className="respondent_input">
          <TextField
            type={"text"}
            autoComplete="off"
            className={`respondent_textfield` + ` ${formErrors.name && "error-border"}`}
            name="name"
            value={jokersCounts}
          />
          {formErrors.name && <p className="error-font text-danger">{formErrors.name}</p>}
        </div>
      </div>

      <div className="percent_container">
        <p style={{ paddingRight: "14px" }}>Percentage Value</p>
        <div className="respondent_input">
          <TextField
            type={"text"}
            autoComplete="off"
            sx={{ textAlign: "center" }}
            style={{ textAlign: "center" }}
            className={`respondent_textfield` + ` ${formErrors.name && "error-border"}`}
            name="name"
            value={`${formatToPrecision(jokerPercentage, 2)} %`}
          />
          {formErrors.name && <p className="error-font text-danger">{formErrors.name}</p>}
        </div>
      </div>
    </div>
  );
};

export default ThresholdBar;
