import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import { GoHome } from "react-icons/go";
import { BsQuestionOctagon } from "react-icons/bs";
import { IoMdNotificationsOutline } from "react-icons/io";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Button } from "@mui/material";

import "./Header.css";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { useCookies } from "react-cookie";

import { resetCreateProjectState } from "../../features/createProject/createProductSlice";
import { listLockedProjects } from "../../services/projectService";
import { getUserDataStart } from "../../features/currentUser/currentUserSlice";
import LogOutModal from "../../components/modals/LogOutModal/LogOutModal";
import { closeLoader, openLoader } from "../../features/loader/loaderSlice";
import { ROUTES } from "../../assets/constants/constant";

const HeaderComp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInformation, setUserInformation] = useState(null);
  const { user } = useSelector((state) => state);
  const [cookies, setCookie, removeCookie] = useCookies(["inizionavigator"]);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const userInfo = useSelector((state) => state?.user?.userInfo ?? "");

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getUserDataStart());
        const userData = await userInfo();
        setUserInformation(userData?.data);
        sessionStorage.setItem("userInfo", JSON.stringify(userData.data));
      } catch (error) {
        // console.error("Error fetching user information:", error);
      }
    };
    fetchData();
  }, []);

  const handleLogoutWithConfirmation = async () => {
    let isHavingLockedProjects = true;

    dispatch(openLoader());
    try {
      const responseData = await listLockedProjects(userInfo.email, userInfo.id);
      const projects = responseData?.data ?? [];
      if (projects.length === 0) isHavingLockedProjects = false;
    } catch (error) {}
    dispatch(closeLoader());

    if (isHavingLockedProjects) {
      setShowLogoutModal(true);
    } else {
      await handleLogout();
    }
  };

  const handleLogout = async () => {
    setShowLogoutModal(false);
    sessionStorage.clear();
    localStorage.clear();
    // Cookies.remove("csrftoken");
    // Cookies.remove("sessionid");
    // Cookies.remove("access-token");
    // navigate("/login");
    removeCookie(`inizionavigator`, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    window.location.href = process.env.REACT_APP_LOGIN_URL;
  };

  async function handleLogoutAndCloseProjectAnyway() {
    await handleLogout();
  }

  async function handleLogoutAndUnlockProject() {
    // dispatch(openLoader());
    try {
      // await unlockAllProject(userInfo.email, userInfo.id);
      // dispatch(closeLoader());

      // await handleLogout();
      navigate(ROUTES.HOME_PAGE);
      setShowLogoutModal(false);
    } catch (error) {
      // dispatch(openSnackbar({ message: "Unable to unlock all projects", type: SNACKBAR_TYPES.ERROR }));
    }
    // dispatch(closeLoader());
  }

  return (
    <div className="container-fluid header-container">
      <nav className="d-flex  justify-content-between align-items-center mx-auto pt-[10px]" style={{ paddingTop: "10px" }}>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-end justify-content-center flex-column">
            <div className="logoTextSize d-flex">
              <span className="logoText">Quant</span> <span className="text-green logoBold">NAV</span>
            </div>
            <span className="mb-[9px] logo d-flex w-100 justify-content-end align-items-end">inizioNavigator.ai</span>
          </div>
        </div>

        <div className="right_nav d-flex align-items-center justify-content-center gap-30">
          <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center gap-30 mr-30">
                <NavLink to="/" className={"active"} onClick={() => dispatch(resetCreateProjectState())}>
                  <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title="Home" arrow placement="bottom">
                    <span>
                      <GoHome className="headerIconStyle" />
                    </span>
                  </Tooltip>
                </NavLink>
                <NavLink to="" className="cursor-notallowed">
                  <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title="User Guide" arrow placement="bottom">
                    <span>
                      <BsQuestionOctagon className="headerIconStyle pointer-events-none disabled" />
                    </span>
                  </Tooltip>
                </NavLink>
                <NavLink to="" className="cursor-notallowed">
                  <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title="Notifications" arrow placement="bottom">
                    <span>
                      <IoMdNotificationsOutline className="headerIconStyle pointer-events-none disabled" />
                    </span>
                  </Tooltip>
                </NavLink>
              </div>
              <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title="User Profile">
                <span className="AvatarIconStyle">
                  <PersonSharpIcon />
                </span>
              </Tooltip>
              <div className="d-flex flex-column mx-2">
                <p className="d-flex align-items-start fs-14 mb-0 userName">{user?.userInfo?.name}</p>
                <p className="d-flex align-items-center fs-11 mb-0 userIdInfo">{user?.userInfo?.email}</p>
              </div>
            </div>
          </div>
          <Button variant="contained" className="iconButtonStyle" size="medium" color="secondary" onClick={() => handleLogoutWithConfirmation()}>
            <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title="Logout">
              <ExitToAppIcon className="logoutIconStyle" />
            </Tooltip>
          </Button>
        </div>
      </nav>
      <LogOutModal
        open={showLogoutModal}
        handleOnClose={() => {
          setShowLogoutModal(false);
        }}
        onCloseProjectAnyway={() => handleLogoutAndCloseProjectAnyway()}
        onUnlockProject={() => handleLogoutAndUnlockProject()}
      />
    </div>
  );
};

export default HeaderComp;
