import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/edit-3.svg";
import { ReactComponent as CopyIcon } from "../../../assets/images/Group1.svg";
import { ReactComponent as LineIcon } from "../../../assets/images/Line 18.svg";
import { ReactComponent as VectorIcon } from "../../../assets/images/Vector.svg";

import "./RuleTable.css";
import { useDispatch, useSelector } from "react-redux";

import {
  DeleteClicked,
  fetchRuleSuccessWithInitCheckedRulesReduxState,
  fetchRulesFailure,
  fetchRulesStart,
  fetchRulesSuccess,
  setCheckedRulesInRuleTable,
  setIsRuleedited,
  setIsSwitchButtonPositionLeft,
  setSelectedRule,
} from "../../../features/designQcRule/Rule/ruleSlice";
import { createRule, deleteRule, getAllRulesByProjectId } from "../../../services/projectService";
import { openSnackbar } from "../../../features/snackbar/snackbarslice";
import {
  deleteAllExceptFirst,
  initTextRuleIsGridArray,
  modifyTextRules,
  resetAllTextRules,
  updateTextRule,
  updateTextRuleName,
} from "../../../features/designQcRule/BuildRule/BuildRuleslice";
import { closeLoader, openLoader } from "../../../features/loader/loaderSlice";
import {
  deleteAdverseAllExceptFirst,
  initAdverseTextRuleIsGridArray,
  modifyAdverseTextRules,
  resetAllAdverseTextRules,
  updateAdverseTextRule,
  updateAdverseTextRuleName,
} from "../../../features/adverseEvent/RuleBuilder/RuleBuilder";
import {
  fetchAdverseRuleSuccessWithInitCheckedRulesReduxState,
  fetchAdverseRulesSuccess,
  setAdverseCheckedRulesInRuleTable,
} from "../../../features/adverseEvent/AdverseRule/AdverseRule";
import { getComparator, stableSort } from "../../../utils/sorting-helper";
import DeleteProjectModalBody from "../../modals/DeleteModal/DeleteModal";
import GeneralModal from "../../modals/GeneralModal/GeneralModal";
import CustomTooltip from "../../Tooltip/Tooltip";
import { SNACKBAR_TYPES } from "../../../assets/constants/constant";

import { DATA_MAP_FILE, Description, Last_updated, Owner, RAW_FILE, RuleTableColumns, Rule_Name, Status } from "./Rule-constant";

import { TableSortLabel } from "@mui/material";
import { useState } from "react";

// import { fetchAdverseRulesSuccess } from "../../../features/adverseEvent/AdverseRule/AdverseRule";

const headCells = [
  {
    id: "rulename",
    numeric: false,
    disablePadding: true,
    label: "Rule Name",
    width: "11%",
  },
  {
    id: "owner",
    numeric: true,
    disablePadding: false,
    label: "Owner",
    width: "11%",
  },
  {
    id: "createdon",
    numeric: true,
    disablePadding: false,
    label: "Created On",
    width: "25%",
  },
  {
    id: "lastupdated",
    numeric: true,
    disablePadding: false,
    label: "Last Updated",
    width: "25%",
  },

  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
    width: "20%",
  },
];
const StyledTableContainer = styled(TableContainer)({
  padding: "0px",
});
const CustomCheckboxrule = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked::after": {
    height: "0px !important",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 18,
  },
  "&.Mui-checked::before": {
    height: "0px !important",
  },
}));

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ position: "sticky", top: 0, zIndex: 1, height: "38px" }}>
      <TableRow sx={{ backgroundColor: "#E3E4FD" }}>
        <TableCell className="check_head" padding="none" align={"left"} sx={{ width: "0%" }}>
          <CustomCheckboxrule
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            className="rule-checkbox"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
          />
        </TableCell>
        <TableCell
          align={"left"}
          padding="none"
          className="table-head-cell-rule-name"
          sx={{
            width: "21%",
          }}
          onClick={createSortHandler(RuleTableColumns.RULE_NAME)}
        >
          {Rule_Name}
          <TableSortLabel active={orderBy === RuleTableColumns.RULE_NAME} direction={orderBy === RuleTableColumns.RULE_NAME ? order : "asc"} />
        </TableCell>
        <TableCell
          align={"left"}
          // padding={headCell.disablePadding ? 'none' : 'normal'}
          padding="none"
          className="table-head-cell description"
          sx={{
            width: "11%",
          }}
          onClick={createSortHandler(RuleTableColumns.RULE_DESCRIPTION)}
        >
          {Description}
          <TableSortLabel active={orderBy === RuleTableColumns.RULE_DESCRIPTION} direction={orderBy === RuleTableColumns.RULE_DESCRIPTION ? order : "asc"} />
        </TableCell>
        <TableCell
          align={"left"}
          // padding={headCell.disablePadding ? 'none' : 'normal'}
          padding="none"
          className="table-head-cell owner-name"
          sx={
            {
              // width: "11%",
            }
          }
          onClick={createSortHandler(RuleTableColumns.RULE_OWNER)}
        >
          {Owner}
          <TableSortLabel active={orderBy === RuleTableColumns.RULE_OWNER} direction={orderBy === RuleTableColumns.RULE_OWNER ? order : "asc"} />
        </TableCell>
        <TableCell
          align={"left"}
          // padding={headCell.disablePadding ? 'none' : 'normal'}
          padding="none"
          className="table-head-cell owner-name"
          sx={
            {
              // width: "11%",
            }
          }
          // onClick={createSortHandler(RuleTableColumns.RULE_OWNER)}
        >
          {RAW_FILE}
          <TableSortLabel active={orderBy === RuleTableColumns.RULE_OWNER} direction={orderBy === RuleTableColumns.RULE_OWNER ? order : "asc"} />
        </TableCell>
        <TableCell
          align={"left"}
          // padding={headCell.disablePadding ? 'none' : 'normal'}
          padding="none"
          className="table-head-cell owner-name"
          sx={
            {
              // width: "11%",
            }
          }
          // onClick={createSortHandler(RuleTableColumns.RULE_OWNER)}
        >
          {DATA_MAP_FILE}
          <TableSortLabel active={orderBy === RuleTableColumns.RULE_OWNER} direction={orderBy === RuleTableColumns.RULE_OWNER ? order : "asc"} />
        </TableCell>

        <TableCell
          align={"left"}
          // padding={headCell.disablePadding ? 'none' : 'normal'}
          padding="none"
          className="table-head-cell status-name"
          sx={
            {
              // width: "11%",
            }
          }
        ></TableCell>

        <TableCell
          align={"left"}
          // padding={headCell.disablePadding ? 'none' : 'normal'}
          padding="none"
          className="table-head-cell-update"
          sx={{
            width: "18%",
          }}
          onClick={createSortHandler(RuleTableColumns.RULE_UPDATED)}
        >
          {Last_updated}
          <TableSortLabel active={orderBy === RuleTableColumns.RULE_UPDATED} direction={orderBy === RuleTableColumns.RULE_UPDATED ? order : "asc"} />
        </TableCell>
        <TableCell
          align={"left"}
          // padding={headCell.disablePadding ? 'none' : 'normal'}
          padding="none"
          className="table-head-cell"
          sx={{
            width: "18%",
          }}
        >
          {Status}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function RuleTable({ selectedOwner, debouncedSearchQuery = "" }) {
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const dispatch = useDispatch();
  const isRuleModified = useSelector((state) => state.rules.isRuleModified);
  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);
  const username = useSelector((state) => state?.user?.userInfo?.email) ?? "";

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const selected = useSelector((state) => state?.rules?.checkedRulesInRuleTable ?? "");
  const adverseSelected = useSelector((state) => state?.adverseRule?.adversecheckedRulesInRuleTable);

  const [editValue, setEditValue] = React.useState("");
  const rules = useSelector((state) => state?.rules?.rules);
  const adverseRules = useSelector((state) => state?.adverseRule?.adverserules);
  const [openDeleterModal, setOpenDeleterModal] = useState(false);
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  const [deleteID, setDeleteID] = useState(null);
  const textRules = useSelector((state) => state?.text?.textRules);
  const adverseTextRules = useSelector((state) => state?.adversetext?.textRules);

  const handleSelectAllClick = (event) => {
    if (selectedHorizontalTab == "Adverse Events") {
      if (event.target.checked) {
        const newSelected = adverseRules.map((rule) => rule.id);
        dispatch(setAdverseCheckedRulesInRuleTable(newSelected));
        return;
      }
      dispatch(setAdverseCheckedRulesInRuleTable([]));
    } else {
      if (event.target.checked) {
        const newSelected = rules.map((rule) => rule.id);
        dispatch(setCheckedRulesInRuleTable(newSelected));
        return;
      }
      dispatch(setCheckedRulesInRuleTable([]));
    }
  };

  // handle row click
  const handleClick = (event, id) => {
    let newSelected = [];
    if (selectedHorizontalTab == "Adverse Events") {
      if (adverseSelected?.includes(id)) {
        newSelected = adverseSelected.filter((selectedId) => selectedId !== id);
      } else {
        newSelected = [...adverseSelected, id];
      }

      dispatch(setAdverseCheckedRulesInRuleTable(newSelected));
    } else {
      if (selected.includes(id)) {
        newSelected = selected.filter((selectedId) => selectedId !== id);
      } else {
        newSelected = [...selected, id];
      }

      dispatch(setCheckedRulesInRuleTable(newSelected));
    }
  };

  // const isSelected = (id) => selected.indexOf(id) !== -1;
  const isSelected = (id) => (selectedHorizontalTab == "Adverse Events" ? adverseSelected?.includes(id) : selected?.includes(id));

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rules.length) : 0;

  // Get rule data from the API
  React.useEffect(() => {
    const getRulesFunction = async () => {
      try {
        dispatch(openLoader());
        let data;
        if (selectedHorizontalTab === "Adverse Events") {
          // data = AdverseRuleTable.data;
          const ruleType = "ADVERSE_EVENT";
          const response = await getAllRulesByProjectId(projectId, ruleType);
          dispatch(fetchAdverseRuleSuccessWithInitCheckedRulesReduxState(data?.items));
          data = response.data;
          const autoSelectedRules = [];
          if (data && data.items && data.items.length) {
            data.items.forEach((item) => {
              if (item.is_rule_active) {
                autoSelectedRules.push(item.id);
              }
            });
          }
          dispatch(setAdverseCheckedRulesInRuleTable(autoSelectedRules));
          dispatch(fetchAdverseRulesSuccess(data?.items));
        } else {
          const ruleType = "JOKER_ANALYSIS";
          const response = await getAllRulesByProjectId(projectId, ruleType);
          dispatch(fetchRuleSuccessWithInitCheckedRulesReduxState(data?.items));
          data = response.data;
          const autoSelectedRules = [];
          if (data && data.items && data.items.length) {
            data.items.forEach((item) => {
              if (item.is_rule_active) {
                autoSelectedRules.push(item.id);
              }
            });
          }
          dispatch(setCheckedRulesInRuleTable(autoSelectedRules));
          dispatch(fetchRulesSuccess(data?.items));
        }
      } catch (error) {
        dispatch(fetchRulesSuccess([]));
        dispatch(fetchAdverseRulesSuccess([]));
        dispatch(openSnackbar({ message: "Failed to fetch rules", type: SNACKBAR_TYPES.ERROR }));
      } finally {
        dispatch(closeLoader());
      }
    };

    getRulesFunction();
  }, []);

  // Delete rule function
  const handleDelete = async (id) => {
    setOpenDeleterModal(true);
    setDeleteID(id);
  };

  const deleteQuestiobnHandler = async () => {
    try {
      dispatch(openLoader());
      const ruleType = selectedHorizontalTab === "Adverse Events" ? "ADVERSE_EVENT" : "JOKER_ANALYSIS";
      await deleteRule(deleteID);

      dispatch(fetchRulesStart());
      const { data } = await getAllRulesByProjectId(projectId, ruleType);
      if (selectedHorizontalTab === "Adverse Events") {
        const updatedTextRules = adverseTextRules.filter((rule) => rule.id !== deleteID);
        dispatch(modifyAdverseTextRules(updatedTextRules));
        dispatch(fetchAdverseRulesSuccess(data?.items));
      } else {
        const updatedTextRules = textRules.filter((rule) => rule.id !== deleteID);
        dispatch(modifyTextRules(updatedTextRules));
        dispatch(fetchRulesSuccess(data?.items));
      }
      dispatch(DeleteClicked(true));

      // dispatch(fetchRulesSuccess(data?.items));
      dispatch(openSnackbar({ message: "Rule deleted successfully", type: SNACKBAR_TYPES.SUCCESS }));
      cancellDeleteHandler();
    } catch (error) {
      dispatch(fetchRulesFailure(error.message));
      dispatch(openSnackbar({ message: "Failed to delete rule", type: SNACKBAR_TYPES.ERROR }));
    } finally {
      dispatch(closeLoader());
    }
  };
  // Copy rule function
  const handleCopy = async (row) => {
    try {
      dispatch(openLoader());
      const ruleType = selectedHorizontalTab === "Adverse Events" ? "ADVERSE_EVENT" : "JOKER_ANALYSIS";
      const createrule = [
        {
          rule_name: `${row.rule_name} - ${new Date().toLocaleString()}`,
          project_id: row.project_id,
          rule: row.rule,
          rule_type: ruleType,
          weight: row.weight,
          rule_type: ruleType,
          optionsMeta: row?.optionsMeta ?? {},
          questionsMeta: row?.questionsMeta ?? {},
          is_rule_active: row.is_rule_active,
        },
      ];
      const newRuleData = {
        rules: createrule,
        updated_by: username,
      };

      const createdRule = await createRule(newRuleData);

      const { data } = await getAllRulesByProjectId(projectId, ruleType);
      if (selectedHorizontalTab === "Adverse Events") {
        dispatch(fetchAdverseRulesSuccess(data?.items));
      } else {
        dispatch(fetchRulesSuccess(data?.items));
      }

      dispatch(openSnackbar({ message: "Rule copied successfully", type: SNACKBAR_TYPES.SUCCESS }));
    } catch (error) {
      dispatch(openSnackbar({ message: "Failed to copy rule", type: SNACKBAR_TYPES.ERROR }));
    } finally {
      dispatch(closeLoader());
    }
  };
  // Handle editing a rule

  const handleEdit = (row) => {
    try {
      dispatch(resetAllTextRules());
      dispatch(resetAllAdverseTextRules());
      dispatch(setSelectedRule(row));
      dispatch(setIsRuleedited(true));
      if (selectedHorizontalTab === "Adverse Events") {
        dispatch(initAdverseTextRuleIsGridArray({ ruleIndex: 0, textRule: row?.rule, questionMetaData: row?.questionsMeta }));
        dispatch(updateAdverseTextRule({ newIndex1: 0, newValue: row?.rule, id: row?.id, questionsMeta: row?.questionsMeta, optionsMeta: row?.optionsMeta }));
        dispatch(
          updateAdverseTextRuleName({ newIndex: 0, newValue: row?.rule_name, id: row?.id, questionsMeta: row?.questionsMeta, optionsMeta: row?.optionsMeta })
        );
        dispatch(deleteAdverseAllExceptFirst());
        dispatch(setIsSwitchButtonPositionLeft(true));
      } else if (selectedHorizontalTab === "Joker Analysis") {
        dispatch(initTextRuleIsGridArray({ ruleIndex: 0, textRule: row?.rule, questionMetaData: row?.questionsMeta }));
        dispatch(updateTextRule({ newIndex1: 0, newValue: row?.rule, id: row?.id, questionsMeta: row?.questionsMeta, optionsMeta: row?.optionsMeta }));
        dispatch(updateTextRule({ newIndex1: 0, newValue: row?.rule, id: row?.id, questionsMeta: row?.questionsMeta, optionsMeta: row?.optionsMeta }));
        dispatch(updateTextRuleName({ newIndex: 0, newValue: row?.rule_name, id: row?.id, questionsMeta: row?.questionsMeta, optionsMeta: row?.optionsMeta }));
        dispatch(deleteAllExceptFirst());
        dispatch(setIsSwitchButtonPositionLeft(true));
      }
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
    }
  };

  React.useEffect(() => {
    if (editValue === "a") {
      throw new Error();
    }
    setEditValue("");
  }, [editValue]);

  // formate date function
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric", hour: "numeric", minute: "numeric", hour12: true };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  // filter rules based on selected owner
  const filteredRules =
    selectedHorizontalTab === "Joker Analysis"
      ? selectedOwner
        ? rules.filter((rule) => rule?.created_by === selectedOwner)
        : rules
      : selectedOwner
        ? adverseRules.filter((rule) => rule?.created_by === selectedOwner)
        : adverseRules;
  // Custom styled checkbox

  const sortedRows = stableSort(filteredRules, getComparator(order, orderBy));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const cancellDeleteHandler = () => {
    setOpenDeleterModal(false);
    setDeleteID(null);
  };

  return (
    <>
      <GeneralModal
        modalBody={<DeleteProjectModalBody deleteQuestiobnHandler={deleteQuestiobnHandler} cancellDeleteHandler={cancellDeleteHandler} />}
        open={openDeleterModal}
        className="w-[340px] h-[340px] rounded bg-white flex flex-col justify-between"
      />
      <StyledTableContainer className="rulecontainer">
        <Box sx={{ width: "100%", padding: "0px" }}>
          <Paper className="rule_paper" sx={{ width: "100%", mb: 2 }}>
            <TableContainer style={{ maxHeight: "calc(100vh - 353px)", overflow: "auto", position: "relative" }}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                {selectedHorizontalTab === "Joker Analysis" ? (
                  <EnhancedTableHead
                    numSelected={selected?.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={filteredRules?.length}
                    onRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    fontFamily="Montserrat, sans-serif"
                  />
                ) : (
                  <EnhancedTableHead
                    numSelected={adverseSelected?.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={filteredRules?.length}
                    onRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    fontFamily="Montserrat, sans-serif"
                  />
                )}
                <TableBody
                  style={{
                    height: "48px",
                    overflow: "auto",
                  }}
                >
                  {sortedRows
                    .filter((elem) => {
                      if (debouncedSearchQuery === "") {
                        return elem;
                      } else if (
                        elem?.rule_name?.toLowerCase()?.includes(debouncedSearchQuery?.toLowerCase()) ||
                        elem?.rule?.toLowerCase()?.includes(debouncedSearchQuery?.toLowerCase())
                      ) {
                        return elem;
                      }
                    })
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          //   onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer", height: "75px", fontFamily: "Montserrat, sans-serif", fontSize: "22px" }}
                          className="row-data"
                        >
                          <TableCell padding="none" className="check_head" style={{ height: "48px", padding: "0px" }}>
                            <CustomCheckboxrule
                              color="primary"
                              checked={isItemSelected}
                              onChange={(e) => handleClick(e, row.id)}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              className="rule-checkbox"
                              sx={{ border: "2px", "& .MuiSvgIcon-root": { fontSize: 18 } }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="left"
                            style={{ height: "48px", padding: "0px" }}
                            sx={{
                              width: "11%",
                            }}
                            className="rule-name"
                          >
                            <CustomTooltip title={row.rule_name} arrow>
                              <span>{row.rule_name}</span>
                            </CustomTooltip>
                            {/* {row.rule_name} */}
                          </TableCell>
                          <TableCell align="left" style={{ height: "48px", padding: "0px" }} className="description">
                            <CustomTooltip title={row.rule} arrow>
                              <span>{row.rule}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell align="left" className="owner-name" style={{ height: "48px", padding: "0px" }}>
                            <CustomTooltip title={row.created_by} arrow>
                              <span>{row.created_by}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell align="left" className="owner-name" style={{ height: "48px", padding: "0px" }}>
                            <CustomTooltip title={row?.rawFile?.name} arrow>
                              <span>{row?.rawFile?.name || ""}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell align="left" className="owner-name" style={{ height: "48px", padding: "0px" }}>
                            <CustomTooltip title={row?.dataMapFile?.name} arrow>
                              <span>{row?.dataMapFile?.name || ""}</span>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell align="left" style={{ color: row?.is_rule_active && row?.is_valid ? "#14804A" : "inherit" }} className="status-name">
                            <div className="status">{isItemSelected && (row?.is_rule_active && row?.is_valid ? "Active" : "")}</div>
                          </TableCell>

                          <TableCell align="left" className="Update-date">
                            {formatDate(row.updated_at)}
                          </TableCell>

                          <TableCell sx={{ backgroundColor: "#f4f4fd" }} align="left" style={{ height: "48px", padding: "0px" }}>
                            <div className="icons w-[90%] px-[5%] flex">
                              <CustomTooltip title="Delete">
                                <IconButton onClick={() => handleDelete(row.id)} className="delete-icon">
                                  <DeleteIcon />
                                  <VectorIcon className="vector-icon" />
                                </IconButton>
                              </CustomTooltip>
                              <LineIcon sx={{ marginTop: "6px", marginBottom: "6px" }} />
                              <CustomTooltip title="Edit">
                                <IconButton>
                                  <EditIcon onClick={() => handleEdit(row)} />
                                </IconButton>
                              </CustomTooltip>
                              <LineIcon sx={{ marginTop: "6px", marginBottom: "6px" }} />
                              <CustomTooltip title="Copy">
                                <IconButton onClick={() => handleCopy(row)}>
                                  <CopyIcon />
                                </IconButton>
                              </CustomTooltip>
                              {/* <LineIcon sx={{ marginTop: "6px", marginBottom: "6px" }} />
                            <Tooltip title="Edit">
                              <IconButton>
                                <UploadIcon onClick={() => handleEdit(row)} />
                              </IconButton>
                            </Tooltip> */}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </StyledTableContainer>
    </>
  );
}
