import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import "./Pagination.css";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";

import TextField from "../../components/TextField/TextField";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  alignItems: "center",
  columnGap: 4,
});

const Pagination = React.memo(({ items = [], handlePagination, pageValue, setPageValue, data, selectedPage, setSelectedPage }) => {
  function handleInputChange(event) {
    const pageOffset = event.target.value;
    if (pageOffset <= items.length - 2) {
      setSelectedPage(pageOffset ? pageOffset : 1);
      handlePagination(pageOffset);
      setPageValue(pageOffset);
    }
  }
  let pageOfResult = null; // Initialize result variable outside of the if block
  const pageCalc = items[0]?.page;
  if (typeof pageCalc === "number" && !isNaN(pageCalc)) {
    pageOfResult = pageCalc * 10 + 1; // Assign the value to result
  }
  let pageEndOFResult = null;
  const itemCount = data?.count;
  if (typeof pageCalc === "number" && !isNaN(pageCalc) && typeof itemCount === "number" && !isNaN(itemCount)) {
    pageEndOFResult = Math.min((pageCalc + 1) * 10, itemCount); // Assign the value to result
  }
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div className="left-pagination">
        <span className="d-flex align-items-center page-results">
          Showing <span className="page-count">{pageOfResult}</span> to <span className="page-count">{pageEndOFResult}</span> of{" "}
          <span className="page-count">{data?.count}</span> results
        </span>
      </div>
      <nav className="d-flex align-items-center gap-3">
        <List>
          {items?.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "…";
            } else if (type === "page") {
              children = (
                <Button
                  key={index}
                  type="button"
                  className="pagination__page"
                  variant="contained"
                  style={{
                    background: selectedPage == page ? "4F46E5" : "transparent",
                    color: selectedPage == page ? "#fff" : "#25282B",
                  }}
                  {...item}
                  // onClick={() => handlePagination(page)}
                >
                  {page}
                </Button>
              );
            } else if (type === "previous") {
              children = (
                <Button
                  variant="outlined"
                  className="pagination__pagecontrol"
                  key={index}
                  type="button"
                  {...item}
                  // disabled={page === 1}
                  // onClick={() => handlePagination(page)}
                >
                  <IoIosArrowRoundBack fontSize={24} />
                  <span className="d-md-none d-lg-block">{type}</span>
                </Button>
              );
            } else if (type === "next") {
              children = (
                <Button variant="outlined" className="pagination__pagecontrol" key={index} type="button" {...item}>
                  <span className="d-md-none d-lg-block">{type}</span> <IoIosArrowRoundForward fontSize={24} />
                </Button>
              );
            }
            return <li key={index}>{children}</li>;
          })}
        </List>
        <div className="pageInput">
          <label className="inputLabel">Go to</label>
          <TextField
            id="standard-basic"
            label=""
            className="form-control"
            autoComplete="off"
            placeholder="Number"
            value={pageValue}
            onChange={handleInputChange}
          />
        </div>
      </nav>
    </div>
  );
});

export default Pagination;
