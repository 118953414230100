import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";

import { formatDate } from "../../../assets/utils/utils";

const SavedFileTable = ({ setSelectedRow }) => {
  const { createProjectDetails } = useSelector((state) => state);
  // const [selectedRow, setSelectedRow] = useState(null);
  const columns = [
    {
      field: "selection",
      headerName: "Select",
      flex: 0.25,
      minWidth: 75,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => <input type="radio" name="radio" value={params.row} onChange={() => setSelectedRow(params.row)} />,
    },
    {
      field: "dataMapName",
      headerName: "Data Map File",
      flex: 0.9,
      minWidth: 250,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "rawDataName",
      headerName: "Survey File",
      flex: 0.9,
      minWidth: 250,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    { field: "owner", headerName: "Owner", flex: 0.5, minWidth: 120, headerClassName: "quant-app-theme--header", cellClassName: "quant-app-theme--cell" },
    {
      field: "createdAt",
      headerName: "Uploaded On",
      flex: 0.85,
      minWidth: 220,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => <div className="dateIcon">{formatDate(params.row.createdAt)}</div>,
    },
  ];

  // const handleSelectionChange = (newSelection) => {
  //   setSelectedRow(newSelection.length > 0 ? newSelection[0].id : null);
  // };

  return (
    <div className="w-100 h-100">
      <DataGrid
        rowHeight={48}
        rows={
          createProjectDetails?.files?.length > 0 &&
          createProjectDetails?.files?.map((item, index) => ({
            ...item,
            id: item.rawDataId + item.mapDataId,
            key: item.rawDataId + item.mapDataId,
          }))
        }
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
        checkboxSelection={false}
        // selectionModel={selectedRow ? [{ id: selectedRow }] : []}
        // onRowSelectionModelChange={handleSelectionChange}
        disableRowSelectionOnClick={true}
      />
    </div>
  );
};

export default SavedFileTable;
