import { createSlice } from "@reduxjs/toolkit";

import { initTextRuleGridArray } from "../../../pages/DesignQC/JockerAnalysis/BuildQCRule/helper/bulidQcRuleHelper";
import { QUESTION_TYPES } from "../../../components/modals/QuestionLibrary/questionLibrary.constant";

const initialState = {
  textRules: [{ rule: "", rule_name: "", id: null, isGrid: [], questionsMeta: {}, optionsMeta: {}, history: [], isExpanded: true }],
  addRuleScrollTrigger: false,
  checkGrid: [],
};

const adversetextSlice = createSlice({
  name: "adversetext",
  initialState,
  reducers: {
    updateAdverseRulequestiontype(state, action) {
      const newArray = [...state.checkGrid];
      newArray.push(action.payload);
      state.checkGrid = newArray;
    },
    undoAdverseTextRule(state, action) {
      const { newIndex1 } = action.payload;
      if (state?.textRules[newIndex1]?.history?.length > 0) {
        const newRules = [...state.textRules];
        let tempRule = newRules[newIndex1];
        const [latestState, ...restHistory] = tempRule.history;
        const newRule = restHistory[0];
        if (restHistory.length == 0) {
          tempRule = { ...tempRule, history: restHistory };
        } else if (restHistory.length == 1) {
          tempRule = { ...tempRule, rule: newRule, history: [newRule] };
        } else {
          tempRule = { ...tempRule, rule: newRule, history: restHistory };
        }

        newRules[newIndex1] = tempRule;
        state.textRules = newRules;
      }
    },
    initAdverseTextRuleIsGridArray(state, action) {
      const ruleIndex = action?.payload?.ruleIndex;
      const textRule = action?.payload?.textRule;
      const questionMetaData = action?.payload?.questionMetaData;

      if (state.textRules[ruleIndex]) {
        state.textRules[ruleIndex].isGrid = initTextRuleGridArray(textRule, questionMetaData);
      }
    },
    updateAdverseTextRule(state, action) {
      const { newIndex1, newValue, gridRule, newGridValue } = action.payload;
      const questionsMeta = action?.payload?.questionsMeta;
      const optionsMeta = action?.payload?.optionsMeta;

      const id = action?.payload?.id ?? null;
      const newRules = [...state.textRules];
      if (state.textRules[newIndex1]) {
        newRules[newIndex1].rule = newValue;
        const prevHistory = newRules[newIndex1].history;
        let newHistory = [newValue, ...prevHistory];
        newHistory = newHistory.length > 4 ? newHistory.slice(0, 4) : newHistory;
        newRules[newIndex1].history = newHistory;
        if (gridRule) {
          if (gridRule == "grid" || gridRule == "single" || gridRule == "multi" || gridRule == "opentext" || gridRule == QUESTION_TYPES.OPEN_TEXT_LIST) {
            // if(!newRules[newIndex1].isGrid.some(obj => obj.rule === newGridValue)){
            const obj = { rule: newGridValue, grid: true };
            newRules[newIndex1].isGrid = [...newRules[newIndex1].isGrid, obj];
            // }
          } else {
            // if(!newRules[newIndex1].isGrid.some(obj => obj.rule === newGridValue)){
            const obj = { rule: newGridValue, grid: false };
            newRules[newIndex1].isGrid = [...newRules[newIndex1].isGrid, obj];
            // }
          }
        }
        if (!gridRule && !newGridValue) {
          const newArray = [];
          const splitArray = newValue.split(" ");
          const gridArray = newRules[newIndex1].isGrid;
          for (const ele of splitArray) {
            if (gridArray.some((obj) => obj.rule === ele && obj.grid == true)) {
              newArray.push({ rule: ele, grid: true });
            }
            if (gridArray.some((obj) => obj.rule === ele && obj.grid == false)) {
              newArray.push({ rule: ele, grid: false });
            }
          }
          newRules[newIndex1].isGrid = newArray;
        }
        if (id) newRules[newIndex1].id = id;
        if (questionsMeta) newRules[newIndex1].questionsMeta = questionsMeta;
        if (optionsMeta) newRules[newIndex1].optionsMeta = optionsMeta;
        state.textRules = newRules;
      } else {
        console.error(`Cannot update text rule at index ${newIndex1}. Index out of bounds.`);
      }
    },
    updateAdverseTextRuleMetaData(state, action) {
      const { index, meta, questionId, optionsMeta } = action.payload;

      const selectedRule = JSON.parse(JSON.stringify(state?.textRules?.[index])) ?? {};

      selectedRule.questionsMeta = selectedRule.questionsMeta ?? {};
      selectedRule.questionsMeta[questionId] = meta;

      let newOptionsMeta = selectedRule.optionsMeta ?? {};
      newOptionsMeta = { ...newOptionsMeta, ...optionsMeta };

      selectedRule.optionsMeta = newOptionsMeta;

      if (state?.textRules?.[index]) {
        state.textRules[index] = selectedRule;
      }
    },
    updateAdverseTextRuleName(state, action) {
      const { newIndex, newValue } = action.payload;
      const id = action?.payload?.id ?? null;
      const newRules = [...state.textRules];

      if (state.textRules[newIndex]) {
        newRules[newIndex].rule_name = newValue;
        if (id) newRules[newIndex].id = id;
        state.textRules = newRules;
      } else {
        console.error(`Cannot update text rule name at index ${newIndex}. Index out of bounds.`);
      }
    },
    addAdverseTextRule(state, action) {
      const updatedTextRules = state.textRules.map((rule, index) => ({
        ...rule,
        isExpanded: false,
      }));
      updatedTextRules.push({ rule: "", rule_name: "", id: null, isGrid: [], questionsMeta: {}, history: [], optionsMeta: {}, isExpanded: true });
      state.textRules = updatedTextRules;
      // state.textRules.push({ rule: "", rule_name: "", id: null, isGrid: [], questionsMeta: {}, optionsMeta: {}, isExpanded: true });
    },
    deleteAdverseTextRule(state, action) {
      const { index } = action.payload;
      if (state.textRules.length > 1) {
        state.textRules.splice(index, 1);
      } else {
        console.error("Cannot delete the last accordion.");
      }
    },
    resetAdverseTextRule(state, action) {
      const { index } = action.payload;
      if (state.textRules[index]) {
        state.textRules[index] = { rule: "", rule_name: "", id: null, isGrid: [], questionsMeta: {}, history: [], optionsMeta: {}, isExpanded: true };
      } else {
        console.error(`Cannot reset text rule at index ${index}. Index out of bounds.`);
      }
    },
    resetAllAdverseTextRules(state) {
      state.textRules = state.textRules.map(() => ({
        rule: "",
        rule_name: "",
        id: null,
        isGrid: [],
        questionsMeta: {},
        history: [],
        optionsMeta: {},
        isExpanded: true,
      }));
    },
    resetEntireAdverseTextRules(state) {
      state.textRules = initialState.textRules;
    },
    deleteAdverseAllExceptFirst(state) {
      state.textRules = state.textRules.slice(0, 1);
    },
    updateAdverseRuleIds(state, action) {
      const { ids } = action.payload;
      state.textRules = state.textRules.map((rule, index) => ({
        ...rule,
        id: ids[index] ?? rule.id,
      }));
    },
    setIsExpandedAdverse(state, action) {
      const updatedTextRules = state.textRules.map((rule, index) => {
        if (index == action.payload.index) {
          return {
            ...rule,
            isExpanded: !rule.isExpanded,
          };
        }
        return rule;
      });

      state.textRules = updatedTextRules;
    },
    modifyAdverseTextRules(state, action) {
      if (action.payload.length === 0) {
        state.textRules = [{ rule: "", rule_name: "", id: null, isGrid: [], questionsMeta: {}, optionsMeta: {}, isExpanded: true }];
      } else {
        state.textRules = action.payload;
      }
    },
    startSaveAdverseRules(state, action) {},
  },
});

export const {
  updateAdverseRulequestiontype,
  undoAdverseTextRule,
  modifyAdverseTextRules,
  updateAdverseRuleIds,
  initAdverseTextRuleIsGridArray,
  deleteAdverseAllExceptFirst,
  updateAdverseTextRule,
  updateAdverseTextRuleMetaData,
  updateAdverseTextRuleName,
  addAdverseTextRule,
  deleteAdverseTextRule,
  resetAdverseTextRule,
  resetAllAdverseTextRules,
  resetEntireAdverseTextRules,
  setIsExpandedAdverse,
  startSaveAdverseRules,
} = adversetextSlice.actions;
export default adversetextSlice.reducer;
