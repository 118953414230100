// SearchComponent.jsx

import React from "react";

import SearchIcon from "../../assets/images/search.svg";
import "./SearchBox.css";

const SearchComponent = ({ value, onChange, width, height }) => (
  <div className="searchBoxContainer">
    <span className="searchIcon" style={{ backgroundImage: `url(${SearchIcon})` }}></span>
    <input
      type="text"
      className="searchBox"
      style={{ width: width || "198px", height: height || "40px" }}
      value={value}
      onChange={onChange}
      placeholder="Search"
      autoComplete="off"
    />
  </div>
);

export default SearchComponent;
