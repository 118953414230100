import { call, put, takeEvery } from "redux-saga/effects";

import { fetchProjectsById } from "../../services/projectService";
import { allValidationFileError, capitalize } from "../../assets/utils/utils";

import {
  createProjectError,
  createProjectStart,
  createProjectSuccess,
  getRawDataFilesSuccess,
  setSelectedFiles,
  setShowSelectedError,
  showSelectedFilesSuccess,
} from "./createProductSlice";

function* getProjectDetailsSaga(action) {
  try {
    const data = yield call(() => fetchProjectsById(action.payload));
    const { files } = data;
    const newFiles = [];
    for (let i = 0; i < files.length; i++) {
      const fileI = files[i];

      // Skip if the file is not of type 'RAW'
      if (fileI.type !== "RAW") {
        continue;
      }

      for (let j = 0; j < files.length; j++) {
        const fileJ = files[j];

        // Skip if the file is not of type 'DATAMAP'
        if (fileJ.type !== "DATAMAP") {
          continue;
        }

        if (
          fileI.pair_name === fileJ.pair_name &&
          (fileI.status === "LOADED" || fileI.status === "MERGED" || fileI.status === "ERROR") &&
          (fileJ.status === "LOADED" || fileJ.status === "MERGED" || fileJ.status === "ERROR")
        ) {
          const fileObj = {
            rawDataId: fileI.id,
            mapDataId: fileJ.id,
            rawDataSize: fileI.file_size,
            mapDataSize: fileJ.file_size,
            rawDataName: fileI.name,
            dataMapName: fileJ.name,
            dataFileType: capitalize(fileI?.data_file_type),
            dataFileStatus: capitalize(fileJ.data_status_type),
            rawFileOverallStatus: fileI.status,
            dataFileOverallStatus: fileJ.status,
            owner: fileI.uploaded_by.name,
            createdAt: fileI.created_at,
          };

          newFiles.push(fileObj);
          break;
        }
      }
    }
    yield put(createProjectSuccess(data));
    yield put(getRawDataFilesSuccess(newFiles));
    if (data.files?.length > 0) {
      const { isValid: valid, isError } = allValidationFileError(data.active_data_map_file, data.active_raw_file);
      let activeRawDataFile = "";
      let activeDataMapFile = "";
      if (valid) {
        yield put(showSelectedFilesSuccess(true));
        activeRawDataFile = {
          ...data?.active_raw_file,
          data_file_type: capitalize(data?.active_raw_file?.data_file_type),
          data_status_type: capitalize(data?.active_raw_file?.data_status_type),
        };

        activeDataMapFile = {
          ...data?.active_data_map_file,
          data_file_type: capitalize(data?.active_data_map_file?.data_file_type),
          data_status_type: capitalize(data?.active_data_map_file?.data_status_type),
        };
      }

      if (isError) {
        yield put(setShowSelectedError());
      }
      yield put(
        setSelectedFiles({
          activeRawDataFile: activeRawDataFile,
          activeDataMapFile: activeDataMapFile,
        })
      );
    } else {
      yield put(showSelectedFilesSuccess(false));
    }
  } catch (error) {
    yield put(createProjectError(error));
  }
}

export function* watchCreateProjectSaga() {
  yield takeEvery(createProjectStart, getProjectDetailsSaga);
}
