import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TripOriginOutlinedIcon from "@mui/icons-material/TripOriginOutlined";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { styled } from "@mui/material/styles";
import { StepConnector, stepConnectorClasses } from "@mui/material";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 7,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00CA9F",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00CA9F",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#AAB3C0",
    borderTopWidth: 2,
    padding: 4,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderRadius: 1,
    borderStyle: "dotted",
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: ownerState.active ? "#4F46E5" : "#AAB3C0",
  display: "flex",
  height: 16,
  alignItems: "center",
}));

const QontoStepIconCompleted = styled(CheckCircleIcon)(({ theme }) => ({
  color: "#00CA9F",
  zIndex: 1,
  fontSize: 18,
  width: 16,
  height: 16,
}));

const QontoStepIconCircle = styled(RadioButtonCheckedIcon)(({ theme }) => ({
  width: 16,
  height: 16,
}));
const QontoStepIconCircleOutline = styled(TripOriginOutlinedIcon)(({ theme }) => ({
  width: 16,
  height: 16,
}));

const CustomStepLabel = styled(StepLabel)(({ theme, ownerState }) => ({
  color: ownerState.active ? "#4F46E5 !important" : ownerState.completed ? "#00CA9F !important" : "#AAB3C0 !important",
}));

function QontoStepIcon(props) {
  const { active, completed } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }}>
      {active ? <QontoStepIconCircleOutline /> : completed ? <QontoStepIconCompleted /> : <QontoStepIconCircle />}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const steps = ["Project Details", "EDA & Design QC", "Create Your Features", "Design Analysis", "Final Output"];

function StepperForm() {
  const { step } = useParams();
  const activeStep = parseInt(step);

  return (
    <>
      <div className=" w-100">
        <div className="stepper_label row"></div>
        <Box>
          <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
            {steps.map((label, index) => (
              <Step key={label}>
                <CustomStepLabel
                  StepIconComponent={QontoStepIcon}
                  // completed={index < activeStep}
                  ownerState={{
                    active: index === activeStep,
                    completed: index < activeStep,
                  }}
                >
                  {label}
                </CustomStepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>
    </>
  );
}

export default StepperForm;
