import React, { Fragment } from "react";
import { Rings } from "react-loader-spinner";
import "./style.css";
import { useSelector } from "react-redux";

export default function Loader() {
  const { loader } = useSelector((state) => state);
  return (
    <>
      {loader.open && (
        <div className="w-screen z-10000 fixed top-0 left-0 h-screen flex items-center justify-center bg-white/80">
          <Rings visible={loader.open} height="80" width="80" color="#4f46e595" ariaLabel="rings-loading" wrapperStyle={{}} wrapperClass="" />
        </div>
      )}
    </>
  );
}
