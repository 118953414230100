import React from "react";
import { createBrowserRouter } from "react-router-dom";

import Layout from "../layout/Layout/layout";
import Home from "../pages/home/Home";
import Notifications from "../components/Notifications/Notifications";
import UploadDocument from "../components/UploadDocument/UploadDocument";
import CreateProjectSteps from "../components/CreateProjectSteps/CreateProjectSteps";
import ProjectDetails from "../pages/ProjectDetails/ProjectDetails";
import DesignQc from "../pages/DesignQC/DesignQc.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
  },
  {
    path: "/create-project/project-details/:step",
    element: (
      <Layout>
        <CreateProjectSteps>
          <ProjectDetails />
        </CreateProjectSteps>
      </Layout>
    ),
  },
  // {
  //   path: "/create-project/view-data/:step",
  //   element: (
  //     <Layout>
  //       <CreateProjectSteps>
  //         <ViewDataLabels />
  //       </CreateProjectSteps>
  //     </Layout>
  //   ),
  // },
  {
    path: "/create-project/design-qc-rules/:step",
    element: (
      <Layout>
        <CreateProjectSteps>
          <DesignQc />
        </CreateProjectSteps>
      </Layout>
    ),
  },
  {
    path: "/notifications",
    element: (
      <Layout>
        <Notifications />
      </Layout>
    ),
  },
  {
    path: "/file-upload",
    element: (
      <Layout>
        <UploadDocument />
      </Layout>
    ),
  },
]);

export default router;
