import React, { useEffect } from "react";

import HeaderComp from "../Header/Header";
import SnackbarComponent from "../../components/Snackbar/Snackbar";
import Loader from "../../components/Loader";

import "./Layout.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const AppLayout = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // const sessionId = Cookies.get("sessionid");
    const csrfToken = Cookies.get("csrftoken");
    // sessionId is accessible if it is not set with httpOnly flag
    // checking the csrftoken if availble then
    if (!csrfToken) {
      //  navigate("/login");
    }
  }, []);
  return (
    <>
      <Loader />
      <div className="layoutStyle">
        <header className="headerStyle">
          <HeaderComp />
        </header>
        <div className="childrenContainer">{children}</div>
        <SnackbarComponent />
      </div>
    </>
  );
};

export default AppLayout;
