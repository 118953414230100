import React from "react";
import AsyncSelect from "react-select/async";

const AsynSelect = ({ onChange, value, loadOptions }) => (
  <AsyncSelect
    isMulti
    classNamePrefix="react-select"
    isClearable={false}
    styles={{
      valueContainer: (provided, { isFocused }) => ({
        ...provided,
        maxHeight: "70px",
        overflowY: "auto",
        padding: "8px",
      }),
      menuList: (base) => ({
        ...base,
        padding: 0,
        maxHeight: "265px",
        fontSize: "14px",
      }),
      control: (provided, { isFocused }) => ({
        ...provided,
        border: isFocused ? "1px solid #E5E5E5" : "1px solid #E5E5E5",
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        color: "#25282B",
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        lineHeight: "21px",
        wordWrap: "break-word",
        height: "41px",
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        color: "#4F46E5",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        lineHeight: "21px",
        wordWrap: "break-word",
        marginLeft: "10px",
      }),
      container: (provided, { isFocused }) => ({
        ...provided,
        width: "100%",
        border: isFocused ? "1px solid #E5E5E5" : "1px solid #E5E5E5",
        minHeight: "44px",
        maxHeight: "70px",
        borderRadius: "4px",
      }),
      input: (provided) => ({
        ...provided,
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
    }}
    cacheOptions={true}
    onChange={onChange}
    placeholder="dummyuser@inizio.health"
    value={value}
    defaultOptions={true}
    loadOptions={loadOptions}
  />
);

export default AsynSelect;
