import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  ruleExecutionResponse: {},
};

export const ruleExecutionSlice = createSlice({
  name: "ruleExecution",
  initialState,
  reducers: {
    getRuleExecutionStart(state) {
      state.loading = true;
    },
    getRuleExecutionSuccess(state, action) {
      state.loading = false;
      state.ruleExecutionResponse = action.payload;
    },
    getRuleExecutionError(state) {
      state.loading = false;
    },
    resetRuleExecutionResponse(state) {
      state.ruleExecutionResponse = {};
    },
    resetAllExecutionState(state) {
      return initialState;
    },
  },
});

export const { resetAllExecutionState, getRuleExecutionStart, getRuleExecutionSuccess, getRuleExecutionError, resetRuleExecutionResponse, loading } =
  ruleExecutionSlice.actions;

export default ruleExecutionSlice.reducer;
