import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";

import { fetchGetAllRulesStartSaga } from "../Rule/ruleSaga";
import { openSnackbar } from "../../snackbar/snackbarslice";
import { closeLoader, openLoader } from "../../loader/loaderSlice";
import { getExecutedRuleData } from "../../../services/ruleExecutionService";
import { getRuleExecutionSuccess } from "../ruleExecution/ruleExecutionSlice";
import { totalRespondentCountApi } from "../../../services/previewService";
import {
  alternativePatternApi,
  getMergedFilePath,
  patternRecognitionIncludeExcludeApi,
  repetitivePatternApi,
  steppedPatternApi,
} from "../../../services/patternService";
import {
  alternativepatternSuccess,
  initApplyExcludedPatternRecognitionState,
  repetitivepatternSuccess,
  setIncludeExcludeApiSuccess,
  setIsPatternRecognitionsApisProcessing,
  setPatternRecognitionApisCalled,
  startPatternRecognitionDataMapper,
  steppedpatternSuccess,
} from "../PatternRecognition/PatternRecognitionSlice";
import { includeExcludeApiMapper } from "../../../pages/DesignQC/JockerAnalysis/PatternRecognition/helper/PatternRecognitionHelper";
import { DESIGN_QC_HORIZONTAL_TABS } from "../../../pages/DesignQC/DesignQc.constants";
import { ACK_APIS_STATUS, SNACKBAR_TYPES } from "../../../assets/constants/constant";
import { fetchGetAllAdverseRulesStartSaga } from "../../adverseEvent/AdverseRule/AdverseRuleSaga";
import { setMergedFilePath } from "../projectMetaData/projectMetaDataSlice";

import { fetchInitPreviewApiStart, setPreviewCalculateAgain, setRespondentCount } from "./previewWeightsSlice";

const patternRecognitionApiCalledLocation = (state) => state?.patternRecognition?.isApiCalled;
const selectedHorizontalTab = (state) => state?.designQc?.selectedHorizontalTab;
const Jokerrule = (state) => state?.rules?.rules;
const checkedRulesInRuleTable = (state) => state?.rules?.checkedRulesInRuleTable;

function* previewRuleExecution(projectId, questionFileId, respondentFileId, username) {
  yield put(openLoader());

  try {
    yield put(openLoader());
    const rules = yield select(Jokerrule);
    const selectedHorizontalTabValue = yield select(selectedHorizontalTab);
    const selectedRulesIds = yield select(checkedRulesInRuleTable);
    if (selectedHorizontalTabValue === DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS) {
      const mappedData = {
        projectId: projectId,
        questionFileId: questionFileId,
        respondentFileId: respondentFileId,
        username: username,
        ruleType: "JOKER_ANALYSIS",
      };
      if (rules.length > 0 && selectedRulesIds.length > 0) {
        const { data } = yield call(() =>
          getExecutedRuleData(mappedData.questionFileId, mappedData.respondentFileId, mappedData.projectId, mappedData.username, mappedData.ruleType)
        );
        yield put(getRuleExecutionSuccess(data));
      }
    }
  } catch (error) {
    yield put(openSnackbar({ message: "Rule execution failed!", type: SNACKBAR_TYPES.ERROR }));
  }
}

function* fetchDataAndMap(dataMapFileName, projectId) {
  const errorApisNames = [];

  try {
    const isPatternApiCalled = yield select(patternRecognitionApiCalledLocation);
    if (isPatternApiCalled) return;

    let apiSuccess = true;
    let isProcessing = false;

    yield put(openLoader());

    let mergedFilePath = yield select((state) => state?.projectMetaData?.mergedFilePath?.data);
    if (mergedFilePath === undefined || mergedFilePath === null) {
      mergedFilePath = yield call(() => getMergedFilePath(dataMapFileName, projectId));
      yield put(setMergedFilePath(mergedFilePath));
    }
    // const mergedFilePath = "Quant/Putnam/Lumiq/Lumiq_dev_test/Harsh Chauhan/dummy/Merged_Layer/Merged_Data_Flat_2024-05-24 11:53:53";

    const fetchAlternativePatternData = function* () {
      try {
        const isAlternativeApiCalled = yield select((state) => state?.patternRecognition?.isAlternativePatternSuccess);
        if (isAlternativeApiCalled) return;

        const response = yield call(() => alternativePatternApi(mergedFilePath, projectId));
        if (response?.status == ACK_APIS_STATUS.COMPLETED) {
          yield put(alternativepatternSuccess(response));
        } else if (response?.status == ACK_APIS_STATUS.ERROR) {
          throw new Error(response?.message ?? "");
        } else {
          yield put(openSnackbar({ message: response?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
          apiSuccess = false;
          isProcessing = true;
        }
      } catch (error) {
        // todo show toast
        // console.error("Error fetching pattern data:", error);
        // yield put(openSnackbar({ message: error?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
        errorApisNames.push("alternative");
        apiSuccess = false;
      }
    };

    const fetchSteppedPatternData = function* () {
      try {
        const isSteppedPatternSuccess = yield select((state) => state?.patternRecognition?.isSteppedPatternSuccess);
        if (isSteppedPatternSuccess) return;

        const response = yield call(() => steppedPatternApi(mergedFilePath, projectId)); // Update with your merge file content
        if (response?.status == ACK_APIS_STATUS.COMPLETED) {
          yield put(steppedpatternSuccess(response));
        } else if (response?.status == ACK_APIS_STATUS.ERROR) {
          throw new Error(response?.message ?? "");
        } else {
          yield put(openSnackbar({ message: response?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
          apiSuccess = false;
          isProcessing = true;
        }
      } catch (error) {
        // todo show toast
        // console.error("Error fetching pattern data:", error);
        // yield put(openSnackbar({ message: error?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
        errorApisNames.push("stepped");
        apiSuccess = false;
      }
    };

    const fetchRepetitivePatternData = function* () {
      try {
        const isRepetitivePatternSuccess = yield select((state) => state?.patternRecognition?.isRepetitivePatternSuccess);
        if (isRepetitivePatternSuccess) return;

        const response = yield call(() => repetitivePatternApi(mergedFilePath, projectId)); // Update with your merge file content
        if (response?.status == ACK_APIS_STATUS.COMPLETED) {
          yield put(repetitivepatternSuccess(response));
        } else if (response?.status == ACK_APIS_STATUS.ERROR) {
          throw new Error(response?.message ?? "");
        } else {
          yield put(openSnackbar({ message: response?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
          apiSuccess = false;
          isProcessing = true;
        }
      } catch (error) {
        // todo show toast
        // console.error("Error fetching pattern data:", error);
        // yield put(openSnackbar({ message: error?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
        errorApisNames.push("repetitive");
        apiSuccess = false;
      }
    };

    // Run all API calls in parallel
    yield all([call(fetchAlternativePatternData), call(fetchSteppedPatternData), call(fetchRepetitivePatternData)]);

    yield put(startPatternRecognitionDataMapper());
    yield put(initApplyExcludedPatternRecognitionState());

    yield put(setIsPatternRecognitionsApisProcessing(isProcessing));

    if (apiSuccess) yield put(setPatternRecognitionApisCalled(true));

    if (errorApisNames.length > 0) throw new Error(`Failed to execute ${errorApisNames.join(", ")} pattern!`);

    // Ensure all actions have been processed
    yield delay(0); // Delay for one tick, adjust if necessary
  } catch (error) {
    yield put(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
  }
}

function* getIncludeExcludeApi() {
  try {
    const tableDataMapStateLocation = (state) => state?.patternRecognition?.tableDataMap;
    const tableDataMap = yield select(tableDataMapStateLocation);
    const apiMappedData = yield includeExcludeApiMapper(tableDataMap);

    const includeExcludeApiData = yield call(() => patternRecognitionIncludeExcludeApi(apiMappedData));
    yield put(setIncludeExcludeApiSuccess(includeExcludeApiData));
  } catch (error) {
    throw new Error("Error while fetching pattern recognition data");
  }
}

function* patternRecognitionApisInit(questionFileId, respondentFileId, dataMapFileName, projectId) {
  try {
    const selectedHorizontalTabValue = yield select(selectedHorizontalTab);
    if (selectedHorizontalTabValue === DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS) {
      yield* fetchDataAndMap(dataMapFileName, projectId);
      yield* getIncludeExcludeApi();
    }
  } catch (error) {
    yield put(openSnackbar({ message: "Pattern recognition apis failed", type: SNACKBAR_TYPES.ERROR }));
  }
}

function* fetchInitPreviewApiSaga(action) {
  try {
    const projectId = action?.payload?.projectId;
    const questionFileId = action?.payload?.questionFileId;
    const respondentFileId = action?.payload?.respondentFileId;
    const username = action?.payload?.username;
    const dataMapFileName = action?.payload?.dataMapFileName;
    const isAdverse = action?.payload?.selectedHorizontalTab === "Adverse Events";

    try {
      const rules = yield select(Jokerrule);
      if (!isAdverse) yield* fetchGetAllRulesStartSaga({ payload: projectId });
      else yield* fetchGetAllAdverseRulesStartSaga({ payload: projectId });

      try {
        const respondentCount = yield select((state) => state?.previewWeights?.respondentCount) ?? undefined;
        if (respondentCount === 0 || respondentCount === undefined) {
          const count = yield call(() => totalRespondentCountApi(questionFileId, respondentFileId, projectId));
          yield put(setRespondentCount(count));
        }
      } catch (error) {
        yield put(openSnackbar({ message: "Unable to fetch respondent counts", type: SNACKBAR_TYPES.ERROR }));
        throw new Error("Something went wrong!");
      }

      yield put(openLoader());

      yield all([
        call(() => patternRecognitionApisInit(questionFileId, respondentFileId, dataMapFileName, projectId)),
        call(() => previewRuleExecution(projectId, questionFileId, respondentFileId, username)),
      ]);
      const selectedHorizontalTabValue = yield select(selectedHorizontalTab);

      if (selectedHorizontalTabValue === DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS) {
        yield put(setPreviewCalculateAgain());
      }
    } catch (error) {
      yield put(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
    } finally {
      yield put(closeLoader());
    }
  } catch (error) {
    console.error({ error });
  }
}

export function* watchPreviewWeightsSaga() {
  yield takeEvery(fetchInitPreviewApiStart, fetchInitPreviewApiSaga);
}
