export const DESIGN_QC_TABS = {
  PATTERN_RECOGNITION: "Pattern Recognition",
  PREVIEW: "Preview",
  BUILD_QC_RULE: "Build QC Rule",
};

export const DESIGN_QC_HORIZONTAL_TABS = {
  BASIC_STATS_DASHBOARD: "Basic Stats Dashboard",
  JOKER_ANALYSIS: "Joker Analysis",
  ADVERSE_EVENTS: "Adverse Events",
};
export const ADVERSE_EVENTS_TABS = {
  ADVERSE_BUILD_QC_RULE: "Build QC Rule",
  ADVERSE_OPEN_TEXT: "Open Text",
  ADVERSE_PREVIEW: "Preview",
};
