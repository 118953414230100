import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material";
import "./TabComponent.css";

export default function TabComponent({ orientation, labels, icons, onTabChange, selectedTab, selectedHorizontalTab }) {
  const newLabesls = labels || [];
  const tabIndex = newLabesls?.findIndex((ele) => ele == selectedTab);
  const [horizontalValue, setHorizontalValue] = React.useState(labels.indexOf(selectedHorizontalTab));
  const [verticalValue, setVerticalValue] = React.useState(tabIndex);
  const CustomIconContainer = styled("div")({
    width: "35px",
    height: "35px",
    backgroundColor: "#edecff",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });
  const PieIcon = styled("img")({
    width: "15px",
    height: "15px",
  });
  const PreviewIcon = styled("img")(({ tabIndex }) => ({
    width: tabIndex === 2 ? "15px" : "15px",
    height: tabIndex === 2 ? "15px" : "15px",
  }));

  const CustomTab = styled(Tab)(({ orientation, index }) => ({
    color: "#A1AEBE",
    ...(orientation === "horizontal" && {
      fontWeight: "500 !important",
    }),
    "&.Mui-selected": {
      color: "#25282b",
      // ...(orientation === "vertical" && {
      //   textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
      // }),
    },

    "& .MuiTabs-indicator": {
      display: "none",
    },
  }));

  const handleChange = (newValue, orientation) => {
    if (orientation === "horizontal") {
      setHorizontalValue(newValue);
    } else {
      setVerticalValue(newValue);
      onTabChange(labels[newValue]);
    }
  };

  const handleHorizontalChange = (event, newValue) => {
    handleChange(newValue, "horizontal");
    onTabChange(labels[newValue]);
  };

  const handleVerticalChange = (event, newValue) => {
    handleChange(newValue, "vertical");
  };
  const getTabClassName = () => {
    if (orientation === "horizontal") {
      return "minitab-horizontal horizontal-tab";
    } else {
      return "minitab-vertical vertical-tab";
    }
  };
  const getTabPadding = () => {
    if (orientation === "vertical") {
      return "0px";
    } else {
      return "0px 0px";
    }
  };
  const getMargin = () => {
    if (orientation === "vertical") {
      return "10px 22px 0 10px";
    } else {
      return "0px 0px 0px 0px";
    }
  };

  React.useEffect(() => {
    setVerticalValue(tabIndex);
  }, [selectedTab]);

  React.useEffect(() => {
    setHorizontalValue(labels.indexOf(selectedHorizontalTab));
  }, [selectedHorizontalTab, labels]);
  return (
    <div style={{ position: "relative" }}>
      <Tabs
        orientation={orientation}
        sx={{ padding: getTabPadding(), margin: getMargin() }}
        value={orientation === "horizontal" ? horizontalValue : verticalValue}
        onChange={orientation === "horizontal" ? handleHorizontalChange : handleVerticalChange}
        className={`commontabs ${orientation === "vertical" ? "vertical-tab-gap" : "horizontal-tab-gap"}`}
      >
        {labels.map((label, index) => {
          const verticalicons = verticalValue === index ? icons?.[index]?.selected : icons?.[index].default;
          const isSelected = verticalValue === index;
          return (
            <CustomTab
              key={index}
              className={getTabClassName()}
              icon={
                <CustomIconContainer className="abcd" style={{ backgroundColor: isSelected ? "#4F46E5" : "" }}>
                  {index === 2 ? <PreviewIcon src={verticalicons} alt={label} tabIndex={index} /> : <PieIcon src={verticalicons} alt={label} />}
                </CustomIconContainer>
              }
              iconPosition="start"
              label={<div className="tab-label">{label}</div>}
            />
          );
        })}
      </Tabs>
    </div>
  );
}
