import { QUESTION_TYPES } from "../../../../../components/modals/QuestionLibrary/questionLibrary.constant";

export function initTextRuleGridArray(expression, questionMetaData) {
  const tokens = tokenizeExpression(expression);

  const foundTokens = [];

  tokens.forEach((token) => {
    if (questionMetaData.hasOwnProperty(token)) {
      let isGrid = false;
      if (
        questionMetaData?.[token]?.type == "grid" ||
        questionMetaData?.[token]?.type == "single" ||
        questionMetaData?.[token]?.type == "multi" ||
        questionMetaData?.[token]?.type == "opentext" ||
        questionMetaData?.[token]?.type == QUESTION_TYPES.OPEN_TEXT_LIST
      ) {
        isGrid = true;
      }

      foundTokens.push({ rule: token, grid: isGrid });
    }
  });

  return foundTokens;
}

function tokenizeExpression(expression) {
  const tokenRegex = /\s*([A-Za-z_]\w*|\d+|\+|\-|\*|\/|\^|\(|\)|\<|\>|\<=|\>=|\=|\!\=|\s+)\s*/g;
  const tokens = [];
  let match;

  while ((match = tokenRegex.exec(expression))) {
    tokens.push(match[1]);
  }

  return tokens;
}
