export const handleRuleTextBackSpace = (rule, caretPosition, handleClose, isResponseButtonVisible, setIsResponseButtonVisible) => {
  try {
    if (caretPosition === 0) return { newRule: rule, caretPosition };

    let start = caretPosition - 1;

    // If the caret is at the start of the text, nothing to remove
    if (start < 0) return { newRule: rule, caretPosition };

    // Move start pointer left until we find a space or reach the start
    let isStartIncreased = false;
    while (start >= 0 && rule[start] !== " ") {
      isStartIncreased = true;
      start--;
    }
    if (isStartIncreased) start = start + 1;

    // Move end pointer right until we find a space or reach the end
    let end = caretPosition - 1;
    let isEndIncreased = false;
    while (end < rule.length && rule[end] !== " ") {
      isEndIncreased = true;
      end++;
    }
    if (isEndIncreased) end = end - 1;

    if (start < 0) {
      start = 0;
    }

    const deletedWord = rule.slice(start, end + 1).trim();

    // Form the new text without the word
    const newText = rule.slice(0, start) + rule.slice(end + 1);

    const trimmedNewText = newText.trim();
    const lastWord = trimmedNewText.split(" ").pop();

    if (deletedWord === "Is" && (lastWord !== "Is" || lastWord == "not")) {
      setIsResponseButtonVisible(false);
      handleClose();
    }

    return { newRule: newText, caretPosition: start };
  } catch (error) {
    console.error({ error });
    return "";
  }
};
