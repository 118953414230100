import React from "react";
import { Radio } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

const QuestionTableSingleSelectRow = ({
  rowClassName,
  selectedRow,
  displayObjId,
  displayObjLabel,
  displayObjType,
  rowMetaShowRadioBtn,
  rowMetaDataOptions,
  rowMetaDataRadioInputAriaLabel,
  handleRadioChange,
  radioColumnIndex = 0,
}) => (
  <TableRow className={rowClassName}>
    {radioColumnIndex === 1 && <TableCell />}
    <TableCell className={radioColumnIndex === 0 ? "question-expand-icon" : "question-radioButton"}>
      {rowMetaShowRadioBtn && (
        <Radio
          checked={selectedRow === displayObjId}
          onChange={() => handleRadioChange(displayObjId, rowMetaDataOptions, displayObjLabel, displayObjType)}
          inputProps={{ "aria-label": rowMetaDataRadioInputAriaLabel }}
          name="radio-buttons"
          sx={{
            marginLeft: "-10px",
            "& .MuiSvgIcon-root": {
              fontSize: 16,
            },
          }}
        />
      )}
    </TableCell>
    {radioColumnIndex === 0 && <TableCell />}
    <TableCell className="questionTable-id" component="th" scope="row">
      {displayObjId}
    </TableCell>
    <TableCell align="left" className="questionTable_question">
      <Typography
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          fontSize: "14px",
          height: "42px",
          lineHeight: "19px",
        }}
        title={displayObjLabel}
      >
        {displayObjLabel}
      </Typography>
    </TableCell>
    <TableCell align="left" className="questionTable-type">
      {displayObjType}
    </TableCell>
  </TableRow>
);

export default QuestionTableSingleSelectRow;
