import React from "react";

import GreenCircleIcon from "../circleIcon/GreenCircleIcon";
import RedCircleIcon from "../circleIcon/RedCircleIcon";
import "./label.css";
export default function LabelIcon() {
  return (
    <div className="true_false">
      <div className="true">
        {/* <div style={{width:"11px",height:"11px"}}> */}

        <GreenCircleIcon style={{ width: "11px", height: "11px" }} />
        {/* </div> */}
        <p style={{ fontSize: "12px", width: "94px" }}>Not Suspicious</p>
      </div>
      <div className="false">
        <RedCircleIcon style={{ width: "11px", height: "11px" }} />

        <p style={{ fontSize: "12px" }}>Suspicious</p>
      </div>
    </div>
  );
}
