import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  ruleExecutionResponse: {},
  adverseeventdata: [],
  adverseeventdataHeader: [],
  mergeAdversedata: [],
};

export const AdverseruleExecutionSlice = createSlice({
  name: "adverseruleExecution",
  initialState,
  reducers: {
    setmergeAdversedata(state, action) {
      state.mergeAdversedata = action.payload;
    },
    setadverseEventdata(state, action) {
      state.count = action.payload.count;
      state.adverseeventdata = action.payload.tableData;
      state.adverseeventdataHeader = action.payload.headerData;
      state.adverseeventdatacolumnGroupData = action.payload.columnGroupData;
    },
    getAdverseRuleExecutionStart(state) {
      state.loading = true;
    },
    getAdverseRuleExecutionSuccess(state, action) {
      state.loading = false;
      state.ruleExecutionResponse = action.payload;
    },
    getAdverseRuleExecutionError(state) {
      state.loading = false;
    },
    resetAdverseRuleExecutionResponse(state) {
      // state.userInfo = {};
    },
    resetAllAdverseExecutionState(state) {
      return initialState;
    },
  },
});

export const {
  resetAllAdverseExecutionState,
  setmergeAdversedata,
  setadverseEventdata,
  getAdverseRuleExecutionStart,
  getAdverseRuleExecutionSuccess,
  getAdverseRuleExecutionError,
  resetAdverseRuleExecutionResponse,
  loading,
} = AdverseruleExecutionSlice.actions;

export default AdverseruleExecutionSlice.reducer;
