import React from "react";

import ButtonComponent from "../../Button/ButtonComponent";
import GeneralModal from "../GeneralModal/GeneralModal";
import "./LogOutModal.css";

import LogoutAlertIcon from "../../../assets/images/LogoutAlert.svg";
import CloseCircularIcon from "../../../assets/images/cross_icon.svg";

const LogOutModal = ({ open, handleOnClose, onCloseProjectAnyway, onUnlockProject }) => (
  <GeneralModal
    open={open}
    onClose={handleOnClose}
    modalBody={<LogoutModalBody onClose={handleOnClose} onCloseProjectAnyway={onCloseProjectAnyway} onUnlockProject={onUnlockProject} />}
    className={"log-out-modal-body"}
  ></GeneralModal>
);

const LogoutModalBody = ({ onClose, onUnlockProject, onCloseProjectAnyway }) => (
  <div className="log-out-modal-container">
    <div onClick={onClose} className="log-out-modal__close-top-icon">
      <img src={CloseCircularIcon} alt="" />
    </div>
    <div className="log-out-modal__icon">
      <img src={LogoutAlertIcon} alt="" />
    </div>
    <div className="log-out-modal__question-message">Sure you want to log out?</div>
    <div className="log-out-modal__label-message">Your projects will remain locked for others. Please unlock them before logging out.</div>

    <div className="log-out-modal__btn-container">
      <ButtonComponent
        onClick={onUnlockProject}
        variant="contained"
        btnType="primary"
        text="Unlock Projects"
        className="log-out-modal__btn btnPrimary log-out-modal__back-btn"
      />
      <ButtonComponent
        onClick={onCloseProjectAnyway}
        variant="outlined"
        btnType="outline"
        text="Log Out Anyway"
        className={"log-out-modal__btn log-out-modal__close-anyway-btn"}
      />
    </div>
  </div>
);

export default LogOutModal;
