const GreenCircleIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_233_15624)">
      <path
        d="M14.8784 7.875C14.8784 11.741 11.7444 14.875 7.87842 14.875C4.01242 14.875 0.878418 11.741 0.878418 7.875C0.878418 4.009 4.01242 0.875 7.87842 0.875C11.7444 0.875 14.8784 4.009 14.8784 7.875Z"
        fill="url(#paint0_radial_233_15624)"
      />
      <path
        d="M14.8784 7.875C14.8784 11.741 11.7444 14.875 7.87842 14.875C4.01242 14.875 0.878418 11.741 0.878418 7.875C0.878418 4.009 4.01242 0.875 7.87842 0.875C11.7444 0.875 14.8784 4.009 14.8784 7.875Z"
        fill="url(#paint1_linear_233_15624)"
      />
      <path
        d="M14.8784 7.875C14.8784 11.741 11.7444 14.875 7.87842 14.875C4.01242 14.875 0.878418 11.741 0.878418 7.875C0.878418 4.009 4.01242 0.875 7.87842 0.875C11.7444 0.875 14.8784 4.009 14.8784 7.875Z"
        fill="url(#paint2_radial_233_15624)"
      />
      <path
        d="M14.8784 7.875C14.8784 11.741 11.7444 14.875 7.87842 14.875C4.01242 14.875 0.878418 11.741 0.878418 7.875C0.878418 4.009 4.01242 0.875 7.87842 0.875C11.7444 0.875 14.8784 4.009 14.8784 7.875Z"
        fill="url(#paint3_radial_233_15624)"
      />
      <path
        d="M14.8784 7.875C14.8784 11.741 11.7444 14.875 7.87842 14.875C4.01242 14.875 0.878418 11.741 0.878418 7.875C0.878418 4.009 4.01242 0.875 7.87842 0.875C11.7444 0.875 14.8784 4.009 14.8784 7.875Z"
        fill="url(#paint4_radial_233_15624)"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_233_15624"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.9683 4.25039) rotate(130.168) scale(13.9043)"
      >
        <stop offset="0.19" stopColor="#5AE68D" />
        <stop offset="0.835" stopColor="#43A684" />
      </radialGradient>
      <linearGradient id="paint1_linear_233_15624" x1="7.87842" y1="0.875" x2="7.87842" y2="4.125" gradientUnits="userSpaceOnUse">
        <stop stopColor="#5ED284" />
        <stop offset="1" stopColor="#5ED284" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_233_15624"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.2181 5.25061) rotate(136.38) scale(7.33835 7.908)"
      >
        <stop offset="0.179" stopColor="#6FFCA5" />
        <stop offset="1" stopColor="#65E6A7" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_233_15624"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.1244 7.875) rotate(180) scale(9.625 10)"
      >
        <stop offset="0.62" stopColor="#64CB85" stopOpacity="0" />
        <stop offset="0.951" stopColor="#A4E4B7" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_233_15624"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.87842 4.375) rotate(90) scale(11.0937 11.4938)"
      >
        <stop offset="0.732" stopColor="#4A9795" stopOpacity="0" />
        <stop offset="1" stopColor="#718CAD" />
      </radialGradient>
      <clipPath id="clip0_233_15624">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GreenCircleIcon;
