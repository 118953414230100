import { Parser } from "expr-eval";

import { optionsExpressionConvertor, questionMetaConvertor } from "./questionMetaConvertor";
import { customValidateExpression } from "./ruleValidator";

const preProcessedExpression = (expression) => {
  let updatedRule = replaceWithArithmeticCalculatedValues(expression);

  updatedRule = preProcessRule(updatedRule);

  updatedRule = checkInvalidArithmeticOperations(updatedRule);

  return updatedRule;
};

const replaceWithArithmeticCalculatedValues = (input) => {
  // Regular expression pattern to capture the "mean" keyword and the variable, with or without parentheses
  const meanRegex = /\bmean\s*\(\s*([a-zA-Z0-9_]+)\s*\)\b|\bmean\s*\(\s*([a-zA-Z0-9_]+)\s*\)|\bmean\s+([a-zA-Z0-9_]+)\b/gi;

  input = input.replace(meanRegex, (match, group1, group2, group3) => {
    const variable = group1 || group2 || group3;

    const value = 1;

    if (value !== null || value !== undefined) return `(${value})`;
    else return variable;
  });

  // Regular expression pattern to capture the "median" keyword and the variable, with or without parentheses
  const medianRegex = /\bmedian\s*\(\s*([a-zA-Z0-9_]+)\s*\)\b|\bmedian\s*\(\s*([a-zA-Z0-9_]+)\s*\)|\bmedian\s+([a-zA-Z0-9_]+)\b/gi;

  input = input.replace(medianRegex, (match, group1, group2, group3) => {
    const variable = group1 || group2 || group3;

    const value = 1;

    if (value !== null || value !== undefined) return `(${value})`;
    else return variable;
  });

  // Regular expression pattern to capture the "mode" keyword and the variable, with or without parentheses
  const modeRegex = /\bmode\s*\(\s*([a-zA-Z0-9_]+)\s*\)\b|\bmode\s*\(\s*([a-zA-Z0-9_]+)\s*\)|\bmode\s+([a-zA-Z0-9_]+)\b/gi;

  input = input.replace(modeRegex, (match, group1, group2, group3) => {
    const variable = group1 || group2 || group3;

    const value = 1;

    if (value !== null || value !== undefined) return `(${value})`;
    else return variable;
  });

  // Regular expression pattern to capture the "mode" keyword and the variable, with or without parentheses
  const standardDeviationRegex = /\bsd\s*\(\s*([a-zA-Z0-9_]+)\s*\)\b|\bsd\s*\(\s*([a-zA-Z0-9_]+)\s*\)|\bsd\s+([a-zA-Z0-9_]+)\b/gi;

  input = input.replace(standardDeviationRegex, (match, group1, group2, group3) => {
    const variable = group1 || group2 || group3;

    const value = 1;

    if (value !== null || value !== undefined) return `(${value})`;
    else return variable;
  });

  // Return the updated input string
  return input;
};

const preProcessRule = (rule) => {
  rule = replaceEqualWithDoubleEqual(rule);

  rule = inNotTransformation(rule);

  rule = replaceIsWithIn(rule);

  rule = Parser.parse(rule).toString();

  return rule;
};

const replaceEqualWithDoubleEqual = (rule) => {
  rule = rule.replace(/"[^"]*"|(?<![=!<>])=(?!=)/g, (match) => {
    // If the match is a quoted string, return it unchanged
    if (match.startsWith('"') && match.endsWith('"')) {
      return match;
    }
    // Otherwise, replace '=' with '=='
    return match.replace(/=/g, "==");
  });

  return rule;
};

const replaceIsWithIn = (expression) => {
  const newExpr = expression.replace(/\bis\b/gi, "==");
  return newExpr;
};

const checkInvalidArithmeticOperations = (expression) => {
  // After replacement, check for any remaining 'mean' instances
  // Regular expressions to find invalid 'mean' instances
  const invalidMeanWordRegex = /\bmean\b(?!\s*\()/gi;
  const invalidMeanParenRegex = /\bmean\s*\(\s*(?![a-zA-Z_][a-zA-Z0-9_]*\s*(?:[\+\-\*\/]\s*\d+(\.\d+)?\s*)?\s*\))/gi;

  if (invalidMeanWordRegex.test(expression) || invalidMeanParenRegex.test(expression)) {
    // if (invalidMeanRegex.test(expression)) {
    throw new Error("There are invalid 'mean' instances in the string.");
  }

  const invalidMedianWordRegex = /\bmedian\b(?!\s*\()/gi;
  const invalidMedianParenRegex = /\bmedian\s*\(\s*(?![a-zA-Z_][a-zA-Z0-9_]*\s*(?:[\+\-\*\/]\s*\d+(\.\d+)?\s*)?\s*\))/gi;

  if (invalidMedianWordRegex.test(expression) || invalidMedianParenRegex.test(expression)) {
    // if (invalidMeanRegex.test(expression)) {
    throw new Error("There are invalid 'median' instances in the string.");
  }

  const invalidModeWordRegex = /\bmode\b(?!\s*\()/gi;
  const invalidModeParenRegex = /\bmode\s*\(\s*(?![a-zA-Z_][a-zA-Z0-9_]*\s*(?:[\+\-\*\/]\s*\d+(\.\d+)?\s*)?\s*\))/gi;
  if (invalidModeWordRegex.test(expression) || invalidModeParenRegex.test(expression)) {
    // if (invalidMeanRegex.test(expression)) {
    throw new Error("There are invalid 'mode' instances in the string.");
  }

  const invalidStandardDeviationWordRegex = /\bsd\b(?!\s*\()/gi;
  const invalidStandardDeviationParenRegex = /\bsd\s*\(\s*(?![a-zA-Z_][a-zA-Z0-9_]*\s*(?:[\+\-\*\/]\s*\d+(\.\d+)?\s*)?\s*\))/gi;
  if (invalidStandardDeviationWordRegex.test(expression) || invalidStandardDeviationParenRegex.test(expression)) {
    // if (invalidMeanRegex.test(expression)) {
    throw new Error("There are invalid 'standard deviation' instances in the string.");
  }

  return expression;
};

const inNotTransformation = (expression) => {
  const newExpr = expression.replace(/\bis not\b/gi, "!=");
  return newExpr;

  // const regex = /(\w+)\s+in\s*\(not\s*\[(.*?)\]\)/gi;
  // return expression.replace(regex, "$1 != $2");
};

export const checkEvaluation = (expression = "", questionMeta = {}, optionsMeta = {}) => {
  let isValid = false;
  let message = null;

  expression = optionsExpressionConvertor(expression, optionsMeta);
  const [categoricalQuestions, numericalQuestions] = questionMetaConvertor(questionMeta);

  try {
    const [isCustomValid, customValidationMessage] = customValidateExpression(categoricalQuestions, numericalQuestions, expression);

    if (!isCustomValid) {
      isValid = false;
      message = customValidationMessage;
      throw new Error();
    }

    const processedExpression = preProcessedExpression(expression);
    const newExpr = Parser.parse(processedExpression).toString();
    isValid = true;
  } catch (error) {
    isValid = false;
  }

  return [isValid, message];
};
