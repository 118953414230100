import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";

import "./TextwithIcon2.css";

// import { ReactComponent as EmptyIcon } from "../../assets/images/empty-icon.svg";
import { useDispatch, useSelector } from "react-redux";

import { updateTextRule, updateTextRuleMetaData } from "../../features/designQcRule/BuildRule/BuildRuleslice";
import { setAdversePreviousQuestionId, setIsgrid, setQuestionLibraryPreviousId } from "../../features/designQcRule/questionlibrary/questionlibrarySlice";
import { handleRuleTextBackSpace } from "../OptionComponent/OptionsHelper";
import { addStringOrAppendOnIndex } from "../../utils/helper";
import { updateAdverseTextRule, updateAdverseTextRuleMetaData } from "../../features/adverseEvent/RuleBuilder/RuleBuilder";
import RuleBuilderTextArea from "../RuleBuilderTextArea/RuleBuilderTextArea";
import { useRuleBuilderTextAreaProvider } from "../../context/RuleBuilderTextArea/ruleBuilderTextAreaProvider";

// import { QuestionSelector, ResponseSelector } from "./TextwithIcon2Helper";
import { ReactComponent as SelectYourResponse } from "../../assets/images/SelectYourResponse.svg";
import { DESIGN_QC_HORIZONTAL_TABS } from "../../pages/DesignQC/DesignQc.constants";
import { QUESTION_TYPES } from "../modals/QuestionLibrary/questionLibrary.constant";

import { OperatorButtons, QuestionSelector, ResponseSelector } from "./TextwithIcon2Helper";

const TextWithIcon2 = ({
  initialValue,
  options,
  value,
  textAreaTitle,
  onChange,
  index,
  caretPosition,
  setCaretPosition,
  caretEndPosition,
  setCaretEndPosition,
  title,
  showOptionCount,
  allowChangeCaret,
  setAllowChangeCaret,
}) => {
  const dispatch = useDispatch();
  const [expression, setExpression] = useState(""); // State to track the expression
  const [isContainerVisible, setIsContainerVisible] = useState(false); // State to track container visibility
  const [isSecondTriggerVisible, setIsSecondTriggerVisible] = useState(false); // State to track second trigger visibility
  const [isThirdTriggerVisible, setIsThirdTriggerVisible] = useState(false); // State to track second trigger visibility
  const [prevValue, setPrevValue] = useState(value); // State to track the previous value

  const textRule = useSelector((state) => state.text.textRules[index]?.rule || "");
  const questionindex = useSelector((state) => state.questions.accordionIndex);
  const addedRule = useSelector((state) => state?.text?.textRules[questionindex]?.rule || "");
  const checkGrid = useSelector((state) => state?.text?.checkGrid);

  const textAreaRef = useRef(null);

  const selectedQuestionId = useSelector((state) => state?.questions?.selectedQuestionId);
  const prevQuestionNanoId = useSelector((state) => state?.questions?.prevQuestionId);
  const adverseSelectedQuestionID = useSelector((state) => state?.questions?.adverseSelectedQuestionId);

  const AdverseprevQuestionId = useSelector((state) => state.questions.AdverseprevQuestionId);
  const adverseTextRule = useSelector((state) => state.adversetext.textRules[index]?.rule || "");
  const addAdverseRule = useSelector((state) => state?.adversetext?.textRules[questionindex]?.rule || "");

  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);

  const jokerQuestionMetaData = useSelector((state) => state?.text?.textRules[index]?.questionsMeta ?? {});
  const jokerOptionMetaData = useSelector((state) => state?.text?.textRules[index]?.optionsMeta ?? {});

  const adverseQuestionMetaData = useSelector((state) => state?.adversetext?.textRules[index]?.questionsMeta ?? {});
  const adverseOptionMetaData = useSelector((state) => state?.adversetext?.textRules[index]?.optionsMeta ?? {});

  const {
    getCaretCharacterOffsetWithin,
    editorRef,
    setCaretCharacterOffsetWithin,
    checkIsNotEditableByCaretPosition,
    setQuestionsMeta,
    setOptionsMeta,
    questionsMeta,
    questionArray,
    restoreCaretPosition,
    restoreAndAppendLengthInCaretPosition,
    reHighlightText,
  } = useRuleBuilderTextAreaProvider();
  const [isResponseButtonVisible, setIsResponseButtonVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedResponses, setSelectedResponses] = useState();
  // React.useEffect(() => {
  //   if (selectedHorizontalTab == "Joker Analysis") {
  //     if (caretPosition !== null && textAreaRef.current && caretPosition === caretEndPosition) {
  //       textAreaRef.current.setSelectionRange(caretPosition, caretPosition);
  //     }
  //   } else {
  //     if (caretPosition !== null && textAreaRef.current && caretPosition === caretEndPosition) {
  //       textAreaRef.current.setSelectionRange(caretPosition, caretPosition);
  //     }
  //   }
  // }, [caretPosition]);

  // useEffect(() => {
  //   if (textAreaRef.current) {
  //     textAreaRef.current.focus();
  //   }
  // }, [showOptionCount]);

  // const getCaretPosition = () => {
  //   if (textAreaRef.current) {
  //     const start = textAreaRef.current.selectionStart;
  //     const end = textAreaRef.current.selectionEnd;
  //     setCaretPosition(start);
  //     setCaretEndPosition(end);
  //   }
  // };

  useEffect(() => {
    const array = textRule.split(" ");

    for (const element of checkGrid) {
      if (array.includes(element.rule)) {
        dispatch(setIsgrid(true));
        break;
      }
    }

    reHighlightText();
  }, [textRule]);

  React.useEffect(() => {
    // if (allowChangeCaret) {
    //   getCaretPosition();
    // }
  }, [textRule]);

  const newIndex1 = JSON.parse(JSON.stringify(index));

  const handleInputChange = (newRule) => {
    if (selectedHorizontalTab == "Joker Analysis") {
      dispatch(updateTextRule({ newIndex1: index, newValue: newRule }));
    } else {
      dispatch(updateAdverseTextRule({ newIndex1: index, newValue: newRule }));
    }

    onChange(newRule);
  };

  useEffect(() => {
    if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS && (prevQuestionNanoId === null || selectedQuestionId.id === prevQuestionNanoId)) {
      setQuestionsMeta(jokerQuestionMetaData);
      setOptionsMeta(jokerOptionMetaData);
    }

    if (selectedQuestionId.id && prevQuestionNanoId !== selectedQuestionId.id) {
      dispatch(setQuestionLibraryPreviousId(selectedQuestionId.id));

      if (questionindex === index) {
        if (checkIsNotEditableByCaretPosition(editorRef?.current)) {
          setIsResponseButtonVisible(false);
          handleClose();
          return;
        }

        const textAreaCaretPosition = getCaretCharacterOffsetWithin(editorRef.current);
        const newVal = `${selectedQuestionId.questionId} `;
        const gridRuleSel = selectedQuestionId.gridRule;

        const newTextRule = addStringOrAppendOnIndex(addedRule, textAreaCaretPosition, newVal);

        dispatch(updateTextRule({ newIndex1: questionindex, newValue: newTextRule, gridRule: gridRuleSel, newGridValue: selectedQuestionId.questionId }));
        dispatch(
          updateTextRuleMetaData({
            index: questionindex,
            meta: selectedQuestionId.meta,
            optionsMeta: selectedQuestionId.optionsMeta,
            questionId: selectedQuestionId.questionId,
          })
        );
        onChange(newTextRule);
        // let questionsArray = Object.keys(useSelector((state) => state?.text?.textRules[questionindex]?.questionsMeta ?? {}));

        // Setting providers data
        setQuestionsMeta((prevMeta) => ({
          ...prevMeta,
          [selectedQuestionId.questionId]: selectedQuestionId.meta, // Use selectedQuestionId.id as the key
        }));
        setOptionsMeta((prevMeta) => ({
          ...prevMeta,
          ...selectedQuestionId.optionsMeta,
        }));

        // setCaretPosition(textAreaCaretPosition + newVal.length, textAreaCaretPosition + newVal.length);
        // setCaretEndPosition(textAreaCaretPosition + newVal.length, textAreaCaretPosition + newVal.length);
        setTimeout(() => {
          // setCaretCharacterOffsetWithin(editorRef.current, textAreaCaretPosition + newVal.length);
          restoreAndAppendLengthInCaretPosition(newVal.length);
          if (editorRef) {
            editorRef.current.focus();
          }
        }, 0);

        setIsResponseButtonVisible(false);
        handleClose();
        console.groupEnd();
      }
    }
  }, [selectedQuestionId.id, prevQuestionNanoId, textRule, dispatch, onChange, index]);

  useEffect(() => {
    if (
      selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS &&
      (AdverseprevQuestionId === null || adverseSelectedQuestionID.id === AdverseprevQuestionId)
    ) {
      setQuestionsMeta(adverseQuestionMetaData);
      setOptionsMeta(adverseOptionMetaData);
    }

    if (adverseSelectedQuestionID.id && AdverseprevQuestionId !== adverseSelectedQuestionID.id) {
      dispatch(setAdversePreviousQuestionId(adverseSelectedQuestionID.id));

      if (questionindex === index) {
        if (checkIsNotEditableByCaretPosition(editorRef?.current)) {
          setIsResponseButtonVisible(false);
          handleClose();
          return;
        }
        const textAreaCaretPosition = getCaretCharacterOffsetWithin(editorRef.current);
        const newVal = `${adverseSelectedQuestionID.questionId} `;
        const gridRuleSel = adverseSelectedQuestionID.gridRule;

        const newTextRule = addStringOrAppendOnIndex(addAdverseRule, textAreaCaretPosition, newVal);

        dispatch(
          updateAdverseTextRule({ newIndex1: questionindex, newValue: newTextRule, gridRule: gridRuleSel, newGridValue: adverseSelectedQuestionID.questionId })
        );
        dispatch(
          updateAdverseTextRuleMetaData({
            index: questionindex,
            meta: adverseSelectedQuestionID.meta,
            optionsMeta: adverseSelectedQuestionID.optionsMeta,
            questionId: adverseSelectedQuestionID.questionId,
          })
        );
        onChange(newTextRule);

        // Setting providers data
        setQuestionsMeta((prevMeta) => ({
          ...prevMeta,
          [adverseSelectedQuestionID.questionId]: adverseSelectedQuestionID.meta, // Use selectedQuestionId.id as the key
        }));
        setOptionsMeta((prevMeta) => ({
          ...prevMeta,
          ...adverseSelectedQuestionID.optionsMeta,
        }));

        // setCaretPosition(textAreaCaretPosition + newVal.length, textAreaCaretPosition + newVal.length);
        // setCaretEndPosition(textAreaCaretPosition + newVal.length, textAreaCaretPosition + newVal.length);
        setTimeout(() => {
          // setCaretCharacterOffsetWithin(editorRef.current, textAreaCaretPosition + newVal.length);
          restoreAndAppendLengthInCaretPosition(newVal.length);
          if (editorRef) {
            editorRef.current.focus();
          }
        }, 0);

        setIsResponseButtonVisible(false);
        handleClose();
      }
    }
  }, [adverseSelectedQuestionID.id, AdverseprevQuestionId, adverseTextRule, dispatch, onChange, index]);

  const handleOperatorsButtonClick = (oper) => {
    if (checkIsNotEditableByCaretPosition(editorRef.current)) {
      if (editorRef) editorRef.current.focus();
      return;
    }

    if (selectedHorizontalTab == "Joker Analysis") {
      const operator = `${oper} `;
      const startPos = getCaretCharacterOffsetWithin(editorRef.current);
      const endPos = getCaretCharacterOffsetWithin(editorRef.current);
      const currentTextRule = textRule || ""; // Ensure textRule is defined

      const newValue = currentTextRule.slice(0, startPos) + operator + currentTextRule.slice(endPos);

      dispatch(updateTextRule({ newIndex1: index, newValue }));
      onChange(newValue);
      setTimeout(() => {
        setCaretCharacterOffsetWithin(editorRef.current, startPos + operator.length);
      }, 0);

      if (showOptionCount) {
        if (["Is", "Is not"].includes(oper)) {
          setIsResponseButtonVisible(true);
          setSelectedResponses(null);
        }
      }
    } else {
      if (checkIsNotEditableByCaretPosition(editorRef.current)) {
        return;
      }

      const operator = `${oper} `;
      const startPos = getCaretCharacterOffsetWithin(editorRef.current);
      const endPos = getCaretCharacterOffsetWithin(editorRef.current);
      const currentTextRule = adverseTextRule || ""; // Ensure textRule is defined
      const newValue = currentTextRule.slice(0, startPos) + operator + currentTextRule.slice(endPos);

      dispatch(updateAdverseTextRule({ newIndex1: index, newValue }));
      onChange(newValue);
      setTimeout(() => {
        setCaretCharacterOffsetWithin(editorRef.current, startPos + operator.length);
      }, 0);

      if (showOptionCount) {
        if (["Is", "Is not"].includes(oper)) {
          setIsResponseButtonVisible(true);
          handleClose();
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Backspace"];
    if (!arrowKeys.includes(e.key) && checkIsNotEditableByCaretPosition(editorRef.current)) {
      e.preventDefault();
      return;
    }

    const numericKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "-", "!", "*", "/", "%", ">", "=", "<", "(", ")", "."];
    const key = e.key;
    if (!numericKeys.includes(key) && key !== "Backspace" && key !== "ArrowLeft" && key !== "ArrowRight" && key !== " ") {
      e.preventDefault();
      return;
    }

    const newRule = numericKeys.includes(key) ? e?.target?.value + key : e?.target?.textContent;

    if (key === "Backspace" && caretPosition === caretEndPosition) {
      const textAreaCaretPosition = getCaretCharacterOffsetWithin(editorRef?.current);

      e.preventDefault();
      const { newRule: updatedRule, caretPosition: updatedCaretPosition } = handleRuleTextBackSpace(
        newRule,
        textAreaCaretPosition,
        handleClose,
        isResponseButtonVisible,
        setIsResponseButtonVisible
      );
      if (selectedHorizontalTab == "Joker Analysis") {
        if (updatedRule !== undefined && updatedRule !== null) {
          dispatch(updateTextRule({ newIndex1: index, newValue: updatedRule }));
          setCaretCharacterOffsetWithin(editorRef?.current, updatedCaretPosition);
        }
      } else if (selectedHorizontalTab == "Adverse Events") {
        if (updatedRule !== undefined && updatedRule !== null) {
          dispatch(updateAdverseTextRule({ newIndex1: index, newValue: updatedRule }));
          setCaretCharacterOffsetWithin(editorRef?.current, updatedCaretPosition);
        }
      }
    }
  };

  const handleRadioBtnChange = (option) => {
    if (checkIsNotEditableByCaretPosition(editorRef.current)) {
      editorRef.current.focus();
      return;
    }

    const optionLabel = ` ${option.option_id} `;

    const startPos = getCaretCharacterOffsetWithin(editorRef?.current);
    const endPos = getCaretCharacterOffsetWithin(editorRef?.current);

    if (selectedHorizontalTab === "Adverse Events") {
      const textRuleArray1 = editorRef?.current.textContent;
      const textRuleArray = textRuleArray1?.split(" ") || [];
      const lengthOfTextRule = textRuleArray.length;
      let lastElementOfTextRule = textRuleArray[lengthOfTextRule - 1] == "" ? textRuleArray[lengthOfTextRule - 2] : textRuleArray[lengthOfTextRule - 1];
      const optionsForVisible = options.map((ele) => ele.option_id);
      lastElementOfTextRule = lastElementOfTextRule.trim();
      const absqjhgefvwc = [...optionsForVisible];
      const doesItInclude = absqjhgefvwc.includes(lastElementOfTextRule.trim());
      let newtextRule = adverseTextRule;
      if (doesItInclude) {
        const lastIndexOf = textRuleArray.lastIndexOf(lastElementOfTextRule.trim());
        const cleanedLastElement = lastElementOfTextRule.trim().replace(/^"(.*)"$/, "$1");
        const newArray = [];

        for (let i = 0; i < textRuleArray.length - 1; i++) {
          if (i != lastIndexOf) {
            let ele = textRuleArray[i];
            if (optionsForVisible.includes(ele.trim())) {
              ele = textRuleArray[i];
            }
            newArray.push(ele);
          }
        }
        newtextRule = newArray.join(" ");
      }

      if (selectedResponses?.option_label === option.option_label) {
        setSelectedResponses(null);
        const updatedTextRule = newtextRule.replace(optionLabel, "").replace(/\s\s+/g, " ").trim();
        dispatch(updateAdverseTextRule({ newIndex1: index, newValue: updatedTextRule }));
        onChange(updatedTextRule);
        setTimeout(() => setCaretCharacterOffsetWithin(editorRef?.current, startPos + optionLabel.length), 0);
      } else {
        setSelectedResponses(option);
        const updatedTextRule = newtextRule.slice(0, startPos) + optionLabel + textRule.slice(endPos);
        dispatch(updateAdverseTextRule({ newIndex1: index, newValue: updatedTextRule }));
        onChange(updatedTextRule);
        // setTimeout(() => {
        //   editorRef?.setSelectionRange(
        //     startPos + optionLabel.length,
        //     startPos + optionLabel.length
        //   );
        //   editorRef?.focus();
        // }, 0);
        const diff = optionLabel.length - lastElementOfTextRule.length - 2;
        const final = doesItInclude ? startPos + diff : startPos + optionLabel.length;
        setTimeout(() => setCaretCharacterOffsetWithin(editorRef?.current, final), 0);
        editorRef.current.focus();
      }
    } else {
      const textRuleArray1 = editorRef?.current.textContent;
      const textRuleArray = textRuleArray1?.split(" ") || [];
      const lengthOfTextRule = textRuleArray.length;
      let lastElementOfTextRule = textRuleArray[lengthOfTextRule - 1] == "" ? textRuleArray[lengthOfTextRule - 2] : textRuleArray[lengthOfTextRule - 1];
      const optionsForVisible = options.map((ele) => ele.option_id);
      lastElementOfTextRule = lastElementOfTextRule.trim();
      const absqjhgefvwc = [...optionsForVisible];
      const doesItInclude = absqjhgefvwc.includes(lastElementOfTextRule.trim());
      let newtextRule = textRule;
      if (doesItInclude) {
        const lastIndexOf = textRuleArray.lastIndexOf(lastElementOfTextRule.trim());
        const cleanedLastElement = lastElementOfTextRule.trim().replace(/^"(.*)"$/, "$1");
        const newArray = [];

        for (let i = 0; i < textRuleArray.length - 1; i++) {
          if (i != lastIndexOf) {
            let ele = textRuleArray[i];
            if (optionsForVisible.includes(ele.trim())) {
              ele = textRuleArray[i];
            }
            newArray.push(ele);
          }
        }
        newtextRule = newArray.join(" ");
      }
      if (selectedResponses?.option_label === option.option_label) {
        setSelectedResponses(null);
        const updatedTextRule = newtextRule.replace(optionLabel, "").replace(/\s\s+/g, " ").trim();
        dispatch(updateTextRule({ newIndex1: index, newValue: updatedTextRule }));
        onChange(updatedTextRule);
        setTimeout(() => setCaretCharacterOffsetWithin(editorRef?.current, startPos + optionLabel.length), 0);
      } else {
        setSelectedResponses(option);
        const updatedTextRule = newtextRule.slice(0, startPos) + optionLabel + newtextRule.slice(endPos);
        dispatch(updateTextRule({ newIndex1: index, newValue: updatedTextRule }));
        onChange(updatedTextRule);
        // setTimeout(() => {
        //   editorRef?.setSelectionRange(
        //     startPos + optionLabel.length,
        //     startPos + optionLabel.length
        //   );
        //   editorRef?.focus();
        // }, 0);
        const diff = optionLabel.length - lastElementOfTextRule.length - 2;
        const final = doesItInclude ? startPos + diff : startPos + optionLabel.length;
        setTimeout(() => setCaretCharacterOffsetWithin(editorRef?.current, final), 0);
        editorRef.current.focus();
        // editorRef.current?.focus();
      }
    }
  };

  const adv_question_type = useSelector((state) => state?.questions?.adverseSelectedQuestionId?.meta?.type);
  const jok_question_type = useSelector((state) => state?.questions?.selectedQuestionId?.meta?.type);
  const question_type = selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS ? adv_question_type : jok_question_type;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const toggleContainerVisibility = () => {
    setIsContainerVisible(!isContainerVisible);
  };

  const handleClose = () => {
    setSelectedResponses(null);
    setIsContainerVisible(false);
  };
  const isExpanded = useSelector((state) => state?.text?.textRules[index]?.isExpanded);
  const isAdverseExpanded = useSelector((state) => state?.adversetext?.textRules[index]?.isExpanded);
  const Expand = selectedHorizontalTab == "Adverse Events" ? isAdverseExpanded : isExpanded;

  return (
    <>
      <div className="text-box-container">
        {!showOptionCount ? (
          <OperatorButtons handleButtonClick={handleOperatorsButtonClick} />
        ) : (
          <>
            <div className="buttonwithresponse">
              <QuestionSelector handleButtonClick={handleOperatorsButtonClick} questiontype={question_type} />
              {question_type !== "opentext" && question_type !== QUESTION_TYPES.OPEN_TEXT_LIST && (
                <div className="selectyourresponse">
                  {isResponseButtonVisible && !isContainerVisible && (
                    <Button variant="" className="demo" onClick={toggleContainerVisibility} startIcon={<SelectYourResponse className="selectresponse" />} />
                  )}
                </div>
              )}
            </div>

            {isContainerVisible && (
              <div className="meanscontainer">
                <ResponseSelector
                  selectedResponses={selectedResponses}
                  onResponseChange={handleRadioBtnChange}
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                  options={options}
                  handleClose={handleClose}
                />
              </div>
            )}
          </>
        )}

        <div className="text-box">
          {/* <textarea
            ref={textAreaRef}
            value={selectedHorizontalTab == "Joker Analysis" ? textRule : adverseTextRule}
            title={textAreaTitle}
            onChange={(e) => handleInputChange(e, newIndex1)}
            onKeyDown={handleKeyDown}
            onClick={(event) => {
              setCaretPosition(event.target.selectionStart);
              setCaretEndPosition(event.target.selectionEnd);
            }}
            onKeyUp={(event) => {
              setCaretPosition(event.target.selectionStart);
              setCaretEndPosition(event.target.selectionEnd);
            }}
            className={textRule.trim() == "" ? "text-area text-area-empty-state" : "text-area"}
          /> */}
          <RuleBuilderTextArea
            textRule={selectedHorizontalTab == "Joker Analysis" ? textRule : adverseTextRule}
            setCaretPosition={setCaretPosition}
            setCaretEndPosition={setCaretEndPosition}
            handleExternalKeyDown={handleKeyDown}
            handleExternalInputChange={handleInputChange}
            newIndex1={1}
            Expand={Expand}
          />
        </div>
      </div>
    </>
  );
};

export default TextWithIcon2;
