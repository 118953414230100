import softIconTrash from "../../../assets/images/deletemodal.svg";
import softDeleteIcon from "../../../assets/images/cross_icon.svg";
import "../DeleteModal/DeleteModal.css";

const DeleteProjectModalBody = ({ cancellDeleteHandler, deleteQuestiobnHandler }) => (
  <div className="soft-delete">
    <div className="soft-delete-container">
      <div onClick={cancellDeleteHandler} className="close-top-icon">
        <img src={softDeleteIcon} alt="" />
      </div>
      <div className="soft-delete-trash-container">
        <img src={softIconTrash} alt="" />
      </div>
      <div className="soft-del-sure-msg">Sure you want to delete?</div>
      <div className="soft-del-label-msg">The Rule will be deleted. You won’t be</div>
      <div className="soft-del-label-msg-second">able to access it.</div>
      <div onClick={deleteQuestiobnHandler} className="confirm-btn">
        Yes, delete it
      </div>
      <div onClick={cancellDeleteHandler} className="cancel-btn-soft">
        No, cancel
      </div>
    </div>
  </div>
);

export default DeleteProjectModalBody;
