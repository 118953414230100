import React from "react";
import { useSelector } from "react-redux";
import * as _ from "lodash";

import JockerTable from "../JockerTable/JockerTable";
import AdverseJokerTable from "../AdverseJokerTable/AdverseJokerTable";

const JokerDatabaseAndSummary = ({
  projectId,
  calculatedRespondentsObject,
  setCalculatedRespondentsObject,
  initApiFunction,
  limit,
  offset,
  setOffset,
  debouncedSearchQuery = "",
}) => {
  // const rules = useSelector((state) => state?.rules?.activeRules) ?? undefined;
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);

  const jokerAnalysisRules = (useSelector((state) => state?.rules?.activeRules) ?? undefined)?.filter((rule) => rule?.is_valid);
  const adverseRules = (useSelector((state) => state?.adverserules?.activeRules) ?? undefined)?.filter((rule) => rule?.is_valid);

  const rules = (selectedHorizontalTab === "Adverse Events" ? adverseRules : selectedHorizontalTab === "Joker Analysis" ? jokerAnalysisRules : [])?.filter(
    (rule) => rule?.is_valid
  );

  const patternRecognitionGroupedDetails = useSelector((state) => state?.patternRecognition?.includeExcludeApi?.mappedData) ?? undefined;

  const respondentDetailsArray = useSelector((state) => state?.ruleExecution?.ruleExecutionResponse) ?? undefined;
  const groupedRespondentDetails = _.groupBy(respondentDetailsArray, "respondent_id");

  return (
    <>
      {selectedHorizontalTab === "Adverse Events" && (
        <div className="">
          <AdverseJokerTable
            groupedRespondentDetails={groupedRespondentDetails}
            patternRecognitionGroupedDetails={patternRecognitionGroupedDetails}
            rules={rules}
            projectId={projectId}
            // debouncedSearchQuery={debouncedSearchQuery}
          />
        </div>
        // <AdverseTable />
      )}
      {selectedHorizontalTab === "Joker Analysis" && (
        <JockerTable
          debouncedSearchQuery={debouncedSearchQuery}
          groupedRespondentDetails={groupedRespondentDetails}
          patternRecognitionGroupedDetails={patternRecognitionGroupedDetails}
          calculatedRespondentObject={calculatedRespondentsObject}
          setCalculatedRespondentsObject={setCalculatedRespondentsObject}
          rules={rules}
          initApiFunction={initApiFunction}
          limit={limit}
          offset={offset}
          setOffset={setOffset}
        />
      )}
    </>
  );
};

export default JokerDatabaseAndSummary;
