import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "@uidotdev/usehooks";

import SwitchButton from "../../../../components/Switch/SwitchButton/SwitchButton.jsx";
import SelectDropdown from "../../../../components/SelectDropdown/SelectDropdown.jsx";
import ButtonComponent from "../../../../components/Button/ButtonComponent.jsx";
import RuleTable from "../../../../components/Tables/RuleTable/RuleTable.jsx";
import TextAreaComponent from "../../../../components/TextareaComponent/TextAreaComponent.jsx";
import useEvaluation from "../../../../hooks/useEvaluation.jsx";
import { getAllRulesByProjectId } from "../../../../services/projectService.js";
import WarningModal from "../../../../components/modals/WarningModal/WarningModal.jsx";
import "./BuildQCRule.css";
import {
  addTextRule,
  deleteTextRule,
  resetAllTextRules,
  startSaveJokerRules,
  updateTextRuleName,
} from "../../../../features/designQcRule/BuildRule/BuildRuleslice.js";
import { setIsRuleModified, setIsSwitchButtonPositionLeft } from "../../../../features/designQcRule/Rule/ruleSlice.js";
import { closeLoader, openLoader } from "../../../../features/loader/loaderSlice.js";
import { OwnerFilter } from "../../../../assets/constants/constant.js";
import SearchComponent from "../../../../components/SearchBox/SearchBox.jsx";
import Norule from "../../../../assets/images/Norule.svg";

function BuildQCRule() {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [showRuleButtons, setShowRuleButtons] = useState(true);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [accordionCount, setAccordionCount] = useState(1);
  const rules = useSelector((state) => state?.rules?.rules);
  const adverseRules = useSelector((state) => state.adverseRule.adverserules);
  const [ruleName, setRuleName] = useState(null);
  const [rule, setRule] = useState(null);
  const [isValidExpression, setExpression, evaluateExpression] = useEvaluation(rule);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [prevRuleName, setPrevRuleName] = useState(null);
  const isSwitchOnLeftPosition = useSelector((state) => state.rules.isSwitchButtonPositionLeft ?? false);
  const isRuleModified = useSelector((state) => state.rules.isRuleModified);
  const [accordions, setAccordions] = useState([{ ruleName: "", rule: "" }]);
  const [table_length, setTable_length] = useState();
  // Redux state selectors
  const textBoxValue = useSelector((state) => state.text.textRule?.rule);
  const textWithIconValue = useSelector((state) => state?.text?.textRules?.rule_name);
  const rule_Rule_name = useSelector((state) => state?.text?.textRules);
  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);

  const dataMapFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);
  const dataMapFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.id);

  const rawFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeRawDataFile?.name);
  const rawFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeRawDataFile?.id);

  const username = useSelector((state) => state?.user?.userInfo?.email) ?? "harsh.chauhan@inizioadvisory.onmicrosoft.com";
  const DEBOUNCED_SEARCH_TIME = 700;
  const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCED_SEARCH_TIME);
  const deleteClicked = useSelector((state) => state?.rules?.deleteclicked);

  useEffect(() => {
    async function fetchUpdatedSearchTerm() {
      // await handleFetchKeywords(offset, debouncedSearchQuery);
      // setOffset(0);
    }

    fetchUpdatedSearchTerm();
  }, [debouncedSearchQuery]);

  React.useEffect(() => {
    if (rule !== null && prevRuleName !== rule) {
      setPrevRuleName(rule);
      setIsRuleModified(true);
    }
  }, [rule]);

  const creators = Array.from(new Set(rules.map((rule) => rule.created_by))).map((owner) => ({
    label: owner,
    value: owner,
  }));
  const handleOwnerChange = (selectedOption) => {
    setSelectedOwner(selectedOption.value);
    setFilterApplied(true);
  };

  const addAccordion = () => {
    dispatch(addTextRule());
    setAccordionCount((prevCount) => prevCount + 1);
  };
  useEffect(() => {
    setRuleName(textWithIconValue);
    setRule(textBoxValue);
  }, [textWithIconValue, textBoxValue]);

  const handleAddRule = () => {
    dispatch(setIsSwitchButtonPositionLeft(true));
    dispatch(addTextRule());
    setAccordionCount((prevCount) => prevCount + 1);
  };

  const handleAccordionChange = (index, field, value) => {
    setAccordions((prevAccordions) => {
      if (field === "delete") {
        if (prevAccordions.length === 1) {
          console.error("Cannot delete the last accordion.");
          return prevAccordions;
        } else {
          // Filter out the accordion at the specified index
          return prevAccordions.filter((_, i) => i !== index);
        }
      } else if (field === "reset") {
        // Reset the accordion at the specified index
        const updatedAccordions = [...prevAccordions];
        updatedAccordions[index] = { ruleName: "", rule: "" };
        return updatedAccordions;
      } else {
        // Update the specific field
        const updatedAccordions = [...prevAccordions];
        updatedAccordions[index][field] = value;
        if (field === "rule") {
          setExpression(value);
        }
        return updatedAccordions;
      }
    });
  };

  const handleDeleteAccordion = (index) => {
    dispatch(deleteTextRule({ index }));
    setAccordionCount((prevCount) => prevCount - 1);
  };

  const handleClearAll = () => {
    dispatch(resetAllTextRules());
  };

  const handleDropdownOptionSelect = (value) => {
    setSelectedOwner(value);
    setFilterApplied(value !== "");
  };

  if (isSwitchOnLeftPosition && showRuleButtons !== false) {
    setShowRuleButtons(false);
  }

  if (!isSwitchOnLeftPosition && showRuleButtons !== true) {
    setShowRuleButtons(true);
  }

  const handleSave = async () => {
    dispatch(startSaveJokerRules());
    // let allValid = true;
    // let invalidMessage = "";

    // for (const ruleObj of rule_Rule_name) {
    //   const { rule, rule_name, questionsMeta, optionsMeta } = ruleObj;
    //   const [preProcessedResult, customValidationMessage] = checkEvaluation(rule, questionsMeta, optionsMeta);

    //   if (!preProcessedResult) {
    //     allValid = false;

    //     if (customValidationMessage) {
    //       invalidMessage = `Invalid rule: ${rule_name}. ` + customValidationMessage;
    //     } else {
    //       invalidMessage = `Invalid rule: "${rule_name}". Please enter a valid expression.`;
    //     }

    //     break;
    //   }
    // }
    // const isEmptyRuleName = rule_Rule_name.some((ruleObj) => !ruleObj?.rule_name || ruleObj?.rule_name.trim() === "");
    // const isEmptyRule = rule_Rule_name.some((ruleObj) => !ruleObj?.rule || ruleObj?.rule.trim() === "");

    // if (isEmptyRuleName) {
    //   dispatch(openSnackbar({ message: "Rule name is empty. Please fill rule name.", type: SNACKBAR_TYPES.ERROR }));
    // } else if (isEmptyRule) {
    //   dispatch(openSnackbar({ message: "Rule is empty. Please fill in the rule.", type: SNACKBAR_TYPES.ERROR }));
    // } else if (!allValid) {
    //   if (invalidMessage) {
    //     dispatch(openSnackbar({ message: invalidMessage, type: SNACKBAR_TYPES.ERROR }));
    //   } else {
    //     dispatch(openSnackbar({ message: "Rule expression is invalid. Please enter a valid expression.", type: SNACKBAR_TYPES.ERROR }));
    //   }
    // } else {
    //   try {
    //     dispatch(openLoader());
    //     const newRulesData = rule_Rule_name.map((ruleObj) => ({
    //       id: ruleObj?.id ?? null,
    //       project_id: projectId,
    //       rule_name: ruleObj.rule_name.trim(),
    //       rule_type: "JOKER_ANALYSIS",
    //       rule: ruleObj.rule.trim(),
    //       optionsMeta: ruleObj?.optionsMeta ?? {},
    //       questionsMeta: ruleObj?.questionsMeta ?? {},
    //       weight: "0",
    //       is_rule_active: false,
    //     }));

    //     const newRuleData = {
    //       rules: newRulesData,
    //       updated_by: username,
    //     };

    //     const response = await createRule(newRuleData);
    //     const ids = response?.data?.map((rule) => rule.id);
    //     dispatch(updateRuleIds({ ids }));
    //     // dispatch(setIsSwitchButtonPositionLeft(true));
    //     dispatch(openSnackbar({ message: "Rule created successfully!", type: SNACKBAR_TYPES.SUCCESS }));
    //     // dispatch(resetEntireTextRules());
    //     dispatch(setIsRuleModified(false));
    //   } catch (error) {
    //     if (error?.response?.data?.errorCode === "E011")
    //       dispatch(openSnackbar({ message: "Duplicate rule name received from Joker Analysis or Adverse Event", type: SNACKBAR_TYPES.ERROR }));
    //     else dispatch(openSnackbar({ message: error?.response?.data?.message, type: SNACKBAR_TYPES.ERROR }));
    //   } finally {
    //     dispatch(closeLoader());
    //   }
    // }
  };

  const handleRuleNameChange = (value) => {
    dispatch(updateTextRuleName(value));
    dispatch(setIsRuleModified(true));
  };

  const handleRuleChange = (value) => {
    setExpression(value);
  };
  const handleApplyAll = () => {
    dispatch(setIsSwitchButtonPositionLeft(true));
    dispatch(addTextRule());
    setAccordionCount((prevCount) => prevCount + 1);
  };
  const handleSearchInputChange = async (event) => {
    const newSearchValue = event.target.value;
    setSearchQuery(newSearchValue);
  };
  // Get rule data from the API
  React.useEffect(() => {
    const getRulesFunction = async () => {
      try {
        dispatch(openLoader());
        const ruleType = "JOKER_ANALYSIS";
        const response = await getAllRulesByProjectId(projectId, ruleType);
        const table_length = response?.data?.items?.length;
        setTable_length(table_length);
      } catch (error) {
        // dispatch(openSnackbar({ message: "Failed to fetch rules", type: SNACKBAR_TYPES.ERROR }));
        console.error("Failed to fetch rules", error);
      } finally {
        dispatch(closeLoader());
      }
    };
    getRulesFunction();
  }, [isSwitchOnLeftPosition, rules.length]);

  return (
    <>
      <div className="buttons">
        <SwitchButton />

        {showRuleButtons && table_length > 0 && (
          <div className="saved_btn_combine">
            <div className="ruletablesearch-unique">
              <SearchComponent value={searchQuery} onChange={handleSearchInputChange} className="open_text_search" width="244px" height="32px" />
            </div>
            <div className="owner_dropdown">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {OwnerFilter.Owner}
                {/* Owner : */}
              </p>
              <SelectDropdown
                options={[{ label: "All Owners", value: "" }, ...creators]}
                value={selectedOwner}
                onChange={handleOwnerChange}
                className={"custom-select-dropdown"}
                placeholder={selectedOwner ? selectedOwner : "All Owners"}
              />
            </div>
            <ButtonComponent
              onClick={handleApplyAll}
              className="file_upload_btn"
              btnType="secondary"
              text="Add Rule"
              sx={{ width: "104px", height: "32px !important" }}
            />
          </div>
        )}
        {!showRuleButtons && (
          <div className="" style={{ marginLeft: "auto" }}>
            <ButtonComponent
              className=""
              btnType="outline"
              text="Clear all"
              onClick={handleClearAll}
              sx={{ width: "104px", marginRight: "10px", height: "32px !important" }}
            />
            <ButtonComponent
              className="file_upload_btn"
              btnType="secondary"
              text="Save"
              onClick={handleSave}
              sx={{ width: "80px", marginRight: "5px", height: "32px !important" }}
            />
            <ButtonComponent
              className="file_upload_btn"
              btnType="secondary"
              text="Add Rule"
              onClick={handleAddRule}
              sx={{ width: "140px", height: "32px !important" }}
            />
          </div>
        )}
      </div>
      <div className="table-rule">
        {!showRuleButtons && (
          <TextAreaComponent
            handleDelete={handleDeleteAccordion}
            onRuleNameChange={handleRuleNameChange}
            onRuleChange={handleRuleChange}
            onAccordionChange={handleAccordionChange}
            addAccordion={handleAddRule}
          />
        )}
      </div>

      <div className="table-rule">
        {showRuleButtons && table_length > 0 && (
          <RuleTable selectedOwner={selectedOwner} debouncedSearchQuery={debouncedSearchQuery} isRuleModified={isRuleModified} />
        )}
      </div>
      {showRuleButtons && table_length == 0 && (
        <div className="no-rule">
          <div className="image_no-rule">
            <img src={Norule} alt="No rule" />
          </div>
          <div>
            <p className="no-rule-p1">No saved rules</p>
            <p className="no-rule-p2">Click on add rule button</p>
            <p className="no-rule-p3"> to add a rule</p>
            <ButtonComponent
              className="btnPrimary"
              variant="contained"
              btnType="primary"
              text="Add Rule"
              onClick={handleAddRule}
              sx={{ width: "154px", height: "38px !important" }}
            />
          </div>
        </div>
      )}
      {showPopup && <WarningModal openModal={showPopup} setOpenModal={setShowPopup} headerText="Warning" titleText={popupMessage} />}
    </>
  );
}

export default BuildQCRule;
