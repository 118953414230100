import AssetNavCardIcon from "../images/AssetNavCardIcon.svg";
import IndicationsCardIcon from "../images/IndicationsCardIcon.svg";
import QuantNAVCardIcon from "../images/QuantNAVCardIcon.svg";
import ComingSoonCardIcon from "../images/coming_soon.png";

export const GIBBERISH_ERROR_CODE = "E014";

export const dataStatusTypeOptions = [
  { label: "Flat", value: "Flat" },
  { label: "Stacked", value: "Stacked", isDisabled: true }, // disabled stacked option
];

export const selectType = Array.from({ length: 10 }, (v, i) => ({
  label: `Wave ${i + 1}`,
  value: `Wave ${i + 1}`,
}));
export const objectiveTypeOptions = [
  { label: "ATU", value: "ATU" },
  { label: "Brand Equity", value: "Brand Equity" },
  { label: "Brand name testing", value: "Brand name testing" },
  { label: "Buying Process", value: "Buying Process" },
  { label: "Clinical intelligence", value: "Clinical intelligence" },
  { label: "Competitor intelligence", value: "Competitor intelligence" },
  { label: "Concept testing", value: "Concept testing" },
  { label: "Conference research", value: "Conference research" },
  { label: "Conjoint", value: "Conjoint" },
  { label: "Consumer Health", value: "Consumer Health" },
  {
    label: "Customer Satisfaction (Loyality)",
    value: "Customer Satisfaction (Loyality)",
  },
  { label: "DFU", value: "DFU" },
  { label: "Demand Assessment", value: "Demand Assessment" },
  { label: "Detail aid testing", value: "Detail aid testing" },
  { label: "Device testing", value: "Device testing" },
  { label: "Diagnostic testing", value: "Diagnostic testing" },
  { label: "Ethnography", value: "Ethnography" },
  { label: "Fieldwork only", value: "Fieldwork only" },
  { label: "Forecasting", value: "Forecasting" },
  { label: "Formulation testing", value: "Formulation testing" },
  { label: "Human Factors", value: "Human Factors" },
  { label: "Licensing opportunity", value: "Licensing opportunity" },
  { label: "Line extension research", value: "Line extension research" },
  { label: "Logo testing", value: "Logo testing" },
  {
    label: "Loss of exclusivity studies",
    value: "Loss of exclusivity studies",
  },
  { label: "Market sizing", value: "Market sizing" },
  { label: "Market understanding", value: "Market understanding" },
  { label: "Message recall", value: "Message recall" },
  { label: "Message testing", value: "Message testing" },
  { label: "Omnichannel", value: "Omnichannel" },
  { label: "Opportunity assessment", value: "Opportunity assessment" },
  {
    label: "PR/healthcare communications",
    value: "PR/healthcare communications",
  },
  { label: "Package testing", value: "Package testing" },
  { label: "Patient Flow", value: "Patient Flow" },
];
export const organizationOptions = [
  { label: "Research Partnership", value: "Research Partnership" },
  { label: "Putnam", value: "Putnam" },
];
export const fileTypeOptions = [
  { label: "Dummy", value: "Dummy" },
  { label: "Interim", value: "Interim" },
  { label: "Final", value: "Final" },
];

export const collaborators = [
  {
    label: "amanpreetloomba@inizio.health",
    value: "amanpreetloomba@inizio.health",
  },
  { label: "johndoe@inizio.health", value: "johndoe@inizio.health" },
  { label: "kamal@inizio.health", value: "kamal@inizio.health" },
  { label: "prasant@inizio.health", value: "prasant@inizio.health" },
  { label: "ruchi@inizio.health", value: "ruchi@inizio.health" },
  { label: "prakhar@inizio.health", value: "prakhar@inizio.health" },
];

export const successArchievedMessage = {
  label: "archieved",
  value: "Project Archieved Successfully!!",
};

export const successDeletedMessage = {
  label: "deleted",
  value: "Project Deleted Successfully!!",
};
export const required = {
  label: "requiredMessage",
  value: "This field is required!",
};
export const fileUploadModalText = {
  uploadFailed: "File Uploaded Failed",
  uploadSuccessfull: "File Uploaded successfully",
  dataValidationInsightsGeneration: "Data validation and insights generation",
  mergedFileInProgress: "Merge file is in progress, Please wait!!",
  mergedFileSuccessFull: "Merge file has been generated successfully.",
  failedToMergeFile: "Failed to merge file.",
  disclaimerNote: "Don't close the pop-up or refresh the window, as it will terminate the process and result in data loss.",
  uploadCorrectFiles: "Upload the correct files",
  maliciousCheckQuery: "V1_MALICIOUS_CHECK",
  uniqueRespIDCheckQuery: "V1_UNIQUE_RESP_ID_CHECK",
  rawVarCountCheckQuery: "V1_RAW_VAR_COUNT_CHECK",
  dataMapFileFmtCheckQuery: "V1_DATA_MAP_FILE_FMT_CHECK",
  dataMapVarMatchCheckQuery: "V1_DATA_MAP_VAR_MATCH_CHECK",
  rowValidationCheckQuery: "V1_ROW_VALIDATION_CHECK",
  dataMapSingleQuestionsCheckQuery: "V1_SINGLE_QUESTIONS_CHECK",
  dataMapMultiQuestionsCheckQuery: "V1_MULTI_QUESTIONS_CHECK",
  dataMapRankingQuestionsCheckQuery: "V1_RANKING_QUESTIONS_CHECK",
  dataMapNumericListQuestionsCheckQuery: "V1_NUMERICLIST_QUESTIONS_CHECK",
  dataMapOpenTextListQuestionsCheckQuery: "V1_OPENTEXTLIST_QUESTIONS_CHECK",
  dataMapGridQuestionsCheckQuery: "V1_GRID_QUESTIONS_CHECK",
};
export const confirmationArchieveTitle = "Sure you want to Archive?";
export const confirmationArchievedDescription = "The project will be archived.";
export const confirmationDeletedDescription = "The project will be deleted. You won’t be able to access it.";
export const confirmationDeleteTitle = "Sure you want to Delete?";

// Login Page Constant
export const LoginPageText =
  "specializes in AI-driven solutions for the life sciences sector, offering an advanced platform that analyzes complex medical data. Their focus is on providing actionable insights to medical affairs, regulatory affairs, and safety teams, ultimately aiming to improve patient care and outcomes.";
export const CardContainerHead = "Our Solutions";
export const AssetNavCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: true,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "Asset",
    subHeading: "NAV",
    paragraph: "An asset prioritization tool that supports with BD scans for one or more therapeutic areas and powers strategic recommendations.",
    imageUrl: AssetNavCardIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const IndicationsCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: true,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "Indication",
    subHeading: "NAV",
    paragraph:
      "An innovative tool for indication prioritization, features a quick search function, state-of-the-art scoring module, and relevant visualizations, ensuring efficient and precise execution of indication prioritization.",
    imageUrl: IndicationsCardIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const VocInsightsCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: true,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "VOC",
    subHeading: "INSIGHTS",
    paragraph:
      "In the pharmaceutical landscape, a cutting-edge app utilizes Generative AI to automate HCP perception analysis from Voice of Customer data, enabling enterprises to navigate dynamic HCP sentiments for strategic decisions.",
    imageUrl: ComingSoonCardIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const QuantNAVCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: false,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "Quant",
    subHeading: "NAV",
    paragraph:
      "A Quantitative project execution tool that enables user to perform data manipulations, apply powerful AI/ML algorithms and generate client ready visual strategic insights from the raw data.",
    imageUrl: QuantNAVCardIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const CardsListing = [
  {
    props: AssetNavCardDetail,
    index: 1,
    // navigationPath: `${process.env.REACT_APP_ASSETNAV_URL}/scope-analysis`,
    // navigationPath: "https://inizionavigator.ai/",
    navigationPath: "#",
  },
  {
    props: IndicationsCardDetail,
    index: 2,
    // navigationPath: "https://inizionavigator.ai/",
    navigationPath: "#",
  },
  {
    props: QuantNAVCardDetail,
    index: 1,
    navigationPath: `${process.env.REACT_APP_UI_URL}/`,
  },
  {
    props: VocInsightsCardDetail,
    index: 1,
    navigationPath: "#",
  },
];
export const SwitchtabLabels = {
  left: "Build Rule",
  right: "Saved Rules",
};
export const OwnerFilter = {
  Owner: "Owner :",
};

export const ROUTES = {
  HOME_PAGE: "/",
};

export const ACK_APIS_STATUS = {
  INITIATED: "INITIATED",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
};

export const RULE_TYPE = {
  JOKER_ANALYSIS: "JOKER_ANALYSIS",
  ADVERSE_EVENT: "ADVERSE_EVENT",
};

export const PROJECT_TYPE = {
  PROJECT: "PROJECT",
  ARCHIVED: "ARCHIVED",
};

export const SNACKBAR_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};
