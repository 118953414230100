import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAccordionIndex, setSelectedQuestionId } from "../../features/designQcRule/questionlibrary/questionlibrarySlice";
import { setIsRuleModified, updateSelectedRule, updateSelectedRuleName } from "../../features/designQcRule/Rule/ruleSlice";
import { deleteTextRule, resetAddRuleScrollTrigger, resetTextRule } from "../../features/designQcRule/BuildRule/BuildRuleslice";

import TextAreaAccordion from "./textAreaHelper/TextAreaAccordion";

export default function TextAreaComponent({ onRuleNameChange, onRuleChange, accordionCount, onAccordionChange }) {
  const textRules = useSelector((state) => state?.text?.textRules);
  const adverseTextRules = useSelector((state) => state?.adversetext?.textRules);
  const accordionIndex = useSelector((state) => state.questions.accordionIndex);
  const textBoxValue = useSelector((state) => state?.text?.textBoxValue);
  const textWithIconValue = useSelector((state) => state?.text?.textWithIconValue);
  const selectedQuestionId = useSelector((state) => state.questions.selectedQuestionId);
  const selectedRule = useSelector((state) => state?.rules?.selectedRule);
  const options = useSelector((state) => state?.questions.options);
  const [ruleName, setRuleName] = useState(selectedRule?.rule_name || textBoxValue);
  const [rule, setRule] = useState(selectedRule?.rule || textWithIconValue);
  const [accordions, setAccordions] = useState([{ ruleName: "", rule: "" }]);
  const lastAccordionRef = useRef(null);
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  const addRuleTrigger = useSelector((state) => state.text.addRuleScrollTrigger);

  const dispatch = useDispatch();
  useEffect(() => {
    if (addRuleTrigger && lastAccordionRef.current) {
      lastAccordionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    dispatch(resetAddRuleScrollTrigger());
  }, [addRuleTrigger]);

  useEffect(() => {
    if (selectedRule) {
      setRuleName(selectedRule.rule_name || "");
      setRule(selectedRule.rule || "");
    }
  }, [selectedRule]);

  useEffect(() => {
    // Check if selectedQuestionId has options
    if (selectedQuestionId && selectedQuestionId.id) {
      if (options && options.length > 0) {
        options.forEach((option, index) => {});
      } else {
        console.error("No options available for the selected question.");
      }
    } else {
      console.error("No selected question or question has no options.");
    }
  }, [selectedQuestionId, options]);

  const handleRuleNameChange = (value) => {
    dispatch(updateSelectedRuleName(value));
    dispatch(setIsRuleModified(true));
  };

  const handleRuleChange = (value) => {
    dispatch(updateSelectedRule(value));
    onRuleChange(value);

    dispatch(setIsRuleModified(true));
  };
  const formattedText = `{"rule_name":"${ruleName}","rule":"${rule}"}`;

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded((prevExpanded) => ({
  //     ...prevExpanded,
  //     [panel]: newExpanded,
  //   }));
  // };

  const handleResetAccordion = (index) => {
    dispatch(resetTextRule({ index: index }));
  };

  const handleDeleteAccordion = (index) => {
    if (textRules.length === 1) {
      console.error("Cannot delete the last accordion.");
      return;
    }
    dispatch(deleteTextRule({ index: index }));
  };
  const handleAddQuestion = (index) => {
    dispatch(setAccordionIndex(index));
  };

  const handleQuestionSelect = (id) => setSelectedQuestionId(id);

  return (
    <div style={{ height: "calc(-353px + 100vh)", overflow: "auto", position: "relative" }}>
      {selectedHorizontalTab !== "Adverse Events" ? (
        <>
          {textRules.map((rule, index) => (
            // style={{ marginBottom: "12px" }}
            <div key={index} ref={index === textRules.length - 1 ? lastAccordionRef : null}>
              <TextAreaAccordion
                key={index}
                index={index}
                selectedQuestionId={selectedQuestionId}
                rule={rule}
                handleQuestionSelect={handleQuestionSelect}
                handleRuleChange={handleRuleChange}
                options={options}
                handleRuleNameChange={handleRuleNameChange}
                handleDeleteAccordion={handleDeleteAccordion}
                handleResetAccordion={handleResetAccordion}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          {adverseTextRules.map((rule, index) => (
            // style={{ marginBottom: "12px" }}
            <div key={index} ref={index === textRules.length - 1 ? lastAccordionRef : null}>
              <TextAreaAccordion
                key={index}
                index={index}
                selectedQuestionId={selectedQuestionId}
                rule={rule}
                handleQuestionSelect={handleQuestionSelect}
                handleRuleChange={handleRuleChange}
                options={options}
                handleRuleNameChange={handleRuleNameChange}
                handleDeleteAccordion={handleDeleteAccordion}
                handleResetAccordion={handleResetAccordion}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}
