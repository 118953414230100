import { call, put, takeLatest } from "redux-saga/effects";

import { listRule } from "../../../services/projectService"; // Import your service function
import { closeLoader, openLoader } from "../../loader/loaderSlice";

import { fetchQuestionsFailure } from "./questionlibrarySlice";

function* fetchQuestionsSaga(action) {
  yield put(openLoader());
  try {
    // const modifiedRules = rules.map(rule => ({
    //   id: rule.id,
    //   ruleName: rule.ruleName,
    //   createdAt: rule.createdAt,
    //   updatedAt: rule.updatedAt
    // }));
    const { projectId, fileId } = action.payload;
    const questions = yield call(listRule, projectId, fileId);
    // yield put(fetchQuestionsSuccess(questions));
  } catch (error) {
    yield put(fetchQuestionsFailure(error.message));
  } finally {
    yield put(closeLoader());
  }
}

export function* watchFetchQuestions() {
  yield takeLatest("question/fetchQuestionStart", fetchQuestionsSaga);
}
