const RedCircleIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8784 8C14.8784 11.866 11.7444 15 7.87842 15C4.01242 15 0.878418 11.866 0.878418 8C0.878418 4.134 4.01242 1 7.87842 1C11.7444 1 14.8784 4.134 14.8784 8Z"
      fill="url(#paint0_radial_233_15618)"
    />
    <path
      d="M14.8784 8C14.8784 11.866 11.7444 15 7.87842 15C4.01242 15 0.878418 11.866 0.878418 8C0.878418 4.134 4.01242 1 7.87842 1C11.7444 1 14.8784 4.134 14.8784 8Z"
      fill="url(#paint1_linear_233_15618)"
    />
    <path
      d="M14.8784 8C14.8784 11.866 11.7444 15 7.87842 15C4.01242 15 0.878418 11.866 0.878418 8C0.878418 4.134 4.01242 1 7.87842 1C11.7444 1 14.8784 4.134 14.8784 8Z"
      fill="url(#paint2_radial_233_15618)"
    />
    <path
      d="M14.8784 8C14.8784 11.866 11.7444 15 7.87842 15C4.01242 15 0.878418 11.866 0.878418 8C0.878418 4.134 4.01242 1 7.87842 1C11.7444 1 14.8784 4.134 14.8784 8Z"
      fill="url(#paint3_radial_233_15618)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_233_15618"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.9684 4.37536) rotate(130.168) scale(13.9043)"
      >
        <stop offset="0.232" stopColor="#F24756" />
        <stop offset="1" stopColor="#B22945" />
      </radialGradient>
      <linearGradient id="paint1_linear_233_15618" x1="7.87842" y1="1" x2="7.87842" y2="4.25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DD4577" />
        <stop offset="1" stopColor="#EF4B5E" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_233_15618"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.2177 5.37474) rotate(136.38) scale(7.33835 7.908)"
      >
        <stop offset="0.179" stopColor="#FF6180" />
        <stop offset="1" stopColor="#E5364A" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_233_15618"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.1244 8) rotate(180) scale(9.625 10)"
      >
        <stop offset="0.62" stopColor="#B73E4B" stopOpacity="0" />
        <stop offset="0.951" stopColor="#D48387" />
      </radialGradient>
    </defs>
  </svg>
);

export default RedCircleIcon;
