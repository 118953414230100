export function getGenAiCombinedData(patternRecognitionGroupedDetails, genaiResponse) {
  const combinedata = {};
  for (const key in patternRecognitionGroupedDetails) {
    if (patternRecognitionGroupedDetails?.hasOwnProperty(key)) {
      combinedata[key] = {
        ...patternRecognitionGroupedDetails[key],
      };
      if (genaiResponse && genaiResponse.length > 0) {
        for (const obj of genaiResponse) {
          if (obj?.respondent_id === key) {
            combinedata[key] = {
              ...combinedata[key],
              Rationale: obj?.Rationale,
              genai_flag: obj?.genai_flag,
              Seriousness_Score: obj?.Seriousness_Score,
            };
          }
        }
      }
    }
    if (genaiResponse && genaiResponse.length > 0) {
      for (const obj of genaiResponse) {
        if (!combinedata.hasOwnProperty(obj?.respondent_id)) {
          combinedata[obj?.respondent_id] = {
            alternating_pattern: 0,
            stepped_pattern: 0,
            repetitive_pattern: 0,
            Rationale: obj?.Rationale,
            genai_flag: obj?.genai_flag,
            Seriousness_Score: obj?.Seriousness_Score,
          };
        }
      }
    }
  }

  return combinedata;
}
