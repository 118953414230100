import React, { useEffect } from "react";

import "./RuleBuilderTextArea.css";
import { useRuleBuilderTextAreaProvider } from "../../context/RuleBuilderTextArea/ruleBuilderTextAreaProvider";
import EmptyIcon from "../../assets/images/empty-icon.svg";

const RuleBuilderTextArea = ({ textRule, setCaretPosition, setCaretEndPosition, handleExternalKeyDown, handleExternalInputChange, Expand }) => {
  const {
    editorRef,
    getCaretCharacterOffsetWithin,
    handleInputUpdated,
    checkIsNotEditableByCaretPosition,
    reHighlightText,

    isOutOfFocus,
    setIsOutOfFocus,

    saveCaretDetails,

    questionArray,
    optionArray,

    saveCaretPosition,
    restoreCaretPosition,
    restoreAndAppendLengthInCaretPosition,
  } = useRuleBuilderTextAreaProvider();

  useEffect(() => {
    const editor = editorRef?.current;
    editor.addEventListener("keydown", handleKeyDown);
    editor.addEventListener("input", handleInputChange);

    return () => {
      editor.removeEventListener("keydown", handleKeyDown);
      editor.removeEventListener("input", handleInputChange);
    };
  }, [
    editorRef,
    handleExternalKeyDown,
    handleExternalInputChange,
    saveCaretDetails,
    saveCaretPosition,
    restoreCaretPosition,
    restoreAndAppendLengthInCaretPosition,
  ]);

  useEffect(() => {
    handleInputUpdated(textRule);
  }, [textRule]);

  const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Backspace"];
  const handleKeyDown = (event) => {
    if (!arrowKeys.includes(event.key) && checkIsNotEditableByCaretPosition(editorRef.current)) {
      event.preventDefault();
      return;
    }
    handleExternalKeyDown(event);
  };

  const handleInputChange = () => {
    reHighlightText();
    handleExternalInputChange(editorRef?.current.innerText);
  };

  return (
    <>
      <div
        id="editor"
        className={textRule.trim() === "" ? "TextAreaEmpty" : "TextArea"}
        ref={editorRef}
        contentEditable="true"
        tabIndex="0"
        spellCheck={false}
        onFocus={() => {
          setIsOutOfFocus(false);
          restoreCaretPosition();
        }}
        onBlur={() => {
          setIsOutOfFocus(true);
          saveCaretPosition();
        }}
        onClick={() => {
          const position = getCaretCharacterOffsetWithin(editorRef?.current);
          // setCaretPosition(position);
          // setCaretEndPosition(position);
          // setCaretCharacterOffsetWithin(editorRef?.current, position)
        }}
        onKeyUp={() => {
          const position = getCaretCharacterOffsetWithin(editorRef?.current);
          // setCaretCharacterOffsetWithin(editorRef?.current, position)
          // setCaretPosition(position);
          // setCaretEndPosition(position);
        }}
        style={{ minWidth: "300px" }}
      ></div>
      {textRule.trim() === "" && Expand && (
        <div className="emptyRule">
          <div className="text-overlay">
            {/* <EmptyIcon /> */}
            <img src={EmptyIcon} alt="empty-icon" />
            <p>Please start by adding a question to begin building query 🚀</p>
          </div>
        </div>
      )}
    </>
  );
};

export default RuleBuilderTextArea;
