import React from "react";
import "./textField.css";
import { IoSearch } from "react-icons/io5";

const TextField = ({ type, className, placeholder, value, onChange, name, disabled = false, showSearchIcon = false, autoComplete = "off" }) => (
  <div className="input-container">
    {showSearchIcon && <IoSearch className="search-icon" />}
    <input
      type={type}
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      name={name}
      disabled={disabled}
      autoComplete={autoComplete}
    />
  </div>
);

export default TextField;
