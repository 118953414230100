export const QUESTION_TYPES = {
  NUMERIC: "numeric",
  OPEN_TEXT: "opentext",
  SINGLE: "single",
  MULTI: "multi",
  RANKING: "ranking",
  NUMERIC_LIST: "numericlist",
  GRID: "grid",
  OPEN_TEXT_LIST: "opentextlist",
};

export const QUESTION_TYPES_ARRAY = [
  { value: QUESTION_TYPES.NUMERIC, label: "Numeric" },
  { value: QUESTION_TYPES.OPEN_TEXT, label: "Open text" },
  { value: QUESTION_TYPES.OPEN_TEXT_LIST, label: "Open Text List" },
  { value: QUESTION_TYPES.SINGLE, label: "Single" },
  { value: QUESTION_TYPES.MULTI, label: "Multi" },
  { value: QUESTION_TYPES.RANKING, label: "Ranking" },
  { value: QUESTION_TYPES.NUMERIC_LIST, label: "Numeric List" },
  { value: QUESTION_TYPES.GRID, label: "Grid" },
];

export const OPTION_DROPDOWN_QUESTION_TYPES_ARRAY = [
  { value: "", label: "All" },
  { value: QUESTION_TYPES.NUMERIC, label: "Numeric" },
  { value: QUESTION_TYPES.OPEN_TEXT, label: "Open text" },
  { value: QUESTION_TYPES.OPEN_TEXT_LIST, label: "Open Text List" },
  { value: QUESTION_TYPES.SINGLE, label: "Single" },
  { value: QUESTION_TYPES.MULTI, label: "Multi" },
  { value: QUESTION_TYPES.RANKING, label: "Ranking" },
  { value: QUESTION_TYPES.NUMERIC_LIST, label: "Numeric List" },
  { value: QUESTION_TYPES.GRID, label: "Grid" },
];
