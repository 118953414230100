import React, { useEffect, useState } from "react";

import ButtonComponent from "../../../../components/Button/ButtonComponent";
import "./OpenText.css";
import SearchComponent from "../../../../components/SearchBox/SearchBox";

import { Checkbox, FormControlLabel, Grid, TableContainer, TextField, styled } from "@mui/material";

import {
  setExcludedKeyWords,
  setFetchedKeywords,
  setOpenTextFieldValue,
  setSelectedKeywords,
  toggleKeyword,
} from "../../../../features/adverseEvent/OpenText/OpentextSlice";

import { useDispatch, useSelector } from "react-redux";

import {
  deselectAllkeyword,
  getActiveKeyWords,
  getKeyword,
  runkeyword,
  saveExcludedActiveKeywords,
  saveKeyword,
  selectAllkeyword,
} from "../../../../services/adverseEventService";
import { closeLoader, openLoader } from "../../../../features/loader/loaderSlice";
import { resetIsOpentextNextTriggered, resetIsOpentextRedirect, setIsOpentextRedirect } from "../../../../features/adverseEvent/AdverseRule/AdverseRule";
import { setDesignQcSelectedTab } from "../../../../features/designQcRule/designQc/DesignQcSlice";
import { getMergedFilePath } from "../../../../services/patternService";
import { openSnackbar } from "../../../../features/snackbar/snackbarslice";
import { ADVERSE_EVENTS_TABS } from "../../DesignQc.constants";
import PaginationComponent from "../../../../components/PaginationComponent/PaginationComponent";

import { useDebounce } from "@uidotdev/usehooks";

import { SNACKBAR_TYPES } from "../../../../assets/constants/constant";

function OpenText() {
  const LIMIT = 100;
  const DEBOUNCED_SEARCH_TIME = 700;

  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedKeywords, setSearchedKeywords] = useState([]);
  const [initSearchQuery, setInitSearchQuery] = useState(false);
  const [showKeywordInput, setShowKeywordInput] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const selectedKeywordsarray = useSelector((state) => state?.opentextField?.selectedKeywords);
  const excludedKeywordsarray = useSelector((state) => state?.opentextField?.excludedKeywords);
  const fetchedKeywords = useSelector((state) => state?.opentextField?.fetchedKeywords);
  const dataMapFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);
  const mergeFile = useSelector((state) => state?.patternRecognition?.mergefile);
  const projectID = useSelector((state) => state?.createProjectDetails?.data?.id);
  const username = useSelector((state) => state?.user?.userInfo?.email) ?? "";
  const keywordValue = useSelector((state) => state?.opentextField?.value);
  const excludedKeywordsIds = useSelector((state) => state?.opentextField?.excludedKeywords);
  const isNextTriggered = useSelector((state) => state?.adverseRule?.isNextTriggered);

  const isRedirect = useSelector((state) => state?.adverseRule?.isRedirect);
  const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCED_SEARCH_TIME);

  const activeKeyword = selectedKeywordsarray.map((obj) => obj.keyword);

  // const projectID = 627;

  // const filteredKeywords = fetchedKeywords.filter(keyword => keyword.keyword.toLowerCase().includes(searchQuery.toLowerCase()));

  useEffect(() => {
    handleFetchKeywords(offset, searchQuery, "firstTime");
  }, []);

  useEffect(() => {
    async function fetchUpdatedSearchTerm() {
      // if (initSearchQuery) {
      await handleFetchKeywords(offset, debouncedSearchQuery);
      setOffset(0);
      // }
    }

    fetchUpdatedSearchTerm();
  }, [debouncedSearchQuery]);

  useEffect(() => {
    const handleTrigger = async () => {
      if (isNextTriggered) {
        dispatch(resetIsOpentextNextTriggered());
        try {
          await handleOpenTextInitiateRequest();
          dispatch(setIsOpentextRedirect());
        } catch (error) {
          console.error("error");
        }
      }
    };

    handleTrigger();
  }, [isNextTriggered]);

  if (isRedirect) {
    dispatch(resetIsOpentextRedirect());
    dispatch(setDesignQcSelectedTab(ADVERSE_EVENTS_TABS.ADVERSE_PREVIEW));
  }

  const handlePageChange = async (newOffset) => {
    setOffset(newOffset);
    await handleFetchKeywords(newOffset, searchQuery);
  };

  const handleSearchInputChange = async (event) => {
    const newSearchValue = event.target.value.toLowerCase();
    setOffset(0);
    setSearchQuery(newSearchValue);
  };

  const handleTextFieldChange = (e) => {
    const newvalue = e.target.value;
    dispatch(setOpenTextFieldValue(newvalue));
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const keywordEnter = useSelector((state) => state?.opentextField?.value);

  const handleSave = async () => {
    const payload = {
      keyword: keywordEnter,
      username: username,
      projectId: projectID,
    };
    dispatch(openLoader());
    try {
      const response = await saveKeyword(payload);
      dispatch(setSelectedKeywords([...selectedKeywordsarray, response.data.id]));
      dispatch(setFetchedKeywords([...fetchedKeywords, response.data]));
      dispatch(openSnackbar({ message: "Keyword added successfully", type: SNACKBAR_TYPES.SUCCESS }));
      setShowKeywordInput(false);
    } catch (error) {
      dispatch(
        openSnackbar({
          message: error?.message ?? "Something went wrong",
          type: SNACKBAR_TYPES.ERROR,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const handleClearAll = async () => {
    dispatch(setOpenTextFieldValue(""));
  };

  const StyledTableContainer = styled(TableContainer)({
    padding: "0px",
  });

  const handleCheckboxChange = (opentext) => {
    dispatch(toggleKeyword(opentext));
  };

  const handleRunClicked = async () => {
    try {
      await handleOpenTextInitiateRequest();
    } catch (error) {
      // console.error({ error });
    }
  };

  const handleFetchKeywords = async (offset, searchTerm) => {
    dispatch(openLoader());
    try {
      // featchActiveData()
      const activeWordsData = await getActiveKeyWords(projectID);
      // dispatch(setAllKeywords(activeWordsData?.data[0]?.active_keyword_ids));

      let selecteArray = activeWordsData?.data[0]?.active_keyword_ids || [];

      selecteArray = selecteArray.map((ele) => Number(ele));
      selecteArray = selecteArray.filter((ele) => !excludedKeywordsarray.includes(ele));

      dispatch(setSelectedKeywords([...new Set([...selectedKeywordsarray, ...selecteArray])]));
      const { data, total: newTotalValue } = await getKeyword(projectID, LIMIT, offset ?? 0, searchTerm ?? null);
      const slicedRes = data.slice(0, 100);

      const excludedKeys = [];
      for (const opentext of data) {
        // let idStr = String(ele.id);
        if (!selecteArray.includes(opentext.id)) {
          excludedKeys.push(Number(opentext.id));
        }
      }

      dispatch(setExcludedKeyWords([...new Set([...excludedKeywordsarray, ...excludedKeys])]));

      if (totalCount !== newTotalValue) {
        setTotalCount(newTotalValue);
      }

      dispatch(setFetchedKeywords(slicedRes));
      // setSearchedKeywords()
    } catch (error) {
      console.error("Error fetching keywords:", error.message);
    } finally {
      dispatch(closeLoader());
    }
  };

  async function handleOpenTextInitiateRequest() {
    try {
      const payload = {
        toUpsertData: {
          project_id: projectID,
          excluded_keyword_ids: excludedKeywordsIds.map((ele) => Number(ele)),
        },
        username: username,
      };

      const mergedFilePath = await getMergedFilePath(dataMapFileName, projectID);
      // const mergedFilePath = "Quant/Putnam/Test client/Test June 13 1/Harsh Chauhan/dummy/Merged_Layer/Merged_Data_Flat_2024-06-13 05:59:12";
      const initiatePayload = {
        mergedFilePath: mergedFilePath,
        projectId: projectID,
      };

      dispatch(openLoader());

      const data = await saveExcludedActiveKeywords(payload);
      const initiatedata = await runkeyword(initiatePayload);
      dispatch(
        openSnackbar({
          message: initiatedata?.data?.message ?? initiatedata?.data?.status ?? "",
          type: SNACKBAR_TYPES.SUCCESS,
          autoHideDuration: 9000,
        })
      );
    } catch (error) {
      dispatch(openSnackbar({ message: "Unable to save keywords", type: SNACKBAR_TYPES.ERROR }));
      throw error;
    } finally {
      dispatch(closeLoader());
    }
  }

  // select deselect button logic
  const handleToggleSelectAll = async () => {
    const data = {
      projectId: projectID,
      username: username,
    };
    try {
      dispatch(openLoader());
      const selectAll = await selectAllkeyword(data);
      const selectedAllWords = selectAll.data.active_keyword_ids.map((word) => Number(word));
      dispatch(setSelectedKeywords([...new Set([...selectedAllWords])]));
      dispatch(setExcludedKeyWords([]));
      setAllSelected(true);
    } catch (error) {
      console.error(error.message);
    } finally {
      dispatch(closeLoader());
    }
  };

  const handleToggleDeSelectAll = async () => {
    const data = {
      projectId: projectID,
      username: username,
    };
    try {
      dispatch(openLoader());

      const selectAll = await deselectAllkeyword(data);
      const selectedAllWords = selectAll?.data?.allkeywords?.data?.map((word) => Number(word.id));
      dispatch(setSelectedKeywords([]));
      dispatch(setExcludedKeyWords([...selectedAllWords]));

      setAllSelected(false);
    } catch (error) {
      console.error(error.message);
    } finally {
      dispatch(closeLoader());
    }
  };
  useEffect(() => {
    if (excludedKeywordsIds.length > 0) {
      setAllSelected(false);
    } else {
      setAllSelected(true);
    }
  }, [excludedKeywordsIds.length]);

  // useEffect(() => {
  //   if(selectedKeywordsarray.length>0){
  //     setAllSelected(true);}
  // }, [selectedKeywordsarray.length]);
  const handleClose = async () => {
    setShowKeywordInput(false);
    // dispatch(setOpenTextFieldValue)
  };

  return (
    <>
      <div className="open_text_main" onClick={stopPropagation}>
        {/* <StyledTableContainer className="previewcontainer"> */}
        {/* <Box sx={{ width: "100%", padding: "0px" }}> */}
        {/* <Paper className="preview_paper" sx={{ width: "100%dispatch(setFetchedKeywords(response.data));", mb: 2 }}> */}
        <div className="opentext_heading_button">
          <div className="coremipsum">
            <div>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Keywords
              </p>
            </div>
            <div className="horizontal_line_heading"></div>
          </div>
          <div className="run_addkeyword">
            <SearchComponent value={searchQuery} onChange={handleSearchInputChange} className="open_text_search" width="244px" height="36px" />
            {/* <ButtonComponent
              // className="file_upload_btn downloadbtn"
              btnType="outline"
              text="Run"
              onClick={handleRunClicked}
              sx={{
                width: "112px",
                padding: "13px 13px 13px 13px",
                height: "34px",
                paddingleft: "30px",
                marginLeft: "9px",
                marginRight: "10px",
              }}
              style={{ marginRight: "10px" }}
            /> */}
            {/* select deselect button */}
            {/* {!allSelected ? (
              <ButtonComponent
                className="file_upload_btn"
                btnType="secondary"
                text={"Select All"}
                onClick={() => handleToggleSelectAll()}
                sx={{
                  width: "116px",
                  height: "34px !important",
                  marginRight: "10px",
                }}
              />
            ) : (
              <ButtonComponent
                className="file_upload_btn"
                btnType="secondary"
                text={"Deselect All"}
                onClick={() => handleToggleDeSelectAll()}
                sx={{
                  width: "116px",
                  height: "34px !important",
                  marginRight: "10px",
                }}
              />
            )} */}
            {/* select deselect button */}
            {/* <ButtonComponent
              className="file_upload_btn"
              btnType="secondary"
              text="Add Keyword"
              onClick={() => setShowKeywordInput(true)}
              sx={{ width: "167px", height: "34px !important" }}
            /> */}
          </div>
        </div>

        <div className="keywords">
          {showKeywordInput && (
            <div className="enter_keywords" onClick={stopPropagation}>
              <TextField
                id="outlined-basic"
                type="text"
                placeholder={"Enter Keywords"}
                value={keywordValue}
                autoComplete="off"
                onChange={(e) => handleTextFieldChange(e)}
                // onClick={(e) => e.target.focus()}
                className={"open_text_field" + " " + "error-border"}
              />
              <ButtonComponent
                className="open_text_save_btn"
                btnType="secondary"
                text="Save"
                onClick={handleSave}
                sx={{
                  width: "80px",
                  marginRight: "12px",
                  marginLeft: "20px",
                  padding: "10px",
                }}
              />
              <ButtonComponent
                className="open_text_clear_btn"
                btnType="outline"
                text="Close"
                // onClick={handleClearAll}
                onClick={handleClose}
                sx={{ width: "85px", height: "32px" }}
              />
            </div>
          )}
          <div className="grid-box">
            {/* <Box> */}
            <Grid container spacing={2}>
              {fetchedKeywords
                // .filter((ele) => {
                //   if (searchQuery.trim() == "") {
                //     return ele;
                //   } else if (ele.keyword.toLowerCase().includes(searchQuery.toLowerCase())) {
                //     return ele;
                //   }
                // })
                .map((opentext) => (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={4}
                    lg={4}
                    key={opentext}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedKeywordsarray.includes(opentext.id)}
                          // checked={true}
                          className="opentext_checkbox"
                          onChange={() => handleCheckboxChange(opentext)}
                          sx={{
                            "&.Mui-checked::after": {
                              height: "0px !important",
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                            },
                            "&.Mui-checked::before": {
                              height: "0px !important",
                            },
                          }}
                          disabled={true}
                          // defaultChecked={selectedKeywordsarray}
                        />
                      }
                      label={<span title={opentext.keyword}>{opentext.keyword}</span>}
                    />
                  </Grid>
                ))}
            </Grid>
            {/* </Box> */}
          </div>
          <div className="opentext-pagination">
            {totalCount && totalCount > 0 && (
              <PaginationComponent className="custom-pagination " limit={LIMIT} offset={offset} totalCount={totalCount} onPageChange={handlePageChange} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default OpenText;
