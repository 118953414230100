import { QUESTION_TYPES } from "../../../modals/QuestionLibrary/questionLibrary.constant";

export const questionTableColumns = {
  ID: "question_id",
  QUESTION_TYPE: "question_type",
  QUESTION: "question",
};

export function isOpenTextSingleRow(questionEntireObject) {
  const questionId = questionEntireObject?.question_id;
  const questionOtherObjectKeys = Object.keys(questionEntireObject).filter((key) => key !== questionId && key !== "question_id") ?? [];

  if (
    questionOtherObjectKeys?.length === 0 &&
    questionEntireObject?.[questionId]?.type === QUESTION_TYPES.OPEN_TEXT &&
    questionEntireObject?.[questionId]?.sub_questions?.length === 1
  ) {
    return true;
  }
  return false;
}
