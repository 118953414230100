import { call, put, select, takeLatest } from "redux-saga/effects";

import { bulkUpsertRules, getAllRulesByProjectId } from "../../../services/projectService";
import { closeLoader, openLoader } from "../../loader/loaderSlice";
import { openSnackbar } from "../../snackbar/snackbarslice";
import { resetPreviewWeights } from "../previewWeights/previewWeightsSlice";
import { SNACKBAR_TYPES } from "../../../assets/constants/constant";

import {
  fetchAllRulesStart,
  fetchRuleSuccessWithInitCheckedRulesReduxState,
  fetchRulesFailure,
  fetchRulesSuccess,
  updatedSelectedAndActiveRules,
} from "./ruleSlice";

// Selector function to get the required value from the state
const checkedRulesInRuleTable = (state) => state?.rules?.checkedRulesInRuleTable;
const usernameState = (state) => state?.user?.userInfo?.email ?? "";
const allRulesState = (state) => state?.rules?.rules;

export function* handleActiveRulesUpdates() {
  try {
    yield put(openLoader());
    // Get the username from the state
    const selectedRulesIds = yield select(checkedRulesInRuleTable);
    const username = yield select(usernameState);
    const rules = yield select(allRulesState);

    const upsertObj = rules.map((rule) => {
      const { created_by, updated_by, created_at, updated_at, rule_version, is_valid, raw_data_file_id, data_map_file_id, rawFile, dataMapFile, ...data } =
        rule;

      if (selectedRulesIds.includes(rule.id)) {
        data.is_rule_active = true;
      } else {
        data.is_rule_active = false;
      }
      // // Ensure raw_data_file_id and data_map_file_id are integers
      // if (data.raw_data_file_id) {
      //   data.raw_data_file_id = parseInt(data.raw_data_file_id, 10);
      // }
      // if (data.data_map_file_id) {
      //   data.data_map_file_id = parseInt(data.data_map_file_id, 10);
      // }

      return data;
    });
    const data = yield call(() => bulkUpsertRules(upsertObj, username));

    yield put(updatedSelectedAndActiveRules(data.data));
    yield put(resetPreviewWeights());
  } catch (error) {
    yield put(openSnackbar({ message: "Couldn't save active rules!", type: SNACKBAR_TYPES.ERROR }));
    throw error;
  } finally {
    yield put(closeLoader());
  }
}

function* fetchRulesSaga() {
  // try {
  //     const rules = yield call(getRules); // Call API function to fetch rules
  //     const modifiedRules = rules.map(rule => ({
  //       id: rule.id,
  //       ruleName: rule.ruleName,
  //       createdAt: rule.createdAt,
  //       updatedAt: rule.updatedAt
  //     }));
  //     yield put(fetchRulesSuccess(modifiedRules));
  //   } catch (error) {
  //     yield put(fetchRulesFailure(error.message));
  //   }
}

export function* fetchGetAllRulesStartSaga(action) {
  yield put(openLoader());
  try {
    const mappedData = {
      projectId: action?.payload ?? "",
    };

    const { data } = yield call(() => getAllRulesByProjectId(mappedData.projectId));

    yield put(fetchRuleSuccessWithInitCheckedRulesReduxState(data?.items));
    yield put(fetchRulesSuccess(data?.items ?? []));
  } catch (error) {
    yield put(fetchRulesFailure(error.message));
  } finally {
    yield put(closeLoader());
  }
}

export function* watchFetchRules() {
  yield takeLatest(fetchAllRulesStart, fetchGetAllRulesStartSaga);
}
