function TickCircleSVG() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.8C11.7555 14.8 14.8 11.7555 14.8 8C14.8 4.24446 11.7555 1.2 8 1.2C4.24446 1.2 1.2 4.24446 1.2 8C1.2 11.7555 4.24446 14.8 8 14.8ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#439771"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.581 5.56562C11.8153 5.79994 11.8153 6.17984 11.581 6.41415L7.00018 10.995L4.57592 8.57071C4.3416 8.33639 4.3416 7.95649 4.57592 7.72218C4.81023 7.48786 5.19013 7.48786 5.42445 7.72218L7.00018 9.29791L10.7325 5.56562C10.9668 5.33131 11.3467 5.33131 11.581 5.56562Z"
        fill="#439771"
      />
    </svg>
  );
}

export default TickCircleSVG;
