function CloseIconSvg(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="g3" opacity="1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.29279 6.29302C6.48031 6.10555 6.73462 6.00023 6.99979 6.00023C7.26495 6.00023 7.51926 6.10555 7.70679 6.29302L11.9998 10.586L16.2928 6.29302C16.385 6.19751 16.4954 6.12133 16.6174 6.06892C16.7394 6.01651 16.8706 5.98892 17.0034 5.98777C17.1362 5.98662 17.2678 6.01192 17.3907 6.0622C17.5136 6.11248 17.6253 6.18673 17.7192 6.28062C17.8131 6.37452 17.8873 6.48617 17.9376 6.60907C17.9879 6.73196 18.0132 6.86364 18.012 6.99642C18.0109 7.1292 17.9833 7.26042 17.9309 7.38242C17.8785 7.50443 17.8023 7.61477 17.7068 7.70702L13.4138 12L17.7068 16.293C17.8889 16.4816 17.9897 16.7342 17.9875 16.9964C17.9852 17.2586 17.88 17.5094 17.6946 17.6948C17.5092 17.8802 17.2584 17.9854 16.9962 17.9877C16.734 17.99 16.4814 17.8892 16.2928 17.707L11.9998 13.414L7.70679 17.707C7.51818 17.8892 7.26558 17.99 7.00339 17.9877C6.74119 17.9854 6.49038 17.8802 6.30497 17.6948C6.11956 17.5094 6.01439 17.2586 6.01211 16.9964C6.00983 16.7342 6.11063 16.4816 6.29279 16.293L10.5858 12L6.29279 7.70702C6.10532 7.51949 6 7.26518 6 7.00002C6 6.73486 6.10532 6.48055 6.29279 6.29302Z"
          fill={props.color}
        />
      </g>
    </svg>
  );
}

export default CloseIconSvg;
