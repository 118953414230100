import { createSlice } from "@reduxjs/toolkit";

import {
  alternatingPatternMapper,
  filterRedundantRespondentsWithQuestionId,
  includeExcludeApiResponseMapper,
  repetitivePatternMapper,
  steppedPatternMapper,
} from "../../../pages/DesignQC/JockerAnalysis/PatternRecognition/helper/PatternRecognitionHelper";

const setExcludedObject = (prevTableMap, excludedObj) => {
  const tableMap = prevTableMap;

  Object.keys(tableMap).forEach((respondentId) => {
    if (tableMap?.[respondentId] !== undefined || tableMap?.[respondentId] !== null) {
      Object.keys(tableMap?.[respondentId]).forEach((questionId) => {
        if (excludedObj?.[respondentId]?.[questionId]) {
          tableMap[respondentId][questionId] = { ...tableMap?.[respondentId]?.[questionId], include: false };
        }
      });
    }
  });

  return tableMap;
};
const initialState = {
  isNextTriggered: false,
  isRedirect: false,

  isApiCalled: false,
  isApiProcessing: true,

  details: [],
  respid: "",
  question_id: "",
  mergefile: "",

  alternative_pattern: [],
  repetitive_pattern: [],
  stepped_pattern: [],

  isAlternativePatternSuccess: false,
  isRepetitivePatternSuccess: false,
  isSteppedPatternSuccess: false,

  tableDataMap: {},

  excludedValues: {},

  data: null,
  error: null,
  isApiCallTriggeredFromPreview: false,

  includeExcludeApi: {
    data: null,
    error: null,
    mappedData: null,
    success: false,
  },
};

const patternRecognitionSlice = createSlice({
  name: "patternRecognition",
  initialState,
  reducers: {
    setMergeFile: (state, action) => {
      state.mergefile = action.payload;
    },
    alternativepatternStart(state) {
      state.loading = true;
      state.error = null;
    },
    alternativepatternSuccess(state, action) {
      state.loading = false;
      const response = action?.payload?.response?.details;
      state.alternative_pattern = response;
      state.error = null;
      state.isAlternativePatternSuccess = true;
    },

    repetitivepatternStart(state) {
      state.loading = true;
      state.error = null;
    },
    repetitivepatternSuccess(state, action) {
      state.loading = false;
      const response = action?.payload?.response?.details;
      state.repetitive_pattern = response;
      state.error = null;
      state.isRepetitivePatternSuccess = true;
    },

    steppedpatternStart(state) {
      state.loading = true;
      state.error = null;
    },
    steppedpatternSuccess(state, action) {
      state.loading = false;
      const response = action?.payload?.response?.details;
      state.stepped_pattern = response;
      state.error = null;
      state.isSteppedPatternSuccess = true;
    },

    startPatternRecognitionDataMapper(state) {
      state.tableDataMap = alternatingPatternMapper(state.tableDataMap, state.alternative_pattern);
      state.tableDataMap = repetitivePatternMapper(state.tableDataMap, state.repetitive_pattern);
      state.tableDataMap = steppedPatternMapper(state.tableDataMap, state.stepped_pattern);
      state.tableDataMap = filterRedundantRespondentsWithQuestionId(state.tableDataMap);
    },

    patternRecognitionError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    addPatternRecognitionExcludedValue(state, action) {
      const respondentId = action?.payload?.respondentId;
      const questionId = action?.payload?.questionId;

      const excludedObj = state.excludedValues;

      if (excludedObj?.[respondentId]?.[questionId]) {
        excludedObj[respondentId][questionId] = true;
      } else if (excludedObj?.[respondentId]) {
        excludedObj[respondentId][questionId] = true;
      } else {
        excludedObj[respondentId] = {};
        excludedObj[respondentId][questionId] = true;
      }

      state.excludedValues = excludedObj;
    },
    removePatternRecognitionExcludedValue(state, action) {
      const respondentId = action?.payload?.respondentId;
      const questionId = action?.payload?.questionId;

      const excludedObj = state.excludedValues;

      if (excludedObj?.[respondentId]?.[questionId]) {
        delete excludedObj[respondentId][questionId];
      }

      state.excludedValues = excludedObj;
    },
    resetPatternRecognitionValues(state) {
      state.excludedValues = {};
    },

    initApplyExcludedPatternRecognitionState(state) {
      const currentTableDataMap = state.tableDataMap;
      const updatedTableDataMap = setExcludedObject(currentTableDataMap, state.excludedValues);
      state.tableDataMap = updatedTableDataMap;
    },

    setTableDataMap(state, action) {
      const currentTableDataMap = action.payload;
      const updatedTableDataMap = setExcludedObject(currentTableDataMap, state.excludedValues);
      state.tableDataMap = updatedTableDataMap;
    },
    resetTableDataMap(state) {
      state.tableDataMap = {};
    },
    setIsPatternRecognitionNextTriggered(state) {
      state.isNextTriggered = true;
    },
    resetIsPatternRecognitionNextTriggered(state) {
      state.isNextTriggered = false;
    },
    setIsPatternRecognitionRedirect(state) {
      state.isRedirect = true;
    },
    resetIsPatternRecognitionRedirect(state) {
      state.isRedirect = false;
    },

    setIncludeExcludeApiSuccess(state, action) {
      const response = action?.payload?.response?.final_score ?? null;

      state.includeExcludeApi.data = response;

      state.includeExcludeApi.mappedData = includeExcludeApiResponseMapper(response);
      state.includeExcludeApi.success = true;
    },
    resetIncludeExcludeApiSuccess(state) {
      state.includeExcludeApi.success = false;
    },

    triggerApiCallFromPreview: (state) => {
      state.isApiCallTriggeredFromPreview = true;
    },
    resetApiCallFromPreview: (state) => {
      state.isApiCallTriggeredFromPreview = false;
    },
    setPatternRecognitionApisCalled(state, action) {
      state.isApiCalled = action.payload;
    },
    resetPatternRecognitionApisCalled(state, action) {
      state.isApiCalled = false;
    },

    setIsPatternRecognitionsApisProcessing(state, action) {
      state.isApiProcessing = action.payload;
    },

    resetAllPatternState(state) {
      return initialState;
    },
    resetPatternArraysAndData(state) {
      state.alternative_pattern = [];
      state.repetitive_pattern = [];
      state.stepped_pattern = [];
      state.data = null;
      state.mergefile = "";
      state.includeExcludeApi.data = null;
      state.includeExcludeApi.success = false;
      state.includeExcludeApi.mappedData = null;
      state.includeExcludeApi.data = null;
    },
  },
});

export const {
  resetAllPatternState,
  resetPatternArraysAndData,
  resetPatternRecognitionApisCalled,
  resetTableDataMap,
  setPatternRecognitionApisCalled,
  steppedpatternStart,
  steppedpatternSuccess,
  repetitivepatternStart,
  alternativepatternSuccess,
  alternativepatternStart,
  patternRecognitionError,
  repetitivepatternSuccess,
  setTableDataMap,
  setCloneTableDataMap,
  addPatternRecognitionExcludedValue,
  removePatternRecognitionExcludedValue,
  resetPatternRecognitionValues,
  startPatternRecognitionDataMapper,
  initApplyExcludedPatternRecognitionState,
  setIsPatternRecognitionNextTriggered,
  resetIsPatternRecognitionNextTriggered,
  setIsPatternRecognitionRedirect,
  resetIsPatternRecognitionRedirect,
  setIncludeExcludeApiSuccess,
  triggerApiCallFromPreview,
  resetApiCallFromPreview,
  setMergeFile,
  setIsPatternRecognitionsApisProcessing,
} = patternRecognitionSlice.actions;

export default patternRecognitionSlice.reducer;
