import FileSaver from "file-saver";

import axios from "../axiosConfig";

const baseURL = process.env.REACT_APP_BASE_URL;

export const saveProjectJokersApi = async (projId, wave, respondentIds, username, respondentFileId) => {
  try {
    const projectId = parseInt(projId);

    const body = {
      projectId: projectId,
      wave: wave,
      respondentIds: respondentIds,
      respondentFileId: respondentFileId,
      username: username,
    };

    const response = await axios.post(`${baseURL}/project-jokers/`, {
      ...body,
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const downloadPreviewJokersApi = async (
  weightFormByRuleId,
  patternRecognitionFormById = {},
  calculatedRespondentsObject = {},
  projId,
  groupedRespondentDetails = {},
  patternRecognitionGroupedDetails = {},
  projectName = "",
  combinedData = {}
) => {
  try {
    const projectId = parseInt(projId);

    const body = {
      weightFormByRuleId,
      patternRecognitionFormById,
      calculatedRespondentsObject,
      projectId,
      groupedRespondentDetails,
      patternRecognitionGroupedDetails,
      combinedDataWithGenAi: combinedData,
    };
    const response = await axios.post(`${baseURL}/download/preview/`, body, {
      responseType: "blob",
    });

    // Create a blob from the response data and trigger the file download
    const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, `${projectName}-Project-Jokers-${new Date().toLocaleString()}.xlsx`);
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const downloadPreviewAdverseApi = async (projId, projectName = "") => {
  try {
    const projectId = parseInt(projId);

    const body = { projectId: projectId };
    const response = await axios.post(`${baseURL}/download/adverse-event-download`, body, {
      responseType: "blob",
    });

    // Create a blob from the response data and trigger the file download
    const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, `${projectName}-Adverse-Event-${new Date().toLocaleString()}.xlsx`);
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const totalRespondentCountApi = async (questionFileId, respondentFileId, projectId) => {
  try {
    const body = {
      // questionFileId: questionFileId,
      // respondentFileId: respondentFileId,
      projectId: projectId,
    };

    const response = await axios.post(`${baseURL}/parsed/respondent/respondent-count`, {
      ...body,
    });

    return response.data.data.count;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const genaiAPI = async (mergeFile, projectId) => {
  try {
    const payload = {
      mergedFilePath: mergeFile,
      projectId: projectId,
    };
    const response = await axios.post(`${baseURL}/pattern-recognition-apis/acknowledgement-apis/genai/get-data`, payload);
    return response.data.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "An error occurred while processing the GenAI Data");
  }
};
