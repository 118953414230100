import { createSlice } from "@reduxjs/toolkit";

import { decodeRulesArrayOptionsMetaFromBase64 } from "../../../utils/helper";

const adverseselectedAndActiveRulesValues = (ruleArray) => {
  const selectedRulesIdsArray = [];
  const newActiveRules = [];

  ruleArray.forEach((rule) => {
    if (rule.is_rule_active) {
      selectedRulesIdsArray.push(rule.id);
      newActiveRules.push(rule);
    }
  });

  return { checkedRulesInRuleTable: selectedRulesIdsArray, activeRules: newActiveRules };
};
const initialState = {
  isNextTriggered: false,
  isRedirect: false,
  adverserules: [],
  selectedRule: null,
  adversecheckedRulesInRuleTable: [],
  activeRules: [],
  checkedRulesInRuleTable: [],
  checkedRulesInRuleTable: [],
  initRulesFetched: false,
  loading: false,
  error: null,
  isSwitchButtonPositionLeft: false,
  isadverseRuleModified: false,
  isadverseRuleEdited: false,
};

const adverseruleTableSlice = createSlice({
  name: "adverserules",
  initialState,
  reducers: {
    fetchAdverseRulesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchAdverseAllRulesStart(state) {
      state.loading = true;
      state.error = null;
    },
    updatedAdverseSelectedAndActiveRules(state, action) {
      const rules = action?.payload;
      const { checkedRulesInRuleTable, activeRules } = adverseselectedAndActiveRulesValues(rules);
      state.checkedRulesInRuleTable = checkedRulesInRuleTable;
      state.activeRules = activeRules;
    },
    fetchAdverseRulesSuccess(state, action) {
      state.loading = false;
      state.adverserules = decodeRulesArrayOptionsMetaFromBase64(action.payload);

      const { adversecheckedRulesInRuleTable, activeRules } = adverseselectedAndActiveRulesValues(state.adverserules);
      state.activeRules = activeRules;
      state.initRulesFetched = true;
    },
    fetchAdverseRuleSuccessWithInitCheckedRulesReduxState(state, action) {
      if (!state.initRulesFetched) {
        const rules = action?.payload ?? [];

        const { checkedRulesInRuleTable, activeRules } = adverseselectedAndActiveRulesValues(rules);

        const existingCheckedRules = state.checkedRulesInRuleTable;

        for (const checkedRuleId of checkedRulesInRuleTable) {
          if (!existingCheckedRules.includes(checkedRuleId)) {
            existingCheckedRules.push(checkedRuleId);
          }
        }

        state.checkedRulesInRuleTable = existingCheckedRules;
        state.activeRules = activeRules;
      }
    },

    fetchAdverseRulesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setAdverseSelectedRule(state, action) {
      state.selectedRule = action.payload;
      state.isSwitchButtonPositionLeft = true;
    },
    clearAdverseSelectedRule(state) {
      state.selectedRule = null;
    },
    setIsSwitchButtonPositionLeft(state, action) {
      state.isSwitchButtonPositionLeft = action.payload;
    },
    updateAdverseSelectedRuleName(state, action) {
      if (state.selectedRule) {
        state.selectedRule.rule_name = action.payload;
      }
    },
    updateAdverseSelectedRule(state, action) {
      if (state.selectedRule) {
        state.selectedRule.rule = action.payload;
      }
    },
    setIsAdverseRuleModified(state, action) {
      state.isRuleModified = action.payload;
    },
    setAdverseCheckedRulesInRuleTable(state, action) {
      state.adversecheckedRulesInRuleTable = action.payload;
    },
    setCheckedRulesInRuleTable(state, action) {
      state.checkedRulesInRuleTable = action.payload;
    },
    setIsRuleedited(state, action) {
      state.isRuleEdited = action.payload;
    },
    setIsOpentextNextTriggered(state) {
      state.isNextTriggered = true;
    },
    resetIsOpentextNextTriggered(state) {
      state.isNextTriggered = false;
    },
    setIsOpentextRedirect(state) {
      state.isRedirect = true;
    },
    resetIsOpentextRedirect(state) {
      state.isRedirect = false;
    },
    resetAllAdverseState(state) {
      return initialState;
    },
  },
});
export const {
  resetAllAdverseState,
  setIsOpentextRedirect,
  resetIsOpentextRedirect,
  resetIsOpentextNextTriggered,
  setCheckedRulesInRuleTable,
  setIsRuleedited,
  updatedAdverseSelectedAndActiveRules,
  fetchAdverseRuleSuccessWithInitCheckedRulesReduxState,
  setIsAdverseRuleModified,
  updateAdverseSelectedRule,
  updateAdverseSelectedRuleName,
  setIsSwitchButtonPositionLeft,
  clearAdverseSelectedRule,
  setAdverseSelectedRule,
  fetchAdverseRulesFailure,
  setAdverseCheckedRulesInRuleTable,
  fetchAdverseRulesSuccess,
  fetchAdverseAllRulesStart,
  fetchAdverseRulesStart,
  setIsOpentextNextTriggered,
} = adverseruleTableSlice.actions;
export default adverseruleTableSlice.reducer;
