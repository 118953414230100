import { createSlice } from "@reduxjs/toolkit";

import { DESIGN_QC_HORIZONTAL_TABS, DESIGN_QC_TABS } from "../../../pages/DesignQC/DesignQc.constants";

const initialState = {
  selectedTab: DESIGN_QC_TABS.BUILD_QC_RULE,
  selectedHorizontalTab: DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD,
  isExpanded: {},
};

export const designQcSlice = createSlice({
  name: "designQcRules/designQc",
  initialState: initialState,
  reducers: {
    setDesignQcSelectedTab(state, action) {
      state.selectedTab = action?.payload;
    },
    resetDesignQcSelectedTab(state) {
      state.selectedTab = initialState.selectedTab;
    },
    setDesignQcHorizontalSelectedTab(state, action) {
      state.selectedHorizontalTab = action?.payload;
    },
    resetDesignQcHorizontalSelectedTab(state) {
      state.selectedHorizontalTab = initialState.selectedHorizontalTab;
    },
    toggleIsExpanded(state, action) {
      const { index } = action.payload;
      state.isExpanded[index] = !state.isExpanded[index];
    },
    setIsExpanded(state, action) {
      const { index, value } = action.payload;
      state.isExpanded[index] = value;
    },
    resetToInitialState(state) {
      state.selectedTab = initialState.selectedTab;
      state.selectedHorizontalTab = initialState.selectedHorizontalTab;
    },
  },
});

export const {
  setIsExpanded,
  toggleIsExpanded,
  setDesignQcSelectedTab,
  resetDesignQcSelectedTab,
  setDesignQcHorizontalSelectedTab,
  resetDesignQcHorizontalSelectedTab,
  resetToInitialState,
} = designQcSlice.actions;

export default designQcSlice.reducer;
