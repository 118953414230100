import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedKeywords: [],
  excludedKeywords: [],
  value: "",
  fetchedKeywords: [],
};

const opentextFieldSlice = createSlice({
  name: "OpentextField",
  initialState,
  reducers: {
    setOpenTextFieldValue: (state, action) => {
      state.value = action.payload;
    },
    setKeywordValue: (state, action) => {
      state.value = action.payload;
    },
    toggleKeyword: (state, action) => {
      let include = false;

      state.selectedKeywords.forEach((item) => {
        if (item == action.payload.id) {
          include = true;
        }
      });
      if (include) {
        state.selectedKeywords = state.selectedKeywords.filter((keyword) => keyword != action.payload.id);
      } else {
        state.selectedKeywords.push(action.payload.id);
      }

      const keyword = action.payload;

      if (include) state.excludedKeywords.push(keyword.id);
      else state.excludedKeywords = state.excludedKeywords.filter((keywordId) => keywordId != keyword.id);
    },
    clearKeywords: (state) => {
      state.selectedKeywords = [];
      state.excludedKeywords = [];
    },
    addKeyword: (state, action) => {
      if (!state.selectedKeywords.includes(action.payload)) {
        state.selectedKeywords.push(action.payload);
      }
    },
    removeKeyword: (state, action) => {
      state.selectedKeywords = state.selectedKeywords.filter((keyword) => keyword !== action.payload);
    },
    setFetchedKeywords: (state, action) => {
      state.fetchedKeywords = action.payload;
    },
    setSelectedKeywords: (state, action) => {
      state.selectedKeywords = action.payload;
    },
    setExcludedKeyWords: (state, action) => {
      state.excludedKeywords = action.payload;
    },
    resetOpentextState: () => initialState,
  },
});

export const {
  resetOpentextState,
  setSelectedKeywords,
  setExcludedKeyWords,
  setKeywordValue,
  setOpenTextFieldValue,
  addKeyword,
  removeKeyword,
  toggleKeyword,
  clearKeywords,
  setFetchedKeywords,
} = opentextFieldSlice.actions;

export default opentextFieldSlice.reducer;
