import React from "react";
import "./SwitchButton.css";
import { useDispatch, useSelector } from "react-redux";

import { setIsSwitchButtonPositionLeft } from "../../../features/designQcRule/Rule/ruleSlice";
import { SwitchtabLabels } from "../../../././assets/constants/constant";

function SwitchButton() {
  const leftPosition = useSelector((state) => state.rules.isSwitchButtonPositionLeft ?? false);
  const dispatch = useDispatch();

  const handleClick = (isLeftPositionClicked) => {
    if (isLeftPositionClicked && !leftPosition) {
      dispatch(setIsSwitchButtonPositionLeft(true));
    }

    if (!isLeftPositionClicked && leftPosition) {
      dispatch(setIsSwitchButtonPositionLeft(false));
    }
  };

  return (
    <div className="form-box">
      <div className="button-box">
        <div id="btn" style={{ left: leftPosition ? "0" : "164px" }}></div>
        <button type="button" className={`toggle-btn ${leftPosition ? "active" : ""}`} onClick={() => handleClick(true)}>
          {SwitchtabLabels.left}
        </button>
        <button type="button" className={`toggle-btn ${!leftPosition ? "active" : ""}`} onClick={() => handleClick(false)}>
          {SwitchtabLabels.right}
        </button>
      </div>
    </div>
  );
}

export default SwitchButton;
