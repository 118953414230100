import React from "react";
import Button from "@mui/material/Button";
import "./ButtonComponent.css";

function ButtonComponent(props) {
  const Primary = ({ text }) => (
    <Button
      variant={variant}
      color="primary"
      className={className}
      onClick={onClick}
      disableRipple
      disabled={disabled}
      component={component}
      sx={{
        "&.Mui-disabled": {
          pointerEvents: "unset",
          cursor: "not-allowed",
          color: "#FFFFFF !important",
          backgroundColor: "#9d99e9 !important",
        },
        height: props.sx ? props.sx.height || "auto" : "auto",
        ...(sx || {}),
      }}
    >
      {children}
      {text}
    </Button>
  );
  const Secondary = ({ text, sx }) => (
    <Button
      variant="contained"
      color="secondary"
      className={props.className}
      onClick={props.onClick}
      disableRipple
      disabled={props.disabled}
      component={props.component}
      sx={{
        "&.Mui-disabled": {
          pointerEvents: "unset",
          cursor: "not-allowed",
        },
        // height: props.sx ? props.sx.height || "40px" : "40px",
        ...(sx || {}),
      }}
      // style={{marginRight: props.sx ? props.sx.marginRight || "5px" : "5px",}}
    >
      {/* {children} */}
      {text}
    </Button>
  );
  const Teritory = ({ text }) => (
    <Button
      variant="contained"
      color="teritory"
      className={className}
      onClick={onClick}
      disableRipple
      disabled={disabled}
      component={component}
      sx={{
        width: props.width || "auto",
        "&.Mui-disabled": {
          pointerEvents: "unset",
          cursor: "not-allowed",
        },
      }}
    >
      {/* {children} */}
      {text}
    </Button>
  );
  const Error = ({ text }) => (
    <Button
      variant={variant}
      color="error"
      className={className}
      onClick={onClick}
      disableRipple
      disabled={disabled}
      component={component}
      sx={{
        "&.Mui-disabled": {
          pointerEvents: "unset",
          cursor: "not-allowed",
        },
      }}
    >
      {children}
      {text}
    </Button>
  );
  const OutlineButton = ({ text }) => (
    <Button
      variant="outlined"
      style={{
        color: "#00CA9F",
        borderColor: "#00CA9F",
        backgroundColor: "white",
        width: props.sx ? props.sx.width || "104px" : "104px",
        height: props.sx ? props.sx.height || "40px" : "40px",
        marginRight: props.sx ? props.sx.marginRight : "0px",
        marginLeft: props.sx ? props.sx.marginLeft || "" : "",
        marginTop: props.sx ? props.sx.marginTop || "" : "",
        marginBottom: props.sx ? props.sx.marginBottom || "" : "",
        padding: props.sx ? props.sx.padding || "6px 16px" : "6px 16px",
      }}
      className={props.className}
      onClick={props.onClick}
      disableRipple
      disabled={props.disabled}
      component={props.component}
      sx={{
        "&.Mui-disabled": {
          pointerEvents: "unset",
          cursor: "not-allowed",
        },
        ...props.sx,
      }}
    >
      {text}
    </Button>
  );
  const buttonTypes = {
    primary: Primary,
    secondary: Secondary,
    teritory: Teritory,
    error: Error,
    outline: OutlineButton,
  };
  const { btnType, text, children, className, onClick, disabled, component, variant, sx } = props;
  const TagName = buttonTypes[btnType];
  return <TagName text={text} sx={sx} />;
}

export default ButtonComponent;
