import React from "react";
import Select from "react-select";
import "./selectDropdown.css";

const SelectDropdown = ({ options, onChange, className, value, disabled, placeholder, position }) => {
  const customStyles = {
    control: (provided, { isFocused, isHovered, isDisabled }) => ({
      // class attribute : class=" css-i32vvf-control"
      ...provided,
      // ...styles,
      borderColor: isFocused || isHovered ? "#4F46E5" : "#D1D5DB",
      boxShadow: isFocused ? "none" : "",
      background: isDisabled ? "#e9ecef" : "#ffffff",
      display: "flex",
      flexWrap: "nowrap",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      height: "40px",
      borderRadius: 4,
      " &:hover": {
        borderColor: isHovered ? "#dee2e6" : "#D1D5DB",
      },
      "&:disabled": {
        borderColor: isDisabled ? "#d1d5db" : "#D1D5DB",
      },
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: "#A1AEBE",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = "#F5F4FF";
      return {
        ...styles,
        backgroundColor: isFocused ? "#F5F4FF" : "#fff",
        color: isFocused ? "#4F46E5" : "",
        cursor: isDisabled ? "not-allowed" : "default",
        borderWidth: isFocused ? "4px" : "0px",
        borderColor: "#4F46E5",
        borderStyle: "solid",
        borderTop: 0,
        borderLeft: 0,
        borderBottom: 0,
        fontSize: 14,
        fontWeight: isFocused ? 600 : 400,
        paddingVertical: 12,
        paddingHorizontal: 14,
      };
    },
    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "#ffffff",
      padding: 10,
      border: "1px solid #A1AEBE",
      borderRadius: 8,
      boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.1)",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#4F46E5",
    }),
  };
  return (
    <Select
      styles={customStyles}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={options}
      className={className}
      isDisabled={disabled}
      menuPlacement={position}
    />
  );
};

export default SelectDropdown;
