import axios from "../axiosConfig";
import { encodeRulesArrayOptionsMetaToBase64 } from "../utils/helper";

// const baseURL = "http://localhost:3500";
const formHeaders = {
  "Content-Type": "multipart/form-data",
};

const baseURL = process.env.REACT_APP_BASE_URL;
const devURL = process.env.REACT_APP_API_DEVQUANT_URL;

// GET users Information
export const userInfo = async () => {
  try {
    return axios.get(`/users/current_user/`);
  } catch (error) {
    throw error;
  }
};

// Function to GET Projects List
export const fetchProjects = async (active = true, pageno = 0) => {
  try {
    return axios.get(`/projects/?limit=10&offset=${(pageno - 1) * 10}&sortby=-updated_at&is_active=${active}`);
  } catch (error) {
    throw error;
  }
};

// Function to GET Acrhive Project List
export const archievedProjects = async (id) => {
  try {
    const { data } = await axios.get(`/projects/?sortby=-updated_at`);
    return data;
  } catch (error) {
    throw error;
  }
};

// Function to GET Projects By Id
export const fetchProjectsById = async (id) => {
  try {
    const { data } = await axios.get(`/projects/${id}/`);
    return data;
  } catch (error) {
    throw error;
  }
};
// Function to GET Saved Files List
export const fetchFiles = async (id) => {
  try {
    const { data } = await axios.get(`/projects/${id}/files/`);
    return data;
  } catch (error) {
    throw error;
  }
};
// Function to POST upload files
export const triggerApplyUploadFiles = async (fileData) => {
  try {
    const { data } = await axios.post(`projects/apply`, fileData); // TODO: replace url
    return data;
  } catch (error) {
    throw error;
  }
};
// Function to CREATE Projects
export const saveProjectdetails = (data) => axios.post(`/projects/`, data);
// Function to UPDATE Projects
export const updateProjectdetails = (data, id) => axios.patch(`/projects/${id}/`, data);
// Function to edit Projects
export const editProjectdetails = (data, id) => axios.patch(`/projects/${id}/`, data);
// Function to CLONE Projects
export const cloneProjects = async (id) => axios.post(`/projects/${id}/clone/`);
// Function to Archive Projects
export const archivedProjects = async (project_id) =>
  axios.post(`/projects/delete_multiple/`, {
    project_ids: project_id,
  });
// Function to Delete Projects
export const deleteProjects = async (project_id) =>
  axios.post(
    `/projects/delete_multiple/?hard=true`,
    {
      project_ids: project_id,
    },
    {}
  );
// Function to Filter Saved Projects List
export const filterProjects = async (search, active = true) => {
  try {
    return axios.get(`/projects/?name=${search}&is_active=${active}`);
  } catch (error) {
    throw error;
  }
};
// Function to Upload Files After Save Projects
export const uploadFilesData = (id, data) =>
  axios.post(`/projects/${id}/files/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// Validations API Sequence 1 to 4
export const fileValidationSequence = (id, validationType) =>
  axios.post(`/projects/${id}/validations/`, {
    type: validationType,
  });

// Activate files after selecting it from Saved Files flow
export const activateFile = (id, data) => axios.post(`/projects/${id}/activate_file/`, data);
export const updateLastViewedProject = (id) =>
  axios.patch(`/users/current_user/`, {
    // TODO: Implement it for
    last_viewed_project: id,
  });
// Upload Saved Files after selection
export const uploadSelectedFiles = (data) => axios.post(`/projects/upload/selected`, data); // TODO: replace url
export const getMergedFileStatus = (projectId) => axios.get(`/projects/${projectId}/`); // TODO: needs to verified

export const questionMapperApi = async (dataMapFileId, username) => {
  try {
    const body = {
      fileId: dataMapFileId,
      username: username,
    };

    return await axios.post(`${baseURL}/parsed/question/`, body);
  } catch (error) {
    throw error;
  }
};

export const respondentMapperApi = async (dataMapFileId, surveyFileId, jokerRespondentIds, username) => {
  try {
    const body = {
      username: username,
      respondentFileId: surveyFileId,
      questionFileId: dataMapFileId,
      jokerRespondentIds: jokerRespondentIds ?? [],
    };

    return await axios.post(`${baseURL}/parsed/respondent`, body);
  } catch (error) {
    throw error;
  }
};

export const getExistingPotentialJokers = async (rawFileId, projId) => {
  try {
    const projectId = parseInt(projId);
    const body = {
      respondentFileId: rawFileId,
      projectId: projectId,
    };

    return await axios.post(`${baseURL}/parsed/respondent/potential-permanent-joker-list`, body);
  } catch (error) {
    throw error;
  }
};

export const getIsFileParsedStatus = async (fileId) => {
  try {
    const body = {
      fileId: parseInt(fileId),
    };

    const response = await axios.post(`${baseURL}/parsed/respondent/check-parsing-status`, body);

    return response?.data?.data?.status ?? false;
  } catch (error) {
    throw error;
  }
};

// Function to fetch rules from the API
export const getRules = async () => {
  try {
    const response = await axios.get(`${baseURL}/rule`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
// Delete rule from id
export const deleteRule = async (id) => {
  try {
    const response = await axios.post(`${baseURL}/rule/delete/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const createRule = async (newRuleData) => {
  // newRuleData.updated_by = "abc";
  try {
    const finalRules = encodeRulesArrayOptionsMetaToBase64(newRuleData?.rules ?? []);

    const finalPayload = {
      rules: finalRules,
      updated_by: newRuleData.updated_by,
    };

    const response = await axios.post(`${baseURL}/rule/bulk-upsert`, finalPayload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const bulkUpsertRules = async (newRuleData, username) => {
  try {
    const finalRules = encodeRulesArrayOptionsMetaToBase64(newRuleData);

    const body = {
      rules: finalRules,
      updated_by: username,
    };

    const response = await axios.post(`${baseURL}/rule/bulk-upsert`, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const listRule = async (projId, limit, offset, searchTerm, filterBy, order, orderBy, dataMapFileId) => {
  try {
    const projectId = parseInt(projId);
    const finalParams = {
      limit: limit ?? 10,
      offset: offset ?? 0,
    };

    if (searchTerm) finalParams.searchTerm = searchTerm;
    if (filterBy) finalParams.filterBy = filterBy;
    if (order && orderBy) {
      finalParams.orderBy = orderBy;
      finalParams.orderType = order;
    }
    const payload = {
      // "questionFileId": dataMapFileId
    };
    const response = await axios.post(`${baseURL}/question/project/${projectId}`, payload, {
      params: finalParams,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAllRulesByProjectId = async (projId, ruleType) => {
  try {
    const projectId = parseInt(projId);
    const INF = 1e18;
    // let url = `${baseURL}/rule/project/${projectId}`;
    const url = `${baseURL}/rule/project/${projectId}`;

    const response = await axios.get(url, {
      params: {
        limit: INF,
        ruleType: ruleType,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProjectCollaborators = async (projectId) => {
  try {
    // let url = `${baseURL}/rule/project/${projectId}`;
    const url = `${devURL}/collaborators/${projectId}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const appendProjectCollaborators = async (payload) => {
  try {
    const url = `${devURL}/collaborators`;
    const response = await axios.post(url, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const url = `${devURL}/users`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeRuleStatus = async (projectId) => {
  try {
    const response = await axios.get(`${baseURL}/rule/reset-status/project/${projectId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const lockProject = async (projId, username, userId) => {
  try {
    const projectId = parseInt(projId);
    const body = {
      userEmail: username,
      userId: userId,
    };

    const response = await axios.post(`${baseURL}/project-access-lock/lock/${projectId}`, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const unlockProject = async (projId, username, userId) => {
  try {
    const projectId = parseInt(projId);
    const body = {
      userEmail: username,
      userId: userId,
    };

    const response = await axios.post(`${baseURL}/project-access-lock/unlock/${projectId}`, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const unlockAllProject = async (username, userId) => {
  try {
    const body = {
      userEmail: username,
      userId: userId,
    };

    const response = await axios.post(`${baseURL}/project-access-lock/unlock-all/`, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const listLockedProjects = async (username, userId) => {
  try {
    const body = {
      userEmail: username,
      userId: userId,
    };

    const response = await axios.post(`${baseURL}/project-access-lock/list-locked-projects/`, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
