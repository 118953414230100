import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./JockerTable.css";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import { TableSortLabel } from "@mui/material";

import On_Off_switch from "../../../../../components/Switch/On_Off_switch/On_Off_switch";
import {
  ALTERNATING_PATTERN,
  Adverse_Preview_Joker,
  GENAI_RATIONALE,
  PATTERN_RECOGNITION_FLAT_COLUMN_OBJECT,
  PATTERN_RECOGNITION_OBJECT,
  REPETITIVE_PATTERN,
  STEPPED_PATTERN,
} from "../constants/preview-constants";
import { formatToPrecision } from "../../../../../utils/helper";
import GreenCircleIcon from "../../../../../components/circleIcon/GreenCircleIcon";
import RedCircleIcon from "../../../../../components/circleIcon/RedCircleIcon";
import { closeLoader, openLoader } from "../../../../../features/loader/loaderSlice";
import { getMergedFilePath } from "../../../../../services/patternService";
import ButtonComponent from "../../../../../components/Button/ButtonComponent";
import { genaiAPI } from "../../../../../services/previewService";
import { openSnackbar } from "../../../../../features/snackbar/snackbarslice";
import { setGenAiFetchedAndCompleted, setGenaiApiStatus, setGenaiResponse } from "../../../../../features/designQcRule/previewWeights/previewWeightsSlice";
import { getComparator, stableSort } from "../../../../../utils/sorting-helper";
import PaginationComponent from "../../../../../components/PaginationComponent/PaginationComponent";
import { ACK_APIS_STATUS, SNACKBAR_TYPES } from "../../../../../assets/constants/constant";
import { setMergedFilePath } from "../../../../../features/designQcRule/projectMetaData/projectMetaDataSlice";
import CustomTooltip from "../../../../../components/Tooltip/Tooltip";

import { JokerTableColumn, jokerTableFinalMergeRows } from "./helper/jokerTableHelper";
import GenAiTable from "./GenaiTable";

function EnhancedTableHead({
  onRequestSort,
  rules,
  order,
  orderBy,
  captureScrollPosition,
  isAlternativePatternSuccess,
  isRepetitivePatternSuccess,
  isSteppedPatternSuccess,
}) {
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);

  const createSortHandler = (property) => (event) => {
    captureScrollPosition();
    onRequestSort(event, property);
  };

  // const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  const columnsToRender = useMemo(() => {
    if (selectedHorizontalTab === "Adverse Events") {
      return Adverse_Preview_Joker;
    } else if (selectedHorizontalTab === "Joker Analysis") {
      return PATTERN_RECOGNITION_OBJECT;
    }
    return {};
  }, [selectedHorizontalTab]);

  const columnCount = isAlternativePatternSuccess + isRepetitivePatternSuccess + isSteppedPatternSuccess;

  return (
    <TableHead className="jocker-table-head" sx={{ position: "sticky", top: 0, zIndex: 1 }}>
      <TableRow sx={{ backgroundColor: "#E3E4FD", width: "15px" }}>
        <TableCell
          className="jocker_table-head-cell_resp-id"
          align="center"
          sx={{
            paddingBottom: "12px",
            width: "7%",
            verticalAlign: "bottom",
            // borderRight: "1px solid white",
            position: "sticky",
            left: 0,
            background: "#E3E4FD",
            cursor: "pointer",
          }}
          onClick={createSortHandler(JokerTableColumn.RESP_ID)}
          rowSpan={2}
        >
          Resp ID
          <TableSortLabel active={orderBy === JokerTableColumn.RESP_ID} direction={orderBy === JokerTableColumn.RESP_ID ? order : "asc"} />
        </TableCell>

        {rules && rules.length !== 0 && (
          <TableCell
            className="jocker_table-head-cell_QC"
            align="center"
            sx={{
              width: "25%",
              // borderRight: "1px solid white",
              // borderBottom: "3px solid white",
              // borderRight: "3px solid white",
            }}
            colSpan={rules?.length ?? 0}
          >
            <span
            // style={{ position: "sticky", left: 200, right: 300 }}
            >
              QC Rules
            </span>
          </TableCell>
        )}
        {selectedHorizontalTab === "Joker Analysis" && columnCount > 0 && (
          <TableCell
            className="jocker_table-head-cell-JA"
            align="center"
            sx={{
              width: "39%",
              // borderRight: "1px solid white",
              // borderBottom: "3px solid white",
            }}
            colSpan={columnCount}
          >
            Pattern Recognition
          </TableCell>
        )}

        {selectedHorizontalTab === "Joker Analysis" && (
          <TableCell
            className="jocker_table-head-cell alternating-pattern jokerJA"
            align="center"
            sx={{
              width: "5%",
              verticalAlign: "bottom",
              position: "sticky",
              right: 112,
              background: "#E3E4FD",
              cursor: "pointer",
            }}
            rowSpan={2}
            onClick={createSortHandler(JokerTableColumn.SCORE)}
          >
            Joker Score
            <TableSortLabel active={orderBy === JokerTableColumn.SCORE} direction={orderBy === JokerTableColumn.SCORE ? order : "asc"} />
          </TableCell>
        )}
        {selectedHorizontalTab === "Joker Analysis" && (
          <TableCell
            className="jocker_table-head-cell_action alternating-pattern"
            align="center"
            sx={{
              width: "6%",
              verticalAlign: "bottom",
              position: "sticky",
              right: 0,
              background: "#E3E4FD",
            }}
            rowSpan={2}
          >
            Include/Exclude
          </TableCell>
        )}
      </TableRow>

      <TableRow sx={{ backgroundColor: "#E3E4FD" }}>
        {rules &&
          rules?.map((rule) => {
            const columnName = `rule_id__${rule?.id}`;

            return (
              <TableCell
                className="jocker_table-head-cell_QC_rule"
                align="center"
                // borderRight: "3px solid white",
                sx={{ cursor: "pointer" }}
                onClick={createSortHandler(columnName)}
              >
                <CustomTooltip title={rule?.rule_name ?? ""}>{rule?.rule_name ?? ""}</CustomTooltip>
                <TableSortLabel active={orderBy === columnName} direction={orderBy === columnName ? order : "asc"} />
              </TableCell>
            );
          })}

        {/* rule header */}
        {columnsToRender &&
          Object.keys(columnsToRender).map((columnKey) => {
            const columnName = columnsToRender[columnKey];

            let showColumn = false;

            if (columnKey === JokerTableColumn.ALTERNATING_PATTERN) {
              showColumn = isAlternativePatternSuccess;
            } else if (columnKey === JokerTableColumn.REPETITIVE_PATTERN) {
              showColumn = isRepetitivePatternSuccess;
            } else if (columnKey === JokerTableColumn.STEPPED_PATTERN) {
              showColumn = isSteppedPatternSuccess;
            }

            if (showColumn) {
              return (
                <TableCell
                  className="jocker_table-head-cell-JA-pattern"
                  align="center"
                  // borderRight: "3px solid white",
                  sx={{ cursor: "pointer" }}
                  onClick={createSortHandler(PATTERN_RECOGNITION_FLAT_COLUMN_OBJECT?.[columnKey])}
                >
                  {columnName ?? ""}
                  <TableSortLabel
                    active={orderBy === PATTERN_RECOGNITION_FLAT_COLUMN_OBJECT?.[columnKey]}
                    direction={orderBy === PATTERN_RECOGNITION_FLAT_COLUMN_OBJECT?.[columnKey] ? order : "asc"}
                  />
                </TableCell>
              );
            } else {
              return <></>;
            }
          })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
};

function JockerTable({
  groupedRespondentDetails,
  patternRecognitionGroupedDetails,
  calculatedRespondentObject,
  setCalculatedRespondentsObject,
  rules,
  initApiFunction,
  limit,
  offset,
  setOffset,
  debouncedSearchQuery = "",
}) {
  const RESPONDENT_COUNT = Object.keys(calculatedRespondentObject).length ?? 0;
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  const dispatch = useDispatch();
  const dataMapFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);
  const projectID = useSelector((state) => state?.createProjectDetails?.data?.id);
  const mergeFile = useSelector((state) => state?.patternRecognition?.mergefile);
  const genaiResponse = useSelector((state) => state?.previewWeights?.genaiResponse);

  const isGenAiLoading = useSelector((state) => state?.previewWeights?.genAiApiStatus) !== ACK_APIS_STATUS.COMPLETED;
  const isGenAiFetchedAndCompleted = useSelector((state) => state?.previewWeights?.isGenAiApiFetchedAndCompleted);
  // const projectID = 511;

  const reduxMergedFilePath = useSelector((state) => state?.projectMetaData?.mergedFilePath?.data);

  const RULE_ID = "rule_id";
  const scrollRef = useRef(null);
  const scrollPosition = useRef(0);

  const [showGenAi, setShowGenAi] = useState(false);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const [dense, setDense] = useState(false);
  const [selected, setSelected] = useState([]);

  // const AdversePopulateData = useSelector((state) => state?.adverseRuleExecution?.mergeAdversedata) ?? undefined;

  const StyledTableContainer = styled(TableContainer)({
    padding: "0px",
  });

  const isPatternApiCalled = useSelector((state) => state?.patternRecognition?.isApiCalled);

  const isAlternativePatternSuccess = useSelector((state) => state?.patternRecognition?.isAlternativePatternSuccess);
  const isSteppedPatternSuccess = useSelector((state) => state?.patternRecognition?.isSteppedPatternSuccess);
  const isRepetitivePatternSuccess = useSelector((state) => state?.patternRecognition?.isRepetitivePatternSuccess);

  const isPatternRecognitionsApisProcessing = useSelector((state) => state?.patternRecognition?.isApiProcessing);

  const isAnyPatternRecognitionSuccess =
    isPatternApiCalled ||
    isAlternativePatternSuccess ||
    isSteppedPatternSuccess ||
    isRepetitivePatternSuccess ||
    (!isPatternRecognitionsApisProcessing && rules.length > 0);

  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);

  // const data = useMemo(() => {
  //   if (selectedHorizontalTab === "Adverse Events") {
  //     return AdversePopulateData;
  //   } else {
  //     return Object.keys(calculatedRespondentObject);
  //   }
  // }, [selectedHorizontalTab]);

  const isSelected = (id) => selected.indexOf(id) !== -1;
  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = rows.map((rule) => rule.id);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  //  Restore scroll position after state change
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollPosition.current;
    }
  });

  // Capture scroll position before state change
  const captureScrollPosition = () => {
    if (scrollRef.current) {
      scrollPosition.current = scrollRef.current.scrollLeft;
    }
  };

  const handleOnSwitchChange = (e, respondentId) => {
    captureScrollPosition();
    const value = e?.target?.checked ?? false;

    if (value && !calculatedRespondentObject[respondentId]?.exclude) {
      setCalculatedRespondentsObject((prevCalculatedRespondentObj) => {
        const newCalculatedObj = _.cloneDeep(prevCalculatedRespondentObj);

        newCalculatedObj[respondentId].exclude = true;

        return newCalculatedObj;
      });
    }

    if (!value && calculatedRespondentObject[respondentId]?.exclude && calculatedRespondentObject[respondentId]?.exclude === true) {
      setCalculatedRespondentsObject((prevCalculatedRespondentObj) => {
        const newCalculatedObj = _.cloneDeep(prevCalculatedRespondentObj);

        newCalculatedObj[respondentId].exclude = false;

        return newCalculatedObj;
      });
    }
  };

  const getGenAiData = async (showDetailedDescriptions) => {
    try {
      if (showDetailedDescriptions) dispatch(openLoader());

      if (isGenAiFetchedAndCompleted) {
        // Early return
        return;
      }

      let mergedFilePath = reduxMergedFilePath;
      if (mergedFilePath === undefined || mergedFilePath === null) {
        mergedFilePath = await getMergedFilePath(dataMapFileName, projectID);
        dispatch(setMergedFilePath(mergedFilePath));
      }

      const result = await genaiAPI(mergedFilePath, projectId);
      dispatch(setGenaiResponse(result?.response?.details));
      dispatch(setGenaiApiStatus(result?.status));

      if (result?.status === ACK_APIS_STATUS.COMPLETED) {
        dispatch(setGenAiFetchedAndCompleted());
      }

      if (showDetailedDescriptions) {
        if (result?.status === ACK_APIS_STATUS.ERROR) {
          dispatch(openSnackbar({ message: result?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
        } else if (result?.status === ACK_APIS_STATUS.COMPLETED) {
          dispatch(openSnackbar({ message: "Data fetched successfully!", type: SNACKBAR_TYPES.SUCCESS }));
        } else {
          dispatch(openSnackbar({ message: result?.message ?? "", type: SNACKBAR_TYPES.SUCCESS }));
        }
      }
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
    } finally {
      if (showDetailedDescriptions) dispatch(closeLoader());
    }
  };

  useEffect(() => {
    getGenAiData();
  }, []);

  const combinedata = {};
  for (const key in patternRecognitionGroupedDetails) {
    if (patternRecognitionGroupedDetails?.hasOwnProperty(key)) {
      combinedata[key] = {
        ...patternRecognitionGroupedDetails[key],
      };
      if (genaiResponse && genaiResponse.length > 0) {
        for (const obj of genaiResponse) {
          if (obj?.respondent_id === key) {
            combinedata[key] = {
              ...combinedata[key],
              Rationale: obj?.Rationale,
              genai_flag: obj?.genai_flag,
              Seriousness_Score: obj?.Seriousness_Score,
            };
          }
        }
      }
    }
    if (genaiResponse && genaiResponse.length > 0) {
      for (const obj of genaiResponse) {
        if (!combinedata.hasOwnProperty(obj?.respondent_id)) {
          combinedata[obj?.respondent_id] = {
            alternating_pattern: 0,
            stepped_pattern: 0,
            repetitive_pattern: 0,
            Rationale: obj?.Rationale,
            genai_flag: obj?.genai_flag,
            Seriousness_Score: obj?.Seriousness_Score,
          };
        }
      }
    }
  }

  // for (let key in combinedata) {
  //   let keysOfcal = Object.keys(calculatedRespondentObject);
  //   if (!keysOfcal.includes(key)) {
  //     calculatedRespondentObject[key] = { score: 0, exclude: false };
  //   }
  // }

  const mergedRows = jokerTableFinalMergeRows(combinedata, calculatedRespondentObject, groupedRespondentDetails) ?? [];
  let sortedRows = stableSort(mergedRows, getComparator(order, orderBy));
  const searchcol = sortedRows?.filter((elem) => {
    const rationale = elem?.[GENAI_RATIONALE]?.toLowerCase();
    const searchQuery = debouncedSearchQuery.toLowerCase();
    if (debouncedSearchQuery === "") {
      return elem;
    } else if (elem[JokerTableColumn.RESP_ID]?.toLowerCase()?.includes(debouncedSearchQuery?.toLowerCase()) || rationale?.includes(searchQuery)) {
      return elem;
    }
  });

  sortedRows = searchcol.slice(offset, offset + limit);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (newOffset) => {
    captureScrollPosition();
    setOffset(newOffset);
  };

  return (
    <>
      <StyledTableContainer className="jockercontainer">
        <Box sx={{ width: "100%", padding: "0px" }}>
          <Paper className="jocker_paper" sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
            {isAnyPatternRecognitionSuccess && (
              <div className="preview__table-container">
                <GenAiTable
                  getGenAiData={getGenAiData}
                  isGenAiLoading={isGenAiLoading}
                  sortedRows={sortedRows}
                  RESPONDENT_COUNT={RESPONDENT_COUNT}
                  handleRequestSort={handleRequestSort}
                  dense={dense}
                  selected={selected}
                  rules={rules}
                  order={order}
                  orderBy={orderBy}
                  captureScrollPosition={captureScrollPosition}
                  isSelected={isSelected}
                  selectedHorizontalTab={selectedHorizontalTab}
                  handleOnSwitchChange={handleOnSwitchChange}
                  showGenAiColumns={showGenAi}
                  setShowGenAiColumns={setShowGenAi}
                />
                <TableContainer ref={scrollRef} style={{ position: "relative" }}>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} className="jocker-recognition-table">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      onSelectAllClick={() => {}}
                      rowCount={RESPONDENT_COUNT}
                      fontFamily="Montserrat"
                      rules={rules}
                      onRequestSort={handleRequestSort}
                      order={order}
                      orderBy={orderBy}
                      captureScrollPosition={captureScrollPosition}
                      isAlternativePatternSuccess={isAlternativePatternSuccess}
                      isRepetitivePatternSuccess={isRepetitivePatternSuccess}
                      isSteppedPatternSuccess={isSteppedPatternSuccess}
                    />
                    <TableBody
                      style={{
                        height: "100px",
                        overflow: "auto",
                      }}
                    >
                      {sortedRows &&
                        sortedRows?.map((rowObj) => {
                          const respondentId = parseInt(rowObj?.[JokerTableColumn.RESP_ID]);
                          const isItemSelected = isSelected(respondentId);
                          const labelId = `enhanced-table-checkbox-${respondentId}`;
                          const showCheck = !!rowObj?.[JokerTableColumn.EXCLUDED];

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={respondentId}
                              selected={isItemSelected}
                              className="jocker_table-row"
                              sx={{
                                cursor: "pointer",
                                height: "75px",
                                fontFamily: "Montserrat, sans-serif",
                                fontSize: "16px",
                              }}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                                style={{
                                  height: "45px",
                                  position: "sticky",
                                  left: 0,
                                  padding: "0px",
                                  background: "white",
                                }}
                              >
                                {respondentId}
                              </TableCell>

                              {rules &&
                                Array.isArray(rules) &&
                                rules?.map((rule) => {
                                  const ruleId = rule?.id;

                                  const result = rowObj?.[`rule_id__${ruleId}`] === true ? 1 : 0;

                                  if (ruleId !== undefined && result !== undefined) {
                                    return (
                                      <TableCell
                                        style={{
                                          height: "45px",
                                          width: "200px",
                                          padding: "0px",
                                        }}
                                        align="center"
                                      >
                                        {renderBubbleByValue(result, 1) ?? ""}
                                      </TableCell>
                                    );
                                  }
                                })}

                              {renderPatternRecognitionCells(rowObj ?? {}, isAlternativePatternSuccess, isRepetitivePatternSuccess, isSteppedPatternSuccess)}
                              {selectedHorizontalTab === "Joker Analysis" && (
                                <TableCell
                                  style={{
                                    height: "45px",
                                    padding: "0px",
                                    position: "sticky",
                                    right: 112,
                                  }}
                                  sx={{ backgroundColor: "#F7F7FF" }}
                                  align="center"
                                >
                                  {formatToPrecision(rowObj?.[JokerTableColumn.SCORE] ?? 0, 2) ?? ""}
                                </TableCell>
                              )}
                              {selectedHorizontalTab === "Joker Analysis" && (
                                <TableCell
                                  style={{
                                    height: "45px",
                                    padding: "0px",
                                    position: "sticky",
                                    right: 0,
                                  }}
                                  sx={{ backgroundColor: "#F7F7FF" }}
                                >
                                  <On_Off_switch isChecked={showCheck} onSwitchChange={(e) => handleOnSwitchChange(e, respondentId)} />
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </Paper>
        </Box>
        {!isAnyPatternRecognitionSuccess && (
          <div className="database_summary_table__refresh-container">
            <p className="database_summary_table__refresh-text">We are retrieving data; please wait and click the button below to refresh the data.</p>
            <ButtonComponent onClick={() => initApiFunction()} variant="outlined" btnType="primary" text="Refresh" className="btnPrimaryOutline btnDisabled" />
          </div>
        )}
      </StyledTableContainer>
      {isAnyPatternRecognitionSuccess && searchcol.length > 0 && (
        <div className="preview__joker-table">
          <PaginationComponent limit={limit} offset={offset} totalCount={searchcol.length ?? 0} onPageChange={handlePageChange} />
        </div>
      )}
    </>
  );
}

export function renderPatternRecognitionCells(patternRecognitionDetail, isAlternativePatternSuccess, isRepetitivePatternSuccess, isSteppedPatternSuccess) {
  return (
    <>
      {isAlternativePatternSuccess && (
        <TableCell style={{ height: "45px", padding: "0px" }} align="center">
          {renderBubbleByValue(patternRecognitionDetail?.[ALTERNATING_PATTERN] ?? 0, 100)}
        </TableCell>
      )}
      {isSteppedPatternSuccess && (
        <TableCell style={{ height: "45px", padding: "0px" }} align="center">
          {renderBubbleByValue(patternRecognitionDetail?.[STEPPED_PATTERN] ?? 0, 100)}
        </TableCell>
      )}
      {isRepetitivePatternSuccess && (
        <TableCell style={{ height: "45px", padding: "0px" }} align="center">
          {renderBubbleByValue(patternRecognitionDetail?.[REPETITIVE_PATTERN] ?? 0, 100)}
        </TableCell>
      )}
      {/* <TableCell style={{ height: "48px", padding: "0px" }} align="center">
        {renderBubbleByValue(patternRecognitionDetail?.[GENAI_DRIVEN], 100)}
      </TableCell>
      <TableCell
        style={{
          height: "48px",
          padding: "0px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "30px",
        }}
        align="center"
      >
        <Tooltip title={patternRecognitionDetail?.[GENAI_RATIONALE]} placement="bottom-start">
          {patternRecognitionDetail?.[GENAI_RATIONALE] ?? "NA"}
        </Tooltip>
      </TableCell> */}
    </>
  );
}

export function renderBubbleByValue(value, scale) {
  if (value === null) return <></>;

  if (typeof value === "boolean") {
    return value ? <GreenCircleIcon /> : <RedCircleIcon />;
  }

  if (value >= scale * 0.5) return <GreenCircleIcon />;
  else return <RedCircleIcon />;
}

export default JockerTable;
