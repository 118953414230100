import React, { useEffect, useRef, useState } from "react";
import { RouterProvider } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./components/Snackbar/Snackbar.css";
import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material";
import IdleTimer from "react-idle-timer";
import Cookies from "js-cookie";
import { useCookies } from "react-cookie";

import { refreshTokenApi, tokenValidationApi } from "./services/patternService";
import store from "./app/store";
import router from "./routes/Routes";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4F46E5",
    },
    secondary: {
      main: "#00CA9F",
    },
    teritory: {
      main: "#EBEBFF",
    },
    success: {
      main: "#439771",
    },
    error: {
      main: "#F44E4E",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    subtitle1: {
      fontSize: 16,
    },
    button: {
      textTransform: "none",
      "& .iconButtonStyle": {
        minWidth: "none !important",
      },
    },
  },
});
function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["inizionavigator"]);

  const token = cookies.inizionavigator && cookies.inizionavigator.token;

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const idleTimerRef = useRef(null);
  const onIdle = () => {
    Cookies.remove("inizionavigator");
  };

  useEffect(() => {
    async function validateToken() {
      try {
        const payload = {
          token: token,
        };
        const response = await tokenValidationApi(payload);
        if (response?.data?.is_success == true) {
          // window.location.href = process.env.REACT_APP_LOGIN_URL;
        } else {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
        }
      } catch (error) {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    }

    validateToken();
  }, [cookies?.inizionavigator?.token]);

  useEffect(() => {
    if (cookies?.inizionavigator?.refreshToken) {
      const tokenExpiry = cookies?.inizionavigator?.exp;
      const currentTime = Date.now();

      const timeDifference = tokenExpiry * 1000 - currentTime - 3 * 60 * 1000;
      setTimeout(() => {
        (async () => {
          try {
            const refreshToken = cookies?.inizionavigator?.refreshToken;
            const payload = {
              refresh_token: refreshToken,
            };

            const response = await refreshTokenApi(payload);

            if (response?.is_success === true) {
              setCookie(`inizionavigator`, response?.responseCode, {
                path: "/",
                domain: process.env.REACT_APP_COOKIE_DOMAIN,
              });
            } else {
              // await LogoutApi(token);
              window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
          } catch (error) {
            console.error("Error refreshing token:", error);
            // await LogoutApi(token);
            window.location.href = process.env.REACT_APP_LOGIN_URL;
          }
        })();
      }, timeDifference);
    }
  }, [cookies?.inizionavigator, token, setCookie, removeCookie]);

  //   // license key for mui data grid
  // LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
  return (
    <IdleTimer ref={idleTimerRef} timeout={5 * 60 * 1000} onIdle={onIdle}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ThemeProvider>
    </IdleTimer>
  );
}

export default App;
