import React, { useEffect, useState } from "react";
import "./ViewDataLabels.css";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import Cookies from "js-cookie";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useDispatch, useSelector } from "react-redux";

import { getUserDataStart } from "../../features/currentUser/currentUserSlice";

function ViewDataLabels() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, createProjectDetails } = useSelector((state) => state);

  const [showPBI, setShowPBI] = useState(true);

  useEffect(() => {
    dispatch(getUserDataStart());

    if ((user.userInfo.pbi_embed_token == "" || null || undefined) && (user.userInfo.pbi_embed_url == "" || null || undefined)) {
      setShowPBI(false);
    }
  }, []);

  useEffect(() => {
    if ((user.userInfo.pbi_embed_token == "" || null || undefined) && (user.userInfo.pbi_embed_url == "" || null || undefined)) {
      setShowPBI(false);
    } else {
      setShowPBI(true);
    }
  }, [user]);

  // Function to navigate next screen
  // const handleNext = () => {
  //   setCurrentStep(currentStep + 1);
  //   navigate("/create-project/view-data/1");
  // };
  const accessToken = Cookies.get("access-token");
  // Function to navigate previous screen
  function goBack() {
    // window.history.back();
    navigate(`/create-project/project-details/0?projectId=${createProjectDetails?.data?.id}`);
  }

  return (
    <div className="">
      <div className="pbi-container">
        <center className="w-100">
          {/* TODO: Remove if not required */}
          {/* <iframe
            height={2930}
            width={"100%"}
            title="Basic Stats"
            src="https://app.powerbi.com/reportEmbed?reportId=023be5d1-af37-42d1-acfc-d4d4a5578858&autoAuth=true&ctid=64ff3b7b-df52-4682-b529-0b7a73c7eadf"
            frameborder="0"
            allowFullScreen="true"
          ></iframe> */}
          {/* {
                "@odata.context": "http://wabi-us-central-b-primary-redirect.analysis.windows.net/v1.0/myorg/groups/9cfb0bbc-e9bf-48d5-8d51-f2c419895402/$metadata#reports/$entity",
                "id": "023be5d1-af37-42d1-acfc-d4d4a5578858",
                "reportType": "PowerBIReport",
                "name": "Basic_Stats_Dashboard_05_03_2024_02",
                "webUrl": "https://app.powerbi.com/groups/9cfb0bbc-e9bf-48d5-8d51-f2c419895402/reports/023be5d1-af37-42d1-acfc-d4d4a5578858",
                "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=023be5d1-af37-42d1-acfc-d4d4a5578858&groupId=9cfb0bbc-e9bf-48d5-8d51-f2c419895402&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQi1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJlcG9ydEVtYmVkIjp0cnVlfX0%3d",
                "isFromPbix": true,
                "isOwnedByMe": true,
                "datasetId": "bf32569e-8721-45b0-a3d5-7124cbe266a0",
                "datasetWorkspaceId": "9cfb0bbc-e9bf-48d5-8d51-f2c419895402",
                "users": [],
                "subscriptions": []
              } */}

          {showPBI && (
            <PowerBIEmbed
              embedConfig={{
                type: "report",
                // Dev Report ID
                id: process.env.PBI_REPORT_ID,
                // Prod Report ID
                // id: "c3eb118d-f06b-446b-8a45-afc1cf044c4e",
                embedUrl: user?.userInfo?.pbi_embed_url,
                accessToken: user?.userInfo?.pbi_embed_token,
                tokenType: models.TokenType.Embed,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: true,
                    },
                  },
                  background: models.BackgroundType.transparent,
                },
              }}
              eventHandlers={
                new Map([
                  ["loaded", function () {}],
                  ["rendered", function () {}],
                  ["error", function (event) {}],
                ])
              }
              cssClassName={"Embed-container"}
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          )}
          {!showPBI && (
            <>
              <div className="error-alert">
                <Alert severity="error">Error in loading power bi report. Please make sure you have access to the report or contact administrator.</Alert>
              </div>
            </>
          )}
        </center>
      </div>
      {/* <div className="d-flex w-100 my-3 gap-2 justify-content-end">
        <Button
          onClick={() => goBack()}
          // onClick={() => navigate("/create-project/project-details/1")}
          className="btnPrimaryOutline"
          color="primary"
          variant="outlined"
        >
          Back
        </Button>
        <Button
          className="btnPrimary"
          color="primary"
          variant="contained"
          onClick={() => navigate("/create-project/design-qc-rules/1")}
          // disabled
          sx={{
            "&.Mui-disabled": {
              pointerEvents: "unset",
              cursor: "not-allowed",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          }}
        >
          Next
        </Button>
      </div> */}
    </div>
  );
}

export default ViewDataLabels;
