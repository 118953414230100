import FileSaver from "file-saver";

import axios from "../axiosConfig";

const devURL = process.env.REACT_APP_API_DEVQUANT_URL;
const baseURL = process.env.REACT_APP_BASE_URL;
const ssoURL = process.env.REACT_APP_API_SSO_URL;

export const steppedPatternApi = async (mergeFile, projectId) => {
  try {
    projectId = parseInt(projectId);
    const payload = {
      mergedFilePath: mergeFile,
      projectId: projectId,
    };
    const response = await axios.post(`${devURL}/pattern-recognition-apis/acknowledgement-apis/stepped_pattern/get-data`, payload);
    return response.data.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const alternativePatternApi = async (mergeFile, projectId) => {
  try {
    projectId = parseInt(projectId);
    const payload = {
      mergedFilePath: mergeFile,
      projectId: projectId,
    };
    const response = await axios.post(`${devURL}/pattern-recognition-apis/acknowledgement-apis/alternative_pattern/get-data`, payload);
    return response.data.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "An error occurred while processing the alternative pattern");
  }
};

export const repetitivePatternApi = async (mergeFile, projectId) => {
  try {
    projectId = parseInt(projectId);
    const payload = {
      mergedFilePath: mergeFile,
      projectId: projectId,
    };
    const response = await axios.post(`${devURL}/pattern-recognition-apis/acknowledgement-apis/repetitive_pattern/get-data`, payload);
    return response.data.data;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const patternRecognitionIncludeExcludeApi = async (body) => {
  try {
    const finalBody = { input: body };
    const response = await axios.post(`${devURL}/pattern-recognition/include_exclude/`, finalBody);
    return response.data.data;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const getMergedFilePath = async (fileName, projectId) => {
  try {
    projectId = parseInt(projectId);
    const queryParams = {
      name: fileName ?? "",
      projectId: parseInt(projectId) ?? null,
    };

    const response = await axios.get(`${baseURL}/file`, {
      params: queryParams,
    });
    return response?.data?.data?.mergeFilePath;
  } catch (error) {
    throw error;
  }
};

export const initiatePatternRecognitionApis = async (projectId, mergedFilePath) => {
  try {
    projectId = parseInt(projectId);
    const payload = {
      projectId: projectId,
      mergedFilePath: mergedFilePath,
    };
    const response = await axios.post(`${devURL}/pattern-recognition-apis/acknowledgement-apis/initiate`, payload);
    return response?.data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const downloadPatternRecognitionApi = async (projectId, mergedFilePath, projectName = "") => {
  try {
    projectId = parseInt(projectId);
    const payload = {
      projectId: projectId,
      mergedFilePath: mergedFilePath,
    };

    const response = await axios.post(`${baseURL}/pattern-recognition/download`, payload, {
      responseType: "blob",
    });

    // Create a blob from the response data and trigger the file download
    const blob = new Blob([response?.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, `${projectName}-Pattern-Recognition-${new Date().toLocaleString()}.xlsx`);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
export const refreshTokenApi = async (payload) => {
  try {
    const response = await axios.post(`${ssoURL}/azure/refresh-token`, payload);
    // debugger;
    return response.data;
  } catch (error) {
    console.error("Error: ", error);
  }
};

export const tokenValidationApi = async (payload) => {
  const data = await axios.post(`${devURL}/auth/validate`, payload);
  return data;
};
