export const JokerTableColumn = {
  RESP_ID: "resp_id",
  SCORE: "score",
  EXCLUDED: "excluded",

  ALTERNATING_PATTERN: "alternating_pattern",
  REPETITIVE_PATTERN: "repetitive_pattern",
  STEPPED_PATTERN: "stepped_pattern",
  GENAI_RATIONALE: "Rationale",
  GENAI_DRIVEN: "genai_flag",
  GENAI_SCORE: "Seriousness_Score",
};

export const jokerTableFinalMergeRows = (combinedata, calculatedRespondentObject, groupedRespondentDetails) => {
  const finalJokerTableData = [];

  for (const key in calculatedRespondentObject) {
    const respondentId = key;
    const jokerTableKey = {};

    jokerTableKey[JokerTableColumn.RESP_ID] = respondentId;
    jokerTableKey[JokerTableColumn.SCORE] = calculatedRespondentObject?.[respondentId]?.score;
    jokerTableKey[JokerTableColumn.EXCLUDED] = calculatedRespondentObject?.[respondentId]?.exclude;

    jokerTableKey[JokerTableColumn.ALTERNATING_PATTERN] = combinedata?.[respondentId]?.[JokerTableColumn.ALTERNATING_PATTERN] ?? 0;
    jokerTableKey[JokerTableColumn.REPETITIVE_PATTERN] = combinedata?.[respondentId]?.[JokerTableColumn.REPETITIVE_PATTERN] ?? 0;
    jokerTableKey[JokerTableColumn.STEPPED_PATTERN] = combinedata?.[respondentId]?.[JokerTableColumn.STEPPED_PATTERN] ?? 0;
    jokerTableKey[JokerTableColumn.GENAI_RATIONALE] = combinedata?.[respondentId]?.[JokerTableColumn.GENAI_RATIONALE] ?? "NA";
    jokerTableKey[JokerTableColumn.GENAI_DRIVEN] = combinedata?.[respondentId]?.[JokerTableColumn.GENAI_DRIVEN] ?? 0;
    jokerTableKey[JokerTableColumn.GENAI_SCORE] = combinedata?.[respondentId]?.[JokerTableColumn.GENAI_SCORE] ?? 0;

    if (groupedRespondentDetails?.[respondentId] !== undefined && groupedRespondentDetails?.[respondentId] !== null) {
      for (const ruleDataObj of groupedRespondentDetails?.[respondentId]) {
        jokerTableKey[`rule_id__${ruleDataObj?.rule_id}`] = ruleDataObj?.value;
      }
    }

    finalJokerTableData.push(jokerTableKey);
  }

  return finalJokerTableData;
};
