import { JokerTableColumn } from "../JockerTable/helper/jokerTableHelper";

export const ALTERNATING_PATTERN = "alternating_pattern";
export const STEPPED_PATTERN = "stepped_pattern";
export const STAGGERED_PATTERN = "staggered_pattern";
export const REPETITIVE_PATTERN = "repetitive_pattern";
export const ACTUAL_VALUE = "answer_code_surveyfile";
export const AE_FLAG = "adverse_event";
export const AE_RATIONAL = "adverse_event_rationale";
export const GENAI_RATIONALE = "Rationale";
export const GENAI_DRIVEN = "genai_flag";
export const GENAI_SCORE = "Seriousness_Score";

export const PATTERN_RECOGNITION_OBJECT = {
  [ALTERNATING_PATTERN]: "Alternating Pattern",
  [STEPPED_PATTERN]: "Stepped Pattern",
  [REPETITIVE_PATTERN]: "Repetitive Pattern",
  // [GENAI_DRIVEN]: "GenAi Driven",
  // [GENAI_RATIONALE]: "Rationale",
};

export const GEN_AI_COLUMNS_OBJECT = {
  [GENAI_SCORE]: "Score",
  [GENAI_RATIONALE]: "Rationale",
};

export const PATTERN_RECOGNITION_FLAT_COLUMN_OBJECT = {
  [ALTERNATING_PATTERN]: JokerTableColumn.ALTERNATING_PATTERN,
  [STEPPED_PATTERN]: JokerTableColumn.STEPPED_PATTERN,
  [REPETITIVE_PATTERN]: JokerTableColumn.REPETITIVE_PATTERN,
  [GENAI_SCORE]: JokerTableColumn.GENAI_SCORE,
  [GENAI_RATIONALE]: JokerTableColumn.GENAI_RATIONALE,
};

export const PATTERN_RECOGNITION_WEIGHT_OBJECT = {
  [ALTERNATING_PATTERN]: "Alternating Pattern",
  [STEPPED_PATTERN]: "Stepped Pattern",
  [REPETITIVE_PATTERN]: "Repetitive Pattern",
};
export const Adverse_Preview_Joker = {
  [ACTUAL_VALUE]: "Actual Value",
  [AE_FLAG]: "AE Flag",
  [AE_RATIONAL]: "AE Rational",
};

// export const patternRecognitionCalculatedGroup = {
//   30035: {
//     alternating_pattern: 30,
//     stepped_pattern: 95,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   40061: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 63,
//     repetitive_pattern: 41,
//   },
//   40062: {
//     alternating_pattern: 70,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 86,
//   },
//   40063: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   80045: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   80046: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90049: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90050: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90060: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90065: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90066: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90067: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90068: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90069: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90070: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90072: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90074: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90076: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90077: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90078: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90082: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90084: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   90086: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100047: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100049: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100050: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100053: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100054: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100056: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100058: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100059: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100060: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100061: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100064: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100065: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100067: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100068: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100069: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100070: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100071: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100072: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100073: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   100078: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   130042: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   130052: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   130057: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   130060: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   130070: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   130087: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   140065: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   140066: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   140070: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   140073: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   140993: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   140997: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   140998: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   140999: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   142110: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   142111: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   142112: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   142114: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   142116: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   142118: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   142119: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
//   142120: {
//     alternating_pattern: 10,
//     stepped_pattern: 15,
//     staggered_pattern: 21,
//     repetitive_pattern: 9,
//   },
// };

export const patternRecognitionCalculatedGroup = {};
