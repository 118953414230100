import { all } from "redux-saga/effects";

import { watchProjectSaga } from "../features/product/productSaga"; // Example saga
import { watchCreateProjectSaga } from "../features/createProject/createProductSaga";
import { watchUserSaga } from "../features/currentUser/currentUserSaga";
import { watchFetchRules } from "../features/designQcRule/Rule/ruleSaga";
import { watchFetchQuestions } from "../features/designQcRule/questionlibrary/questionlibrarySaga";
import { watchRuleExecutionSaga } from "../features/designQcRule/ruleExecution/ruleExecutionSaga";
import { watchProjectJokersSaga } from "../features/designQcRule/projectJokers/projectJokersSaga";
import { watchPatternRecognitionSaga } from "../features/designQcRule/PatternRecognition/PatternRecognitionSaga";
import { watchPreviewWeightsSaga } from "../features/designQcRule/previewWeights/previewWeightsSaga";
import { watchAdverseRuleExecutionSaga } from "../features/adverseEvent/AdverseRuleExecution/AdverseruleExecutionSaga";
import { watchBuildRuleSaga } from "../features/designQcRule/BuildRule/BuildRuleSaga";
import { watchRuleBuilderSaga } from "../features/adverseEvent/RuleBuilder/RuleBuilderSaga";
// import { watchFetchAdverseRules } from "../features/adverseEvent/AdverseRule/AdverseRuleSaga";

export default function* rootSaga() {
  yield all([
    watchProjectSaga(),
    watchCreateProjectSaga(),
    watchPatternRecognitionSaga(),
    watchUserSaga(),
    watchFetchRules(),
    watchFetchQuestions(),
    watchRuleExecutionSaga(),
    watchProjectJokersSaga(),
    watchPreviewWeightsSaga(),
    watchAdverseRuleExecutionSaga(),
    watchBuildRuleSaga(),
    watchRuleBuilderSaga(),
  ]);
}
