import React from "react";

const SavedFilesSVG = (props) => (
  <svg width={props.iconWidth} height={props.iconHeight} viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 36V4C0.5 2.067 2.067 0.5 4 0.5H17.9C18.7987 0.5 19.663 0.845712 20.3138 1.46552L28.4138 9.1798C29.1074 9.8404 29.5 10.7564 29.5 11.7143V36C29.5 37.933 27.933 39.5 26 39.5H4C2.067 39.5 0.5 37.933 0.5 36Z"
      fill="white"
      stroke="#C7C7C7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5352 26.8C18.2907 26.8 21.3352 23.7555 21.3352 20C21.3352 16.2445 18.2907 13.2 14.5352 13.2C10.7796 13.2 7.73516 16.2445 7.73516 20C7.73516 23.7555 10.7796 26.8 14.5352 26.8ZM14.5352 28C18.9534 28 22.5352 24.4183 22.5352 20C22.5352 15.5817 18.9534 12 14.5352 12C10.1169 12 6.53516 15.5817 6.53516 20C6.53516 24.4183 10.1169 28 14.5352 28Z"
      fill="#439771"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1162 17.5656C18.3505 17.7999 18.3505 18.1798 18.1162 18.4141L13.5353 22.995L11.1111 20.5707C10.8768 20.3364 10.8768 19.9565 11.1111 19.7222C11.3454 19.4879 11.7253 19.4879 11.9596 19.7222L13.5353 21.2979L17.2676 17.5656C17.5019 17.3313 17.8818 17.3313 18.1162 17.5656Z"
      fill="#439771"
    />
  </svg>
);

export default SavedFilesSVG;
