import { createSlice } from "@reduxjs/toolkit";

export const projectJokersSlice = createSlice({
  name: "projectJokers",
  initialState: {
    loading: true,
    projectJokersResponse: {},
    adverseeventdata: [],
  },
  reducers: {
    // setadverseEventdata(state, action) {
    //   state.adverseeventdata = action.payload;
    // },
    saveProjectJokersStart(state) {
      state.loading = true;
    },
    saveProjectJokersSuccess(state, action) {
      state.loading = false;
      state.projectJokersResponse = action.payload;
    },
    saveProjectJokersError(state) {
      state.loading = false;
    },
    resetProjectJokersResponse(state) {
      state.projectJokersResponse = {};
    },
  },
});

export const { saveProjectJokersStart, saveProjectJokersSuccess, saveProjectJokersError, resetProjectJokersResponse } = projectJokersSlice.actions;

export default projectJokersSlice.reducer;
