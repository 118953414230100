import axios from "axios";
import Cookies from "js-cookie";

// axios.defaults.headers["X-CSRFToken"] = Cookies.get("csrftoken");
// axios.defaults.headers.patch["X-CSRFToken"] = Cookies.get("csrftoken");

// PATCH BASEURL and Required Headers in API calls.

const FetchToken = () => {
  const cookie = Cookies.get("inizionavigator");
  if (!cookie) return null;
  const token = JSON.parse(cookie).token;
  return token;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const setAuthorizationHeader = (config) => {
  const token = FetchToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};
// Add a request interceptor to handle requests
instance.interceptors.request.use(
  (config) => setAuthorizationHeader(config),
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

// Add a response interceptor to handle responses
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if ((error.response && error.response.status === 302) || error.response.status === 401) {
      clearSession();
      redirectToLogin();
    } else if (error.code === "ECONNABORTED") {
      // Handle timeout error
    } else if (error.response === undefined) {
      clearSession();
      redirectToLogin();
    } else {
    }
    return Promise.reject(error);
  }
);

function clearSession() {
  sessionStorage.clear();
  localStorage.clear();
  Cookies.remove("inizionavigator");
}

function redirectToLogin() {
  window.location.href = process.env.REACT_APP_LOGIN_URL;
}

export default instance;
