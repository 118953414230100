import axios from "../axiosConfig";

const baseURL = process.env.REACT_APP_BASE_URL;

// Function to fetch rules from the API
export const ruleExecutionApi = async (questionFileId, respondentFileId, projId, username, ruleType) => {
  try {
    const projectId = parseInt(projId);

    const payload = {
      project_id: projectId,
      // questionFileId: questionFileId,
      // respondentFileId: respondentFileId,
      username: username,
    };

    if (ruleType) {
      payload.ruleType = ruleType;
    }

    const response = await axios.post(`${baseURL}/respondent-rule-execution/rule`, payload);

    return response.data;
  } catch (error) {
    if (error?.response?.data?.data?.length > 0) {
      // throw new Error(error?.response?.data?.data?.[0]);
      throw error.response.data;
    } else {
      throw new Error(error?.response?.data?.message ?? "Something went wrong!");
    }
  }
};

export const getExecutedRuleData = async (questionFileId, respondentFileId, projId, username, ruleType) => {
  try {
    const projectId = parseInt(projId);

    const payload = {
      project_id: projectId,
      // questionFileId: questionFileId,
      // respondentFileId: respondentFileId,
      username: username,
    };

    if (ruleType) {
      payload.ruleType = ruleType;
    }

    const response = await axios.post(`${baseURL}/respondent-rule-execution/rule-execution-data`, payload);

    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? "Something went wrong!");
  }
};
