import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { MoreVert } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "./ViewProjectTable.css";
import { PiNoteLight } from "react-icons/pi";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { FiUserMinus, FiUserPlus } from "react-icons/fi";

import AssignUserModal from "../../modals/AssgnUserModal/AssignUserModal";
import { openSnackbar } from "../../../features/snackbar/snackbarslice";
import { cloneProjects, lockProject, unlockProject, updateLastViewedProject } from "../../../services/projectService";
import { getProjectsByIdStart } from "../../../features/product/projectSlice";
import { formatDate } from "../../../assets/utils/utils";
import Pagination from "../../Pagination/Pagination";
import {
  resetAllPatternState,
  resetPatternArraysAndData,
  resetPatternRecognitionApisCalled,
  resetTableDataMap,
} from "../../../features/designQcRule/PatternRecognition/PatternRecognitionSlice";
import { resetAllTextRules } from "../../../features/designQcRule/BuildRule/BuildRuleslice";
import { resetAllAdverseTextRules } from "../../../features/adverseEvent/RuleBuilder/RuleBuilder";
import { resetOpentextState } from "../../../features/adverseEvent/OpenText/OpentextSlice";
import { resetAllState } from "../../../features/designQcRule/previewWeights/previewWeightsSlice";
import { resetToInitialState } from "../../../features/designQcRule/designQc/DesignQcSlice";
import { resetAllExecutionState } from "../../../features/designQcRule/ruleExecution/ruleExecutionSlice";
import { resetAllAdverseState } from "../../../features/adverseEvent/AdverseRule/AdverseRule";
import { resetAllAdverseExecutionState } from "../../../features/adverseEvent/AdverseRuleExecution/AdverseRuleExecutionSlice";
import { resetAllRuleState } from "../../../features/designQcRule/Rule/ruleSlice";
import { resetAllQuestionState } from "../../../features/designQcRule/questionlibrary/questionlibrarySlice";
import deleteicon from "../../../assets/images/blackdelete.svg";
import undelete from "../../../assets/images/undelete.svg";
import bluedelete from "../../../assets/images/bluedelete.svg";
import blueundelete from "../../../assets/images/blueundelete.svg";
import { resetProjectMetaData } from "../../../features/designQcRule/projectMetaData/projectMetaDataSlice";
import LockSwitch from "../../LockSwitch/LockSwitch";
import AccessDeniedModal from "../../modals/AccessDeniedModal/AccessDeniedModal";
import { closeLoader, openLoader } from "../../../features/loader/loaderSlice";
import { SNACKBAR_TYPES } from "../../../assets/constants/constant";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root": {
      height: 34,
      minHeight: 34,
    },
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: "none",
      borderRadius: 3,
      boxShadow: "0px 0px 2px rgba(0,0,0,0.3)",
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#5E5ADB",
      borderColor: "#5E5ADB",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
      width: 8,
      height: 8,
      backgroundColor: "#1890ff",
      transform: "none",
      top: "39%",
      border: 0,
    },
  };
}
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color: "#25282B",
  fontFamily: "Montserrat",
  WebkitFontSmoothing: "auto",
  letterSpacing: "0.2px",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: "#ffffff",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: 0,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid #E9EDF5",
  },
  "& .MuiDataGrid-cell": {
    color: "#25282B",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  ...customCheckbox(theme),
}));
const ViewProjectTable = ({
  data,
  projectType,
  selectedRow,
  setSelectedRow,
  getAllProjects,
  activeTab,
  handleClose,
  anchorEl,
  setAnchorEl,
  multipleDeleteProjects,
  multipleArchivedProjects,
  setMultipleDeleteModal,
  setMultipleArchivedModal,
  items,
  handlePagination,
  pageValue,
  setPageValue,
  selectedPage,
  setSelectedPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // select icon on hover
  const [hoveredItem, setHoveredItem] = useState(null);
  const handleMouseEnter = (item) => setHoveredItem(item);
  const handleMouseLeave = () => setHoveredItem(null);
  // State to control Assign user modal
  const [openAssignUserModal, setOpenAssignUserModal] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState();
  const [currentProjectOwnerEmail, setCurrentProjectOwnerEmail] = useState();
  const [currentCollaborator, setCurrentCollaborator] = useState([]);
  const [activepage, setActivepage] = useState({ projects: 1, archived: 1 });

  const [openAccessDeniedModal, setOpenAccessDeniedModal] = useState(false);
  const [accessDeniedUserEmail, setAccessDeniedUserEmail] = useState("");

  const userEmail = useSelector((state) => state?.user?.userInfo?.email);
  const userInfo = useSelector((state) => state?.user?.userInfo ?? "");

  // Edit project and navigate to create project screen
  const handleEdit = async () => {
    if (selectedRow?.length > 0) {
      const projectId = selectedRow[0];
      dispatch(getProjectsByIdStart(projectId));
      navigate(`/create-project/project-details/0?projectId=${projectId}`);
      await updateLastViewedProject(projectId); // this is not called correctly
    }
    handleClose();
  };

  const handleLinkClick = async (projectId, row, event) => {
    event.preventDefault();

    const { lockUserEmail, lockUserId } = getLockUserInfo(row?.collaborators, row?.project_lock_user_id, row.created_by, userInfo);
    const isLocked = lockUserId !== null && lockUserId !== undefined;

    if (row?.collaborators?.length > 0 && isLocked && lockUserId !== userInfo.id) {
      setAccessDeniedUserEmail(lockUserEmail ?? "");
      setOpenAccessDeniedModal(true);
    } else {
      updateLastViewedProject(projectId);
      navigate(`/create-project/project-details/0?projectId=${projectId}`);
    }
  };

  // Display soft confirmation to when selecting clone project
  const handleClone = async () => {
    if (selectedRow?.length > 0) {
      try {
        const { data } = await cloneProjects(selectedRow);
        dispatch(
          openSnackbar({
            message: `Project ${data.name} cloned Successfully!!`,
            type: SNACKBAR_TYPES.SUCCESS,
          })
        );
        getAllProjects();
        handleClose();
        navigate(`/create-project/project-details/0?projectId=${data?.id}&enable_basic_details_edit=true`);
      } catch (error) {
        dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
        handleClose();
      }
    }
  };

  const renderSwitch = (collaborators, projectId, row) => {
    const { lockUserEmail, lockUserId } = getLockUserInfo(collaborators, row.project_lock_user_id, row.created_by, userInfo);
    const isLocked = lockUserId !== null && lockUserId !== undefined;

    // const isLocked = row.project_lock_user_id !== null && row.project_lock_user_id !== undefined;
    // let lockUserEmail = null;
    // let lockUserId = null;

    // if (isLocked) {
    //   lockUserId = row.project_lock_user_id;

    //   const lockOwners = collaborators?.filter((collaboratorObject) => {
    //     const collaborator = collaboratorObject?.collaborator;
    //     return parseInt(collaborator?.id) == parseInt(lockUserId);
    //   });
    //   lockUserEmail = lockOwners?.[0]?.collaborator?.email;

    //   if (lockUserId === userInfo.id) lockUserEmail = userInfo.email;
    // }

    async function handleSwitchToggleRequest(e) {
      if (isLocked) {
        if (lockUserId !== userInfo.id) {
          setAccessDeniedUserEmail(lockUserEmail ?? "");
          setOpenAccessDeniedModal(true);
        } else {
          dispatch(openLoader());
          try {
            await unlockProject(projectId, userInfo.email, userInfo.id);
            await handlePagination(selectedPage);
          } catch (error) {
            dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
            dispatch(closeLoader());
          }
        }
      } else {
        dispatch(openLoader());
        try {
          await lockProject(projectId, userInfo.email, userInfo.id);
          await handlePagination(selectedPage);
        } catch (error) {
          dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
          dispatch(closeLoader());
        }
      }
    }

    if (collaborators?.length > 0) {
      return <LockSwitch isChecked={isLocked} onClick={handleSwitchToggleRequest} toolTipTitle={lockUserEmail} />;
    } else {
      return <></>;
    }
  };

  // Adding Collaborators in table
  const renderCollaborators = (collaborators) => (
    <AvatarGroup max={4} className="avatar-group cursor-notallowed ">
      {collaborators.map((collaborator, index) => {
        const AvatarName = collaborator?.collaborator?.email;
        return (
          <Avatar key={index} className="avatar-list">
            {AvatarName?.[0]}
          </Avatar>
        );
      })}
    </AvatarGroup>
  );

  // handle action menu click
  const handleMenuClick = (event, record) => {
    if (anchorEl !== event.currentTarget) {
      setSelectedRow([record.id]);
      setAnchorEl(event.currentTarget);
    }
  };

  // Function used to add/Assign user to the project
  const handleAssignUser = (e, record) => {
    e.preventDefault();
    const collaborator = record.row?.collaborators.map((col) => ({
      label: col.collaborator.email,
      value: parseInt(col.collaborator.id),
    }));
    setCurrentProjectId(record.id);
    setCurrentProjectOwnerEmail(record?.row?.created_by?.email ?? "");
    setCurrentCollaborator(collaborator);
    setOpenAssignUserModal(true);
  };

  function getLockUserInfo(collaborators, projectLockUserId, createdByObj, userInfo) {
    const isLocked = projectLockUserId !== null && projectLockUserId !== undefined;
    let lockUserEmail = null;
    let lockUserId = null;

    if (isLocked) {
      lockUserId = projectLockUserId;

      const lockOwners = collaborators?.filter((collaboratorObject) => {
        const collaborator = collaboratorObject?.collaborator;
        return parseInt(collaborator?.id) === parseInt(lockUserId);
      });

      lockUserEmail = lockOwners?.[0]?.collaborator?.email ?? null;

      // If the locked user is the current user, use the current user's email
      if (lockUserId === userInfo?.id) {
        lockUserEmail = userInfo?.email ?? null;
      }

      if (lockUserEmail === null && createdByObj?.id == projectLockUserId) {
        lockUserEmail = createdByObj?.email ?? null;
      }
    }

    return { lockUserEmail, lockUserId };
  }

  useEffect(() => {
    dispatch(resetPatternArraysAndData());
    dispatch(resetAllState());
    dispatch(resetTableDataMap());
    dispatch(resetAllTextRules());
    dispatch(resetAllAdverseTextRules());
    dispatch(resetPatternRecognitionApisCalled());
    dispatch(resetToInitialState());
    dispatch(resetAllPatternState());
    dispatch(resetAllExecutionState());
    dispatch(resetOpentextState());
    dispatch(resetAllAdverseState());
    dispatch(resetAllAdverseExecutionState());
    dispatch(resetAllRuleState());
    dispatch(resetAllQuestionState());
    dispatch(resetProjectMetaData());
  }, []);

  const renderActions = (record) => (
    <div className="d-flex gap-2 justify-content-center">
      <span className="cursor-notallowed">
        <IconButton className="assignUser pointer-events-none " onClick={(e) => handleAssignUser(e, record)}>
          {record?.row?.collaborators?.length > 0 ? (
            <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title="Remove User">
              <span className="assign-remove-icon">
                <FiUserMinus fontSize="14px" color="#D1293D" className="assignIcon pointer-events-none" />
              </span>
            </Tooltip>
          ) : (
            <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title="Assign User">
              <span className="assign-user-icon">
                <FiUserPlus fontSize="14px" color="#646DF3" className="assignIcon pointer-events-none" />
              </span>
            </Tooltip>
          )}
        </IconButton>
      </span>

      <Button
        aria-controls={anchorEl ? "actions-menu" : undefined}
        aria-haspopup="true"
        onClick={(event) => handleMenuClick(event, record)}
        endIcon={<MoreVert color="#439771" />}
        className="actionMenuIcon"
        onMouseOver={(event) => handleMenuClick(event, record)}
      ></Button>

      <Menu
        id="actions-menu"
        className="action-box"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          boxShadow: "none",
          borderRadius: 4,
          p: 2,
        }}
        MenuListProps={{ onMouseLeave: handleClose }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {activeTab === "projects" ? (
          <div>
            {/* <MenuItem
                className="d-flex popListItem gap-2"
                onClick={() => {
                  dispatch(resetCreateProjectState());
                  handleEdit();
                }}
              >
                <EditIcon /> Edit
              </MenuItem> */}

            {/* remove clone */}
            {/* <MenuItem className="d-flex popListItem gap-2" onClick={() => handleClone()} onMouseEnter={() => handleMouseEnter('clone')}
            onMouseLeave={handleMouseLeave}
              sx={{
                "&:hover": {
                  backgroundColor: "#eeedfd",
                  color: "#4F46E5",
                  fontWeight:"600 !important"
                },
              }}
              >
                <img src={hoveredItem === 'clone' ? bluecloneicon :cloneicon} alt="clone icon"/> Clone
              </MenuItem> */}
            <MenuItem
              className="d-flex popListItem gap-2"
              onClick={() => setMultipleArchivedModal(true)}
              onMouseEnter={() => handleMouseEnter("delete")}
              onMouseLeave={handleMouseLeave}
              sx={{
                "&:hover": {
                  backgroundColor: "#eeedfd",
                  color: "#4F46E5",
                  fontWeight: "600 !important",
                },
              }}
            >
              {/* <ArchiveOutlinedIcon /> */}
              <img src={hoveredItem === "delete" ? bluedelete : deleteicon} alt="delete icon" />
              Delete
            </MenuItem>
            <MenuItem
              className="d-flex popListItem gap-2"
              onMouseEnter={() => handleMouseEnter("undelete")}
              onMouseLeave={handleMouseLeave}
              sx={{
                "&:hover": {
                  backgroundColor: "#eeedfd",
                  color: "#4F46E5",
                  fontWeight: "600 !important",
                },
              }}
            >
              {/* <ArchiveOutlinedIcon /> */}
              <img src={hoveredItem === "undelete" ? blueundelete : undelete} alt="delete icon" />
              Un-Delete
            </MenuItem>
          </div>
        ) : (
          // <MenuItem className="d-flex popListItem gap-2" onClick={() => setMultipleDeleteModal(true)}>
          //   <DeleteIcon /> Delete
          // </MenuItem>
          <MenuItem
            className="d-flex popListItem gap-2"
            onClick={() => setMultipleDeleteModal(true)}
            onMouseEnter={() => handleMouseEnter("delete")}
            onMouseLeave={handleMouseLeave}
            sx={{
              "&:hover": {
                backgroundColor: "#eeedfd",
                color: "#4F46E5",
                fontWeight: "600 !important",
              },
            }}
          >
            {/* <ArchiveOutlinedIcon /> */}
            <img src={hoveredItem === "delete" ? bluedelete : deleteicon} alt="delete icon" />
            <div>Delete</div>
          </MenuItem>
        )}
      </Menu>
    </div>
  );

  const columns = [
    {
      field: "name",
      headerName: "Project Name",
      flex: 1,
      minWidth: 180,
      headerClassName: "quant-app-theme--header",
      cellClassName: ["quant-app-theme--cell"],
      renderCell: (params) => (
        <Link
          to={`/create-project/project-details/0?projectId=${params.row.id}`}
          className="project-name-link"
          onClick={(event) => handleLinkClick(params.row.id, params?.row, event)}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 180,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "objective_type",
      headerName: "Objective Type",
      flex: 0.8,
      minWidth: 140,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 0.8,
      minWidth: 120,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "progress",
      headerName: "Status",
      flex: 0.3,
      minWidth: 100,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",

      renderCell: (params) => {
        const status = params?.row?.progress;
        let statusClass = "";
        if (status < 0.6) {
          statusClass = "statusBgRed";
        } else if (status >= 0.6 && status < 0.8) {
          statusClass = "statusBgOrange";
        } else {
          statusClass = "statusBgGreen";
        }
        return <center className={`${statusClass}`}>{(status * 100.0).toFixed(0)}%</center>;
      },
    },
    {
      field: "updated_at",
      headerName: "Last Updated",
      flex: 0.75,
      minWidth: 190,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => (
        <div className="dateIcon">
          <PiNoteLight color="#4f46e5" fontSize={16} /> {formatDate(params.row.updated_at)}
        </div>
      ),
    },
    {
      field: "collborators",
      headerName: "Collaborators",
      renderCell: ({ row }) => renderCollaborators(row.collaborators),
      flex: 0.5,
      minWidth: 160,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "projectLock",
      headerName: "Lock",
      renderCell: ({ row }) => renderSwitch(row?.collaborators, row?.id, row),
      flex: 0.5,
      minWidth: 60,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 120,
      renderCell: (e) => renderActions(e),
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
  ];

  return (
    <div className="w-100 project-table-container">
      <StyledDataGrid
        sortingOrder={["desc", "asc"]}
        rowHeight={48}
        rows={data?.results?.map((item, index) => ({
          ...item,
          id: item.id,
          key: index + item.id,
        }))}
        rowSelectionModel={selectedRow}
        onRowSelectionModelChange={(e) => setSelectedRow(e)}
        columns={columns}
        // initialState={{
        //   pagination: {
        //     paginationModel: { page: 1, pageSize: 10 },
        //   },
        // }}
        // pageSizeOptions={[10, 20]}
        // autoPageSize={false}
        // pagination={false}
        checkboxSelection
        disableRowSelectionOnClick={true}
        sx={{
          boxShadow: 3,
          border: 0,
          backgroundColor: "#fff",
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        components={{
          Pagination: () => (
            <Pagination
              count={Math.ceil(data.count / 2)}
              activepage={activepage}
              activeTab={activeTab}
              items={items}
              className="grid-pagination"
              handlePagination={handlePagination}
              pageValue={pageValue}
              setPageValue={setPageValue}
              data={data}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          ),
        }}
      />
      {/* <Pagination /> */}
      <AssignUserModal
        currentProjectOwnerEmail={currentProjectOwnerEmail}
        userEmail={userEmail}
        openModal={openAssignUserModal}
        setOpenModal={setOpenAssignUserModal}
        assignProject={currentProjectId}
        assigned={currentCollaborator}
        setAssigned={setCurrentCollaborator}
      />
      <AccessDeniedModal open={openAccessDeniedModal} userEmail={accessDeniedUserEmail} resetOpen={() => setOpenAccessDeniedModal(false)} />
    </div>
  );
};

export default ViewProjectTable;
