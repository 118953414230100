// src/features/counterSlice.js

import { createSlice } from "@reduxjs/toolkit";

import { PROJECT_TYPE } from "../../assets/constants/constant";

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    data: { results: [] },
    archived: { results: [] },
    editProjectId: null,
    editProjectdetails: {},
    loading: true,
    error: null,
  },
  reducers: {
    getProjectsStart(state) {
      state.loading = true;
      state.error = null;
    },
    getProjectsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    getArchivedprojectStart(state) {
      state.loading = true;
      state.error = null;
    },
    getArchivedprojectSuccess(state, action) {
      state.loading = false;
      state.archived = action.payload;
    },
    getProjectError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getProjectsByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    getProjectById(state, action) {
      state.loading = false;
      state.editProjectId = action.payload;
    },
    setProjectLocked(state, action) {
      const projectId = action?.payload?.id;
      const projectType = action?.payload?.projectType;

      const projectsArray = projectType === PROJECT_TYPE.PROJECT ? state.data.results : state.archived.results;

      for (const project of projectsArray) {
        if (project.id === projectId) project.project_lock_user_id = true;
      }
    },
    addCollaborator(state, action) {
      const { projectName, collaborators } = action.payload;
      const addData = state.data.results.map((ele) => {
        if (ele.name === projectName) {
          ele.collaborators = collaborators;
        }
        return ele;
      });
      state.data = addData;
    },
  },
});

export const {
  getProjectsStart,
  getProjectsSuccess,
  getProjectError,
  getProjectById,
  getProjectsByIdStart,
  getArchivedprojectStart,
  getArchivedprojectSuccess,
  addCollaborator,
  setProjectLocked,
} = projectSlice.actions;

export default projectSlice.reducer;
