export function isFloat(value) {
  return typeof value === "number" && !Number.isInteger(value);
}

/**
 * Formats a number to a specified precision level.
 * @param {number} value - The number to format.
 * @param {number} precision - The number of decimal places to format to.
 * @returns {string} - The formatted number as a string.
 */
export function formatToPrecision(value, precision) {
  if (typeof value !== "number" || typeof precision !== "number") {
    throw new TypeError("Both value and precision must be numbers");
  }

  const multiplier = Math.pow(10, precision);
  const formattedValue = Math.round(value * multiplier) / multiplier;

  // Convert to string and return
  return formattedValue.toString();
}

export function addStringOnIndex(mainValue, index, inputValue) {
  try {
    // Check if both mainValue and inputValue are strings
    if (typeof mainValue !== "string" || typeof inputValue !== "string") {
      throw new TypeError("Both mainValue and inputValue must be strings");
    }

    // Check if index is within the valid range (0 to string length)
    if (index < 0 || index > mainValue.length) {
      throw new TypeError("Index is out of range");
    }

    // Perform string manipulation to insert the inputValue at the index
    return mainValue.slice(0, index) + inputValue + mainValue.slice(index);
  } catch (error) {
    return mainValue;
  }
}

export function addStringOrAppendOnIndex(mainValue, index, inputValue) {
  try {
    // Check if both mainValue and inputValue are strings
    if (typeof mainValue !== "string" || typeof inputValue !== "string") {
      throw new TypeError("Both mainValue and inputValue must be strings");
    }

    // Check if index is within the valid range (0 to string length)
    if (index < 0) {
      throw new TypeError("Index is out of range");
    }

    if (index > mainValue.length) {
      mainValue = mainValue + inputValue;
      return mainValue;
    }

    // Perform string manipulation to insert the inputValue at the index
    return mainValue.slice(0, index) + inputValue + mainValue.slice(index);
  } catch (error) {
    return mainValue;
  }
}

export function getWordAtCaret(text, caretPos) {
  try {
    // Split the text into segments
    const words = text.split(/(\s+|\b|\(|\))/);

    // Initialize caret position trackers
    let start = 0;
    let end = 0;
    let word = "";

    for (const segment of words) {
      end = start + segment.length;
      if (caretPos >= start && caretPos <= end) {
        word = segment.trim(); // Remove extra whitespace
        break;
      }
      start = end;
    }

    return word;
  } catch (error) {
    return "";
  }
}

export function encodeToBase64(input) {
  return btoa(unescape(encodeURIComponent(input)));
}

export function decodeFromBase64(input) {
  return decodeURIComponent(escape(atob(input)));
}

export function encodeRulesArrayOptionsMetaToBase64(rules) {
  try {
    if (!Array.isArray(rules)) {
      throw new TypeError("Expected an array of rules");
    }

    // Create a deep copy of rules array
    const newRules = rules.map((rule) => ({
      ...rule,
      optionsMeta: { ...rule.optionsMeta },
    }));

    if (newRules.length > 0) {
      for (const rule of newRules) {
        if (rule?.optionsMeta && Object.keys(rule.optionsMeta).length > 0) {
          Object.keys(rule.optionsMeta).forEach((key) => {
            try {
              rule.optionsMeta[key] = encodeToBase64(rule.optionsMeta[key]);
            } catch (error) {
              rule.optionsMeta[key] = rule.optionsMeta[key];
            }
          });
        }
      }
    }

    return newRules;
  } catch (err) {
    return rules;
  }
}

export function decodeRulesArrayOptionsMetaFromBase64(rules) {
  try {
    if (!Array.isArray(rules)) {
      throw new TypeError("Expected an array of rules");
    }

    // Create a deep copy of rules array
    const newRules = rules.map((rule) => ({
      ...rule,
      optionsMeta: { ...rule.optionsMeta },
    }));

    if (newRules.length > 0) {
      for (const rule of newRules) {
        if (rule?.optionsMeta && Object.keys(rule.optionsMeta).length > 0) {
          Object.keys(rule.optionsMeta).forEach((key) => {
            try {
              rule.optionsMeta[key] = decodeFromBase64(rule.optionsMeta[key]);
            } catch (error) {
              console.error("Failed to decode from base64:", error);
              rule.optionsMeta[key] = rule.optionsMeta[key];
            }
          });
        }
      }
    }

    return newRules;
  } catch (error) {
    return rules; // Return the original input if decoding fails
  }
}

export function numberToPercentage(value, decimals = 1) {
  // Ensure value is a number
  const number = parseFloat(value);

  // Check if number is valid
  if (isNaN(number)) {
    return "NAN";
  }

  // Calculate percentage with specified decimals
  const percentage = `${(number * 100).toFixed(decimals)}%`;

  return percentage;
}

export function numberToPercentageFloor(value) {
  // Ensure value is a number
  const number = parseFloat(value);

  // Check if the number is valid
  if (isNaN(number)) {
    return "NAN";
  }

  // Calculate the percentage and apply floor
  const percentage = `${Math.floor(number * 100)}%`;

  return percentage;
}
