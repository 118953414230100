import { Box, Button, LinearProgress, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";

import "./FileUploadModal.css";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { ImSpinner8 } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { IoCheckmarkCircle, IoClose, IoCloseCircle } from "react-icons/io5";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";

import { SNACKBAR_TYPES, fileUploadModalText } from "../../../assets/constants/constant";
import FileIconUploadSVG from "../../../assets/images/FileIconUploadSVG";
import {
  fileValidationSequence,
  getExistingPotentialJokers,
  getMergedFileStatus,
  questionMapperApi,
  removeRuleStatus,
  respondentMapperApi,
} from "../../../services/projectService";
import {
  maliciousCheckError,
  maliciousCheckSuccess,
  resetState,
  validateDataFileError,
  validateDataFileSuccess,
  validateDataGridCheckError,
  validateDataGridCheckSuccess,
  validateDataMatchCheckError,
  validateDataMatchCheckSuccess,
  validateDataMultiCheckError,
  validateDataMultiCheckSuccess,
  validateDataNumericListCheckError,
  validateDataNumericListCheckSuccess,
  validateDataOpenTextListCheckError,
  validateDataOpenTextListCheckSuccess,
  validateDataRankingCheckError,
  validateDataRankingCheckSuccess,
  validateDataRowValidationCheckError,
  validateDataRowValidationCheckSuccess,
  validateDataSingleCheckError,
  validateDataSingleCheckSuccess,
  validateIdError,
  validateIdSuccess,
  validateRawFileError,
  validateRawFileSuccess,
} from "../../../features/createProject/validateFilesSlice";
import { createProjectStart } from "../../../features/createProject/createProductSlice";
import TickCircleSVG from "../../../assets/images/TickCircleSVG";

// Select dropdown MUI

import { validationFileError } from "../../../assets/utils/utils";
import ButtonComponent from "../../Button/ButtonComponent";
import { getMergedFilePath, initiatePatternRecognitionApis } from "../../../services/patternService";
import { openSnackbar } from "../../../features/snackbar/snackbarslice";
import { closeLoader } from "../../../features/loader/loaderSlice";
import PermanentRespondentModal from "../PermanentRespondentModal/PermanentRespondentModal";

const FileUploadModal = ({
  openModal = false,
  rawDataFile,
  mapDataFile,
  setopenModal,
  progress = 0,
  setProgress,
  setFileType,
  upladedSuccess,
  setUploadedSuccess,
  duplicateErrorMessage,
  setDuplicateErrorMessage,
  rawErrorMessage,
  setRawErrorMessage,
  dataErrorMessage,
  setDataErrorMessage,
  fileType,
  projectDetails,
  projectId,
}) => {
  const [validationStart, setvalidationStart] = useState(false);
  const [mergedFileStatus, setMergedFileStatus] = useState(false);
  const [mergedFileStatusIsError, setMergedFileStatusIsError] = useState(false);
  // const [parsedFileStatus, setParsedFileStatus] = useState(false);
  const dispatch = useDispatch();
  const { validateFiles } = useSelector((state) => state);
  const [hasError, sethasError] = useState(false);
  const [showPermanentRespondentModal, setShowPermanentRespondentModal] = useState(false);
  const [potentialPermanentJokerList, setPotentialPermanentJokerList] = useState([]);

  const navigate = useNavigate();
  const username = useSelector((state) => state?.user?.userInfo?.email) ?? "harsh.chauhan@inizioadvisory.onmicrosoft.com";
  // const username = "harsh.chauhan@inizioadvisory.onmicrosoft.com";
  useEffect(() => {
    setUploadedSuccess("pending");
    setvalidationStart(false);
    let timer;
    if (openModal) {
      timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress === 100 ? 100 : prevProgress >= 80 ? 80 : (prevProgress += 10)));
      }, 800);
    }
    return () => {
      clearInterval(timer);
      setProgress(10);
    };
  }, [openModal]);

  const validateFilesSequential = async () => {
    sethasError(false);
    setvalidationStart(true);

    const data = {
      name: projectDetails?.name,
      client_name: projectDetails?.client_name,
      organization_name: projectDetails?.organization_name.value,
      file_type: fileType?.file_type.value,
    };

    let errorOccurred = false;

    try {
      const { data: maliciousCheck } = await fileValidationSequence(projectId, fileUploadModalText.maliciousCheckQuery);
      const valid = validationFileError(
        maliciousCheck.active_data_map_file.file_validations,
        maliciousCheck.active_raw_file.file_validations,
        "V1_MALICIOUS_CHECK"
      );
      if (!valid) {
        throw new Error("Validation failed");
      } else {
        dispatch(maliciousCheckSuccess(maliciousCheck.message));
      }
    } catch (error) {
      errorOccurred = true;
      dispatch(maliciousCheckError());
    }

    // TODO: Call sequential API once developed
    try {
      const { data: validateId } = await fileValidationSequence(projectId, fileUploadModalText.uniqueRespIDCheckQuery);

      const valid = validationFileError([], validateId.active_raw_file.file_validations, fileUploadModalText.uniqueRespIDCheckQuery);
      const fileValidations = validateId.active_raw_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.uniqueRespIDCheckQuery);
      const validIdMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validIdMessage);
      } else {
        dispatch(validateIdSuccess(validIdMessage));
      }
    } catch (error) {
      dispatch(validateIdError(error.message));
      errorOccurred = true;
    }

    try {
      const { data: validateDataMapFile } = await fileValidationSequence(projectId, fileUploadModalText.dataMapFileFmtCheckQuery);
      const valid = validationFileError(validateDataMapFile.active_data_map_file.file_validations, [], fileUploadModalText.dataMapFileFmtCheckQuery);
      const fileValidations = validateDataMapFile.active_data_map_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.dataMapFileFmtCheckQuery);
      const validateDataMapFileMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validateDataMapFileMessage);
      } else {
        dispatch(validateDataFileSuccess(validateDataMapFileMessage));
      }
    } catch (error) {
      dispatch(validateDataFileError(error.message));
      errorOccurred = true;
    }

    try {
      const { data: validateRawFile } = await fileValidationSequence(projectId, fileUploadModalText.rawVarCountCheckQuery);
      const valid = validationFileError([], validateRawFile.active_raw_file.file_validations, fileUploadModalText.rawVarCountCheckQuery);
      const fileValidations = validateRawFile.active_raw_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.rawVarCountCheckQuery);
      const validateRawFileMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validateRawFileMessage);
      } else {
        dispatch(validateRawFileSuccess(validateRawFileMessage));
      }
    } catch (error) {
      dispatch(validateRawFileError(error.message));
      errorOccurred = true;
    }

    try {
      const { data: validateDataMapFile } = await fileValidationSequence(projectId, fileUploadModalText.rowValidationCheckQuery);
      const valid = validationFileError(validateDataMapFile.active_data_map_file.file_validations, [], fileUploadModalText.rowValidationCheckQuery);
      const fileValidations = validateDataMapFile.active_data_map_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.rowValidationCheckQuery);
      const validateDataMapFileMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validateDataMapFileMessage);
      } else {
        dispatch(validateDataRowValidationCheckSuccess(validateDataMapFileMessage));
      }
    } catch (error) {
      dispatch(validateDataRowValidationCheckError(error.message));
      errorOccurred = true;
    }

    try {
      const { data: validateDataMapFile } = await fileValidationSequence(projectId, fileUploadModalText.dataMapSingleQuestionsCheckQuery);
      const valid = validationFileError(validateDataMapFile.active_data_map_file.file_validations, [], fileUploadModalText.dataMapSingleQuestionsCheckQuery);
      const fileValidations = validateDataMapFile.active_data_map_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.dataMapSingleQuestionsCheckQuery);
      const validateDataMapFileMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validateDataMapFileMessage);
      } else {
        dispatch(validateDataSingleCheckSuccess(validateDataMapFileMessage));
      }
    } catch (error) {
      dispatch(validateDataSingleCheckError(error.message));
      errorOccurred = true;
    }

    try {
      const { data: validateDataMapFile } = await fileValidationSequence(projectId, fileUploadModalText.dataMapMultiQuestionsCheckQuery);
      const valid = validationFileError(validateDataMapFile.active_data_map_file.file_validations, [], fileUploadModalText.dataMapMultiQuestionsCheckQuery);
      const fileValidations = validateDataMapFile.active_data_map_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.dataMapMultiQuestionsCheckQuery);
      const validateDataMapFileMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validateDataMapFileMessage);
      } else {
        dispatch(validateDataMultiCheckSuccess(validateDataMapFileMessage));
      }
    } catch (error) {
      dispatch(validateDataMultiCheckError(error.message));
      errorOccurred = true;
    }

    try {
      const { data: validateDataMapFile } = await fileValidationSequence(projectId, fileUploadModalText.dataMapRankingQuestionsCheckQuery);
      const valid = validationFileError(validateDataMapFile.active_data_map_file.file_validations, [], fileUploadModalText.dataMapRankingQuestionsCheckQuery);
      const fileValidations = validateDataMapFile.active_data_map_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.dataMapRankingQuestionsCheckQuery);
      const validateDataMapFileMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validateDataMapFileMessage);
      } else {
        dispatch(validateDataRankingCheckSuccess(validateDataMapFileMessage));
      }
    } catch (error) {
      dispatch(validateDataRankingCheckError(error.message));
      errorOccurred = true;
    }

    try {
      const { data: validateDataMapFile } = await fileValidationSequence(projectId, fileUploadModalText.dataMapNumericListQuestionsCheckQuery);
      const valid = validationFileError(
        validateDataMapFile.active_data_map_file.file_validations,
        [],
        fileUploadModalText.dataMapNumericListQuestionsCheckQuery
      );
      const fileValidations = validateDataMapFile.active_data_map_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.dataMapNumericListQuestionsCheckQuery);
      const validateDataMapFileMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validateDataMapFileMessage);
      } else {
        dispatch(validateDataNumericListCheckSuccess(validateDataMapFileMessage));
      }
    } catch (error) {
      dispatch(validateDataNumericListCheckError(error.message));
      errorOccurred = true;
    }

    try {
      const { data: validateDataMapFile } = await fileValidationSequence(projectId, fileUploadModalText.dataMapOpenTextListQuestionsCheckQuery);
      const valid = validationFileError(
        validateDataMapFile.active_data_map_file.file_validations,
        [],
        fileUploadModalText.dataMapOpenTextListQuestionsCheckQuery
      );
      const fileValidations = validateDataMapFile.active_data_map_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.dataMapOpenTextListQuestionsCheckQuery);
      const validateDataMapFileMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validateDataMapFileMessage);
      } else {
        dispatch(validateDataOpenTextListCheckSuccess(validateDataMapFileMessage));
      }
    } catch (error) {
      dispatch(validateDataOpenTextListCheckError(error.message));
      errorOccurred = true;
    }

    try {
      const { data: validateDataMapFile } = await fileValidationSequence(projectId, fileUploadModalText.dataMapGridQuestionsCheckQuery);
      const valid = validationFileError(validateDataMapFile.active_data_map_file.file_validations, [], fileUploadModalText.dataMapGridQuestionsCheckQuery);
      const fileValidations = validateDataMapFile.active_data_map_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.dataMapGridQuestionsCheckQuery);
      const validateDataMapFileMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validateDataMapFileMessage);
      } else {
        dispatch(validateDataGridCheckSuccess(validateDataMapFileMessage));
      }
    } catch (error) {
      dispatch(validateDataGridCheckError(error.message));
      errorOccurred = true;
    }

    try {
      const { data: validateDataMapFileCheck } = await fileValidationSequence(projectId, fileUploadModalText.dataMapVarMatchCheckQuery);
      const valid = validationFileError(validateDataMapFileCheck.active_data_map_file.file_validations, [], fileUploadModalText.dataMapVarMatchCheckQuery);
      const fileValidations = validateDataMapFileCheck.active_data_map_file.file_validations;
      const validation = fileValidations.find((item) => item.validation.code === fileUploadModalText.dataMapVarMatchCheckQuery);
      const validateDataMapFileCheckMessage = validation ? validation.message : null;

      if (!valid) {
        throw new Error(validateDataMapFileCheckMessage);
      } else {
        dispatch(validateDataMatchCheckSuccess(validateDataMapFileCheckMessage));
      }
    } catch (error) {
      dispatch(validateDataMatchCheckError(error.message));
      errorOccurred = true;
    }

    try {
      if (
        errorOccurred ||
        validateFiles.validateId.status === "error" ||
        validateFiles.validateRawFile.status === "error" ||
        validateFiles.validateDataFile.status === "error" ||
        validateFiles.validateDataMatchCheck.status === "error" ||
        validateFiles.validateDataRowValidationCheck.status === "error" ||
        validateFiles.validateDataSingleCheck.status === "error" ||
        validateFiles.validateDataMultiCheck.status === "error" ||
        validateFiles.validateDataRankingCheck.status === "error" ||
        validateFiles.validateDataNumericListCheck.status === "error" ||
        validateFiles.validateDataOpenTextListCheck.status === "error" ||
        validateFiles.validateDataGridCheck.status === "error"
      ) {
        sethasError(true);
        return;
      }

      setMergedFileStatus(false);
      setMergedFileStatusIsError(false);
      dispatch(createProjectStart(projectId)); // TODO: This is triggered at time interval(needs to be handled from BE)

      const duration = 600 * 1000; // 5 minutes in milliseconds
      const interval = 30 * 1000; // 10 seconds in milliseconds
      const endTime = Date.now() + duration;

      const fetchData = async () => {
        try {
          const { data } = await getMergedFileStatus(projectId);
          if (
            data?.data_file_type?.status == "LOADED" ||
            data?.active_raw_file?.status == "LOADED" ||
            data?.data_file_type?.status == "MERGED" ||
            data?.active_raw_file?.status == "MERGE"
          ) {
            try {
              clearInterval(intervalId);
              // setParsedFileStatus(false);
              dispatch(createProjectStart(projectId));

              const getPotentialJokerRespondents = async () => {
                try {
                  const response = await getExistingPotentialJokers(data?.active_raw_file?.id, projectId);
                  const potentialJokerList = response?.data?.data ?? [];

                  setPotentialPermanentJokerList(potentialJokerList);
                  return potentialJokerList;
                } catch (error) {
                  dispatch(openSnackbar({ message: "Couldn't fetch existing jokers", type: SNACKBAR_TYPES.ERROR }));
                }
              };

              const potentialJokerList = await getPotentialJokerRespondents();

              if (potentialJokerList.length > 0) {
                setShowPermanentRespondentModal(true);
                setMergedFileStatus(true);
              } else {
                const mapperApiCalls = async () => {
                  try {
                    await questionMapperApi(data?.active_data_map_file?.id, username);
                    await respondentMapperApi(data?.active_data_map_file?.id, data?.active_raw_file?.id, [], username);
                  } catch (error) {
                    dispatch(openSnackbar({ message: "Failed to parse files!", type: SNACKBAR_TYPES.ERROR }));
                  }

                  try {
                    await removeRuleStatus(projectId);
                  } catch (error) {
                    console.error({ error });
                  }
                };

                const initiatePatternRecognitionApiCalls = async () => {
                  try {
                    const mergedFilePath = await getMergedFilePath(data?.active_data_map_file?.name, projectId);
                    await initiatePatternRecognitionApis(projectId, mergedFilePath);
                  } catch (error) {
                    dispatch(openSnackbar({ message: "Failed to initiate pattern recognition apis!", type: SNACKBAR_TYPES.ERROR }));
                  }
                };

                try {
                  // dispatch(openLoader());
                  await mapperApiCalls();
                  await initiatePatternRecognitionApiCalls();
                  // setParsedFileStatus(true);
                  setMergedFileStatus(true);
                  navigate("/create-project/design-qc-rules/1");
                } catch (error) {
                } finally {
                  // dispatch(closeLoader());
                }
              }
            } catch (error) {}
          } else if (data?.data_file_type?.status == "ERROR" || data?.active_raw_file?.status == "ERROR") {
            if (intervalId) clearInterval(intervalId);
            setMergedFileStatus(false);
            setMergedFileStatusIsError(true);
            // setParsedFileStatus(false);
            dispatch(closeLoader());
            dispatch(openSnackbar({ message: "Failed to merge file", type: SNACKBAR_TYPES.ERROR }));
          } else {
            setMergedFileStatus(false);
            // setParsedFileStatus(false);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const intervalId = setInterval(fetchData, interval);

      // Stop setInterval after 5 minutes
      setTimeout(() => {
        clearInterval(intervalId);
      }, duration);
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    if (upladedSuccess === "success") {
      dispatch(resetState());
      validateFilesSequential();
    }
  }, [upladedSuccess]);

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    // 'label + &': {
    //   marginTop: theme.spacing(3),
    // },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      minHeight: 28,
      height: 28,
      padding: 0,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

  const styles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };
  const validationError = `${
    validationStart &&
    // validateFiles.maliciousCheck.status === "error" ||
    (validateFiles.validateId.status === "error" ||
      validateFiles.validateRawFile.status === "error" ||
      validateFiles.validateDataFile.status === "error" ||
      validateFiles.validateDataRowValidationCheck.status === "error" ||
      validateFiles.validateDataSingleCheck.status === "error" ||
      validateFiles.validateDataMultiCheck.status === "error" ||
      validateFiles.validateDataRankingCheck.status === "error" ||
      validateFiles.validateDataNumericListCheck.status === "error" ||
      validateFiles.validateDataOpenTextListCheck.status === "error" ||
      validateFiles.validateDataGridCheck.status === "error" ||
      validateFiles.validateDataMatchCheck.status === "error")
  }`;

  return (
    <>
      {!showPermanentRespondentModal ? (
        <Modal
          open={openModal}
          //   onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="fileUploadModal" onClick={(e) => e.stopPropagation()}>
            <Button
              className="closeModalBtn sizeLarge"
              onClick={(e) => {
                e.preventDefault();
                setopenModal(false);
                dispatch(resetState());
              }}
            >
              <IoClose fontSize="28px" width="24px" height="24px" color="#4D4D4D" />
            </Button>
            <div className="file_header">
              <div className="attach">Attachments</div>
              <div className="header_right d-flex align-items-baseline gap-3">
                {/* <label htmlFor="inputPassword4">Data file type</label>
           <Select
              labelId="select-dropdown-label"
              id="select-dropdown"
              className="single-select"
              classNamePrefix="select"
              // options={fileTypeOptions}
              value={fileType?.file_type?.value}
              disabled={true}
              onChange={(e) => setFileType({ ...fileType, file_type: e })}
              styles={styles}
            >
              {fileTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              </Select>*
            <SelectDropdown
              className="basic-single"
              classNamePrefix="select"
              options={fileTypeOptions}
              value={fileType?.file_type}
              disabled={true}
              onChange={(e) => setFileType({ ...fileType, file_type: e })}
              styles={styles}
            />
              */}
              </div>
            </div>
            <div className="w-100 modal-content-body d-flex flex-column align-items-center justify-content-center p-3">
              {!validationStart && (
                <div className="row w-100">
                  <div className="col-md-12 file_progress_upload p-2 mb-2">
                    <span className="fileTitleUpload py-2 d-flex">Raw Data File</span>
                    <div className="uploadContainer p-3 bg-white">
                      <div className="d-flex align-items-center gap-3 w-100">
                        <FileIconUploadSVG iconWidth="30" iconHeight="40" viewBox="0 0 30 48" />
                        <div className="d-flex flex-column">
                          <span className="fileName">{rawDataFile?.name}</span>
                          <span className="uploadFileSize">{(rawDataFile?.size / 1024).toFixed(2)} kb</span>
                        </div>
                      </div>
                      {upladedSuccess === "pending" && (
                        <div className="w-100 my-2">
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                            className="progressBg"
                            sx={{
                              color: "#6366F1",
                              backgroundColor: "#6366F1",
                            }}
                          />
                          <span className="uploadProgress">{progress}/100% Uploaded</span>
                        </div>
                      )}
                      {upladedSuccess === "success" && (
                        <div className="w-100 mt-2 d-flex align-items-center justify-content-start gap-2">
                          <TickCircleSVG />
                          <span className="fileUploadSuccess">{fileUploadModalText.uploadSuccessfull}</span>
                        </div>
                      )}
                      {upladedSuccess === "fail" && (
                        <div className="w-100 mt-2 d-flex align-items-center justify-content-start gap-2">
                          {rawErrorMessage ? (
                            <span className="fileUploadError">
                              <CancelRoundedIcon color="red" /> {rawErrorMessage}
                            </span>
                          ) : (
                            " "
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 file_progress_upload p-2 mt-2">
                    <span className="fileTitleUpload py-2 d-flex">Data Map File </span>
                    <div className="uploadContainer p-3 bg-white">
                      <div className="d-flex align-items-center gap-3 w-100">
                        <FileIconUploadSVG iconWidth="30" iconHeight="40" viewBox="0 0 30 48" />
                        <div className="d-flex flex-column">
                          <span className="fileName">{mapDataFile?.name}</span>
                          <span className="uploadFileSize">{(mapDataFile?.size / 1024).toFixed(2)} kb</span>
                        </div>
                      </div>
                      {upladedSuccess === "pending" && (
                        <div className="w-100 my-2">
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                              color: "#6366F1",
                              backgroundColor: "#6366F1",
                            }}
                          />
                          <span className="uploadProgress">{progress}/100% Uploaded</span>
                        </div>
                      )}
                      {upladedSuccess === "success" && (
                        <div className="w-100 mt-2 d-flex align-items-center justify-content-start gap-2">
                          <TickCircleSVG />
                          <span className="fileUploadSuccess">{fileUploadModalText.uploadSuccessfull}</span>
                        </div>
                      )}
                      {upladedSuccess === "fail" && (
                        <div className="w-100 mt-2 d-flex align-items-center justify-content-start gap-2">
                          {dataErrorMessage ? (
                            <span className="fileUploadError">
                              <CancelRoundedIcon color="red" /> {dataErrorMessage}
                            </span>
                          ) : (
                            " "
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {validationStart && (
                <>
                  {" "}
                  <div className="validate_header w-100">
                    <div className="validate_filename">
                      <FileIconUploadSVG conWidth="20" iconHeight="26" viewBox="0 6 30 42" />

                      <span className="saved-filename">{rawDataFile?.name}</span>
                    </div>
                    <div className="validate_filename">
                      <FileIconUploadSVG conWidth="20" iconHeight="26" viewBox="0 6 30 42" />
                      <div className="d-flex flex-column">
                        <span className="saved-filename">{mapDataFile?.name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="validate_body m-2">
                    <div className="success_message">
                      <div className="subTitle-text-upload">{fileUploadModalText.dataValidationInsightsGeneration}</div>

                      {[
                        // validateFiles.maliciousCheck,
                        validateFiles.validateId,
                        validateFiles.validateDataFile,
                        validateFiles.validateRawFile,
                        validateFiles.validateDataRowValidationCheck,
                        validateFiles.validateDataSingleCheck,
                        validateFiles.validateDataMultiCheck,
                        validateFiles.validateDataRankingCheck,
                        validateFiles.validateDataNumericListCheck,
                        validateFiles.validateDataOpenTextListCheck,
                        validateFiles.validateDataGridCheck,
                        validateFiles.validateDataMatchCheck,
                      ].map((validationType) => (
                        <>
                          {(validationType.status === "success" || validationType.status === "pending") && (
                            <div className="d-flex gap-2 pb-2">
                              <div className="d-flex align-items-center">
                                {validationType.status === "pending" ? (
                                  <ImSpinner8 color="#4f46e5" fontSize="20px" className="rotating-spinner" />
                                ) : validationType.status === "success" ? (
                                  <IoCheckmarkCircle fontSize="20" color="#4F46E5" />
                                ) : (
                                  <IoCloseCircle fontSize="20" color="#EF0707" />
                                )}
                              </div>
                              {validationType.status === "pending" ? (
                                <span className="validationTextProcess">{validationType.message}</span>
                              ) : validationType.status === "success" ? (
                                <span className="validationText">{validationType.successMessage}</span>
                              ) : (
                                <span className="validationText">{validationType.errorMessage}</span>
                              )}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                    {validationStart &&
                      // validateFiles.maliciousCheck.status === "success" &&
                      validateFiles.validateId.status === "success" &&
                      validateFiles.validateRawFile.status === "success" &&
                      validateFiles.validateDataFile.status === "success" &&
                      validateFiles.validateDataRowValidationCheck.status === "success" &&
                      validateFiles.validateDataSingleCheck.status === "success" &&
                      validateFiles.validateDataMultiCheck.status === "success" &&
                      validateFiles.validateDataRankingCheck.status === "success" &&
                      validateFiles.validateDataNumericListCheck.status === "success" &&
                      validateFiles.validateDataOpenTextListCheck.status === "success" &&
                      validateFiles.validateDataGridCheck.status === "success" &&
                      validateFiles.validateDataMatchCheck.status === "success" && (
                        <div className="validation-success-message mt-2">
                          {mergedFileStatus && !mergedFileStatusIsError && (
                            <span className="d-flex justify-content-start align-items-center gap-2">
                              <IoCheckmarkCircle fontSize="20" color="#2EBC7C" />
                              {fileUploadModalText.mergedFileSuccessFull}
                            </span>
                          )}
                          {!mergedFileStatus && !mergedFileStatusIsError && (
                            <span className="d-flex justify-content-start align-items-center gap-2">
                              <ImSpinner8 color="#4f46e5" fontSize="20px" className="rotating-spinner" />
                              {fileUploadModalText.mergedFileInProgress}
                            </span>
                          )}
                          {mergedFileStatusIsError && (
                            <span className="d-flex justify-content-start align-items-center gap-2">
                              <IoCloseCircle fontSize="20" color="#EF0707" />
                              {fileUploadModalText.failedToMergeFile}
                            </span>
                          )}
                        </div>
                      )}

                    {/* {validationStart &&
                  // validateFiles.maliciousCheck.status === "success" &&
                  validateFiles.validateId.status === "success" &&
                  validateFiles.validateRawFile.status === "success" &&
                  validateFiles.validateDataFile.status === "success" &&
                  validateFiles.validateDataMatchCheck.status === "success" &&
                  mergedFileStatus && (
                    <div className="validation-success-message mt-2">
                      {parsedFileStatus && (
                        <span className="d-flex justify-content-start align-items-center gap-2">
                          <IoCheckmarkCircle fontSize="20" color="#2EBC7C" />
                          Merged file has been parsed successfully
                        </span>
                      )}
                      {!parsedFileStatus && (
                        <span className="d-flex justify-content-start align-items-center gap-2">
                          <ImSpinner8 color="#4f46e5" fontSize="20px" className="rotating-spinner" />
                          Merged file parsing is in progress, Please wait!!
                        </span>
                      )}
                    </div>
                  )} */}

                    {/* <span className="disclaimer">Note: {fileUploadModalText.disclaimerNote}</span> */}

                    {validationError === "true" && (
                      <div className={validationError === "true" && "error_message"}>
                        {[
                          // validateFiles.maliciousCheck,
                          validateFiles.validateId,
                          validateFiles.validateDataFile,
                          validateFiles.validateRawFile,
                          validateFiles.validateDataRowValidationCheck,
                          validateFiles.validateDataSingleCheck,
                          validateFiles.validateDataMultiCheck,
                          validateFiles.validateDataRankingCheck,
                          validateFiles.validateDataNumericListCheck,
                          validateFiles.validateDataOpenTextListCheck,
                          validateFiles.validateDataGridCheck,
                          validateFiles.validateDataMatchCheck,
                        ].map((validationType) => (
                          <>
                            {validationType.status === "error" && (
                              <div className="d-flex gap-2 pb-2">
                                <div className="d-flex align-items-center">
                                  {validationType.status === "pending" ? (
                                    <ImSpinner8 color="#4f46e5" fontSize="20px" className="rotating-spinner" />
                                  ) : validationType.status === "success" ? (
                                    <IoCheckmarkCircle fontSize="20" color="#4F46E5" />
                                  ) : (
                                    <IoCloseCircle fontSize="20" color="#EF0707" />
                                  )}
                                </div>
                                <span className="validationText">{validationType.errorMessage} </span>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="w-100 d-flex justify-content-between align-items-baseline pb-3 px-3 gap-2">
              {validationStart &&
                hasError &&
                // validateFiles.maliciousCheck.status === "error" ||
                (validateFiles?.validateId?.status === "error" ||
                  validateFiles?.validateDataFile?.status === "error" ||
                  validateFiles?.validateRawFile?.status === "error" ||
                  validateFiles?.validateDataRowValidationCheck?.status === "error" ||
                  validateFiles?.validateDataSingleCheck?.status === "error" ||
                  validateFiles?.validateDataMultiCheck?.status === "error" ||
                  validateFiles?.validateDataRankingCheck?.status === "error" ||
                  validateFiles?.validateDataNumericListCheck?.status === "error" ||
                  validateFiles?.validateDataOpenTextListCheck?.status === "error" ||
                  validateFiles?.validateDataGridCheck?.status === "error" ||
                  validateFiles?.validateDataMatchCheck?.status === "error") && <span className="error-text">{fileUploadModalText.uploadCorrectFiles}</span>}
              <div className="footer-btns">
                {!validationStart ? (
                  <>
                    {upladedSuccess === "fail" ? (
                      <ButtonComponent
                        onClick={(e) => {
                          e.preventDefault();
                          setopenModal(false);
                          dispatch(resetState());
                        }}
                        variant="outlined"
                        btnType="error"
                        text="Close"
                        className={"btnDanger"}
                      />
                    ) : (
                      <>
                        {/* {!mergedFileStatus &&
                      (!validateFiles.maliciousCheck.error ||
                        !validateFiles.validateId.error ||
                        !validateFiles.validateRawFile.error ||
                        !validateFiles.validateDataFile.error ||
                        !validateFiles.validateDataMatchCheck.error) && (
                        <>
                          <ButtonComponent
                            onClick={(e) => {
                              e.preventDefault();
                              setopenModal(false);
                              dispatch(resetState());
                            }}
                            variant="outlined"
                            btnType="error"
                            text="Close"
                            className={"btnDanger"}
                          />
                        </>
                      )} */}
                      </>
                    )}
                    {/* <ButtonComponent
                  onClick={() => validateFilesSequential()}
                  variant="contained"
                  btnType="primary"
                  text="Validate & Merge"
                  className="btnPrimary"
                  disabled={upladedSuccess !== "success"}
                /> */}
                  </>
                ) : (
                  <>
                    {validationStart &&
                    hasError &&
                    (validateFiles.maliciousCheck.status === "error" ||
                      validateFiles?.validateId?.status === "error" ||
                      validateFiles?.validateDataFile?.status === "error" ||
                      validateFiles?.validateRawFile?.status === "error" ||
                      validateFiles?.validateDataRowValidationCheck?.status === "error" ||
                      validateFiles?.validateDataSingleCheck?.status === "error" ||
                      validateFiles?.validateDataMultiCheck?.status === "error" ||
                      validateFiles?.validateDataRankingCheck?.status === "error" ||
                      validateFiles?.validateDataNumericListCheck?.status === "error" ||
                      validateFiles?.validateDataOpenTextListCheck?.status === "error" ||
                      validateFiles?.validateDataGridCheck?.status === "error" ||
                      validateFiles?.validateDataMatchCheck?.status === "error") ? (
                      <ButtonComponent
                        onClick={(e) => {
                          e.preventDefault();
                          setopenModal(false);
                          dispatch(resetState());
                        }}
                        variant="outlined"
                        btnType="error"
                        text="Close"
                        className="btnDanger"
                      />
                    ) : (
                      <>
                        {/* {!mergedFileStatus &&
                      (validateFiles.maliciousCheck.error ||
                        validateFiles.validateId.error ||
                        validateFiles.validateRawFile.error ||
                        validateFiles.validateDataFile.error ||
                        validateFiles.validateDataMatchCheck.error) && (
                        <ButtonComponent
                          onClick={(e) => {
                            e.preventDefault();
                            setopenModal(false);
                            dispatch(resetState());
                          }}
                          variant="outlined"
                          btnType="error"
                          text="Close"
                          className="btnDanger"
                        />
                      )} */}
                      </>
                    )}
                    {/* <ButtonComponent
                  onClick={() => {
                    dispatch(resetState());
                    setopenModal(false);
                  }}
                  variant="contained"
                  btnType="primary"
                  text="Submit"
                  className="btnPrimary"
                  disabled={
                    !mergedFileStatus &&
                    (!validateFiles.maliciousCheck.error ||
                      !validateFiles.validateId.error ||
                      !validateFiles.validateRawFile.error ||
                      !validateFiles.validateDataFile.error ||
                      !validateFiles.validateDataMatchCheck.error)
                  }
                /> */}
                  </>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      ) : (
        <>
          {potentialPermanentJokerList && potentialPermanentJokerList.length > 0 && (
            <PermanentRespondentModal
              open={openModal}
              onClose={() => {
                setopenModal(false);
                dispatch(resetState());
              }}
              potentialPermanentJokerList={potentialPermanentJokerList}
            />
          )}
        </>
      )}
    </>
  );
};

export default FileUploadModal;
