import { useContext, useEffect, useMemo, useRef, useState } from "react";

import RuleBuilderTextAreaContext from "./ruleBuilderTextAreaContext";

import "../../components/RuleBuilderTextArea/RuleBuilderTextArea.css";

export default function RuleBuilderTextAreaProvider({ children }) {
  const editorRef = useRef(null);

  const [saveCaretDetails, setSaveCaretDetails] = useState(0);

  // const [questionArray, setQuestionArray] = useState([]);
  const [questionsMeta, setQuestionsMeta] = useState({});

  // const [optionArray, setOptionArray] = useState([]);
  const [optionsMeta, setOptionsMeta] = useState({});
  const [isOutOfFocus, setIsOutOfFocus] = useState(true);

  const questionArray = useMemo(() => Object.keys(questionsMeta), [questionsMeta]);
  const optionArray = useMemo(() => Object.keys(optionsMeta), [optionsMeta]);

  // const [caretPosition, setCaretPosition] = useState(0);
  // const [caretEndPosition, setCaretEndPosition] = useState(0);

  useEffect(() => {
    // Assuming editorRef.current contains the current text to be highlighted
    reHighlightText();
  }, [questionArray, optionArray]);

  const patterns = {
    bracket: /[\[\](){}]/,
    number: /\b\d+\b/,
    string: /'[^']*'|"[^"]*"/,
    operator: /[-+*/=<>!&|%^~]+/,
    logicalOperator: /\b(?:and|or|Is|not)\b/,
    advancedOperator: /\b(?:Mean|Median|Mode|sd)\b/,
    variable: /\b[a-zA-Z_]\w*\b/,
  };

  const getCaretCharacterOffsetWithin = (element) => {
    if (isOutOfFocus) {
      return saveCaretDetails;
    }

    let caretOffset = 0;
    const sel = window.getSelection();
    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
  };

  const highlightText = (text) => {
    let newHTML = "";

    const getVariableValue = (variable) => {
      let label = questionsMeta?.[variable]?.label ?? null;
      if (label === null) label = optionsMeta?.[variable] ?? null;

      return label || "";
    };

    const variableArrays = {
      type1: questionArray,
      type2: optionArray,
    };

    // const parts = text.split(/(\s+)/);
    const parts = text.split(/(\s+|[\[\](){}])/).filter((part) => part);
    let depth = 0;

    parts.forEach((val) => {
      if (patterns.bracket.test(val)) {
        if (val === "(" || val === "{" || val === "[") {
          depth++;
        }
        newHTML += `<span class='bracket-depth-${depth % 9}'>${val}</span>`;
        if (val === ")" || val === "}" || val === "]") {
          depth = Math.max(0, depth - 1);
        }
      } else if (patterns.number.test(val)) {
        newHTML += `<span class='number'>${val}</span>`;
      } else if (patterns.string.test(val)) {
        newHTML += `<span class='string'>${val}</span>`;
      } else if (patterns.operator.test(val)) {
        newHTML += `<span class='operator'>${val}</span>`;
      } else if (patterns.logicalOperator.test(val)) {
        newHTML += `<span class='logicaloperator'>${val}</span>`; // Highlight logical operators
      } else if (patterns.advancedOperator.test(val)) {
        newHTML += `<span class='advancedOperator'>${val}</span>`; // Highlight logical operators
      } else if (patterns.variable.test(val)) {
        let variableClass = "variable"; // Default class
        const variableValue = getVariableValue(val);

        if (variableArrays.type1.includes(val)) {
          variableClass = "variable-type1";
        } else if (variableArrays.type2.includes(val)) {
          variableClass = "variable-type2";
        }

        newHTML += `<span class='${variableClass}' title='${variableValue}'>${val}</span>`; // Highlight direct variables
      } else {
        newHTML += `<span class='other'>${val}</span>`;
      }
    });

    return newHTML;
  };

  const getSpanContainingCaret = (element, offset) => {
    let charCount = 0;
    const nodeStack = [element];
    let node;
    let spanNode = null;

    while ((node = nodeStack.pop())) {
      if (node.nodeType === 3) {
        const nextCharCount = charCount + node.length;
        if (offset >= charCount && offset <= nextCharCount) {
          spanNode = node.parentNode;
          break;
        }
        charCount = nextCharCount;
      } else if (node.nodeType === 1) {
        for (let i = node.childNodes.length - 1; i >= 0; i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }
    return spanNode;
  };

  const findEndCaretPositionForSpan = (parentElement, spanElement) => {
    // let offset = 0;
    // let node = parentElement;

    // const walker = document.createTreeWalker(parentElement, NodeFilter.SHOW_TEXT, null, false);

    // while (walker.nextNode()) {
    //   if (walker.currentNode === spanElement) {
    //     break;
    //   }
    //   offset += walker.currentNode.textContent.length;
    // }

    // return offset;

    // Create a range that selects the end of the span element
    const range = document.createRange();
    range.selectNodeContents(spanElement);
    range.collapse(false); // Collapse to the end of the span

    // Create a range from the start of the contenteditable div to the end of the span
    const fullRange = document.createRange();
    fullRange.setStart(editorRef.current, 0);
    fullRange.setEnd(range.endContainer, range.endOffset);

    // The offset will be the length of the text within the fullRange
    const offset = fullRange.toString().length;

    return offset;
  };

  const checkIsNotEditableByCaretPosition = (element) => {
    const caretPosition = getCaretCharacterOffsetWithin(element);
    const spanContainingCaret = getSpanContainingCaret(element, caretPosition);

    const advaOperator = ["Mean", "Median", "Mode", "sd"];

    if (spanContainingCaret && spanContainingCaret.nodeName === "SPAN") {
      const spanText = spanContainingCaret.innerText.trim();

      const endOffset = findEndCaretPositionForSpan(element, spanContainingCaret);
      const startOffset = endOffset - spanText.length;

      if (caretPosition > startOffset && caretPosition < endOffset) {
        if (questionArray.includes(spanText)) return true;
        if (optionArray.includes(spanText)) return true;
        if (advaOperator.includes(spanText)) return true;
        // return questionArray.includes(spanText) || optionArray.includes(spanText) || patterns.advancedOperator.test(spanText) ;
      }
    }
    return false;
  };

  const setCaretCharacterOffsetWithin = (element, offset) => {
    const range = document.createRange();
    const sel = window.getSelection();
    let charCount = 0,
      nodeStack = [element],
      node,
      found = false;

    while (!found && (node = nodeStack.pop())) {
      if (node.nodeType === 3) {
        const nextCharCount = charCount + node.length;
        if (offset >= charCount && offset <= nextCharCount) {
          range.setStart(node, offset - charCount);
          range.setEnd(node, offset - charCount);
          found = true;
        }
        charCount = nextCharCount;
      } else if (node.nodeType === 1) {
        for (let i = node.childNodes.length - 1; i >= 0; i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    sel.removeAllRanges();
    sel.addRange(range);
  };

  const handleInputUpdated = (rule) => {
    reHighlightText(rule);
  };

  // //////////////////////////////////////////////////////////////////
  /* UTILITY FUNCTIONS */

  const reHighlightText = (newRuleText) => {
    if (editorRef.current) {
      const currentCaretOffset = getCaretCharacterOffsetWithin(editorRef.current);

      const text = newRuleText ?? editorRef.current.innerText;
      const highlightedText = highlightText(text);
      editorRef.current.innerHTML = highlightedText;

      setCaretCharacterOffsetWithin(editorRef.current, currentCaretOffset);
    }
  };

  // ///////////////////////////////////////////////////////////////////

  const saveCaretPosition = () => {
    // return getCaretCharacterOffsetWithin(editorRef.current);
    // const selection = window.getSelection();
    // let finalCaretDetails = null;

    // if (selection.rangeCount > 0) {
    //   const range = selection.getRangeAt(0);
    //   finalCaretDetails = {
    //     startContainer: range.startContainer,
    //     startOffset: range.startOffset,
    //     endContainer: range.endContainer,
    //     endOffset: range.endOffset,
    //   };
    // }

    const finalCaretDetails = getCaretCharacterOffsetWithin(editorRef.current);

    setSaveCaretDetails(finalCaretDetails);
  };

  const restoreCaretPosition = () => {
    // const savedPosition = saveCaretPosition;

    // if (savedPosition) {
    //   const selection = window.getSelection();
    //   const range = document.createRange();
    //   range.setStart(savedPosition.startContainer, savedPosition.startOffset);
    //   range.setEnd(savedPosition.endContainer, savedPosition.endOffset);
    //   selection.removeAllRanges();
    //   selection.addRange(range);
    // }

    setCaretCharacterOffsetWithin(editorRef.current, saveCaretDetails);
  };

  const restoreAndAppendLengthInCaretPosition = (textLength) => {
    // const savedPosition = saveCaretPosition;

    // if (savedPosition) {
    //   const selection = window.getSelection();
    //   const range = document.createRange();
    //   range.setStart(savedPosition.startContainer, savedPosition.startOffset);
    //   range.setEnd(savedPosition.endContainer, savedPosition.endOffset);
    //   selection.removeAllRanges();
    //   selection.addRange(range);
    // }

    setCaretCharacterOffsetWithin(editorRef.current, saveCaretDetails + textLength);
  };

  const ruleBuilderTextAreaContextValues = {
    editorRef,
    getCaretCharacterOffsetWithin,
    setCaretCharacterOffsetWithin,
    handleInputUpdated,
    optionArray,
    questionArray,
    checkIsNotEditableByCaretPosition,
    setQuestionsMeta,
    setOptionsMeta,
    saveCaretDetails,

    isOutOfFocus,
    setIsOutOfFocus,

    reHighlightText,

    highlightText,

    saveCaretPosition,
    restoreCaretPosition,
    restoreAndAppendLengthInCaretPosition,
  };

  return <RuleBuilderTextAreaContext.Provider value={ruleBuilderTextAreaContextValues}>{children}</RuleBuilderTextAreaContext.Provider>;
}

export const useRuleBuilderTextAreaProvider = () => useContext(RuleBuilderTextAreaContext);
