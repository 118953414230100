import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import productReducer from "../features/product/projectSlice";
import createProjectReducer from "../features/createProject/createProductSlice";
import validateFilesSlice from "../features/createProject/validateFilesSlice";
import snackbarReducer from "../features/snackbar/snackbarslice";
import loaderSlice from "../features/loader/loaderSlice";
import currentUserSlice from "../features/currentUser/currentUserSlice";
import rulesSlice from "../features/designQcRule/Rule/ruleSlice";
import questionlibrarySlice from "../features/designQcRule/questionlibrary/questionlibrarySlice";
import ruleExecutionSlice from "../features/designQcRule/ruleExecution/ruleExecutionSlice";
import textSlice from "../features/designQcRule/BuildRule/BuildRuleslice";
import previewWeightsSlice from "../features/designQcRule/previewWeights/previewWeightsSlice";
import patternRecognitionSlice from "../features/designQcRule/PatternRecognition/PatternRecognitionSlice";
import designQcSlice from "../features/designQcRule/designQc/DesignQcSlice";
import adverseRuleSlice from "../features/adverseEvent/AdverseRule/AdverseRule";
import adversetextSlice from "../features/adverseEvent/RuleBuilder/RuleBuilder";
import AdverseRuleExecutionSlice from "../features/adverseEvent/AdverseRuleExecution/AdverseRuleExecutionSlice";
import opentextFieldSlice from "../features/adverseEvent/OpenText/OpentextSlice";
import projectMetaDataSlice from "../features/designQcRule/projectMetaData/projectMetaDataSlice";

import rootSaga from "./saga";
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    product: productReducer,
    createProjectDetails: createProjectReducer,
    snackbar: snackbarReducer,
    loader: loaderSlice,
    validateFiles: validateFilesSlice,
    user: currentUserSlice,
    rules: rulesSlice,
    questions: questionlibrarySlice,
    ruleExecution: ruleExecutionSlice,
    text: textSlice,
    previewWeights: previewWeightsSlice,
    patternRecognition: patternRecognitionSlice,
    designQc: designQcSlice,
    adverseRule: adverseRuleSlice,
    adversetext: adversetextSlice,
    adverseRuleExecution: AdverseRuleExecutionSlice,
    opentextField: opentextFieldSlice,
    projectMetaData: projectMetaDataSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
