import React from "react";

import "./Counter.css";
import pluse from "../../assets/images/plus11.svg";
import minus from "../../assets/images/minus11.svg";

function Counter({ minValue = 0, maxValue = 100, handleOnCounterChange, count = 0 }) {
  if (isNaN(count)) {
    handleOnCounterChange(0);
  }

  const handleIncrementCounter = () => {
    if (count < maxValue) {
      handleOnCounterChange(count + 1);
    }
  };

  const handleDecrementCounter = () => {
    if (count > minValue) {
      handleOnCounterChange(count - 1);
    }
  };

  const handleChange = (event) => {
    const inputValue = parseInt(event.target.value);

    if (inputValue > maxValue || inputValue < minValue) return;

    let finalValue = Math.min(Math.max(inputValue, minValue), maxValue);

    if (isNaN(inputValue)) {
      finalValue = 0;
    }

    if (finalValue !== count) {
      handleOnCounterChange(finalValue);
    }
  };

  return (
    <div className="counter-btn-group">
      <button className="decrement-btn" onClick={handleDecrementCounter}>
        <img src={minus} alt="minus" />
      </button>
      <input className="count" autoComplete="off" value={count} onChange={handleChange} />
      <button className="increment-btn" onClick={handleIncrementCounter}>
        <img src={pluse} alt="pluse" />
      </button>
    </div>
  );
}

export default React.memo(Counter);
