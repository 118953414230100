// src/features/counterSlice.js

import { createSlice } from "@reduxjs/toolkit";

export const loaderslice = createSlice({
  name: "loader",
  initialState: {
    open: false,
  },
  reducers: {
    openLoader(state, action) {
      state.open = true;
    },
    closeLoader(state) {
      state.open = false;
    },
  },
});

export const { openLoader, closeLoader } = loaderslice.actions;

export default loaderslice.reducer;
