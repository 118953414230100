import { createSlice } from "@reduxjs/toolkit";

import { SNACKBAR_TYPES } from "./../../assets/constants/constant";

export const snackbarSlice = createSlice({
  name: "project",
  initialState: {
    open: false,
    message: "",
    type: SNACKBAR_TYPES.SUCCESS, // Default type to 'success'
    anchorOrigin: { vertical: "top", horizontal: "right" },
    autoHideDuration: 9000,
  },
  reducers: {
    openSnackbar(state, action) {
      state.open = true;
      state.message = action.payload.message;
      state.type = action.payload.type || SNACKBAR_TYPES.SUCCESS; // Accepts 'success', 'error', or 'warning'
      state.autoHideDuration = action.payload.autoHideDuration ?? 9000;
    },
    closeSnackbar(state) {
      state.open = false;
      state.message = "";
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
