import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import TextWithIcon2 from "../../TextWithIcon2/TextWithIcon2";
import { ReactComponent as MinusIcon } from "../../../assets/images/minus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/plus.svg";
import TextBoxWithIcon from "../../demo1/TextBoxWithIcon";
import { getWordAtCaret } from "../../../utils/helper";
import { setIsExpanded } from "../../../features/designQcRule/BuildRule/BuildRuleslice";
import { setIsExpandedAdverse } from "../../../features/adverseEvent/RuleBuilder/RuleBuilder";
import RuleBuilderTextAreaProvider from "../../../context/RuleBuilderTextArea/ruleBuilderTextAreaProvider";
import { DESIGN_QC_HORIZONTAL_TABS } from "../../../pages/DesignQC/DesignQc.constants";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={props.expanded ? <MinusIcon className="minus-icon" /> : <PlusIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "white" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, 0)",
  flexDirection: "row-reverse",
  "&:hover": {
    // Remove hover effect
    backgroundColor: "transparent",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    padding: "10px", // Add padding to the expand icon
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    "&.css-1betqn-MuiAccordionSummary-content": {
      // Add the class to target specific styles
      "& .MuiTypography-root": {
        "&.MuiTypography-body1": {
          width: "100%", // Target body1 variant
          // Add your custom styles here
        },
      },
    },
  },
  height: "54px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, 0)",
}));

// const getLastWord = (text) => {
//   if (!text) return "";
//   const words = text.trim().split(" ");
//   return words[words.length - 1];
// };

const TextAreaAccordion = ({
  index,
  selectedQuestionId,
  rule,
  handleQuestionSelect,
  handleRuleChange,
  options,
  handleRuleNameChange,
  handleDeleteAccordion,
  handleResetAccordion,
}) => {
  // const [isExpanded, setIsExpanded] = useState(index === 0 ? true : false);
  const isExpanded = useSelector((state) => state?.text?.textRules[index]?.isExpanded);
  const isAdverseExpanded = useSelector((state) => state?.adversetext?.textRules[index]?.isExpanded);

  const dispatch = useDispatch();
  const [caretPosition, setCaretPosition] = useState(0);
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  const [caretEndPosition, setCaretEndPosition] = useState(0);
  const textRules = useSelector((state) => state?.text?.textRules[index]?.rule);
  const selectedText = useSelector((state) => state?.text?.textRules[index] || { isGrid: [] });
  const selectedAdverseText = useSelector((state) => state?.adversetext?.textRules[index] || { isGrid: [] });
  const [allowChangeCaret, setAllowChangeCaret] = useState(true);

  const showOptionComponent = selectedQuestionId?.id && options?.length > 0;
  let showOptionCount = false;
  const showOptionCountLenth = selectedText?.isGrid?.length;
  const showOptionCountAdverseLenth = selectedAdverseText?.isGrid?.length;

  if (selectedHorizontalTab === "Adverse Events") {
    if (selectedAdverseText?.isGrid[showOptionCountAdverseLenth - 1] && selectedAdverseText?.isGrid[showOptionCountAdverseLenth - 1]?.grid == true) {
      showOptionCount = true;
    }
  } else if (selectedText?.isGrid[showOptionCountLenth - 1] && selectedText?.isGrid[showOptionCountLenth - 1]?.grid == true) {
    showOptionCount = true;
  }

  const [textAreaTitle, setTextAreaTitle] = useState("");

  useEffect(() => {
    const actualRule = rule?.rule ?? "";

    const questionId = getWordAtCaret(actualRule, caretPosition);

    const questionMeta = rule?.questionsMeta ?? {};
    const optionsMeta = rule?.optionsMeta ?? {};

    let label = questionMeta?.[questionId]?.label ?? null;

    if (label === null) label = optionsMeta?.[questionId] ?? null;

    setTextAreaTitle(label);
  }, [caretPosition]);

  const changeExpandAccordian = () => {
    if (selectedHorizontalTab == "Adverse Events") {
      dispatch(setIsExpandedAdverse({ index }));
    } else {
      dispatch(setIsExpanded({ index }));
    }
  };

  // /remove border if rule is empty
  // const removeBorder = (selectedHorizontalTab === "Adverse Events" ? isAdverseExpanded : isExpanded) && rule?.rule?.length == 0;
  return (
    <>
      <RuleBuilderTextAreaProvider>
        <Accordion
          key={`panel${index}`}
          sx={{
            borderRadius: "5px",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
            // /remove border if rule is empty
            // ...(removeBorder ? {border: "none"} : { }),
          }}
          expanded={selectedHorizontalTab == "Adverse Events" ? isAdverseExpanded : isExpanded}
          onChange={() => {}}
        >
          <AccordionSummary
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
            expanded={selectedHorizontalTab == DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS ? isAdverseExpanded : isExpanded}
            onClick={changeExpandAccordian}
            sx={{ padding: "0px" }}
          >
            <Typography sx={{ width: "100%" }}>
              <TextBoxWithIcon
                initialValue={rule.rule_name}
                index={index}
                onChange={(value) => handleRuleNameChange(index, value)}
                setSelectedQuestionId={handleQuestionSelect}
                onClick={(event) => event.stopPropagation()}
                onDelete={() => handleDeleteAccordion(index)}
                onReset={() => handleResetAccordion(index)}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0px" }}>
            {/* {false ? (
              <OptionComponent
                options={options}
                index={index}
                setSelectedQuestionId={handleQuestionSelect}
                onChange={(value) => handleRuleChange(index, value)}
                value={rule.rule}
                textAreaTitle={textAreaTitle}
                caretPosition={caretPosition}
                setCaretPosition={setCaretPosition}
                caretEndPosition={caretEndPosition}
                setCaretEndPosition={setCaretEndPosition}
                showOptionCount={showOptionCount}
                allowChangeCaret={allowChangeCaret}
                setAllowChangeCaret={setAllowChangeCaret}
              />
            ) : (
              <TextWithIcon2
                options={options}
                initialValue={rule.rule}
                index={index}
                onChange={(value) => handleRuleChange(index, value)}
                setSelectedQuestionId={handleQuestionSelect}
                textAreaTitle={textAreaTitle}
                caretPosition={caretPosition}
                setCaretPosition={setCaretPosition}
                caretEndPosition={caretEndPosition}
                setCaretEndPosition={setCaretEndPosition}
                showOptionCount={showOptionCount}
                allowChangeCaret={allowChangeCaret}
                setAllowChangeCaret={setAllowChangeCaret}
              />
            )} */}
            <TextWithIcon2
              options={options}
              initialValue={rule.rule}
              index={index}
              onChange={(value) => handleRuleChange(index, value)}
              setSelectedQuestionId={handleQuestionSelect}
              textAreaTitle={textAreaTitle}
              caretPosition={caretPosition}
              setCaretPosition={setCaretPosition}
              caretEndPosition={caretEndPosition}
              setCaretEndPosition={setCaretEndPosition}
              showOptionCount={showOptionCount}
              allowChangeCaret={allowChangeCaret}
              setAllowChangeCaret={setAllowChangeCaret}
            />
          </AccordionDetails>
        </Accordion>
      </RuleBuilderTextAreaProvider>
    </>
  );
};

export default TextAreaAccordion;
