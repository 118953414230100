import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./AdverseJokerTable.css";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { Adverse_Preview_Joker, PATTERN_RECOGNITION_OBJECT } from "../constants/preview-constants";
import GreenCircleIcon from "../../../../../components/circleIcon/GreenCircleIcon";
import RedCircleIcon from "../../../../../components/circleIcon/RedCircleIcon";
import { closeLoader, openLoader } from "../../../../../features/loader/loaderSlice";
import { getAdverseEvent } from "../../../../../services/adverseEventService";
import { openSnackbar } from "../../../../../features/snackbar/snackbarslice";
import ButtonComponent from "../../../../../components/Button/ButtonComponent";
import SearchComponent from "../../../../../components/SearchBox/SearchBox";
import PaginationComponent from "../../../../../components/PaginationComponent/PaginationComponent";
import LabelIcon from "../../../../../components/Label/label";
import { SNACKBAR_TYPES } from "../../../../../assets/constants/constant";

function EnhancedTableHead({ adverseEventHeaders, projectId }) {
  const [groupColumnData, setGroupColumnData] = useState([]);
  const [columnsdata, setColumnsdata] = useState([]);
  const [Datafetch, setDatafetch] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  const AdversePopulateData = useSelector((state) => state?.adverseRuleExecution?.adverseeventdata) ?? undefined;
  const count = useSelector((state) => state?.adverseRuleExecution?.count) ?? undefined;
  const AdversePopulateDataHeaders = useSelector((state) => state?.adverseRuleExecution?.adverseeventdataHeader) ?? undefined;
  const AdversePoplateColumnData = useSelector((state) => state?.adverseRuleExecution?.adverseeventdatacolumnGroupData) ?? undefined;
  const debouncedSearchQuery = useSelector((state) => state?.previewWeights?.debouncedSearchQuery);

  const columnsToRender = useMemo(() => {
    if (selectedHorizontalTab === "Adverse Events") {
      return Adverse_Preview_Joker;
    } else if (selectedHorizontalTab === "Joker Analysis") {
      return PATTERN_RECOGNITION_OBJECT;
    }
    return {};
  }, [selectedHorizontalTab]);

  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);

  const handlePaginationModelChange = (model) => {
    setPage(model.page);
    // setPaginationModelForRowTrial(model.page, model.pageSize);
  };

  const setPaginationModelForRowTrial = async (pageData) => {
    setDatafetch(true);
    const payload = {
      projectId: projectId,
      limit: 10,
      offset: pageData * 10,
    };
    dispatch(openLoader());
    try {
      const adversedata = await getAdverseEvent(payload);
      setGroupColumnData(adversedata?.data?.columnGroupData);
      setRows(adversedata?.data?.tableData);
      setInitialRows(adversedata?.data?.tableData);
      setColumnsdata(setColumnsDatamap(adversedata?.data?.headerData));
    } catch (err) {
      dispatch(openSnackbar({ message: err.message, type: SNACKBAR_TYPES.ERROR }));
    } finally {
      dispatch(closeLoader());
    }
  };

  const { questions, rules } = useMemo(() => {
    if (adverseEventHeaders) {
      const aeHeader = adverseEventHeaders.filter((header) => header.type === "ae");

      const ruleHeader = adverseEventHeaders.filter((header) => header.type === "rule");

      return { questions: aeHeader, rules: ruleHeader };
    } else {
      return [];
    }
  }, [adverseEventHeaders]);

  const setColumnsDatamap = (columnDataArray) => {
    const columns = columnDataArray?.map((column) => {
      if (column.type === "rule") {
        return {
          ...column,
          width: 140,
          renderCell: (params) => {
            const cellValue = params.row[column.field];
            if (cellValue === true) {
              return <GreenCircleIcon />;
            } else {
              return <RedCircleIcon />;
            }
          },
        };
      } else {
        return { ...column, width: 140 };
      }
    });
    return columns;
  };

  const columns = (rowData?.headerData || AdversePopulateDataHeaders).map((column) => {
    if (column.type === "rule") {
      return {
        ...column,
        renderCell: (params) => {
          const cellValue = params.row[column.field];
          if (cellValue === true) {
            return <GreenCircleIcon />;
          } else {
            return <RedCircleIcon />;
          }
        },
      };
    } else {
      return column;
    }
  });

  const handleSearch = async (event) => {
    const newSearchValue = event.target.value;
    setSearchQuery(newSearchValue);
    if (newSearchValue.trim() === "") {
      setRows(initialRows);
    } else {
      const filteredRows = initialRows?.filter((row) => Object.values(row).some((field) => String(field).toLowerCase().includes(newSearchValue)));

      setRows(filteredRows);
      setOffset(0);
    }
  };

  useEffect(() => {
    setRows(AdversePopulateData);
    setGroupColumnData(AdversePoplateColumnData);
    setInitialRows(AdversePopulateData);
    setColumnsdata(setColumnsDatamap(AdversePopulateDataHeaders));
  }, []);

  // custom pagination
  const totalCount = rows?.length || 0;
  const handlePageChange = (newOffset) => {
    setOffset(newOffset);
  };
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsPerPage,
    page: page,
  });
  // 2nd pagination

  const pagination_row = rows.slice(offset, offset + rowsPerPage);

  return (
    <>
      <div className="database_heading_adverse">
        <div className="maindatabseheding">
          <div>
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Database and summary
            </p>
          </div>

          <div className="horizontal_line_heading"></div>
        </div>
        {/* {selectedHorizontalTab === "Joker Analysis" && ( */}
        <div className="ruletablesearch">
          <SearchComponent value={searchQuery} onChange={handleSearch} className="open_text_search" width="244px" height="32px" />
        </div>
        {/* )} */}
        {/* <div className="true_false">
          <div className="true">
            <GreenCircleIcon />
            <p style={{ fontSize: "12px" }}>True</p>
          </div>
          <div className="false">
            <RedCircleIcon />

            <p style={{ fontSize: "12px" }}>False</p>
          </div>
        </div> */}
        <LabelIcon />
      </div>
      {rows?.length || columnsdata?.length ? (
        // rowData?.columnGroupData?.length ||
        // AdversePoplateColumnData?.length
        <div
          className="database_summary_table"
          style={{
            minWidth: 800,
            height: 360,
            width: "100%",
            overflowX: "auto",
          }}
        >
          <DataGridPro
            experimentalFeatures={{ columnGrouping: true }}
            rows={pagination_row}
            columns={columnsdata}
            disableRowSelectionOnClick
            columnGroupingModel={groupColumnData}
            disableColumnMenu
            getRowId={(row) => row.res_id}
            pagination="client"
            page={page}
            // rowCount={rows.length}
            // initialState={{
            //   pagination: { paginationModel: { pageSize: 10 } },
            // }}
            // onPaginationModelChange={handlePaginationModelChange}

            // custome pagination

            // onPaginationModelChange={handlePaginationModelChange}
            paginationModel={paginationModel}
            pageSizeOptions={[rowsPerPage]}
            slots={{
              pagination: () => <PaginationComponent limit={rowsPerPage} offset={offset} totalCount={totalCount} onPageChange={handlePageChange} />,
            }}
          />
        </div>
      ) : (
        <div className="adverse-joker-table__refresh-container">
          <p className="adverse-joker-table__refresh-text">We are retrieving data; please wait and click the button below to refresh the data.</p>
          <ButtonComponent
            onClick={() => setPaginationModelForRowTrial(0)}
            variant="outlined"
            btnType="primary"
            text="Refresh"
            className="btnPrimaryOutline btnDisabled"
          />
        </div>
      )}
    </>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
};

function AdverseJokerTable({ groupedRespondentDetails, patternRecognitionGroupedDetails, rules, projectId, debouncedSearchQuery = "" }) {
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  const dispatch = useDispatch();
  const dataMapFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);
  // const projectID = useSelector((state) => state?.createProjectDetails?.data?.id);

  const RULE_ID = "rule_id";

  const [dense, setDense] = useState(false);
  const [selected, setSelected] = useState([]);
  const AdversePopulateData = useSelector((state) => state?.adverseRuleExecution?.adverseeventdata) ?? undefined;
  const AdversePopulateDataHeaders = useSelector((state) => state?.adverseRuleExecution?.adverseeventdataHeader) ?? undefined;

  const StyledTableContainer = styled(TableContainer)({
    padding: "0px",
  });

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <>
      <StyledTableContainer className="jockercontainer">
        <Box sx={{ width: "100%", padding: "0px" }}>
          <Paper className="jocker_paper" sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
            {/* over flow and height removed maxHeight: "calc(100vh - 386px)", overflow: "auto", */}
            {/* <TableContainer style={{ position: "relative" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                className="jocker-recognition-table"
              > */}
            {/* <EnhancedTableHead numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={rows.length} fontFamily="Montserrat" /> */}
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={() => {}}
              fontFamily="Montserrat"
              rules={rules}
              adverseEventHeaders={AdversePopulateDataHeaders}
              projectId={projectId}
              // debouncedSearchQuery={debouncedSearchQuery}
            />
            {/* </Table>
            </TableContainer> */}
          </Paper>
        </Box>
      </StyledTableContainer>
    </>
  );
}

function renderBubbleByValue(value, scale) {
  if (value === null) return <></>;

  if (value >= scale * 0.5) return <GreenCircleIcon />;
  else return <RedCircleIcon />;
}

export default AdverseJokerTable;
