import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import First from "../../assets/images/First.svg";
import Last from "../../assets/images/Last.svg";

export default function PaginationComponent({ limit, offset, totalCount, onPageChange, boundaryCount = 1 }) {
  // Calculate the total number of pages
  const totalPages = Math.ceil(totalCount / limit);

  // Calculate the current page based on the offset and limit
  const currentPage = Math.floor(offset / limit) + 1;

  // Handle the page change event
  const handlePageChange = (event, value) => {
    // Calculate the new offset
    const newOffset = (value - 1) * limit;
    // Call the onPageChange callback with the new offset
    onPageChange(newOffset);
  };
  return (
    <Stack sx={{ backgroundColor: "rgba(229, 232, 234, 0.5)" }}>
      {/* <Pagination count={10} shape="rounded" /> */}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        boundaryCount={boundaryCount}
        icon={{
          first: <img src={First} alt="First" />,
          last: <img src={Last} alt="Last" />,
        }}
        sx={{
          "& .MuiPaginationItem-root": {
            borderRadius: "8px",
            border: "2px solid #F1F1F1",
            "&:hover": {
              backgroundColor: "inherit",
              color: "inherit",
            },
          },
          "& .MuiPaginationItem-page.Mui-selected": {
            color: "white",
            backgroundColor: "#4F46E5",
          },
        }}
      />
    </Stack>
  );
}
