import { ReactComponent as Operator } from "../../assets/images/AdvanceOperator.svg";
import { ReactComponent as Operator1 } from "../../assets/images/AdvanceOPerations1.svg";
import { ReactComponent as Operator2 } from "../../assets/images/close.svg";

import "./TextwithIcon2.css";
import { Box, Button, FormControlLabel, Grid, Link, Radio } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as CloseButton } from "../../assets/images/Closebutton.svg";
import { openSnackbar } from "../../features/snackbar/snackbarslice";
import { useRuleBuilderTextAreaProvider } from "../../context/RuleBuilderTextArea/ruleBuilderTextAreaProvider";
import { QUESTION_TYPES } from "../modals/QuestionLibrary/questionLibrary.constant";
import { SNACKBAR_TYPES } from "../../assets/constants/constant";

export const QuestionSelector = ({ handleButtonClick, questiontype }) => (
  <div className="buttonsresponse">
    <Button variant="" className="custom-button" onClick={() => handleButtonClick("Is")} style={{ color: "#4F46E5" }}>
      Is
    </Button>
    <Button variant="" className="custom-button" onClick={() => handleButtonClick("Is not")} style={{ color: "#4F46E5" }}>
      Is not
    </Button>
    <Button variant="" className="custom-button" onClick={() => handleButtonClick("and")} style={{ color: "#4F46E5" }}>
      and
    </Button>
    <Button variant="" className="custom-button" onClick={() => handleButtonClick("or")} style={{ color: "#4F46E5" }}>
      or
    </Button>
    <Button variant="" className="custom-button" onClick={() => handleButtonClick("(")} style={{ color: "#4F46E5" }}>
      (
    </Button>
    <Button variant="" className="custom-button" onClick={() => handleButtonClick(")")} style={{ color: "#4F46E5" }}>
      )
    </Button>
    {/* <Button variant="" className="custom-button" onClick={() => handleButtonClick("\"gibberish\"")} style={{ color: "#4F46E5" }}>
          gibberish
        </Button> */}
    {(questiontype === "opentext" || questiontype === QUESTION_TYPES.OPEN_TEXT_LIST) && (
      <Button variant="" className="custom-button" onClick={() => handleButtonClick('"gibberish"')} style={{ color: "#4F46E5" }}>
        "Gibberish"
      </Button>
    )}
  </div>
);

export const ResponseSelector = ({ selectedResponses, onResponseChange, searchQuery, onSearchChange, options, handleClose }) => {
  const dispatch = useDispatch();
  const { editorRef, saveCaretPosition, restoreCaretPosition } = useRuleBuilderTextAreaProvider();
  const truncateText = (text, maxLength) => (text.length > maxLength ? `${text.slice(0, maxLength)}...` : text);
  return (
    <div
      className="responsecontainer"
      style={{
        backgroundColor: "rgba(235, 235, 255, 0.5)",
        maxHeight: "calc(100vh - 300px)",
        overflowY: "auto",
      }}
    >
      <Box sx={{ flexGrow: 1 }} className="option-container">
        <div className="textclass">
          <Link
            component="button"
            variant=""
            sx={{
              textDecoration: "none",
              color: "#4F46E5",
              paddingLeft: "17px",
            }}
            onClick={() => {
              // console.info("I'm a button.");
            }}
          >
            Select your response
          </Link>

          <div className="textfield">
            <input
              type="text"
              className="textfieldsetting"
              value={searchQuery}
              placeholder="Search"
              autoComplete="off"
              onChange={onSearchChange}
              style={{
                width: "176px",
                height: "26px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            <Button className="buttonsaction" onClick={handleClose}>
              <CloseButton />
            </Button>
          </div>
        </div>
        <Box sx={{ overflowY: "scroll" }}>
          <div className="optionscontainer">
            <Grid container spacing={2}>
              {options
                // .filter((option) => typeof option.option_label === 'string' && option.option_label.toLowerCase().includes(searchQuery.toLowerCase()))
                .map((option) => {
                  if (!option.option_label) {
                    dispatch(
                      openSnackbar({
                        message: "No Options is there.",
                        type: SNACKBAR_TYPES.ERROR,
                      })
                    );
                    return null;
                  }
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      key={option.option_label}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControlLabel
                        control={
                          // <Checkbox sx={{ "& .MuiSvgIcon-root": { borderRadius: "4px" } }} checked={selectedResponses.includes(option)} onChange={() => onResponseChange(option)} />
                          <Radio
                            checked={selectedResponses?.option_label === option.option_label}
                            onChange={() => {
                              setTimeout(() => {
                                onResponseChange(option);
                              }, 0);
                            }}
                            // inputProps={{ "aria-label": "select row" }}
                            name="radio-buttons"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                borderRadius: "4px",
                                fontSize: 16,
                              },
                            }}
                          />
                        }
                        label={
                          <span
                            className="ellipsis"
                            title={option.option_label}
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              Width: "100px",
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {truncateText(option?.option_label, 70)}
                          </span>
                        }
                        // sx={{ "& .MuiFormControlLabel-label": { maxWidth: 'calc(100% - 20px)' } }}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export const OperatorButtons = ({ handleButtonClick }) => {
  const [isContainerVisible, setIsContainerVisible] = useState(false);

  const operators = [
    { symbol: "+", label: "+" },
    { symbol: "-", label: "-" },
    { symbol: "*", label: "*" },
    { symbol: "/", label: "/" },
    { symbol: "%", label: "%" },
    { symbol: ">=", label: ">=" },
    { symbol: "<=", label: "<=" },
    { symbol: ">", label: ">" },
    { symbol: "<", label: "<" },
    { symbol: "=", label: "=" },
    { symbol: "!=", label: "!=" },
    { symbol: "(", label: "(" },
    { symbol: ")", label: ")" },
    { symbol: "and", label: "and" },
    { symbol: "or", label: "or" },
  ];

  const statisticalOperators = [
    { symbol: "Mean (", label: "Mean" },
    { symbol: "Mode (", label: "Mode" },
    { symbol: "Median (", label: "Median" },
    { symbol: "sd (", label: "σ" },
  ];

  const toggleContainerVisibility = () => {
    setIsContainerVisible(!isContainerVisible);
  };

  return (
    <div>
      <div className="button-row">
        <div className="buttonalign">
          {operators.map((operator, index) => (
            <Button key={index} variant="" className="custom-button" onClick={() => handleButtonClick(operator.symbol)} style={{ color: "#4F46E5" }}>
              {operator.label}
            </Button>
          ))}
          {isContainerVisible && <></>}
        </div>
        <div className="hemlo">
          {!isContainerVisible && (
            <Button variant="" className="demo" onClick={toggleContainerVisibility}>
              <Operator />
            </Button>
          )}
        </div>
      </div>

      {isContainerVisible && (
        <div className="mean-container">
          <div className="button-row1">
            {statisticalOperators.map((operator, index) => (
              <Button key={index} variant="" className="custom-button" onClick={() => handleButtonClick(operator.symbol)} style={{ color: "#4F46E5" }}>
                {operator.label}
              </Button>
            ))}
          </div>
          <div className="demo1">
            <Button variant="" className="d" onClick={toggleContainerVisibility} startIcon={<Operator1 className="start-icon" />}></Button>
            <Button variant="" className="d" onClick={toggleContainerVisibility} startIcon={<Operator2 className="start-icon" />}></Button>
          </div>
        </div>
      )}
    </div>
  );
};
