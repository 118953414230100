import { call, put, select, takeEvery } from "redux-saga/effects";

import { ruleExecutionApi } from "../../../services/ruleExecutionService";
import { openSnackbar } from "../../snackbar/snackbarslice";
import { setDesignQcSelectedTab } from "../designQc/DesignQcSlice";
import { DESIGN_QC_TABS } from "../../../pages/DesignQC/DesignQc.constants";
import { closeLoader, openLoader } from "../../loader/loaderSlice";
import { handleActiveRulesUpdates } from "../Rule/ruleSaga";
import { setPreviewCalculateAgain } from "../previewWeights/previewWeightsSlice";
import { GIBBERISH_ERROR_CODE, SNACKBAR_TYPES } from "../../../assets/constants/constant";

import { getRuleExecutionStart, getRuleExecutionSuccess } from "./ruleExecutionSlice";

function* getRuleExecutionStartSaga(action) {
  yield put(openLoader());

  let redirectTab = action?.payload?.redirectTab ?? true;
  const previewCalculateAgain = action?.payload?.previewCalculateAgain ?? true;

  try {
    try {
      yield* handleActiveRulesUpdates();
    } catch (error) {
      yield put(openSnackbar({ message: error?.message, type: SNACKBAR_TYPES.ERROR }));
      return;
    }
    yield put(openLoader());
    // /
    const allRulesState = (state) => state?.rules?.rules;
    const checkedRulesInRuleTable = (state) => state?.rules?.checkedRulesInRuleTable;
    const rules = yield select(allRulesState);
    const selectedRulesIds = yield select(checkedRulesInRuleTable);
    const upsertObj = rules.map((rule) => {
      const { created_by, updated_by, created_at, updated_at, rule_version, is_valid, raw_data_file_id, data_map_file_id, rawFile, dataMapFile, ...data } =
        rule;

      if (selectedRulesIds.includes(rule.id)) {
        data.is_rule_active = true;
      } else {
        data.is_rule_active = false;
      }

      return data;
    });
    // /
    const mappedData = {
      projectId: action?.payload?.projectId,
      questionFileId: action?.payload?.questionFileId,
      respondentFileId: action?.payload?.respondentFileId,
      username: action?.payload?.username,
    };
    if (upsertObj.length > 0 && selectedRulesIds.length > 0 && rules.length > 0) {
      const { data } = yield call(() => ruleExecutionApi(mappedData.questionFileId, mappedData.respondentFileId, mappedData.projectId, mappedData.username));
      yield put(getRuleExecutionSuccess(data));
      yield put(openSnackbar({ message: "Rule execution completed!", type: SNACKBAR_TYPES.SUCCESS }));
    }
    if (redirectTab) yield put(setDesignQcSelectedTab(DESIGN_QC_TABS.PATTERN_RECOGNITION));
    if (previewCalculateAgain) yield put(setPreviewCalculateAgain());
  } catch (error) {
    // Extract error message and errorCode from the API response
    const errorMessage = error?.data?.[0] || error?.message || "Something went wrong!";
    const errorCode = error?.errorCode; // Extract errorCode from the error object

    // Check errorCode and toggle redirectTab accordingly
    if (errorCode) {
      switch (errorCode) {
        case GIBBERISH_ERROR_CODE:
          redirectTab = false;
          yield put(openSnackbar({ message: errorMessage, type: SNACKBAR_TYPES.WARNING }));
          break;
        default:
          redirectTab = true;
          yield put(openSnackbar({ message: errorMessage, type: SNACKBAR_TYPES.ERROR }));
          break;
      }
    }

    if (redirectTab) yield put(setDesignQcSelectedTab(DESIGN_QC_TABS.PATTERN_RECOGNITION));
    if (previewCalculateAgain) yield put(setPreviewCalculateAgain());
    // yield put(setDesignQcSelectedTab(DESIGN_QC_TABS.PATTERN_RECOGNITION));
  } finally {
    yield put(closeLoader());
  }
}

export function* watchRuleExecutionSaga() {
  yield takeEvery(getRuleExecutionStart, getRuleExecutionStartSaga);
}
