import axios from "../axiosConfig";
import { encodeRulesArrayOptionsMetaToBase64 } from "../utils/helper";
// import {baseURL}

// const baseURL = "http://localhost:3500";
const formHeaders = {
  "Content-Type": "multipart/form-data",
};

const baseURL = process.env.REACT_APP_BASE_URL;

// Function to fetch rules from the API
export const getKeyword = async (projectID, limit, offset, searchTerm) => {
  try {
    projectID = parseInt(projectID);
    const response = await axios.get(`${baseURL}/adverse/keywords?`, {
      params: { projectId: projectID, limit: limit, offset: offset, searchTerm: searchTerm },
    });
    return { data: response?.data?.data?.data, total: response?.data?.data?.total };
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? "");
  }
};

export const saveKeyword = async (data) => {
  try {
    const response = await axios.post(`${baseURL}/adverse/keywords/save-keywords`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const saveActiveKeywords = async (data) => {
  try {
    const response = await axios.post(`${baseURL}/adverse/active-keywords`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getActiveKeyWords = async (projectId) => {
  try {
    projectId = parseInt(projectId);
    const response = await axios.get(`${baseURL}/adverse/active-keywords?projectId=${projectId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const saveExcludedActiveKeywords = async (data) => {
  try {
    // data.username = "ab";

    const response = await axios.post(`${baseURL}/adverse/active-keywords/excluded`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const activeKeywords = async (data) => {
  try {
    const response = await axios.post(`${baseURL}/adverse/keywords/active-keywords`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const runkeyword = async (data) => {
  try {
    const response = await axios.post(`${baseURL}/adverse/ds-model-api/initiate`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const bulkUpsertAdverseRules = async (newRuleData, username) => {
  try {
    const finalRules = encodeRulesArrayOptionsMetaToBase64(newRuleData);

    const body = {
      rules: finalRules,
      updated_by: "username",
    };

    const response = await axios.post(`${baseURL}/rule/bulk-upsert`, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAdverseEvent = async (payload) => {
  try {
    const response = await axios.post(`${baseURL}/Rule/get-adverse-event-data`, payload);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const selectAllkeyword = async (data) => {
  try {
    const response = await axios.post(`${baseURL}/adverse/active-keywords/SelectallKeywords`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const deselectAllkeyword = async (data) => {
  try {
    const response = await axios.post(`${baseURL}/adverse/active-keywords/DeselectallKeywords`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
