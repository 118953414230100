import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "@uidotdev/usehooks";

import CloseIconSvg from "../../../assets/images/CloseIconSVG";
import SelectDropdown from "../../SelectDropdown/SelectDropdown";
import { SNACKBAR_TYPES } from "../../../assets/constants/constant";
import "./QuestionLibrary.css";
import SearchComponent from "../../SearchBox/SearchBox";
import QuestionTable from "../../Tables/QuestionTable/QuestionTable";
import { listRule } from "../../../services/projectService";
import { fetchQuestionsSuccess, setOpenQuestionModal } from "../../../features/designQcRule/questionlibrary/questionlibrarySlice";
import { closeLoader, openLoader } from "../../../features/loader/loaderSlice";
import { openSnackbar } from "../../../features/snackbar/snackbarslice";

import { OPTION_DROPDOWN_QUESTION_TYPES_ARRAY } from "./questionLibrary.constant";

const QuestionLibrary = ({ openModal, collaboratorDropDown, setCollaboratorDropdown, assignProject }) => {
  const DEBOUNCED_SEARCH_TIME = 700;

  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [initSearchQuery, setInitSearchQuery] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionTypes, setQuestionTypes] = useState(OPTION_DROPDOWN_QUESTION_TYPES_ARRAY);
  const [questionList, setQuestionList] = React.useState(undefined);
  const [selectedQuestionObject, setSelectedQuestionObject] = useState(null);
  const [offset, setOffset] = useState(0);
  const [totalQuestionsCounts, setTotalQuestionsCounts] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const selectedQuestionValue = selectedQuestionObject?.value ?? null;
  const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCED_SEARCH_TIME);

  const limit = 10;
  const offsetAmount = 10;

  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);
  const fileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);
  const dataMapFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.id);

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch question list and extract types
    const fetchQuestionTypes = async () => {
      try {
        await handleTriggerQueryBuilderSearch(projectId, limit, offset, searchQuery, selectedQuestionValue);
        // const response = await listRule(projectId);
        // const questionList = response.data.questions;
        // const uniqueTypes = [...new Set(questionList.map((question) => question.type))];
        // const types = uniqueTypes.map((type) => ({ value: type, label: type }));
        // setQuestionTypes(types);
      } catch (error) {
        // console.error(error.message);
        dispatch(openSnackbar({ message: "Sorry! unable to fetch questions", type: SNACKBAR_TYPES.ERROR }));
      }
    };
    fetchQuestionTypes();
  }, []);

  const handleTriggerQueryBuilderSearch = async (projectId, limit, offset, searchTerm, questionType, order, orderBy) => {
    dispatch(openLoader());
    try {
      const questionListResponse = await listRule(projectId, limit, offset, searchTerm, questionType, order, orderBy, dataMapFileId);

      const questions = questionListResponse?.data?.items;
      const totalCount = questionListResponse?.data?.totalRecords;

      setQuestionList(questions);
      setTotalQuestionsCounts(totalCount);
      dispatch(fetchQuestionsSuccess(questions));
    } catch (error) {
      // todo: show the toast
      console.error({ error });
    } finally {
      dispatch(closeLoader());
    }
  };

  useEffect(() => {
    async function fetchUpdatedSearchTerm() {
      if (initSearchQuery) {
        await handleTriggerQueryBuilderSearch(projectId, limit, offset, debouncedSearchQuery, selectedQuestionValue);
      }
    }

    fetchUpdatedSearchTerm();
  }, [debouncedSearchQuery]);

  const handleSearchInputChange = async (event) => {
    const newValue = event.target.value;
    setSearchQuery(newValue);
    setInitSearchQuery(true);

    setOffset(0);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const handlePageChange = async (newOffset) => {
    setOffset(newOffset);
    await handleTriggerQueryBuilderSearch(projectId, limit, newOffset, searchQuery, selectedQuestionValue);
  };

  const handleQuestionTypeChange = async (selectedType) => {
    try {
      setSelectedQuestionObject(selectedType ?? null);

      const newSelectedValue = selectedType?.value ?? null;

      const newOffset = 0;
      setOffset(newOffset);
      await handleTriggerQueryBuilderSearch(projectId, limit, newOffset, searchQuery, newSelectedValue);
    } catch (error) {
      console.error({ error });
    }
  };

  const handleRequestSort = async (property) => {
    const isAsc = orderBy === property && order === "asc";

    const newOrder = isAsc ? "desc" : "asc";
    const newOrderBy = property;

    await handleTriggerQueryBuilderSearch(projectId, limit, offset, searchQuery, selectedQuestionValue, newOrder, newOrderBy);

    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  return (
    <Modal
      open={openModal}
      onClose={() => dispatch(setOpenQuestionModal(false))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="question_libraryModal"
    >
      <Box className="questionModal assignUserModal">
        <div className="file_header">
          <div className="attach">Question Library </div>
          <Button onClick={() => dispatch(setOpenQuestionModal(false))} className="closeIconBtn mx-2">
            <CloseIconSvg fillColor="#ffffff" />
          </Button>
        </div>
        <div className="questionbox w-100 d-flex flex-column  justify-content-center">
          <div className="Questionheading d-flex">
            <div className="text">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "19.2px",
                  textAlign: "left",
                  color: "#25282B",
                  marginTop: "6px",
                  marginBottom: "6px",
                }}
              >
                Question Library
              </p>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19.2px",
                  textAlign: "left",
                  color: "#a1aebe",
                  marginBottom: "6px",
                }}
              >
                Please select one question
              </p>
            </div>
            <div className="search_drop d-flex" style={{ alignItems: "center", marginBottom: "10px" }}>
              <div className="search_question">
                <SearchComponent value={searchQuery} onChange={handleSearchInputChange} />
              </div>
              <SelectDropdown
                options={questionTypes}
                value={selectedQuestionObject}
                className={"file_select_dropdown"}
                placeholder="Question Type"
                onChange={handleQuestionTypeChange}
              />
            </div>
          </div>
        </div>
        <div className="questiontable">
          <QuestionTable
            selectedType={collaboratorDropDown?.value}
            questionList={questionList}
            setQuestionList={setQuestionList}
            limit={limit}
            offset={offset}
            totalCount={totalQuestionsCounts}
            handlePageChange={handlePageChange}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default QuestionLibrary;
