import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./PatternRecognition.css";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import { TableSortLabel } from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";

import RedCircleIcon from "../../../../components/circleIcon/RedCircleIcon";
import PaginationComponent from "../../../../components/PaginationComponent/PaginationComponent";
import {
  addPatternRecognitionExcludedValue,
  alternativepatternSuccess,
  initApplyExcludedPatternRecognitionState,
  removePatternRecognitionExcludedValue,
  repetitivepatternSuccess,
  resetIsPatternRecognitionNextTriggered,
  resetIsPatternRecognitionRedirect,
  setIncludeExcludeApiSuccess,
  setIsPatternRecognitionRedirect,
  setIsPatternRecognitionsApisProcessing,
  setMergeFile,
  setPatternRecognitionApisCalled,
  setTableDataMap,
  startPatternRecognitionDataMapper,
  steppedpatternSuccess,
} from "../../../../features/designQcRule/PatternRecognition/PatternRecognitionSlice";
import {
  alternativePatternApi,
  downloadPatternRecognitionApi,
  getMergedFilePath,
  patternRecognitionIncludeExcludeApi,
  repetitivePatternApi,
  steppedPatternApi,
} from "../../../../services/patternService";
import { numberToPercentageFloor } from "../../../../utils/helper";
import GreenCircleIcon from "../../../../components/circleIcon/GreenCircleIcon";
import On_Off_switch from "../../../../components/Switch/On_Off_switch/On_Off_switch";
import { setDesignQcSelectedTab } from "../../../../features/designQcRule/designQc/DesignQcSlice";
import { DESIGN_QC_TABS } from "../../DesignQc.constants";
import { closeLoader, openLoader } from "../../../../features/loader/loaderSlice";
import { openSnackbar } from "../../../../features/snackbar/snackbarslice";
import { ACK_APIS_STATUS, SNACKBAR_TYPES } from "../../../../assets/constants/constant";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
// import ButtonComponent from "../../../../components/Button/ButtonComponent";
import downloadarrow from "../../../../assets/images/downloadarrow.svg";
import { getComparator, stableSort } from "../../../../utils/sorting-helper";
import SearchComponent from "../../../../components/SearchBox/SearchBox";

import { flattenPatternRecognitionColumns, flattenPatternRecognitionData, includeExcludeApiMapper } from "./helper/PatternRecognitionHelper";

const rows = [];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isAlternativePatternSuccess,
    isSteppedPatternSuccess,
    isRepetitivePatternSuccess,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="pattern-table-head" sx={{ position: "sticky", top: 0, zIndex: 1 }}>
      <TableRow sx={{ backgroundColor: "#E3E4FD", width: "15px" }}>
        <TableCell
          className="pattern_table-head-cell resp-id"
          align="center"
          sx={{
            paddingBottom: "12px",
            width: "6.5%",
            verticalAlign: "bottom",
            borderRight: "1px solid white",
            cursor: "pointer",
          }}
          rowSpan={2}
          onClick={createSortHandler(flattenPatternRecognitionColumns.RESP_ID)}
        >
          Resp ID
          <TableSortLabel
            active={orderBy === flattenPatternRecognitionColumns.RESP_ID}
            direction={orderBy === flattenPatternRecognitionColumns.RESP_ID ? order : "asc"}
          />
        </TableCell>
        <TableCell
          className="pattern_table-head-cell question-series"
          align="center"
          sx={{
            // width: "7.65%",
            verticalAlign: "bottom",
            borderRight: "1px solid white",
            cursor: "pointer",
          }}
          rowSpan={2}
          onClick={createSortHandler(flattenPatternRecognitionColumns.QUESTION_ID)}
        >
          Question Series
          <TableSortLabel
            active={orderBy === flattenPatternRecognitionColumns.QUESTION_ID}
            direction={orderBy === flattenPatternRecognitionColumns.QUESTION_ID ? order : "asc"}
          />
        </TableCell>
        <TableCell
          className="pattern_table-head-cell question-series"
          align="center"
          sx={{
            // width: "7.6%",
            verticalAlign: "bottom",
            borderRight: "1px solid white",
            cursor: "pointer",
          }}
          rowSpan={2}
          onClick={createSortHandler(flattenPatternRecognitionColumns.QUESTION_TYPE)}
        >
          Question Type
          <TableSortLabel
            active={orderBy === flattenPatternRecognitionColumns.QUESTION_TYPE}
            direction={orderBy === flattenPatternRecognitionColumns.QUESTION_TYPE ? order : "asc"}
          />
        </TableCell>
        {isRepetitivePatternSuccess && (
          <TableCell
            className="pattern_table-head-cell"
            align="center"
            sx={{
              width: "19%",
              borderRight: "1px solid white",
              borderBottom: "1px solid white",
            }}
            colSpan={2}
          >
            Repetitive Pattern
          </TableCell>
        )}
        {isAlternativePatternSuccess && (
          <TableCell
            className="pattern_table-head-cell"
            align="center"
            sx={{
              width: "19%",
              borderRight: "1px solid white",
              borderBottom: "1px solid white",
            }}
            colSpan={2}
          >
            Alter Pattern
          </TableCell>
        )}
        {isSteppedPatternSuccess && (
          <TableCell
            className="pattern_table-head-cell"
            align="center"
            sx={{
              width: "19%",
              borderRight: "1px solid white",
              borderBottom: "1px solid white",
            }}
            colSpan={2}
          >
            Stepped Pattern
          </TableCell>
        )}
        {/* <TableCell className="pattern_table-head-cell" align="center" sx={{ width: "15%", borderRight: "1px solid white", borderBottom: "1px solid white" }} colSpan={1}>
          Gen AI Driven
        </TableCell> */}
        <TableCell className="pattern_table-head-cell alternating-pattern" align="center" sx={{ width: "14%", verticalAlign: "bottom" }} rowSpan={2}>
          Joker/Not Joker
        </TableCell>
      </TableRow>

      <TableRow sx={{ backgroundColor: "#E3E4FD" }}>
        {isRepetitivePatternSuccess && (
          <>
            <TableCell
              className="pattern_table-head-cell"
              align="center"
              sx={{ borderRight: "1px solid white", cursor: "pointer" }}
              onClick={createSortHandler(flattenPatternRecognitionColumns.REPETITIVE_PATTERN_LABEL)}
            >
              Label
              <TableSortLabel
                active={orderBy === flattenPatternRecognitionColumns.REPETITIVE_PATTERN_LABEL}
                direction={orderBy === flattenPatternRecognitionColumns.REPETITIVE_PATTERN_LABEL ? order : "asc"}
              />
            </TableCell>
            <TableCell
              className="pattern_table-head-cell"
              align="center"
              sx={{ borderRight: "1px solid white", cursor: "pointer" }}
              onClick={createSortHandler(flattenPatternRecognitionColumns.REPETITIVE_SCORE)}
            >
              Score
              <TableSortLabel
                active={orderBy === flattenPatternRecognitionColumns.REPETITIVE_SCORE}
                direction={orderBy === flattenPatternRecognitionColumns.REPETITIVE_SCORE ? order : "asc"}
              />
            </TableCell>
          </>
        )}
        {isAlternativePatternSuccess && (
          <>
            <TableCell
              className="pattern_table-head-cell"
              align="center"
              sx={{ borderRight: "1px solid white", cursor: "pointer" }}
              onClick={createSortHandler(flattenPatternRecognitionColumns.ALTERNATING_PATTERN_LABEL)}
            >
              Label
              <TableSortLabel
                active={orderBy === flattenPatternRecognitionColumns.ALTERNATING_PATTERN_LABEL}
                direction={orderBy === flattenPatternRecognitionColumns.ALTERNATING_PATTERN_LABEL ? order : "asc"}
              />
            </TableCell>
            <TableCell
              className="pattern_table-head-cell"
              align="center"
              sx={{ borderRight: "1px solid white", cursor: "pointer" }}
              onClick={createSortHandler(flattenPatternRecognitionColumns.ALTERNATING_SCORE)}
            >
              Score
              <TableSortLabel
                active={orderBy === flattenPatternRecognitionColumns.ALTERNATING_SCORE}
                direction={orderBy === flattenPatternRecognitionColumns.ALTERNATING_SCORE ? order : "asc"}
              />
            </TableCell>
          </>
        )}
        {isSteppedPatternSuccess && (
          <>
            <TableCell
              className="pattern_table-head-cell"
              align="center"
              sx={{ borderRight: "1px solid white", cursor: "pointer" }}
              onClick={createSortHandler(flattenPatternRecognitionColumns.STEPPED_PATTERN_LABEL)}
            >
              Label
              <TableSortLabel
                active={orderBy === flattenPatternRecognitionColumns.STEPPED_PATTERN_LABEL}
                direction={orderBy === flattenPatternRecognitionColumns.STEPPED_PATTERN_LABEL ? order : "asc"}
              />
            </TableCell>
            <TableCell
              className="pattern_table-head-cell"
              align="center"
              sx={{ borderRight: "1px solid white", cursor: "pointer" }}
              onClick={createSortHandler(flattenPatternRecognitionColumns.STEPPED_PATTERN_SCORE)}
            >
              Score
              <TableSortLabel
                active={orderBy === flattenPatternRecognitionColumns.STEPPED_PATTERN_SCORE}
                direction={orderBy === flattenPatternRecognitionColumns.STEPPED_PATTERN_SCORE ? order : "asc"}
              />
            </TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
};

function PatternRecognition() {
  const [dense, setDense] = useState(false);
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("respId");

  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  // Ref to store the scroll position
  const scrollRef = useRef(null);
  const scrollPosition = useRef(0);

  const StyledTableContainer = styled(TableContainer)({
    padding: "0px",
  });

  const rules = useSelector((state) => state.rules.rules);

  const dataMapFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);
  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);
  const projectName = useSelector((state) => state?.createProjectDetails?.data?.name);

  const tableDataMap = useSelector((state) => state?.patternRecognition?.tableDataMap);
  const DEBOUNCED_SEARCH_TIME = 700;
  const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCED_SEARCH_TIME);
  const flattenTableMap = flattenPatternRecognitionData(tableDataMap);
  let sortedRows = stableSort(flattenTableMap, getComparator(order, orderBy));
  const searchcol = sortedRows.filter((elem) => {
    if (debouncedSearchQuery === "") {
      return elem;
    } else if (
      elem[flattenPatternRecognitionColumns.RESP_ID].toLowerCase()?.includes(debouncedSearchQuery?.toLowerCase()) ||
      elem?.question_type?.toLowerCase()?.includes(debouncedSearchQuery?.toLowerCase()) ||
      elem?.question_id?.toLowerCase()?.includes(debouncedSearchQuery?.toLowerCase())
    ) {
      return elem;
    }
  });
  sortedRows = searchcol.slice(offset, offset + limit);

  const isNextTriggered = useSelector((state) => state?.patternRecognition?.isNextTriggered);
  const isRedirect = useSelector((state) => state?.patternRecognition?.isRedirect);

  const isPatternApiCalled = useSelector((state) => state?.patternRecognition?.isApiCalled);

  const isAlternativePatternSuccess = useSelector((state) => state?.patternRecognition?.isAlternativePatternSuccess);
  const isSteppedPatternSuccess = useSelector((state) => state?.patternRecognition?.isSteppedPatternSuccess);
  const isRepetitivePatternSuccess = useSelector((state) => state?.patternRecognition?.isRepetitivePatternSuccess);

  const isAnyPatternRecognitionSuccess = isPatternApiCalled || isAlternativePatternSuccess || isSteppedPatternSuccess || isRepetitivePatternSuccess;

  useEffect(() => {
    async function fetchUpdatedSearchTerm() {
      // await handleFetchKeywords(offset, debouncedSearchQuery);
      // setOffset(0);
    }

    fetchUpdatedSearchTerm();
  }, [debouncedSearchQuery]);

  useEffect(() => {
    fetchDataAndMap();
  }, []);

  // Restore scroll position after state change
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition.current;
    }
  });

  // Capture scroll position before state change
  const captureScrollPosition = () => {
    if (scrollRef.current) {
      scrollPosition.current = scrollRef.current.scrollTop;
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((rule) => rule.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const fetchDataAndMap = async () => {
    const errorApisNames = [];

    try {
      if (isPatternApiCalled) return;

      let apiSuccess = true;
      let isProcessing = false;

      dispatch(openLoader());
      const mergedFilePath = await getMergedFilePath(dataMapFileName, projectId);
      dispatch(setMergeFile(mergedFilePath));

      const fetchAlternativePatternData = async () => {
        try {
          if (isAlternativePatternSuccess) return;

          const response = await alternativePatternApi(mergedFilePath, projectId);
          if (response?.status == ACK_APIS_STATUS.COMPLETED) {
            dispatch(alternativepatternSuccess(response));
          } else if (response?.status == ACK_APIS_STATUS.ERROR) {
            throw new Error(response?.message ?? "");
          } else {
            dispatch(openSnackbar({ message: response?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
            apiSuccess = false;
            isProcessing = true;
          }
        } catch (error) {
          // todo show toast
          // console.error("Error fetching pattern data:", error);
          // dispatch(openSnackbar({ message: error?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
          errorApisNames.push("alternative");
          apiSuccess = false;
        }
      };

      const fetchSteppedPatternData = async () => {
        try {
          if (isSteppedPatternSuccess) return;

          const response = await steppedPatternApi(mergedFilePath, projectId); // Update with your merge file content
          if (response?.status == ACK_APIS_STATUS.COMPLETED) {
            dispatch(steppedpatternSuccess(response));
          } else if (response?.status == ACK_APIS_STATUS.ERROR) {
            throw new Error(response?.message ?? "");
          } else {
            dispatch(openSnackbar({ message: response?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
            apiSuccess = false;
            isProcessing = true;
          }
        } catch (error) {
          // todo show toast
          // console.error("Error fetching pattern data:", error);
          // dispatch(openSnackbar({ message: error?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
          errorApisNames.push("stepped");
          apiSuccess = false;
        }
      };

      const fetchRepetitivePatternData = async () => {
        try {
          if (isRepetitivePatternSuccess) return;

          const response = await repetitivePatternApi(mergedFilePath, projectId); // Update with your merge file content
          if (response?.status == ACK_APIS_STATUS.COMPLETED) {
            dispatch(repetitivepatternSuccess(response));
          } else if (response?.status == ACK_APIS_STATUS.ERROR) {
            throw new Error(response?.message ?? "");
          } else {
            dispatch(openSnackbar({ message: response?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
            apiSuccess = false;
            isProcessing = true;
          }
        } catch (error) {
          // todo show toast
          // console.error("Error fetching pattern data:", error);
          // dispatch(openSnackbar({ message: error?.message ?? "", type: SNACKBAR_TYPES.ERROR }));
          errorApisNames.push("repetitive");
          apiSuccess = false;
        }
      };

      await Promise.all([fetchAlternativePatternData(), fetchSteppedPatternData(), fetchRepetitivePatternData()]);

      dispatch(startPatternRecognitionDataMapper());
      dispatch(initApplyExcludedPatternRecognitionState());

      dispatch(setIsPatternRecognitionsApisProcessing(isProcessing));

      if (apiSuccess) dispatch(setPatternRecognitionApisCalled(true));

      if (errorApisNames.length > 0) throw new Error(`Failed to execute ${errorApisNames.join(", ")} pattern!`);
    } catch (error) {
      // todo show toast
      dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
    } finally {
      dispatch(closeLoader());
    }
  };

  useEffect(() => {
    async function handleNext() {
      try {
        dispatch(openLoader());
        const data = await handleIncludeExcludeApi();
        dispatch(setIncludeExcludeApiSuccess(data));
        dispatch(setIsPatternRecognitionRedirect());
      } catch (error) {
        dispatch(
          openSnackbar({
            message: "Unable to fetch included respondedents!",
            type: SNACKBAR_TYPES.ERROR,
          })
        );
        dispatch(setIsPatternRecognitionRedirect());
      } finally {
        dispatch(closeLoader());
      }
    }

    if (isNextTriggered) {
      dispatch(resetIsPatternRecognitionNextTriggered());
      handleNext();
    }
  }, [isNextTriggered]);

  if (isRedirect) {
    dispatch(resetIsPatternRecognitionRedirect());
    dispatch(setDesignQcSelectedTab(DESIGN_QC_TABS.PREVIEW));
  }
  const projectID = useSelector((state) => state?.createProjectDetails?.data?.id);
  const handleIncludeExcludeApi = async () => {
    try {
      dispatch(openLoader());
      const mergedFilePath = await getMergedFilePath(dataMapFileName, projectId);
      const apiMappedData = includeExcludeApiMapper(tableDataMap, mergedFilePath, projectID);
      return await patternRecognitionIncludeExcludeApi(apiMappedData);
    } catch (error) {
      throw error;
      // todo snackbar
    } finally {
      dispatch(closeLoader());
    }
  };

  const handleDownloadClicked = async () => {
    try {
      dispatch(openLoader());

      const mergedFilePath = await getMergedFilePath(dataMapFileName, projectId);
      await downloadPatternRecognitionApi(projectId, mergedFilePath, projectName);
      dispatch(
        openSnackbar({
          message: "Pattern recognition file downloaded successfully!",
          type: SNACKBAR_TYPES.SUCCESS,
        })
      );
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
    } finally {
      dispatch(closeLoader());
    }
  };

  // /download preview
  const handleOnSwitchChange = (e, respondentId, questionId) => {
    captureScrollPosition();

    const newTableDataMap = _.cloneDeep(tableDataMap);

    const value = !(e?.target?.checked ?? false);

    if (value && newTableDataMap[respondentId][questionId]) {
      newTableDataMap[respondentId][questionId].include = true;
      dispatch(
        removePatternRecognitionExcludedValue({
          respondentId: respondentId,
          questionId: questionId,
        })
      );
    }
    if (
      !value &&
      (newTableDataMap?.[respondentId]?.[questionId]?.include === undefined ||
        newTableDataMap?.[respondentId]?.[questionId]?.include === null ||
        tableDataMap?.[respondentId]?.[questionId]?.include === true)
    ) {
      newTableDataMap[respondentId][questionId].include = false;
      dispatch(
        addPatternRecognitionExcludedValue({
          respondentId: respondentId,
          questionId: questionId,
        })
      );
    }

    dispatch(setTableDataMap(newTableDataMap));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (newOffset) => {
    captureScrollPosition();
    setOffset(newOffset);
  };
  const handleSearchInputChange = async (event) => {
    const newSearchValue = event.target.value;
    setSearchQuery(newSearchValue);
  };

  return (
    <>
      <div className="patterncontainer">
        <Box sx={{ width: "100%", padding: "0px", height: isAnyPatternRecognitionSuccess ? "100%" : "unset" }}>
          <Paper className="pattern_paper" sx={{ width: "100%", mb: 2, boxShadow: "none", height: "100%", display: "flex", flexDirection: "column" }}>
            <div className="pattern_download">
              <div className="true_false">
                <div className="true">
                  {/* <div style={{width:"11px",height:"11px"}}> */}

                  <GreenCircleIcon style={{ width: "11px", height: "11px" }} />
                  {/* </div> */}
                  <p style={{ fontSize: "12px", width: "94px" }}>Not Suspicious</p>
                </div>
                <div className="false">
                  <RedCircleIcon style={{ width: "11px", height: "11px" }} />

                  <p style={{ fontSize: "12px" }}>Suspicious</p>
                </div>
              </div>
              <div className="patternsearch">
                <SearchComponent value={searchQuery} onChange={handleSearchInputChange} className="open_text_search" width="244px" height="32px" />
              </div>
              <div className="download_btn_pattern">
                <img
                  src={downloadarrow}
                  alt="download"
                  style={{
                    width: "15px",
                    height: "15px",
                    position: "relative",
                    right: "-29px",
                    zIndex: "1000",
                  }}
                />
                <ButtonComponent
                  className="downloadbtn"
                  btnType="outline"
                  text="Download"
                  onClick={handleDownloadClicked}
                  sx={{
                    width: "125px",
                    padding: "13px 13px 13px 30px",
                    height: "32px",
                    paddingleft: "30px",
                  }}
                />
              </div>
            </div>
            {isAnyPatternRecognitionSuccess && (
              <TableContainer
                ref={scrollRef}
                style={{
                  maxHeight: "calc(100vh - 407px)",
                  overflow: "auto",
                  position: "relative",
                  flex: 1,
                }}
              >
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} className="pattern-recognition-table">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={tableDataMap.length}
                    fontFamily="Montserrat"
                    onRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    isAlternativePatternSuccess={isAlternativePatternSuccess}
                    isRepetitivePatternSuccess={isRepetitivePatternSuccess}
                    isSteppedPatternSuccess={isSteppedPatternSuccess}
                  />
                  <TableBody
                    style={{
                      height: "100px",
                      overflow: "auto",
                    }}
                  >
                    {sortedRows &&
                      Array.isArray(sortedRows) &&
                      sortedRows.map((questionObject) => {
                        // const questionObject = flattenTableMap[index];
                        const respId = questionObject?.[flattenPatternRecognitionColumns.RESP_ID];
                        const questionId = questionObject?.question_id;
                        const isIncluded = !(questionObject?.include ?? false);

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            className="pattern_table-row"
                            sx={{
                              cursor: "pointer",
                              height: "75px",
                              fontFamily: "Montserrat, sans-serif",
                              fontSize: "16px",
                            }}
                          >
                            <TableCell component="th" scope="row" padding="none" align="center" style={{ height: "48px", padding: "0px" }}>
                              {`${respId}`}
                            </TableCell>
                            <>
                              <TableCell style={{ height: "48px", padding: "0px" }} align="center" className="li">
                                {questionId}
                              </TableCell>
                              <TableCell style={{ height: "48px", padding: "0px" }} align="center" className="li">
                                {questionObject?.question_type}
                              </TableCell>
                              {isRepetitivePatternSuccess && (
                                <>
                                  <TableCell style={{ height: "48px", padding: "0px" }} align="center">
                                    {questionObject?.repetitive_pattern_label ? <GreenCircleIcon /> : <RedCircleIcon />}
                                  </TableCell>
                                  <TableCell style={{ height: "48px", padding: "0px" }} align="center">
                                    {questionObject?.repetitive_score != null
                                      ? numberToPercentageFloor(questionObject?.repetitive_score?.toFixed(2), 0)
                                      : "NAN"}
                                  </TableCell>
                                </>
                              )}
                              {isAlternativePatternSuccess && (
                                <>
                                  <TableCell style={{ height: "48px", padding: "0px" }} align="center">
                                    {questionObject?.alternating_pattern_label ? <GreenCircleIcon /> : <RedCircleIcon />}
                                  </TableCell>
                                  <TableCell style={{ height: "48px", padding: "0px" }} align="center">
                                    {questionObject?.alternating_score != null
                                      ? numberToPercentageFloor(questionObject?.alternating_score?.toFixed(2), 0)
                                      : "NAN"}
                                  </TableCell>
                                </>
                              )}
                              {isSteppedPatternSuccess && (
                                <>
                                  <TableCell style={{ height: "48px", padding: "0px" }} align="center">
                                    {questionObject?.stepped_pattern_label ? <GreenCircleIcon /> : <RedCircleIcon />}
                                  </TableCell>
                                  <TableCell style={{ height: "48px", padding: "0px" }} align="center">
                                    {questionObject?.stepped_pattern_score != null
                                      ? numberToPercentageFloor(questionObject?.stepped_pattern_score?.toFixed(2), 0)
                                      : "NAN"}
                                  </TableCell>
                                </>
                              )}
                            </>
                            <TableCell style={{ height: "48px", padding: "0px" }} sx={{ backgroundColor: "#F7F7FF" }}>
                              <On_Off_switch isChecked={isIncluded} onSwitchChange={(e) => handleOnSwitchChange(e, respId, questionId)} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {/* <DataGridDemo tableDataMap={tableDataMap} handleOnSwitchChange={handleOnSwitchChange} scrollPosition={scrollPosition} /> */}
            {searchcol.length > 0 && (
              <div className="pattern-recognition__pagination-component">
                <PaginationComponent limit={limit} offset={offset} totalCount={searchcol.length ?? 0} onPageChange={handlePageChange} />
              </div>
            )}
          </Paper>
        </Box>
        {!isAnyPatternRecognitionSuccess && (
          <div className="pattern-recognition__refresh-container">
            <p className="pattern-recognition__refresh-text">We are retrieving data; please wait and click the button below to refresh the data.</p>
            <ButtonComponent onClick={() => fetchDataAndMap()} variant="outlined" btnType="primary" text="Refresh" className="btnPrimaryOutline btnDisabled" />
          </div>
        )}
      </div>
    </>
  );
}

export default PatternRecognition;
