import { Box, Modal } from "@mui/material";
import React, { useState } from "react";

import "./../FileUpload/FileUploadModal.css";
import "./PermanentRespondentModal.css";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { questionMapperApi, removeRuleStatus, respondentMapperApi } from "../../../services/projectService";

// Select dropdown MUI
import { closeLoader, openLoader } from "../../../features/loader/loaderSlice";
import { openSnackbar } from "../../../features/snackbar/snackbarslice";
import { getMergedFilePath, initiatePatternRecognitionApis } from "../../../services/patternService";
import ButtonComponent from "../../Button/ButtonComponent";
import DefaultToggleBtn from "../../Switch/DefaultToggleBtn/DefaultToggleBtn";
import { SNACKBAR_TYPES } from "../../../assets/constants/constant";

const PermanentRespondentModal = ({ open, onClose, potentialPermanentJokerList = [] }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);
  const dataMapFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.id);
  const dataMapFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);
  const rawFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeRawDataFile?.id);
  const username = useSelector((state) => state?.user?.userInfo?.email ?? "");

  const [toggleStatus, setToggleStatus] = useState(
    potentialPermanentJokerList?.reduce((acc, respondentId) => {
      acc[respondentId] = true;
      return acc;
    }, {})
  );

  // State for "Exclude All" toggle
  const [excludeAll, setExcludeAll] = useState(false);

  // Handle toggle change
  const handleToggleChange = (respondentId) => {
    setToggleStatus((prevStatus) => {
      const newStatus = {
        ...prevStatus,
        [respondentId]: !prevStatus[respondentId],
      };
      setExcludeAll(Object.values(newStatus).every((status) => !status));
      return newStatus;
    });
  };

  const handleExcludeAllChange = () => {
    const newStatus = !excludeAll;
    setExcludeAll(newStatus);
    setToggleStatus((prevStatus) =>
      Object.keys(prevStatus).reduce((acc, respondentId) => {
        acc[respondentId] = !newStatus;
        return acc;
      }, {})
    );
  };

  async function onSave() {
    try {
      const filteredJokerList = potentialPermanentJokerList.filter((respondentId) => toggleStatus[respondentId]);

      const mapperApiCalls = async () => {
        try {
          await questionMapperApi(dataMapFileId, username);
          await respondentMapperApi(dataMapFileId, rawFileId, filteredJokerList, username);
        } catch (error) {
          dispatch(openSnackbar({ message: "Failed to parse files!", type: SNACKBAR_TYPES.ERROR }));
        }

        try {
          await removeRuleStatus(projectId);
        } catch (error) {}
      };

      const initiatePatternRecognitionApiCalls = async () => {
        try {
          const mergedFilePath = await getMergedFilePath(dataMapFileName, projectId);
          await initiatePatternRecognitionApis(projectId, mergedFilePath);
        } catch (error) {
          dispatch(openSnackbar({ message: "Failed to initiate pattern recognition apis!", type: SNACKBAR_TYPES.ERROR }));
        }
      };

      try {
        dispatch(openLoader());
        await mapperApiCalls();
        await initiatePatternRecognitionApiCalls();

        navigate("/create-project/design-qc-rules/1");
      } catch (error) {
      } finally {
        dispatch(closeLoader());
      }
    } catch (error) {
      dispatch(openSnackbar({ message: "Something went wrong!", type: SNACKBAR_TYPES.ERROR }));
    }
  }

  // if (potentialPermanentJokerList !== undefined && potentialPermanentJokerList !== null && potentialPermanentJokerList.length === 0) {
  //   onSave();
  // }

  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="fileUploadModal" onClick={(e) => e.stopPropagation()}>
        <div className="file_header">
          <div className="attach">Joker In Database</div>
          <div className="header_right d-flex align-items-baseline gap-3"></div>
        </div>
        <div className="w-100 modal-content-body d-flex flex-column align-items-center justify-content-center p-3">
          <div className="permanent-respondent-modal__header container mb-2 d-flex align-items-center gap-3">
            Do you want to exclude the following Respondent Ids?
            <div className="permanent-respondent-modal__header--medium-size d-flex gap-2 align-items-center">
              <DefaultToggleBtn isChecked={excludeAll} onSwitchChange={handleExcludeAllChange} />
              <span className="permanent-respondent-modal__exclude-all-text">Exclude All</span>
            </div>
          </div>
          <div className="container mb-4">
            <div className="row permanent-joker-modal">
              {potentialPermanentJokerList &&
                potentialPermanentJokerList?.map((respondentId, index) => (
                  <div className="h-min-content col-6 d-flex gap-2 align-items-center" key={index}>
                    <DefaultToggleBtn isChecked={toggleStatus[respondentId]} onSwitchChange={() => handleToggleChange(respondentId)} disabled={excludeAll} />
                    <span>{respondentId}</span>
                  </div>
                ))}
            </div>
          </div>
          <div className="w-100 d-flex justify-content-between align-items-baseline gap-2">
            <div className="footer-btns">
              <ButtonComponent
                onClick={(e) => {
                  e.preventDefault();
                  onSave();
                }}
                variant="contained"
                btnType="primary"
                text="Save & Next"
                className="btnPrimary"
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PermanentRespondentModal;
