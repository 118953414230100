import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import * as _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { PATTERN_RECOGNITION_WEIGHT_OBJECT } from "../constants/preview-constants";
import {
  resetPreviewCalculateAgain,
  setFirstPreviewCalculateBtnClicked,
  setPatternRecognitionFormById,
  setWeightFormByRuleId,
} from "../../../../../features/designQcRule/previewWeights/previewWeightsSlice";
import ButtonComponent from "../../../../../components/Button/ButtonComponent";
import { closeLoader, openLoader } from "../../../../../features/loader/loaderSlice";
import { downloadPreviewAdverseApi, downloadPreviewJokersApi } from "../../../../../services/previewService";
import { openSnackbar } from "../../../../../features/snackbar/snackbarslice";
import downloadarrow from "../../../../../assets/images/downloadarrow.svg";
import { bulkUpsertRules } from "../../../../../services/projectService";
import { getAdverseEvent, runkeyword } from "../../../../../services/adverseEventService";
import { setadverseEventdata, setmergeAdversedata } from "../../../../../features/adverseEvent/AdverseRuleExecution/AdverseRuleExecutionSlice";
import { getMergedFilePath } from "../../../../../services/patternService";
// import { setadverseEventdata } from "../../../../../features/designQcRule/projectJokers/projectJokersSlice";
// import { setadverseEventdata } from "../../../../../features/adverseEvent/AdverseRuleExecution/AdverseRuleExecutionSlice";
import { DESIGN_QC_HORIZONTAL_TABS } from "../../../DesignQc.constants";

import "./JokerWeightInput.css";
import { setMergedFilePath } from "../../../../../features/designQcRule/projectMetaData/projectMetaDataSlice";
import { SNACKBAR_TYPES } from "../../../../../assets/constants/constant";

import { getGenAiCombinedData } from "./jokerWeightInputHelper";

function EnhancedTableHead(props) {
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  const { order, orderBy } = props;
  return (
    <TableHead className="preview_table_head" sx={{ position: "sticky", top: 0, zIndex: 1 }}>
      <TableRow sx={{ backgroundColor: "#E3E4FD" }}>
        <TableCell className="pattern_table-head-cell-qc" align="left" sx={{ paddingBottom: "12px", width: "15%", paddingLeft: "19px" }}>
          Design QC
        </TableCell>
        <TableCell className="pattern_table-head-cell-rule-name" align="left" sx={{ width: "20%" }}>
          Rule Name
        </TableCell>
        <TableCell className="pattern_table-head-cell-rule" align="left" sx={{ width: "40%" }}>
          Rule
        </TableCell>
        {selectedHorizontalTab === "Joker Analysis" && (
          <TableCell className="pattern_table-head-cell-weight" align="left" sx={{ width: "20%" }}>
            Weight
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const rows = [];

const JokerWeightInput = ({
  formErrors,
  calculatedRespondentsObject,
  setCalculatedRespondentsObject,
  setIsCalculationInit,
  isRuleFetched,
  isInitRuleApiDone,
  limit,
  offset,
  setOffset,
}) => {
  const [dense, setDense] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  const username = useSelector((state) => state?.user?.userInfo?.email) ?? "";
  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);
  const projectName = useSelector((state) => state?.createProjectDetails?.data?.name);
  const thresholdValue = useSelector((state) => state?.previewWeights?.thresholdScore);
  const adverseeventpreview = useSelector((state) => state?.projectJokers?.adverseeventdata);
  const Adversedatasummary = useSelector((state) => state?.adverseRuleExecution?.adverseeventdata);
  const AdversedatasummaryHeaders = useSelector((state) => state?.adverseRuleExecution?.adverseeventdataHeader);

  // // [ruleId]: {
  // //   data: rowDetails,
  // //   weightValue: newValue,
  // // }
  // const [weightFormByRuleId, setWeightFormByRuleId] = useState({});
  // // [rule_name]: {
  // //   data: rowDetails,
  // //   weightValue: newValue,
  // // }
  // const [patternRecognitionFormById, setPatternRecognitionFormById] = useState({});

  const jokerAnalysisRules = (useSelector((state) => state?.rules?.activeRules) ?? undefined)?.filter((rule) => rule?.is_valid);
  const adverseRules = (useSelector((state) => state?.adverseRule?.activeRules) ?? undefined)?.filter((rule) => rule?.is_valid);

  const dataMapFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);
  const projectID = useSelector((state) => state?.createProjectDetails?.data?.id);

  const rules = (selectedHorizontalTab === "Adverse Events" ? adverseRules : selectedHorizontalTab === "Joker Analysis" ? jokerAnalysisRules : [])?.filter(
    (rule) => rule?.is_valid
  );

  const weightFormByRuleId = useSelector((state) => state?.previewWeights?.weightFormByRuleId);
  const patternRecognitionFormById = useSelector((state) => state?.previewWeights?.patternRecognitionFormById);

  const patternRecognitionGroupedDetails = useSelector((state) => state?.patternRecognition?.includeExcludeApi?.mappedData) ?? undefined;
  const respondentDetailsArray = useSelector((state) => state?.ruleExecution?.ruleExecutionResponse) ?? undefined;
  const genaiResponse = useSelector((state) => state?.previewWeights?.genaiResponse);

  // adverse
  const adverserespondentDetailsArray = useSelector((state) => state?.adverseRuleExecution?.ruleExecutionResponse) ?? undefined;
  // adverse
  const adversegroupedRespondentDetails = _.groupBy(adverserespondentDetailsArray, "respondent_id");

  const groupedRespondentDetails = _.groupBy(respondentDetailsArray, "respondent_id");

  const previewCalculateAgain = useSelector((state) => state?.previewWeights?.previewCalculateAgain);
  const reduxMergedFilePath = useSelector((state) => state?.projectMetaData?.mergedFilePath?.data);

  const dispatch = useDispatch();

  const isSelected = useCallback((id) => selected.indexOf(id) !== -1, [selected]);

  // /////////////////////////////////////////////////////////////////////////////////////////

  // adverse
  // Adverse Events

  useEffect(() => {
    const fetchData = async () => {
      try {
        let mergedFilePath = reduxMergedFilePath;
        if (mergedFilePath === undefined || mergedFilePath === null) {
          mergedFilePath = await getMergedFilePath(dataMapFileName, projectID);
          dispatch(setMergedFilePath(mergedFilePath));
        }

        // const mergedFilePath = "Quant/Putnam/Test client/Test June 13 1/Harsh Chauhan/dummy/Merged_Layer/Merged_Data_Flat_2024-06-13 05:59:12";
        // const projectId = 627;
        const payload = {
          mergedFilePath: mergedFilePath,
          projectId: projectId,
        };
        dispatch(openLoader());
        try {
          const initiatePayload = {
            mergedFilePath: mergedFilePath,
            projectId: projectId,
          };
          if (selectedHorizontalTab !== DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS) {
            const initiatedata = await runkeyword(initiatePayload);
            dispatch(
              openSnackbar({ message: initiatedata?.data?.message ?? initiatedata?.data?.status ?? "", type: SNACKBAR_TYPES.SUCCESS, autoHideDuration: 9000 })
            );
            const adversedata = await getAdverseEvent(payload);
            dispatch(setadverseEventdata(adversedata?.data));
          }
        } catch (error) {
          dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
        } finally {
          dispatch(closeLoader());
        }
      } catch (error) {
        dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
      }
    };

    fetchData();
  }, []);

  // adverse

  useEffect(() => {
    if (isRuleFetched && isInitRuleApiDone && previewCalculateAgain) {
      dispatch(resetPreviewCalculateAgain());
      initRuleWeightForm();
      initPatternWeightForm();
      handleCalculateJokerClicked(true);
    }
  }, [isRuleFetched, isInitRuleApiDone, previewCalculateAgain]);

  const initRuleWeightForm = () => {
    const newWeightForm = _.cloneDeep(weightFormByRuleId);

    rules?.map((row, index, n) => {
      const weightValue = weightFormByRuleId?.[row?.id]?.weightValue ?? null;

      if (weightValue === null) {
        const ruleId = parseInt(row?.id) ?? -1;

        const initWeight = parseFloat(row?.weight);
        let finalWeight = 0;

        if (initWeight !== null && initWeight !== undefined && !isNaN(initWeight)) {
          finalWeight = initWeight;
        }

        newWeightForm[ruleId] = {
          data: row,
          weightValue: finalWeight,
          error: false,
        };
      }
    });

    dispatch(setWeightFormByRuleId(newWeightForm));
  };

  const initPatternWeightForm = () => {
    const newWeightForm = _.cloneDeep(patternRecognitionFormById);
    Object.keys(PATTERN_RECOGNITION_WEIGHT_OBJECT).map((key, index, n) => {
      const weightValue = patternRecognitionFormById[key]?.weightValue ?? null;
      const rowDetails = { key: key };
      if (weightValue === null) {
        const ruleId = rowDetails?.key;

        newWeightForm[ruleId] = {
          data: rowDetails,
          weightValue: 0,
          error: false,
        };
      }
    });

    dispatch(setPatternRecognitionFormById(newWeightForm));
  };
  const handleWheel = (event) => {
    if (event.target.type === "number") {
      event.preventDefault();
    }
  };

  const handleRulesRowValueChange = (e, rowDetails) => {
    const newValue = parseFloat(e.target.value);
    if (newValue < 0 || newValue > 10) {
      return;
    }
    const ruleId = parseInt(rowDetails.id) ?? -1;

    const newWeightForm = _.cloneDeep(weightFormByRuleId);

    newWeightForm[ruleId] = {
      data: rowDetails,
      weightValue: newValue,
      error: false,
    };

    dispatch(setWeightFormByRuleId(newWeightForm));
  };

  const handlePatternRecognitionRowValueChange = (e, rowDetails) => {
    const newValue = parseFloat(e.target.value);
    if (newValue < 0 || newValue > 10) {
      return;
    }
    const ruleId = rowDetails?.key;

    const newWeightForm = _.cloneDeep(patternRecognitionFormById);

    newWeightForm[ruleId] = {
      data: rowDetails,
      weightValue: newValue,
    };

    dispatch(setPatternRecognitionFormById(newWeightForm));
  };

  const handleDownloadClicked = async () => {
    try {
      dispatch(openLoader());
      if (selectedHorizontalTab == DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS) {
        const finalCalculatedRespondents = {};

        Object.keys(calculatedRespondentsObject).forEach((key) => {
          if (calculatedRespondentsObject[key]?.exclude !== true) {
            finalCalculatedRespondents[key] = calculatedRespondentsObject[key].score;
          }
        });

        const combinedData = getGenAiCombinedData(patternRecognitionGroupedDetails, genaiResponse);

        await downloadPreviewJokersApi(
          weightFormByRuleId,
          patternRecognitionFormById,
          finalCalculatedRespondents,
          projectId,
          groupedRespondentDetails,
          patternRecognitionGroupedDetails,
          projectName,
          combinedData
        );
      } else if (selectedHorizontalTab == DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS) {
        await downloadPreviewAdverseApi(projectId, projectName);
      }
      dispatch(openSnackbar({ message: "Preview downloaded successfully!", type: SNACKBAR_TYPES.SUCCESS }));
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
    } finally {
      dispatch(closeLoader());
    }
  };

  // adverse merge data

  function mergeData(Adversedatasummary, adversegroupedRespondentDetails) {
    const mergedArray = [];
    const respondentDetailsMap = {};
    for (const [respondentId, details] of Object.entries(adversegroupedRespondentDetails)) {
      respondentDetailsMap[respondentId] = details;
    }
    if (Adversedatasummary) {
      for (const summary of Adversedatasummary) {
        const respondentId = summary.respondent_id;
        if (respondentDetailsMap[respondentId]) {
          for (const detail of respondentDetailsMap[respondentId]) {
            mergedArray.push({
              respondent_id: respondentId,
              value: detail.value,
              adverse_event_data: summary.data,
            });
          }
        } else {
          mergedArray.push({
            respondent_id: respondentId,
            value: false,
            adverse_event_data: summary.data,
          });
        }
      }
    }

    dispatch(setmergeAdversedata(mergedArray));
    return mergedArray;
  }

  const mergedData = mergeData(Adversedatasummary, adversegroupedRespondentDetails);

  // Merge the data

  // adverse

  const handleCalculateJokerClicked = async (isInitial) => {
    try {
      setOffset(0);
      if (!isInitial) {
        validationCheck();
        dispatch(setFirstPreviewCalculateBtnClicked());
      }

      const calculatedRespondentObject = {};

      // RULES CALCULATIONS
      if (groupedRespondentDetails !== undefined)
        Object.keys(groupedRespondentDetails)?.forEach((key) => {
          const respondentId = parseInt(key);
          const respondentRulesArray = groupedRespondentDetails[key];

          if (calculatedRespondentObject[respondentId] === undefined || calculatedRespondentObject[respondentId] === null) {
            calculatedRespondentObject[respondentId] = { score: 0, exclude: false };
          }

          let calculatedValue = 0;

          for (const respondentRule of respondentRulesArray) {
            const respondentRuleAnswer = respondentRule?.value === true ? 1 : 0;

            const respondentRuleId = respondentRule?.rule_id ?? -1;
            const ruleWeight = weightFormByRuleId[respondentRuleId]?.weightValue ?? 0;

            calculatedValue = calculatedValue + respondentRuleAnswer * ruleWeight;
          }

          // if (calculatedValue < thresholdValue) return;

          calculatedRespondentObject[respondentId].score = (calculatedRespondentObject[respondentId]?.score ?? 0) + calculatedValue;
        });

      // PATTERN RECOGNITION CALCULATIONS
      if (patternRecognitionGroupedDetails !== undefined)
        Object.keys(patternRecognitionGroupedDetails)?.forEach((key) => {
          const respondentId = parseInt(key);

          if (calculatedRespondentObject[respondentId] === undefined || calculatedRespondentObject[respondentId] === null) {
            calculatedRespondentObject[respondentId] = { score: 0, exclude: false };
          }

          let calculatedValue = 0;

          Object.keys(PATTERN_RECOGNITION_WEIGHT_OBJECT).forEach((ruleColumn) => {
            const weight = patternRecognitionFormById?.[ruleColumn]?.weightValue ?? 0;

            const newValue = (patternRecognitionGroupedDetails?.[respondentId]?.[ruleColumn] * weight) / 100;

            if (!isNaN(newValue)) {
              calculatedValue = calculatedValue + newValue;
            }
          });

          calculatedRespondentObject[respondentId].score = (calculatedRespondentObject[respondentId]?.score ?? 0) + calculatedValue;
        });

      const filteredRespondentObject = {};
      if (calculatedRespondentObject !== undefined) {
        Object.keys(calculatedRespondentObject)?.forEach((respondentId) => {
          if (calculatedRespondentObject[respondentId]?.score >= thresholdValue) {
            filteredRespondentObject[respondentId] = calculatedRespondentObject[respondentId];
          }
        });
      }

      try {
        await updateRulesWeights();

        setCalculatedRespondentsObject(filteredRespondentObject);

        if (!isInitial) dispatch(openSnackbar({ message: "Joker calculation completed successfully!", type: SNACKBAR_TYPES.SUCCESS }));

        setIsCalculationInit(true);
      } catch (error) {
        dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
        // todo: show toast
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const validationCheck = () => {
    try {
      handleEachFieldValidation();

      // check all the rules values are entered or not
      const enteredRulesCount = Object?.keys(weightFormByRuleId)?.length ?? 0;
      if (enteredRulesCount !== rules.length) {
        // todo: throw toast exception
        dispatch(openSnackbar({ message: "Enter values for all rules", type: SNACKBAR_TYPES.ERROR }));
        throw new Error("Enter values for all rules");
      }

      // validate sum of 100 for rules
      // const totalWeightForRules = Object.values(weightFormByRuleId).reduce((sum, ruleWeightObject) => sum + ruleWeightObject?.weightValue, 0);
      // if (totalWeightForRules !== 100) {
      //   // todo: throw toast exception
      //   dispatch(openSnackbar({ message: "Sum of all rules must be 100", type: SNACKBAR_TYPES.ERROR }));
      //   throw new Error("Sum of all rules must be 100");
      // }

      // check all the pattern recognition values are entered or not
      const enteredPatternRecognitionCount = Object?.keys(patternRecognitionFormById)?.length ?? 0;
      if (enteredPatternRecognitionCount !== Object.keys(PATTERN_RECOGNITION_WEIGHT_OBJECT).length) {
        // todo: throw toast exception
        dispatch(openSnackbar({ message: "Enter values for all pattern recognition values", type: SNACKBAR_TYPES.ERROR }));
        throw new Error("Enter values for all pattern recognition values");
      }

      // validate sum of 100 for pattern recognition
      // const totalWeightForPatternRecognition = Object.values(patternRecognitionFormById).reduce((sum, ruleWeightObject) => sum + ruleWeightObject?.weightValue, 0);
      // if (totalWeightForPatternRecognition !== 100) {
      //   // todo: throw toast exception
      //   dispatch(openSnackbar({ message: "Sum of all pattern recognition must be 100", type: SNACKBAR_TYPES.ERROR }));
      //   throw new Error("Sum of all pattern recognition must be 100");
      // }
    } catch (error) {
      // console.log({ patternRecognitionFormById });
      throw error;
    }
  };

  const handleEachFieldValidation = () => {
    try {
      function handleRuleValidation() {
        let isError = false;
        const updatedWeightFormByRuleId = {};

        rules?.forEach((rule) => {
          const ruleId = rule?.id;

          if (ruleId === undefined || ruleId === null) return;

          let enteredValue = _.cloneDeep(weightFormByRuleId[ruleId]);

          if (!enteredValue) {
            enteredValue = { error: true };
            isError = true;
          }

          if (
            enteredValue?.weightValue === undefined ||
            enteredValue?.weightValue === null ||
            isNaN(enteredValue?.weightValue) ||
            enteredValue?.weightValue < 0 ||
            enteredValue?.data === undefined ||
            enteredValue?.data === null
          ) {
            enteredValue.error = true;
            isError = true;
          }

          updatedWeightFormByRuleId[ruleId] = enteredValue;
        });

        if (isError) {
          dispatch(setWeightFormByRuleId(updatedWeightFormByRuleId));
          // setWeightFormByRuleId(updatedWeightFormByRuleId);
        }

        return isError;
      }

      const isRuleError = handleRuleValidation();

      function handlePatternRecognitionValidation() {
        let isError = false;
        const updatedPatternRecognitionFormByRuleId = {};

        Object.keys(PATTERN_RECOGNITION_WEIGHT_OBJECT)?.forEach((rule) => {
          const ruleId = rule;

          if (ruleId === undefined || ruleId === null) return;

          let enteredValue = _.cloneDeep(patternRecognitionFormById[ruleId]);

          if (!enteredValue) {
            enteredValue = { error: true };
            isError = true;
          }

          if (
            enteredValue?.weightValue === undefined ||
            enteredValue?.weightValue === null ||
            isNaN(enteredValue?.weightValue) ||
            enteredValue?.weightValue < 0 ||
            enteredValue?.data === undefined ||
            enteredValue?.data === null
          ) {
            enteredValue.error = true;
            isError = true;
          }

          updatedPatternRecognitionFormByRuleId[ruleId] = enteredValue;
        });

        if (isError) {
          dispatch(setPatternRecognitionFormById(updatedPatternRecognitionFormByRuleId));
          // setPatternRecognitionFormById(updatedPatternRecognitionFormByRuleId);
        }

        return isError;
      }

      const isPatternRecognitionError = handlePatternRecognitionValidation();

      if (isRuleError || isPatternRecognitionError) {
        throw new Error("Invalid Input values");
      }
    } catch (error) {
      throw error;
    }
  };

  console.log({ weightFormByRuleId });

  const updateRulesWeights = async () => {
    try {
      dispatch(openLoader());
      const updatedRulesArray = [];

      Object.keys(weightFormByRuleId).forEach((ruleId) => {
        const {
          updated_by,
          updated_at,
          created_at,
          created_by,
          is_valid,
          rule_version,
          raw_data_file_id,
          data_map_file_id,
          rawFile,
          dataMapFile,
          ...otherWeightData
        } = weightFormByRuleId[ruleId]?.data ?? {};

        // console.log({ otherWeightData });

        const data = {
          ...otherWeightData,
          id: parseInt(ruleId),
          weight: parseFloat(weightFormByRuleId[ruleId]?.weightValue ?? 0).toString(),
        };

        updatedRulesArray.push(data);
      });

      await bulkUpsertRules(updatedRulesArray, username);
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
      // todo: show toast message
      // console.log({ error });
    } finally {
      dispatch(closeLoader());
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <div className="preview_heading_button">
        <div className="preview_heading">
          {selectedHorizontalTab === "Joker Analysis" && (
            <div>
              <p style={{ fontFamily: "Montserrat", fontSize: "16px", fontWeight: "600" }}>Preview and enter weights</p>
            </div>
          )}
          {selectedHorizontalTab === "Adverse Events" && (
            <div>
              <p style={{ fontFamily: "Montserrat", fontSize: "16px", fontWeight: "600" }}>Preview</p>
            </div>
          )}
          <div className="horizontal_line_heading"></div>
        </div>
        <div className="btn_download_calc">
          <img src={downloadarrow} alt="download" style={{ width: "15px", height: "15px", position: "relative", right: "-27px", zIndex: 1 }} />
          <ButtonComponent
            className="downloadbtn"
            btnType="outline"
            text="Download"
            onClick={handleDownloadClicked}
            sx={{ width: "125px", padding: "13px 13px 13px 30px", height: "32px", paddingleft: "30px" }}
          />

          {selectedHorizontalTab === "Joker Analysis" && (
            <ButtonComponent
              className="file_upload_btn"
              btnType="secondary"
              text="Calculate joker score"
              onClick={() => handleCalculateJokerClicked()}
              sx={{ width: "201px", height: "32px !important", marginLeft: "9px" }}
            />
          )}
        </div>
      </div>
      {/* Joker analysis preview table */}
      {selectedHorizontalTab === "Joker Analysis" && (
        <TableContainer style={{ overflow: "auto", position: "relative" }}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} className="preview_table_weight">
            <EnhancedTableHead
              numSelected={selected.length}
              // onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              fontFamily="Montserrat, sans-serif"
            />
            <TableBody
              style={{
                height: "100px",
                overflow: "auto",
              }}
            >
              {rules &&
                Array.isArray(rules) &&
                rules?.map((row, index, n) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isError = weightFormByRuleId?.[row.id]?.error ?? false;
                  const weightValue = weightFormByRuleId?.[row.id]?.weightValue ?? null;
                  console.log(row.rule_name, "++++++");

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className="preview_table-row"
                      sx={{ cursor: "pointer", height: "75px", fontFamily: "Montserrat, sans-serif", fontSize: "22px" }}
                    >
                      {index === 0 && (
                        <TableCell
                          className="custom_QC"
                          rowSpan={n.length}
                          sx={{ backgroundColor: "#f7f9fb", paddingLeft: "19px", fontWeight: "500", borderBottom: "12px solid #ffffff", paddingBottom: "12px" }}
                        >
                          Custom QC Rules
                        </TableCell>
                      )}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="left"
                        style={{ height: "48px", padding: "0px", paddingLeft: "27px" }}
                      >
                        {row.rule_name}
                      </TableCell>
                      <TableCell align="left" className="li" style={{ height: "48px", padding: "0px", paddingLeft: "38px" }}>
                        {row.rule}
                      </TableCell>

                      <TableCell align="left" style={{ height: "48px", padding: "8px", paddingLeft: "53px" }}>
                        <div className="preview_text_box">
                          <TextField
                            type="number"
                            className={`preview_textfield` + ` ${formErrors.name && "error-border"}`}
                            placeholder={"Enter weight"}
                            name="name"
                            InputLabelProps={{
                              shrink: false,
                              inputProps: { min: 0 },
                            }}
                            autoComplete="off"
                            error={isError}
                            value={weightValue}
                            helperText={isError && "Invalid input"}
                            onChange={(e) => handleRulesRowValueChange(e, row)}
                            onKeyPress={(event) => {
                              if (event?.key === "-" || event?.key === "+") {
                                event.preventDefault();
                              }
                            }}
                          />
                          {formErrors.name && <p className="error-font text-danger">{formErrors.name}</p>}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {Object.keys(PATTERN_RECOGNITION_WEIGHT_OBJECT).map((key, index, n) => {
                const rule = PATTERN_RECOGNITION_WEIGHT_OBJECT[key];

                const weightValue = patternRecognitionFormById[key]?.weightValue ?? null;
                const isError = patternRecognitionFormById[key]?.error ?? false;

                return (
                  <TableRow key={index}>
                    {index === 0 && (
                      <TableCell align="left" sx={{ background: "#f7f9fb", paddingLeft: "19px", fontWeight: "500" }} rowSpan={n.length}>
                        Pattern Recognition
                      </TableCell>
                    )}
                    <TableCell align="left" style={{ paddingLeft: "27px" }}>
                      {rule}
                    </TableCell>
                    <TableCell align="left" style={{ paddingLeft: "38px" }}>
                      -
                    </TableCell>
                    <TableCell align="left" style={{ padding: "8px", paddingLeft: "53px" }}>
                      <div className="preview_text_box">
                        <TextField
                          type="number"
                          autoComplete="off"
                          className={`preview_textfield` + ` ${formErrors.name && "error-border"}`}
                          placeholder={"Enter weight"}
                          name="name"
                          InputLabelProps={{
                            shrink: false,
                            inputProps: { min: 0 },
                          }}
                          value={weightValue}
                          error={isError}
                          helperText={isError && "Invalid input"}
                          onChange={(e) => handlePatternRecognitionRowValueChange(e, { key: key })}
                          onKeyPress={(event) => {
                            if (event?.key === "-" || event?.key === "+") {
                              event.preventDefault();
                            }
                          }}
                        />
                        {formErrors.name && <p className="error-font text-danger">{formErrors.name}</p>}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* adverse event preview table */}
      {selectedHorizontalTab === "Adverse Events" && (
        <TableContainer style={{ overflow: "auto", position: "relative" }}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} className="preview_table_weight">
            <EnhancedTableHead
              numSelected={selected.length}
              // onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              fontFamily="Montserrat, sans-serif"
            />
            <TableBody
              style={{
                height: "100px",
                overflow: "auto",
              }}
            >
              {rules &&
                Array.isArray(rules) &&
                rules?.map((row, index, n) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // const isError = weightFormByRuleId?.[row.id]?.error ?? false;
                  // const weightValue = weightFormByRuleId?.[row.id]?.weightValue ?? null;
                  console.log(row.rule_name, "++++++");

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className="preview_table-row"
                      sx={{ cursor: "pointer", height: "75px", fontFamily: "Montserrat, sans-serif", fontSize: "22px" }}
                    >
                      {index === 0 && (
                        <TableCell rowSpan={n.length} sx={{ backgroundColor: "#f7f9fb", paddingLeft: "19px", fontWeight: "500", borderBottom: "none" }}>
                          Custom QC Rules
                        </TableCell>
                      )}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="left"
                        style={{ height: "48px", padding: "0px", paddingLeft: "27px" }}
                      >
                        {row.rule_name}
                      </TableCell>
                      <TableCell align="left" className="li" style={{ height: "48px", padding: "0px", paddingLeft: "38px" }}>
                        {row.rule}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default JokerWeightInput;
