import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mergedFilePath: {
    data: undefined,
    loading: true,
  },
};

const projectMetaDataSlice = createSlice({
  name: "projectMetaData",
  initialState,
  reducers: {
    getMergedFilePathStart(state) {
      state.loading = true;
    },
    getMergedFilePathSuccess(state, action) {
      state.loading = false;
      state.mergedFilePath.data = action.payload;
    },
    setMergedFilePath(state, action) {
      state.mergedFilePath.data = action.payload ?? undefined;
    },
    getUserDataError(state) {
      state.loading = false;
    },

    resetProjectMetaData(state) {
      return initialState;
    },
  },
});

export const { getMergedFilePathStart, getMergedFilePathSuccess, setMergedFilePath, getUserDataError, resetProjectMetaData } = projectMetaDataSlice.actions;

export default projectMetaDataSlice.reducer;
