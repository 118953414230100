import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  maliciousCheck: {
    status: "pending",
    message: "Malicious file check for Survey/Data map file",
    successMessage: "",
    errorMessage: "",
    error: true,
  },
  validateId: {
    status: "pending",
    message: "Survey file has unique respondents",
    successMessage: "",
    errorMessage: "",
    error: true,
  },
  validateRawFile: {
    status: "pending",
    message: "Number of unique variables in variable id column in data map file is greater than or equal to the total unique attribute in survey file",
    successMessage: "",
    errorMessage: "",
    error: true,
  },
  validateDataFile: {
    status: "pending",
    message: "Data map file has all the required attributes",
    successMessage: "",
    errorMessage: "",
    error: true,
  },
  validateDataMatchCheck: {
    status: "pending",
    message: "Data map file is in sync with survey file",
    successMessage: "",
    errorMessage: "",
    error: true,
  },

  validateDataRowValidationCheck: {
    status: "pending",
    message: "Data map file contains all valid Rows",
    successMessage: "",
    errorMessage: "",
    error: true,
  },

  validateDataSingleCheck: {
    status: "pending",
    message: "Data map file contains all valid Single questions",
    successMessage: "",
    errorMessage: "",
    error: true,
  },
  validateDataMultiCheck: {
    status: "pending",
    message: "Data map file contains all valid Multi type questions",
    successMessage: "",
    errorMessage: "",
    error: true,
  },
  validateDataRankingCheck: {
    status: "pending",
    message: "Data map file contains all valid Ranking questions",
    successMessage: "",
    errorMessage: "",
    error: true,
  },
  validateDataNumericListCheck: {
    status: "pending",
    message: "Data map file contains all valid Numeric List questions",
    successMessage: "",
    errorMessage: "",
    error: true,
  },
  validateDataOpenTextListCheck: {
    status: "pending",
    message: "Data map file contains all valid Open Text List questions",
    successMessage: "",
    errorMessage: "",
    error: true,
  },
  validateDataGridCheck: {
    status: "pending",
    message: "Data map file contains all valid Grid questions",
    successMessage: "",
    errorMessage: "",
    error: true,
  },
};

export const validateFilesSlice = createSlice({
  name: "createProjectStep",
  initialState: initialState,
  reducers: {
    maliciousCheckSuccess(state, action) {
      state.maliciousCheck.status = "success";
      state.maliciousCheck.message = action.payload;
      state.maliciousCheck.error = false;
    },
    maliciousCheckError(state, action) {
      state.maliciousCheck.status = "error";
      state.maliciousCheck.message = action.payload || state.maliciousCheck.message;
      state.maliciousCheck.error = true;
    },
    validateIdSuccess(state, action) {
      state.validateId.status = "success";
      state.validateId.successMessage = action.payload;
      state.validateId.error = false;
    },
    validateIdError(state, action) {
      state.validateId.status = "error";
      state.validateId.errorMessage = action.payload;
      state.validateId.error = true;
    },
    validateRawFileSuccess(state, action) {
      state.validateRawFile.status = "success";
      state.validateRawFile.successMessage = action.payload;
      state.validateRawFile.error = false;
    },
    validateRawFileError(state, action) {
      state.validateRawFile.status = "error";
      state.validateRawFile.errorMessage = action.payload;
      state.validateRawFile.error = true;
    },
    validateDataFileSuccess(state, action) {
      state.validateDataFile.status = "success";
      state.validateDataFile.successMessage = action.payload;
      state.validateDataFile.error = false;
    },
    validateDataFileError(state, action) {
      state.validateDataFile.status = "error";
      state.validateDataFile.errorMessage = action.payload;
      state.validateDataFile.error = true;
    },
    validateDataMatchCheckSuccess(state, action) {
      state.validateDataMatchCheck.status = "success";
      state.validateDataMatchCheck.successMessage = action.payload;
      state.validateDataMatchCheck.error = false;
    },
    validateDataMatchCheckError(state, action) {
      state.validateDataMatchCheck.status = "error";
      state.validateDataMatchCheck.errorMessage = action.payload;
      state.validateDataMatchCheck.error = true;
    },

    validateDataRowValidationCheckSuccess(state, action) {
      state.validateDataRowValidationCheck.status = "success";
      state.validateDataRowValidationCheck.successMessage = action.payload;
      state.validateDataRowValidationCheck.error = false;
    },
    validateDataRowValidationCheckError(state, action) {
      state.validateDataRowValidationCheck.status = "error";
      state.validateDataRowValidationCheck.errorMessage = action.payload;
      state.validateDataRowValidationCheck.error = true;
    },

    validateDataSingleCheckSuccess(state, action) {
      state.validateDataSingleCheck.status = "success";
      state.validateDataSingleCheck.successMessage = action.payload;
      state.validateDataSingleCheck.error = false;
    },
    validateDataSingleCheckError(state, action) {
      state.validateDataSingleCheck.status = "error";
      state.validateDataSingleCheck.errorMessage = action.payload;
      state.validateDataSingleCheck.error = true;
    },
    validateDataMultiCheckSuccess(state, action) {
      state.validateDataMultiCheck.status = "success";
      state.validateDataMultiCheck.successMessage = action.payload;
      state.validateDataMultiCheck.error = false;
    },
    validateDataMultiCheckError(state, action) {
      state.validateDataMultiCheck.status = "error";
      state.validateDataMultiCheck.errorMessage = action.payload;
      state.validateDataMultiCheck.error = true;
    },
    validateDataRankingCheckSuccess(state, action) {
      state.validateDataRankingCheck.status = "success";
      state.validateDataRankingCheck.successMessage = action.payload;
      state.validateDataRankingCheck.error = false;
    },
    validateDataRankingCheckError(state, action) {
      state.validateDataRankingCheck.status = "error";
      state.validateDataRankingCheck.errorMessage = action.payload;
      state.validateDataRankingCheck.error = true;
    },
    validateDataNumericListCheckSuccess(state, action) {
      state.validateDataNumericListCheck.status = "success";
      state.validateDataNumericListCheck.successMessage = action.payload;
      state.validateDataNumericListCheck.error = false;
    },
    validateDataNumericListCheckError(state, action) {
      state.validateDataNumericListCheck.status = "error";
      state.validateDataNumericListCheck.errorMessage = action.payload;
      state.validateDataNumericListCheck.error = true;
    },
    validateDataOpenTextListCheckSuccess(state, action) {
      state.validateDataOpenTextListCheck.status = "success";
      state.validateDataOpenTextListCheck.successMessage = action.payload;
      state.validateDataOpenTextListCheck.error = false;
    },
    validateDataOpenTextListCheckError(state, action) {
      state.validateDataOpenTextListCheck.status = "error";
      state.validateDataOpenTextListCheck.errorMessage = action.payload;
      state.validateDataOpenTextListCheck.error = true;
    },
    validateDataGridCheckSuccess(state, action) {
      state.validateDataGridCheck.status = "success";
      state.validateDataGridCheck.successMessage = action.payload;
      state.validateDataGridCheck.error = false;
    },
    validateDataGridCheckError(state, action) {
      state.validateDataGridCheck.status = "error";
      state.validateDataGridCheck.errorMessage = action.payload;
      state.validateDataGridCheck.error = true;
    },
    resetState(state) {
      state.maliciousCheck.status = initialState.maliciousCheck.status;
      state.maliciousCheck.message = initialState.maliciousCheck.message;
      state.validateId.status = initialState.validateId.status;
      state.validateId.message = initialState.validateId.message;
      state.validateRawFile.status = initialState.validateRawFile.status;
      state.validateRawFile.message = initialState.validateRawFile.message;
      state.validateDataFile.status = initialState.validateDataFile.status;
      state.validateDataFile.message = initialState.validateDataFile.message;
      state.validateDataMatchCheck.status = initialState.validateDataMatchCheck.status;
      state.validateDataMatchCheck.message = initialState.validateDataMatchCheck.message;

      state.validateDataRowValidationCheck.status = initialState.validateDataRowValidationCheck.status;
      state.validateDataRowValidationCheck.message = initialState.validateDataRowValidationCheck.message;
      state.validateDataSingleCheck.status = initialState.validateDataSingleCheck.status;
      state.validateDataSingleCheck.message = initialState.validateDataSingleCheck.message;
      state.validateDataMultiCheck.status = initialState.validateDataMultiCheck.status;
      state.validateDataMultiCheck.message = initialState.validateDataMultiCheck.message;
      state.validateDataRankingCheck.status = initialState.validateDataRankingCheck.status;
      state.validateDataRankingCheck.message = initialState.validateDataRankingCheck.message;
      state.validateDataNumericListCheck.status = initialState.validateDataNumericListCheck.status;
      state.validateDataNumericListCheck.message = initialState.validateDataNumericListCheck.message;
      state.validateDataOpenTextListCheck.status = initialState.validateDataOpenTextListCheck.status;
      state.validateDataOpenTextListCheck.message = initialState.validateDataOpenTextListCheck.message;
      state.validateDataGridCheck.status = initialState.validateDataGridCheck.status;
      state.validateDataGridCheck.message = initialState.validateDataGridCheck.message;
    },
  },
});

export const {
  maliciousCheckError,
  maliciousCheckSuccess,
  validateRawFileError,
  validateRawFileSuccess,
  validateIdError,
  validateIdSuccess,
  startValidation,
  resetState,
  validateDataFileSuccess,
  validateDataFileError,
  validateDataMatchCheckSuccess,
  validateDataMatchCheckError,

  validateDataRowValidationCheckSuccess,
  validateDataRowValidationCheckError,

  validateDataSingleCheckSuccess,
  validateDataSingleCheckError,
  validateDataMultiCheckSuccess,
  validateDataMultiCheckError,
  validateDataRankingCheckSuccess,
  validateDataRankingCheckError,
  validateDataNumericListCheckSuccess,
  validateDataNumericListCheckError,
  validateDataOpenTextListCheckSuccess,
  validateDataOpenTextListCheckError,
  validateDataGridCheckSuccess,
  validateDataGridCheckError,
} = validateFilesSlice.actions;

export default validateFilesSlice.reducer;
