export function customValidateExpression(categoricalQuestions, numericalQuestions, rule) {
  const categoricalPattern = new RegExp(`\\b(${categoricalQuestions.join("|")})\\b`, "i");
  const numericalPattern = new RegExp(`\\b(${numericalQuestions.join("|")})\\b`, "i");

  // Case-insensitive function names
  const functionNames = ["mean", "mode", "median", "sd"];
  const functionNamesPattern = new RegExp(`\\b(${functionNames.join("|")})\\b`, "i");

  const comparisonOperators = ["<", "<=", ">", ">=", "=", "==", "!=", "is", "is not"];
  const logicalOperators = ["and", "or"];
  const arithmeticOperators = ["+", "-", "*", "/", "%"];

  // Tokenize the expression
  function tokenize(expression) {
    const regex = /"[^"]*"|\b\d+(\.\d+)?\b|\b(mean|mode|median|sd)\s*\([^)]*\)|[<>=!]=?|and|or|is not|is|[+\-*/%]|\(|\)|\w+/gi;
    return expression.match(regex);
  }

  // Check if a function call is valid
  function isValidFunctionCall(token) {
    const functionMatch = /^(\w+)\s*\(([^)]*)\)$/.exec(token);
    if (functionMatch) {
      const functionName = functionMatch[1];
      const args = functionMatch[2].split(",").map((arg) => arg.trim());

      if (functionNames.includes(functionName.toLowerCase())) {
        for (const arg of args) {
          if (!numericalPattern.test(arg)) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  // Validate the expression
  function isValidExpression(expression) {
    const tokens = tokenize(expression);
    if (!tokens) return [false, "Invalid expression format."];

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];

      if (categoricalPattern.test(token)) {
        // Categorical question checks
        if (i + 1 < tokens.length && comparisonOperators.includes(tokens[i + 1].toLowerCase()) && tokens[i + 2].startsWith('"')) {
          // Valid categorical comparison
          i += 2;
        } else {
          return [false, `Invalid use of categorical question '${token}'.`];
        }
      } else if (numericalPattern.test(token)) {
        // Numerical question checks
        if (i + 1 < tokens.length && (comparisonOperators.includes(tokens[i + 1]) || arithmeticOperators.includes(tokens[i + 1]))) {
          // Valid numerical comparison or arithmetic operation
          const nextToken = tokens[i + 2];
          if (numericalPattern.test(nextToken) || isValidFunctionCall(nextToken) || !isNaN(nextToken)) {
            i += 2;
          } else {
            return [false, `Invalid use of numerical question '${token}'.`];
          }
        } else if (i + 1 < tokens.length && comparisonOperators.includes(tokens[i + 1].toLowerCase())) {
          // Invalid numerical "is" comparison
          return [false, `Invalid use of 'is' operator with numerical question '${token}'.`];
        } else {
          // Numerical question can be used standalone
        }
      } else if (functionNamesPattern.test(token)) {
        // Function name token
        const functionMatch = token.match(/^(\w+)\s*\(([^)]*)\)$/);
        if (functionMatch) {
          const functionName = functionMatch[1];
          const args = functionMatch[2].split(",").map((arg) => arg.trim());

          if (functionNames.includes(functionName.toLowerCase())) {
            for (const arg of args) {
              if (!numericalPattern.test(arg)) {
                return [false, `Invalid argument '${arg}' in function '${functionName}(${args.join(", ")})'.`];
              }
            }
            // Skip over the function call and its arguments
            i += args.join(",").length + 2;
          } else {
            return [false, `Invalid function call '${token}'.`];
          }
        } else {
          return [false, `Invalid function syntax '${token}'.`];
        }
      } else if (
        logicalOperators.includes(token.toLowerCase()) ||
        comparisonOperators.includes(token) ||
        arithmeticOperators.includes(token) ||
        token === "(" ||
        token === ")"
      ) {
        // Valid tokens in the context
      } else if (!isNaN(token)) {
        // Valid numerical literal
      } else {
        return [false, `Invalid token '${token}' in expression.`];
      }
    }

    return [true, "Expression is valid."];
  }

  return isValidExpression(rule);
}

//   // Example usage
//   const categoricalQuestions = ['M4_1'];
//   const numericalQuestions = ['G2_2', 'loi'];
//   const rule = 'M4_1 Is "6" or M4_1 Is "4"';
//   const [isValid, message] = validateExpression(categoricalQuestions, numericalQuestions, rule);

//   if (isValid) {
//   } else {
//   }
