import { createSlice } from "@reduxjs/toolkit";

export const createProjectSlice = createSlice({
  name: "createProjectStep",
  initialState: {
    loading: true,
    data: {},
    files: [],
    filterFiles: [],
    selectedFiles: { activeRawDataFile: "", activeDataMapFile: "" },
    error: null,
    showSelectedFiles: false,
    showSelectedError: false,
  },
  reducers: {
    createProjectStart(state) {
      state.loading = true;
      state.error = null;
    },
    createProjectSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;

      if (state?.data?.id !== undefined || state?.data?.id !== null) {
        state.data.id = parseInt(state?.data?.id);
      }
    },
    createProjectError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getRawDataFileStart(state, action) {
      state.loading = true;
      state.error = null;
    },
    getRawDataFilesSuccess(state, action) {
      state.files = action.payload;
      state.filterFiles = action.payload;
    },
    showSelectedFilesSuccess(state, action) {
      state.showSelectedFiles = action.payload;
      state.showSelectedError = false;
    },
    setShowSelectedError(state, action) {
      state.showSelectedError = true;
    },
    setSelectedFiles(state, action) {
      state.selectedFiles = action.payload;
    },
    filterFiles(state, action) {
      state.files = action.payload;
    },
    resetCreateProjectState(state) {
      state.loading = true;
      state.data = {};
      state.files = [];
      state.filterFiles = [];
      state.error = null;
      state.showSelectedFiles = false;
      state.showSelectedError = false;
      state.selectedFiles = {};
    },
  },
});

export const {
  createProjectStart,
  createProjectSuccess,
  createProjectError,
  getRawDataFilesSuccess,
  getRawDataFileStart,
  showSelectedFilesSuccess,
  setShowSelectedError,
  resetCreateProjectState,
  setSelectedFiles,
  filterFiles,
} = createProjectSlice.actions;

export default createProjectSlice.reducer;
