import { createSlice } from "@reduxjs/toolkit";

import { decodeRulesArrayOptionsMetaFromBase64 } from "../../../utils/helper";

const selectedAndActiveRulesValues = (ruleArray) => {
  const selectedRulesIdsArray = [];
  const newActiveRules = [];

  ruleArray.forEach((rule) => {
    if (rule.is_rule_active) {
      selectedRulesIdsArray.push(rule.id);
      newActiveRules.push(rule);
    }
  });

  return { checkedRulesInRuleTable: selectedRulesIdsArray, activeRules: newActiveRules };
};

const initialState = {
  rules: [],
  selectedRule: null,
  checkedRulesInRuleTable: [],
  activeRules: [],
  initRulesFetched: false,
  loading: false,
  error: null,
  isSwitchButtonPositionLeft: true,
  isRuleModified: false,
  isRuleEdited: false,
  deleteclicked: false,
};

const ruleTableSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    fetchRulesStart(state) {
      state.loading = true;
      state.error = null;
    },
    DeleteClicked(state) {
      state.deleteclicked = true;
    },
    fetchAllRulesStart(state) {
      state.loading = true;
      state.error = null;
    },
    updatedSelectedAndActiveRules(state, action) {
      const rules = action?.payload;

      const { checkedRulesInRuleTable, activeRules } = selectedAndActiveRulesValues(rules);

      state.checkedRulesInRuleTable = checkedRulesInRuleTable;
      state.activeRules = activeRules;
    },
    fetchRulesSuccess(state, action) {
      state.loading = false;
      state.rules = decodeRulesArrayOptionsMetaFromBase64(action.payload);

      const { checkedRulesInRuleTable, activeRules } = selectedAndActiveRulesValues(state.rules);

      state.activeRules = activeRules;
      state.initRulesFetched = true;
    },
    fetchRuleSuccessWithInitCheckedRulesReduxState(state, action) {
      if (!state.initRulesFetched) {
        const rules = action?.payload ?? [];

        const { checkedRulesInRuleTable, activeRules } = selectedAndActiveRulesValues(rules);

        const existingCheckedRules = state.checkedRulesInRuleTable;

        for (const checkedRuleId of checkedRulesInRuleTable) {
          if (!existingCheckedRules.includes(checkedRuleId)) {
            existingCheckedRules.push(checkedRuleId);
          }
        }

        state.checkedRulesInRuleTable = existingCheckedRules;
        state.activeRules = activeRules;
      }
    },
    fetchRulesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setSelectedRule(state, action) {
      state.selectedRule = action.payload;
      state.isSwitchButtonPositionLeft = true;
    },
    clearSelectedRule(state) {
      state.selectedRule = null;
    },
    setIsSwitchButtonPositionLeft(state, action) {
      state.isSwitchButtonPositionLeft = action.payload;
    },
    updateSelectedRuleName(state, action) {
      if (state.selectedRule) {
        state.selectedRule.rule_name = action.payload;
      }
    },
    updateSelectedRule(state, action) {
      if (state.selectedRule) {
        state.selectedRule.rule = action.payload;
      }
    },
    setIsRuleModified(state, action) {
      state.isRuleModified = action.payload;
    },
    setCheckedRulesInRuleTable(state, action) {
      state.checkedRulesInRuleTable = action.payload;
    },
    setIsRuleedited(state, action) {
      state.isRuleEdited = action.payload;
    },
    resetAllRuleState(state) {
      return initialState;
    },
  },
});
export const {
  DeleteClicked,
  resetAllRuleState,
  setIsRuleedited,
  fetchRulesStart,
  fetchAllRulesStart,
  fetchRulesSuccess,
  fetchRulesFailure,
  setSelectedRule,
  clearSelectedRule,
  fetchRuleSuccessWithInitCheckedRulesReduxState,
  setIsSwitchButtonPositionLeft,
  updatedSelectedAndActiveRules,
  updateSelectedRule,
  updateSelectedRuleName,
  setIsRuleModified,
  setCheckedRulesInRuleTable,
} = ruleTableSlice.actions;
export default ruleTableSlice.reducer;
