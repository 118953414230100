import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function FileIconUploadSVG(props) {
  return (
    <SvgIcon className="fileUploadIconSVG_Upload">
      <svg width={props.iconWidth} height={props.iconHeight} viewBox={props.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 43V11C0.5 9.067 2.067 7.5 4 7.5H17.9C18.7987 7.5 19.663 7.84571 20.3138 8.46552L28.4138 16.1798C29.1074 16.8404 29.5 17.7564 29.5 18.7143V43C29.5 44.933 27.933 46.5 26 46.5H4C2.067 46.5 0.5 44.933 0.5 43Z"
          fill="white"
          stroke="#E5E9EB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 21C9 20.4696 9.21071 19.9609 9.58579 19.5858C9.96086 19.2107 10.4696 19 11 19H15.586C16.1164 19.0001 16.625 19.2109 17 19.586L20.414 23C20.7891 23.375 20.9999 23.8836 21 24.414V33C21 33.5304 20.7893 34.0391 20.4142 34.4142C20.0391 34.7893 19.5304 35 19 35H11C10.4696 35 9.96086 34.7893 9.58579 34.4142C9.21071 34.0391 9 33.5304 9 33V21ZM11 27C11 26.7348 11.1054 26.4804 11.2929 26.2929C11.4804 26.1054 11.7348 26 12 26H18C18.2652 26 18.5196 26.1054 18.7071 26.2929C18.8946 26.4804 19 26.7348 19 27C19 27.2652 18.8946 27.5196 18.7071 27.7071C18.5196 27.8946 18.2652 28 18 28H12C11.7348 28 11.4804 27.8946 11.2929 27.7071C11.1054 27.5196 11 27.2652 11 27ZM12 30C11.7348 30 11.4804 30.1054 11.2929 30.2929C11.1054 30.4804 11 30.7348 11 31C11 31.2652 11.1054 31.5196 11.2929 31.7071C11.4804 31.8946 11.7348 32 12 32H18C18.2652 32 18.5196 31.8946 18.7071 31.7071C18.8946 31.5196 19 31.2652 19 31C19 30.7348 18.8946 30.4804 18.7071 30.2929C18.5196 30.1054 18.2652 30 18 30H12Z"
          fill="#646DF3"
        />
      </svg>
    </SvgIcon>
  );
}
