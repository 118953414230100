import React from "react";

import GeneralModal from "../GeneralModal/GeneralModal";
import "./AccessDeniedModal.css";
import ButtonComponent from "../../Button/ButtonComponent";
import InformationIcon from "../../../assets/images/RedInformationIcon.svg";
import LockIcon from "../../../assets/images/LockIcon.svg";
import CloseCircularIcon from "../../../assets/images/cross_icon.svg";

const AccessDeniedModal = ({ open, userEmail, resetOpen }) => {
  function handleOnClose() {
    resetOpen();
  }

  return (
    <GeneralModal
      open={open}
      onClose={handleOnClose}
      modalBody={<AccessDeniedModalBody userEmail={userEmail} onClose={handleOnClose} />}
      className={"access-denied-modal-body"}
    ></GeneralModal>
  );
};

const AccessDeniedModalBody = ({ userEmail, onClose }) => (
  <div className="access-denied-modal-container">
    <div onClick={onClose} className="access-denied-modal__close-top-icon">
      <img src={CloseCircularIcon} alt="" />
    </div>
    <div className="access-denied-modal__icon">
      <img src={LockIcon} alt="" />
    </div>
    <div className="access-denied-modal__denied-message">
      <img src={InformationIcon} className="access-denied-modal__error-icon" />
      Access Denied
    </div>
    <div className="access-denied-modal__label-message">
      This project is currently in use by <span className="access-denied-modal__label-email">"{userEmail}".</span>
    </div>

    <ButtonComponent onClick={onClose} variant="contained" btnType="primary" text="Back to projects" className="btnPrimary access-denied-modal__back-btn" />
  </div>
);

export default AccessDeniedModal;
