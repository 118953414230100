import { Box, Button, Modal } from "@mui/material";
import React from "react";

import CloseIconSvg from "../../../assets/images/CloseIconSVG";
import "./WarningModal.css";

const WarningModal = ({ openModal, setOpenModal, headerText, titleText }) => (
  <Modal
    open={openModal}
    onClose={() => setOpenModal(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="No_option_selected"
  >
    <Box className="fileUploadModal assignUserModal">
      <div className="file_header">
        <div className="attach">{headerText}</div>
        <Button onClick={() => setOpenModal(false)} className="closeIconBtn mx-2">
          <CloseIconSvg fillColor="#ffffff" />
        </Button>
      </div>
      <div className="w-100 d-flex flex-column align-items-center justify-content-center p-4">
        <div className="contentBg">
          <h4 className="contentTitle">{titleText}</h4>
        </div>
      </div>
    </Box>
  </Modal>
);

export default WarningModal;
