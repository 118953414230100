import React, { useEffect, useState } from "react";

import TabComponent from "../../components/Tabs/Tab/TabComponent";

import "./DesignQc.css";
import { useDispatch, useSelector } from "react-redux";

import { openSnackbar } from "../../features/snackbar/snackbarslice";
import { setDesignQcHorizontalSelectedTab, setDesignQcSelectedTab } from "../../features/designQcRule/designQc/DesignQcSlice";

import { useNavigate } from "react-router-dom";

import { getRuleExecutionStart } from "../../features/designQcRule/ruleExecution/ruleExecutionSlice";
import Group from "../../assets/images/Group.svg";
import Pie from "../../assets/images/pie.svg";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { setPreviewIsNextTriggered } from "../../features/designQcRule/previewWeights/previewWeightsSlice";
import { setIsPatternRecognitionNextTriggered } from "../../features/designQcRule/PatternRecognition/PatternRecognitionSlice";
import ViewDataLabels from "../ViewDataLabels/ViewDataLabels";
import { getUserDataStart } from "../../features/currentUser/currentUserSlice";
import { ROUTES, RULE_TYPE, SNACKBAR_TYPES } from "../../assets/constants/constant";
import Eyeunselect from "../../assets/images/vertical tab selected/eyeun.svg";
import patternicon from "../../assets/images/vertical tab selected/pattern.svg";
import build from "../../assets/images/vertical tab selected/build.svg";
import adverseicon from "../../assets/images/adverseicon.svg";
import buildun from "../../assets/images/vertical tab unselected/buildun.svg";
import patternun from "../../assets/images/vertical tab unselected/patternun.svg";
import eye from "../../assets/images/vertical tab unselected/eye.svg";
import { setIsOpentextNextTriggered } from "../../features/adverseEvent/AdverseRule/AdverseRule";
import { getAdverseRuleExecutionStart } from "../../features/adverseEvent/AdverseRuleExecution/AdverseRuleExecutionSlice.js";
import { fetchRulesSuccess, setIsSwitchButtonPositionLeft } from "../../features/designQcRule/Rule/ruleSlice";
import { resetAllAdverseTextRules } from "../../features/adverseEvent/RuleBuilder/RuleBuilder.js";
import { resetAllTextRules, startSaveRulesAndNext } from "../../features/designQcRule/BuildRule/BuildRuleslice.js";

import BuildQCRule from "./JockerAnalysis/BuildQCRule/BuildQCRule";
import Preview from "./JockerAnalysis/Preview/PreviewScreen";
import PatternRecognition from "./JockerAnalysis/PatternRecognition/PatternRecognition";
import OpenText from "./AdverseEvents/OpenText/OpenText";
import { ADVERSE_EVENTS_TABS, DESIGN_QC_HORIZONTAL_TABS, DESIGN_QC_TABS } from "./DesignQc.constants";
import RuleBuilder from "./AdverseEvents/RuleBuilder/RuleBuilder";

function DesignQc() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [previousTab, setPreviousTab] = useState(null);
  const [tabHistory, setTabHistory] = useState([]);
  const [selectedVerticalTabIndex, setSelectedVerticalTabIndex] = useState(0);
  const selectedTab = useSelector((state) => state?.designQc?.selectedTab) ?? "";
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab) ?? "";
  const selected = useSelector((state) => state?.rules?.isSwitchButtonPositionLeft);

  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);
  const username = useSelector((state) => state?.user?.userInfo?.email) ?? "";
  const tryme = useSelector((state) => state?.rules?.isSwitchButtonPositionLeft);
  const dataMapFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);

  const dataMapFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.id);
  const rawFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeRawDataFile?.name);
  const rawFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeRawDataFile?.id);
  const horizontalLabels = [
    DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD,
    DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS,
    DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS,
  ];
  const verticalLabels = [DESIGN_QC_TABS.BUILD_QC_RULE, DESIGN_QC_TABS.PATTERN_RECOGNITION, DESIGN_QC_TABS.PREVIEW];
  const verticalLabels2 = ["Build QC Rule", "Open Text", "Preview"];
  const rules = useSelector((state) => state?.rules?.rules);
  const isRuleBuilderTextEditorShowing = useSelector((state) => state?.rules?.isSwitchButtonPositionLeft);
  let NEXT_BTN_TEXT = "Next";

  const horizontalIcons = [
    { default: Pie, selected: Pie },
    { default: Group, selected: Group },
    { default: adverseicon, selected: adverseicon },
  ];
  const verticalIcons = [
    { default: buildun, selected: build },
    { default: patternun, selected: patternicon },
    { default: eye, selected: Eyeunselect },
  ];

  useEffect(() => {
    if (projectId === undefined || projectId === null) {
      navigate(ROUTES.HOME_PAGE);
    }

    if (username === undefined || username == "" || username === null) {
      dispatch(getUserDataStart());
    }
    handleHorizontalTabChange(DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD);
  }, []);

  useEffect(() => {
    setTabHistory((prevHistory) => [...prevHistory, selectedTab]);
  }, [selectedTab]);

  const handleTabChange = (newValue) => {
    if (newValue !== selectedTab) {
      setPreviousTab(selectedTab);
      dispatch(setDesignQcSelectedTab(newValue));
      dispatch(resetAllTextRules());
      dispatch(resetAllAdverseTextRules());
    }
  };
  const handleHorizontalTabChange = (newValue) => {
    dispatch(setIsSwitchButtonPositionLeft(true));
    dispatch(setDesignQcSelectedTab(DESIGN_QC_TABS.BUILD_QC_RULE));
    dispatch(setDesignQcHorizontalSelectedTab(newValue));
    dispatch(resetAllTextRules());
    dispatch(resetAllAdverseTextRules());
  };

  const renderHorizontalTabContent = () => {
    if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD) {
      return <ViewDataLabels />;
    } else if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS) {
      let tabContentComp = null;
      if (selectedTab === ADVERSE_EVENTS_TABS.ADVERSE_BUILD_QC_RULE) {
        return (tabContentComp = <RuleBuilder />);
      } else if (selectedTab === ADVERSE_EVENTS_TABS.ADVERSE_OPEN_TEXT) {
        return (tabContentComp = <OpenText />);
      } else if (selectedTab === ADVERSE_EVENTS_TABS.ADVERSE_PREVIEW) {
        return (tabContentComp = <Preview />);
      }
    } else if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS) {
      let tabContentComp = null;
      if (selectedTab === DESIGN_QC_TABS.BUILD_QC_RULE) {
        return (tabContentComp = <BuildQCRule />);
      } else if (selectedTab === DESIGN_QC_TABS.PATTERN_RECOGNITION) {
        return (tabContentComp = <PatternRecognition />);
      } else if (selectedTab === DESIGN_QC_TABS.PREVIEW) {
        return (tabContentComp = <Preview />);
      }
    }
  };

  const renderVerticalTabContent = () => {
    if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD) {
      return null;
    } else if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS) {
      return <TabComponent orientation="vertical" labels={verticalLabels2} icons={verticalIcons} onTabChange={handleTabChange} selectedTab={selectedTab} />;
    } else {
      return <TabComponent orientation="vertical" labels={verticalLabels} icons={verticalIcons} onTabChange={handleTabChange} selectedTab={selectedTab} />;
    }
  };

  const handleNextButtonClick = async () => {
    try {
      if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD) {
        handleHorizontalTabChange(DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS);
      } else if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS) {
        if (selectedTab === DESIGN_QC_TABS.BUILD_QC_RULE) {
          if (isRuleBuilderTextEditorShowing) {
            dispatch(startSaveRulesAndNext({ ruleType: RULE_TYPE.JOKER_ANALYSIS }));
          } else {
            dispatch(
              getRuleExecutionStart({
                projectId: projectId,
                questionFileId: dataMapFileId,
                respondentFileId: rawFileId,
                username: username,
              })
            );
            dispatch(resetAllTextRules());
            dispatch(resetAllAdverseTextRules());
          }
        } else if (selectedTab === DESIGN_QC_TABS.PATTERN_RECOGNITION) {
          dispatch(setIsPatternRecognitionNextTriggered());
          dispatch(resetAllTextRules());
          dispatch(resetAllAdverseTextRules());
        } else if (selectedTab === DESIGN_QC_TABS.PREVIEW) {
          dispatch(setPreviewIsNextTriggered());
          dispatch(resetAllTextRules());
          dispatch(resetAllAdverseTextRules());
        }
      } else if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS) {
        if (selectedTab === ADVERSE_EVENTS_TABS.ADVERSE_BUILD_QC_RULE) {
          if (isRuleBuilderTextEditorShowing) {
            dispatch(startSaveRulesAndNext({ ruleType: RULE_TYPE.ADVERSE_EVENT }));
          } else {
            dispatch(
              getAdverseRuleExecutionStart({
                projectId: projectId,
                questionFileId: dataMapFileId,
                respondentFileId: rawFileId,
                username: username,
              })
            );
          }
        } else if (selectedTab === ADVERSE_EVENTS_TABS.ADVERSE_OPEN_TEXT) {
          dispatch(setIsOpentextNextTriggered());
        }
        // handleHorizontalTabChange(DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS);
        // navigate("/create-project/preview-qc-joker-score/3");
      }
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
    }
  };

  const handleBackButtonClick = () => {
    if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD) {
      // empty rule array
      dispatch(fetchRulesSuccess([]));
      let redirectString = "/create-project/project-details/0";
      if (projectId) redirectString += `?projectId=${projectId}`;
      navigate(redirectString);
    } else if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS) {
      if (selectedTab === DESIGN_QC_TABS.BUILD_QC_RULE) {
        handleHorizontalTabChange(DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD);
      }

      if (tabHistory.length === 1) {
        return;
      }

      const updatedHistory = [...tabHistory];

      updatedHistory.pop();
      const previousTab = updatedHistory.pop();

      dispatch(setDesignQcSelectedTab(previousTab));
      setTabHistory(updatedHistory);
    } else if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS) {
      // handleHorizontalTabChange(DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS);
      if (selectedTab === ADVERSE_EVENTS_TABS.ADVERSE_BUILD_QC_RULE) {
        handleHorizontalTabChange(DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS);
      } else if (selectedTab === ADVERSE_EVENTS_TABS.ADVERSE_OPEN_TEXT) {
        dispatch(setDesignQcSelectedTab(ADVERSE_EVENTS_TABS.ADVERSE_BUILD_QC_RULE));
      } else if (selectedTab === ADVERSE_EVENTS_TABS.ADVERSE_PREVIEW) {
        dispatch(setDesignQcSelectedTab(ADVERSE_EVENTS_TABS.ADVERSE_OPEN_TEXT));
      }
    }
    if (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS && selectedTab === DESIGN_QC_TABS.PREVIEW) {
      dispatch(setDesignQcSelectedTab(DESIGN_QC_TABS.PATTERN_RECOGNITION));
    }
  };

  useEffect(() => {
    dispatch(resetAllTextRules());
    dispatch(resetAllAdverseTextRules());
  }, []);

  if (
    (selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS || selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.JOKER_ANALYSIS) &&
    selectedTab === DESIGN_QC_TABS.BUILD_QC_RULE
  ) {
    if (isRuleBuilderTextEditorShowing) {
      NEXT_BTN_TEXT = "Save & Next";
    } else {
      NEXT_BTN_TEXT = "Apply & Next";
    }
  }

  return (
    <>
      <div
        className="DesignQc child-container-designQC"
        style={{
          height: selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD ? "auto" : "",
        }}
      >
        <form
          onSubmit={(e) => e.preventDefault()}
          className="external-box container-fluid"
          style={{
            height: selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD ? "auto" : "",
          }}
        >
          <div className={`carddesign w-100 ${selectedHorizontalTab !== DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD ? "form-content" : ""}`}>
            <div className="tab">
              <TabComponent
                orientation="horizontal"
                labels={horizontalLabels}
                icons={horizontalIcons}
                onTabChange={handleHorizontalTabChange}
                selectedHorizontalTab={selectedHorizontalTab}
              />
            </div>

            <div className={`${selectedHorizontalTab !== DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD ? "maincontainer" : "maincontainer-dashboard"} `}>
              {selectedHorizontalTab !== DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD && (
                <div className="leftsidecontainer">{renderVerticalTabContent()}</div>
              )}
              <div
                className={selectedHorizontalTab !== DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD ? "container-keyword" : "container2"}
                style={{
                  overflow: selectedHorizontalTab !== DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD ? "" : "initial",
                  height: selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.BASIC_STATS_DASHBOARD ? "auto" : "",
                }}
              >
                {renderHorizontalTabContent()}
              </div>
            </div>
          </div>
        </form>
        <div className="d-flex w-100  gap-2 justify-content-end" style={{ paddingRight: "30px", paddingTop: "24px" }}>
          <ButtonComponent onClick={handleBackButtonClick} variant="outlined" btnType="primary" text="Back" className="btnPrimaryOutline btnDisabled" />
          <ButtonComponent
            onClick={handleNextButtonClick}
            variant="contained"
            btnType="primary"
            text={NEXT_BTN_TEXT}
            disabled={selectedHorizontalTab === DESIGN_QC_HORIZONTAL_TABS.ADVERSE_EVENTS && selectedTab === ADVERSE_EVENTS_TABS.ADVERSE_PREVIEW}
            className="btnPrimary"
          />
        </div>
      </div>
    </>
  );
}

export default DesignQc;
