import { call, put, select, takeEvery } from "redux-saga/effects";

import { closeLoader, openLoader } from "../../loader/loaderSlice";
import { openSnackbar } from "../../snackbar/snackbarslice";
import { handleAdverseActiveRulesUpdates } from "../AdverseRule/AdverseRuleSaga";
import { ruleExecutionApi } from "../../../services/ruleExecutionService";
import { setDesignQcSelectedTab } from "../../designQcRule/designQc/DesignQcSlice";
import { ADVERSE_EVENTS_TABS } from "../../../pages/DesignQC/DesignQc.constants";
import { GIBBERISH_ERROR_CODE, SNACKBAR_TYPES } from "../../../assets/constants/constant";

import { getAdverseRuleExecutionStart, getAdverseRuleExecutionSuccess } from "./AdverseRuleExecutionSlice";

function* getAdverseRuleExecutionStartSaga(action) {
  yield put(openLoader());

  let redirectTab = action?.payload?.redirectTab ?? true;
  const previewCalculateAgain = action?.payload?.previewCalculateAgain ?? true;
  const Adverserule = (state) => state?.adverseRule?.adverserules;
  const checkedRulesInRuleTable = (state) => state?.adverseRule?.adversecheckedRulesInRuleTable;

  try {
    const rules = yield select(Adverserule);
    try {
      yield* handleAdverseActiveRulesUpdates();
    } catch (error) {
      yield put(openSnackbar({ message: "Rule execution failed!", type: SNACKBAR_TYPES.ERROR }));
      return;
    }
    yield put(openLoader());
    const selectedRulesIds = yield select(checkedRulesInRuleTable);
    const mappedData = {
      projectId: action?.payload?.projectId,
      questionFileId: action?.payload?.questionFileId,
      respondentFileId: action?.payload?.respondentFileId,
      username: action?.payload?.username,
      ruleType: "ADVERSE_EVENT",
    };

    if (rules.length > 0 && selectedRulesIds.length > 0) {
      const { data } = yield call(() =>
        ruleExecutionApi(mappedData.questionFileId, mappedData.respondentFileId, mappedData.projectId, mappedData.username, mappedData.ruleType)
      );
      yield put(getAdverseRuleExecutionSuccess(data));
      yield put(openSnackbar({ message: "Rule execution completed!", type: SNACKBAR_TYPES.SUCCESS }));
    }

    if (redirectTab) yield put(setDesignQcSelectedTab(ADVERSE_EVENTS_TABS.ADVERSE_OPEN_TEXT));
    // if (previewCalculateAgain) yield put(setPreviewCalculateAgain());
  } catch (error) {
    // Extract error message and errorCode from the API response
    const errorMessage = error?.data?.[0] || error?.message || "Something went wrong!";
    const errorCode = error?.errorCode; // Extract errorCode from the error object

    // Check errorCode and toggle redirectTab accordingly
    if (errorCode) {
      switch (errorCode) {
        case GIBBERISH_ERROR_CODE:
          redirectTab = false;
          yield put(openSnackbar({ message: errorMessage ?? "Rule execution failed!", type: SNACKBAR_TYPES.WARNING }));
          break;
        default:
          redirectTab = true;
          yield put(openSnackbar({ message: errorMessage ?? "Rule execution failed!", type: SNACKBAR_TYPES.ERROR }));
          break;
      }
    }

    if (redirectTab) yield put(setDesignQcSelectedTab(ADVERSE_EVENTS_TABS.ADVERSE_OPEN_TEXT));
    //  if (previewCalculateAgain) yield put(setPreviewCalculateAgain());
    // yield put(setDesignQcSelectedTab(DESIGN_QC_TABS.PATTERN_RECOGNITION));
  } finally {
    yield put(closeLoader());
  }
}

export function* watchAdverseRuleExecutionSaga() {
  yield takeEvery(getAdverseRuleExecutionStart, getAdverseRuleExecutionStartSaga);
}
