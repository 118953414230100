import { call, takeEvery } from "redux-saga/effects";

import { RULE_TYPE } from "../../../assets/constants/constant";
import { saveRulesSagaFunction } from "../../designQcRule/BuildRule/BuildRuleSaga";

import { startSaveAdverseRules } from "./RuleBuilder";

function* startSaveAdverseRulesSaga(action) {
  try {
    yield call(() => saveRulesSagaFunction(RULE_TYPE.ADVERSE_EVENT));
  } catch (error) {
    console.error({ error });
  }
}

export function* watchRuleBuilderSaga() {
  yield takeEvery(startSaveAdverseRules, startSaveAdverseRulesSaga);
}
