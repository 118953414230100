import MuiTooltip from "@mui/material/Tooltip";
import React from "react";

export default function CustomTooltip(props) {
  const defaultStyle = {
    tooltip: {
      sx: {
        backgroundColor: "#F8F7F7",
        color: "#25282B",
        boxShadow: "0px 6px 18px 0px #97979740",
        border: "1px solid #CDCDCD",
        borderRadius: "4px",
        fontSize: "12px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        lineHeight: "22px",
        maxHeight: "300px",
        overflowY: "auto",
        font: "Montserrat !important",
      },
    },
  };

  return <MuiTooltip componentsProps={defaultStyle} {...props} />;
}
