import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./GeneralModal.css";
function GeneralModal({ open, onClose, modalBody, className }) {
  const mergedClassName = `generic-modal-boxStyleClass ${className}`;

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={mergedClassName}>{modalBody}</Box>
    </Modal>
  );
}

export default GeneralModal;
