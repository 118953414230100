import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import { FaPlus, FaRegTrashCan } from "react-icons/fa6";
import { MdOutlineDescription } from "react-icons/md";
import { PiArchiveBold } from "react-icons/pi";

import "./Home.css";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import usePagination from "@mui/material/usePagination";
import { BsQuestionLg } from "react-icons/bs";

import {
  PROJECT_TYPE,
  SNACKBAR_TYPES,
  confirmationArchieveTitle,
  confirmationArchievedDescription,
  confirmationDeleteTitle,
  confirmationDeletedDescription,
  successArchievedMessage,
  successDeletedMessage,
} from "../../assets/constants/constant";
import { resetCreateProjectState } from "../../features/createProject/createProductSlice";
import { openSnackbar } from "../../features/snackbar/snackbarslice";
import ConfirmationModal from "../../components/modals/ConfirmationModal/ConfirmationModal";
import ViewProjectTable from "../../components/Tables/ViewProjectTable/ViewProjectTable";
import TextField from "../../components/TextField/TextField";
import { archivedProjects, deleteProjects, filterProjects } from "../../services/projectService";
import { getArchivedprojectStart, getArchivedprojectSuccess, getProjectsStart, getProjectsSuccess } from "../../features/product/projectSlice";
import { closeLoader, openLoader } from "../../features/loader/loaderSlice";
import ButtonComponent from "../../components/Button/ButtonComponent";

function Home() {
  const { product } = useSelector((state) => state);
  const [activeTab, setActiveTab] = useState("projects");
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverArchive, setIsHoverArchive] = useState(false);
  const [multipleDeleteModal, setMultipleDeleteModal] = useState(false);
  const [multipleArchivedmodal, setMultipleArchivedModal] = useState(false);
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageValue, setPageValue] = useState("");
  const [search, setSearch] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);

  // pagination logic
  const count = activeTab === "projects" ? Math.ceil(product.data.count / 10) : Math.ceil(product.archived.count / 10);
  const { items } = usePagination({
    count: count,
    onChange: (e, page) => {
      setSelectedPage(page);
      handlePagination(page);
    },
  });
  // End of pagination logic

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              cursor: "not-allowed",
              PointerEvent: "none",
            },
          },
        },
      },
    },
  });

  //  Actions to fetch all projects and archived projects from the server.
  const getAllProjects = () => {
    dispatch(getProjectsStart(1));
    dispatch(getArchivedprojectStart(1));
  };

  // Filters the projects array based on the provided value.
  const fetchSearchProjects = async (value, active) => {
    try {
      dispatch(openLoader());
      const { data } = await filterProjects(value, active);
      dispatch(getProjectsSuccess({ results: data?.results, ...data }));
      dispatch(closeLoader());
    } catch (error) {
      dispatch(closeLoader());
    }
  };

  // Archived the selected project
  const fetchSearchArchived = async (value, active) => {
    try {
      dispatch(openLoader());
      const { data } = await filterProjects(value, active);
      dispatch(getArchivedprojectSuccess({ results: data?.results, ...data }));
      dispatch(closeLoader());
    } catch (error) {
      dispatch(closeLoader());
    }
  };

  const handlePagination = (page) => {
    switch (activeTab) {
      case "projects":
        return dispatch(getProjectsStart(page));
      case "archived":
        return dispatch(getArchivedprojectStart(page));
      default:
    }
  };

  // TODO: Implement debounce and check the search functionality for the My Projects (if string not matches)

  // Calls the `getAllProjects` function when the component mounts to fetch all projects and archived projects from the server.
  useEffect(() => {
    getAllProjects();
  }, []);

  // Search filter function
  const searchFilterprojects = (value) => {
    setSearch(value);
    if (value) {
      switch (activeTab) {
        case "projects":
          return fetchSearchProjects(value, true);
        case "archived":
          return fetchSearchArchived(value, false);
        default:
      }
    } else {
      getAllProjects();
    }
  };

  // Multi select archived projects
  const multipleArchivedProjects = async () => {
    if (selectedRow?.length > 0) {
      try {
        dispatch(openLoader());
        const { data } = await archivedProjects(selectedRow);
        dispatch(closeLoader());
        dispatch(
          openSnackbar({
            message: successArchievedMessage.value,
            type: SNACKBAR_TYPES.SUCCESS,
          })
        );
        getAllProjects();
        handleClose();
        setMultipleArchivedModal(false);
      } catch (error) {
        dispatch(closeLoader());
        dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
        handleClose();
        setMultipleArchivedModal(false);
      }
    }
  };

  // Multi select and delete from Archive project lists
  const multipleDeleteProjects = async () => {
    if (selectedRow?.length > 0) {
      try {
        dispatch(openLoader());
        const { data } = await deleteProjects(selectedRow);
        dispatch(closeLoader());
        dispatch(
          openSnackbar({
            message: successDeletedMessage.value,
            type: SNACKBAR_TYPES.SUCCESS,
          })
        );
        getAllProjects();
        handleClose();
        setMultipleDeleteModal(false);
      } catch (error) {
        dispatch(closeLoader());
        dispatch(openSnackbar({ message: error.message, type: SNACKBAR_TYPES.ERROR }));
        handleClose();
        setMultipleDeleteModal(false);
      }
    }
  };

  // Sets the `anchorEl` state to `null` to close the menu.
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {product?.data?.count <= 0 && product?.archived?.count <= 0 && search === "" ? (
        <div className="container-fluid mx-auto screenToHeight">
          <div className="show-project">
            <div className="header-title">
              <h4>
                My projects <span className="text-secondary">(0)</span>
              </h4>
              <p>View your saved projects or create new project </p>
            </div>
            <div className="project-container">
              <div className="no-project">
                <div className="project-image">
                  <ul className="loading-project">
                    <li className="list-items">
                      <span className="q-icon">
                        <BsQuestionLg color="#fff" />
                      </span>
                      <div className="anim-line">
                        <span className="row-line-sm"></span>
                        <span className="row-line-lg"></span>
                      </div>
                    </li>
                    <li className="list-items">
                      <span className="q-icon">
                        <BsQuestionLg color="#fff" />
                      </span>
                      <div className="anim-line">
                        <span className="row-line-sm"></span>
                        <span className="row-line-lg"></span>
                      </div>
                    </li>
                    <li className="list-items">
                      <span className="q-icon">
                        <BsQuestionLg color="#fff" />
                      </span>
                      <div className="anim-line">
                        <span className="row-line-sm"></span>
                        <span className="row-line-lg"></span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="no-proj-info">
                  <h4 className="my-3">No saved projects</h4>
                  <p className="mb-3">Click on create project button to add a new project </p>
                  <NavLink to="/create-project/project-details/0" onClick={() => dispatch(resetCreateProjectState())}>
                    <Button className="pr-3 pl-0 gap-2 btnPrimary" variant="contained" color="primary">
                      <FaPlus fontSize="small" className="addIconSize" color="#ffffff" />
                      Create Project
                    </Button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid mx-auto screenToHeight">
          <div className="d-flex justify-content-xl-between align-items-xl-center align-items-md-start flex-md-column flex-xl-row justify-content-md-start gap-md-3 pb-3">
            <div className="d-flex gap-10">
              <Button
                onClick={() => {
                  setPageValue("");
                  setActiveTab("projects");
                  setSelectedPage(0);
                }}
                className={`d-flex align-items-center justify-content-between ${activeTab === "projects" ? "btnSecondary" : "btnSecondaryActive"}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                variant="contained"
                color="secondary"
                disableRipple
                style={{
                  backgroundColor: `${activeTab === "projects" ? "#ffffff" : isHovered ? "#ffffff" : "#eeeef2"}`,
                }}
              >
                <div className="d-flex align-items-center gap-2">
                  <span
                    className="iconBgCircle"
                    style={{
                      backgroundColor: `${activeTab === "projects" ? "#ffffff" : isHovered ? "#ffffff" : "#eeeef2"}`,
                    }}
                  >
                    <MdOutlineDescription
                      color={`${activeTab === "projects" ? "#00CA9F" : isHovered ? "#00CA9F" : "#25282B"}`}
                      style={{
                        backgroundColor: `${activeTab === "projects" ? "#ffffff" : isHovered ? "#ffffff" : "#eeeef2"}`,
                      }}
                    />
                  </span>
                  <span
                    className="btnTextSize"
                    style={{
                      fontFamily: `${activeTab === "projects" ? "Montserrat-Bold" : isHovered ? "Montserrat-Medium" : "Montserrat-Medium"}`,
                      fontWeight: `${activeTab === "projects" ? "700" : isHovered ? "500" : "500"}`,
                    }}
                  >
                    My projects
                  </span>
                </div>
                <span className="ms-2 btnCountWidth">{product?.data?.count}</span>{" "}
              </Button>
              <Button
                onClick={() => {
                  setPageValue("");
                  setActiveTab("archived");
                  setSelectedPage(0);
                }}
                className={`d-flex align-items-center justify-content-between ${activeTab === "archived" ? "btnSecondary" : "btnSecondaryActive"}`}
                variant="outlined"
                onMouseEnter={() => setIsHoverArchive(true)}
                onMouseLeave={() => setIsHoverArchive(false)}
                disableRipple
                style={{
                  backgroundColor: `${activeTab === "projects" ? "#ffffff" : isHoverArchive ? "#fff" : "#eeeef2"}`,
                }}
              >
                <div className="d-flex align-items-center gap-2">
                  <span
                    className="iconBgCircle"
                    style={{
                      backgroundColor: `${activeTab === "projects" ? "#eeeef2" : isHoverArchive ? "#ffffff" : "#ffffff"}`,
                    }}
                  >
                    <PiArchiveBold color={`${activeTab === "projects" ? (isHoverArchive ? "#00CA9F" : "#25282B") : isHoverArchive ? "#00CA9F" : "#00CA9F"}`} />
                  </span>
                  <span
                    className="btnTextSize"
                    style={{
                      fontFamily: `${activeTab === "projects" ? "Montserrat-Medium" : isHoverArchive ? "Montserrat-Bold" : "Montserrat-Bold"}`,
                      fontWeight: `${activeTab === "projects" ? "500" : isHoverArchive ? "700" : "700"}`,
                    }}
                  >
                    Archive
                  </span>
                </div>
                <span className="ms-2 btnCountWidth">{product?.archived?.count}</span>{" "}
              </Button>
            </div>

            <div className="d-flex gap-2">
              <TextField
                type={"text"}
                className={"text-search"}
                placeholder={"Search"}
                value={search}
                name="client_name"
                autoComplete="off"
                showSearchIcon={true}
                onChange={(e) => searchFilterprojects(e.target.value)}
              />

              {activeTab === "projects" ? (
                <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title="Archive Projects" arrow placement="left-start">
                  <span>
                    <Button
                      className={selectedRow <= 0 ? "btnDanger d-flex gap-2 cursor-notallowed" : "btnDanger cursor-pointer d-flex gap-2"}
                      disableRipple
                      color="error"
                      onClick={() => setMultipleArchivedModal(true)}
                      disabled={selectedRow <= 0}
                      sx={{
                        "&.Mui-disabled": {
                          pointerEvents: "unset",
                          cursor: "not-allowed",
                        },
                      }}
                    >
                      <span>
                        <PiArchiveBold color={selectedRow <= 0 ? "#A1AEBE" : "#ef4444"} />{" "}
                      </span>
                      <span className={selectedRow <= 0 ? "pointer-events-none" : ""}>Archive Projects</span>
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title="Delete Projects" arrow placement="left-start">
                  <span>
                    <Button
                      className={selectedRow <= 0 ? "btnDanger d-flex gap-2 cursor-notallowed" : "btnDanger cursor-pointer d-flex gap-2"}
                      disableRipple
                      variant="contained"
                      color="error"
                      onClick={() => setMultipleDeleteModal(true)}
                      disabled={selectedRow <= 0}
                      sx={{
                        "&.Mui-disabled": {
                          pointerEvents: "unset",
                          cursor: "not-allowed",
                        },
                      }}
                    >
                      <span className="p-0">
                        <FaRegTrashCan color={selectedRow <= 0 ? "#A1AEBE" : "#ef4444"} />
                      </span>{" "}
                      <span className={selectedRow <= 0 ? "pointer-events-none" : ""}>Delete Projects</span>
                    </Button>
                  </span>
                </Tooltip>
              )}

              <NavLink to="/create-project/project-details/0" onClick={() => dispatch(resetCreateProjectState())}>
                <ButtonComponent
                  variant="contained"
                  btnType="primary"
                  text="Create Project"
                  className="btnPrimary"
                  children={<FaPlus fontSize="small" className="addIconSize" color="#ffffff" />}
                />
              </NavLink>
            </div>
          </div>

          <ConfirmationModal
            openConfirmation={multipleDeleteModal}
            setOpenConfirmation={(e) => setMultipleDeleteModal(e)}
            onConfirm={multipleDeleteProjects}
            confirmationTitle={confirmationDeleteTitle}
            confirmationDescription={confirmationDeletedDescription}
          />
          <ConfirmationModal
            openConfirmation={multipleArchivedmodal}
            setOpenConfirmation={(e) => setMultipleArchivedModal(e)}
            onConfirm={multipleArchivedProjects}
            confirmationTitle={confirmationArchieveTitle}
            confirmationDescription={confirmationArchievedDescription}
          />
          <div className="container-fluid px-0">
            <ViewProjectTable
              data={activeTab === "projects" ? product.data : product.archived}
              projectType={activeTab === "projects" ? PROJECT_TYPE.PROJECT : PROJECT_TYPE.ARCHIVED}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              getAllProjects={getAllProjects}
              activeTab={activeTab}
              handleClose={handleClose}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              multipleDeleteProjects={multipleDeleteProjects}
              multipleArchivedProjects={multipleArchivedProjects}
              setMultipleDeleteModal={setMultipleDeleteModal}
              setMultipleArchivedModal={setMultipleArchivedModal}
              items={items}
              handlePagination={handlePagination}
              pageValue={pageValue}
              setPageValue={setPageValue}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
