import * as _ from "lodash";

export function s(questionId) {
  // [id] : {
  // included: false
  // }
}

const ALTERNATING_PATTERN_LABEL = "alternating_pattern_label";
const ALTERNATING_SCORE = "alternating_score";
const REPETITIVE_PATTERN_LABEL = "repetitive_pattern_label";
const REPETITIVE_SCORE = "repetitive_score";
const STEPPED_PATTERN_LABEL = "stepped_pattern_label";
const STEPPED_PATTERN_SCORE = "stepped_pattern_score";
const QUESTION_TYPE = "question_type";

export function alternatingPatternMapper(tableMappedData, alternatingPattern) {
  const clonedTableMapper = _.cloneDeep(tableMappedData);

  alternatingPattern?.forEach((detail) => {
    const respId = detail?.respid;

    if (!clonedTableMapper.hasOwnProperty(respId)) {
      clonedTableMapper[respId] = {
        [detail.question_id]: {
          [ALTERNATING_PATTERN_LABEL]: !!detail.label,
          [ALTERNATING_SCORE]: detail?.alter_pattern_score,
          [QUESTION_TYPE]: detail?.question_type,
          include: true,
        },
      };
    } else {
      clonedTableMapper[respId] = {
        ...clonedTableMapper[respId],
        [detail.question_id]: {
          ...clonedTableMapper[respId][detail.question_id],
          [ALTERNATING_PATTERN_LABEL]: !!detail.label,
          [ALTERNATING_SCORE]: detail?.alter_pattern_score,
          [QUESTION_TYPE]: detail?.question_type,
          include: true,
        },
      };
    }
  });

  return clonedTableMapper;
}

export function repetitivePatternMapper(clonedTableMapper, repetitivePattern) {
  //   const clonedTableMapper = _.cloneDeep(tableMappedData);

  repetitivePattern?.forEach((detail) => {
    const respId = detail?.respid;

    if (!clonedTableMapper.hasOwnProperty(respId)) {
      clonedTableMapper[respId] = {
        [detail.question_id]: {
          [REPETITIVE_PATTERN_LABEL]: !!detail.label,
          [REPETITIVE_SCORE]: detail?.repetitiveness_score,
          [QUESTION_TYPE]: detail?.question_type,
          include: true,
        },
      };
    } else {
      clonedTableMapper[respId] = {
        ...clonedTableMapper[respId],
        [detail.question_id]: {
          ...clonedTableMapper[respId][detail.question_id],
          [REPETITIVE_PATTERN_LABEL]: !!detail.label,
          [REPETITIVE_SCORE]: detail?.repetitiveness_score,
          [QUESTION_TYPE]: detail?.question_type,
          include: true,
        },
      };
    }
  });

  return clonedTableMapper;
}

export function steppedPatternMapper(tableMappedData, steppedPattern) {
  const clonedTableMapper = _.cloneDeep(tableMappedData);

  steppedPattern?.forEach((detail) => {
    const respId = detail?.respid;

    if (!clonedTableMapper.hasOwnProperty(respId)) {
      clonedTableMapper[respId] = {
        [detail.question_id]: {
          [STEPPED_PATTERN_LABEL]: !!detail.stepped_pattern_flag,
          [STEPPED_PATTERN_SCORE]: detail.stepped_pattern_score,
          [QUESTION_TYPE]: detail?.question_type,
          include: true,
        },
      };
    } else {
      clonedTableMapper[respId] = {
        ...clonedTableMapper[respId],
        [detail.question_id]: {
          ...clonedTableMapper[respId][detail.question_id],
          [STEPPED_PATTERN_LABEL]: !!detail.stepped_pattern_flag,
          [STEPPED_PATTERN_SCORE]: detail.stepped_pattern_score,
          [QUESTION_TYPE]: detail?.question_type,
          include: true,
        },
      };
    }
  });

  return clonedTableMapper;
}

export function filterRedundantRespondentsWithQuestionId(tableMappedData) {
  const updatedTableMappedData = {};

  Object.keys(tableMappedData)?.forEach((respondentId) => {
    const questionObjArray = tableMappedData[respondentId];

    const finalQuestionObject = {};

    Object.keys(questionObjArray)?.forEach((questionId) => {
      const questionObj = questionObjArray[questionId];

      if (questionObj[ALTERNATING_PATTERN_LABEL] === true || questionObj[REPETITIVE_PATTERN_LABEL] === true || questionObj[STEPPED_PATTERN_LABEL] === true) {
        finalQuestionObject[questionId] = { ...questionObj };
      }
    });

    if (finalQuestionObject && Object.keys(finalQuestionObject).length > 0) {
      updatedTableMappedData[respondentId] = finalQuestionObject;
    }
  });

  return updatedTableMappedData;
}

export function includeExcludeApiMapper(tableMappedData, mergedFilePath, projectID) {
  const finalTableMappedData = {};
  // for respondent ID and question ID
  const tempdata = {
    project_id: projectID,
    merge_file: mergedFilePath,
    input: {},
  };
  // Object.keys(tableMappedData).forEach((respondentId) => {
  //   const questionObjArray = tableMappedData[respondentId];
  //   tempdata[respondentId] = []
  //   Object.keys(questionObjArray).forEach((questionId) => {
  //     if (questionObjArray[questionId].include === true) {
  //       tempdata[respondentId].push(questionId)
  //     }
  //   })
  // })

  Object.keys(tableMappedData)?.forEach((respondentId) => {
    const questionObjArray = tableMappedData[respondentId];

    const reqQuestionObject = {};
    // for respondent ID and question ID
    tempdata.input[respondentId] = [];
    // for respondent ID and question ID
    Object.keys(questionObjArray)?.forEach((questionId) => {
      const questionObj = questionObjArray[questionId];

      if (questionObjArray[questionId].include === true) {
        const { include, ...filteredReqQuestionObject } = questionObjArray[questionId];
        reqQuestionObject[questionId] = filteredReqQuestionObject;
        // for respondent ID and question ID
        tempdata.input[respondentId].push(questionId);
        // for respondent ID and question ID
        if (questionObj[ALTERNATING_PATTERN_LABEL] !== undefined)
          reqQuestionObject[questionId][ALTERNATING_PATTERN_LABEL] = questionObj[ALTERNATING_PATTERN_LABEL] ? "True" : "False";
        if (questionObj[REPETITIVE_PATTERN_LABEL] !== undefined)
          reqQuestionObject[questionId][REPETITIVE_PATTERN_LABEL] = questionObj[REPETITIVE_PATTERN_LABEL] ? "True" : "False";
        if (questionObj[STEPPED_PATTERN_LABEL] !== undefined)
          reqQuestionObject[questionId][STEPPED_PATTERN_LABEL] = questionObj[STEPPED_PATTERN_LABEL] ? "True" : "False";
      }
    });

    if (reqQuestionObject && Object.keys(reqQuestionObject).length > 0) {
      finalTableMappedData[respondentId] = reqQuestionObject;
    }

    // for respondent ID and question ID
    Object.keys(tempdata.input)?.forEach((respondentId) => {
      if (tempdata.input[respondentId].length === 0) {
        delete tempdata.input[respondentId];
      }
    });
    // for respondent ID and question ID
  });

  return finalTableMappedData;
}

export function includeExcludeApiResponseMapper(scoreArray) {
  try {
    const RESPONDENT_ID = "respid";
    const REPET_SCORE = "repetitive_score";
    const REPETITIVE_SCORE = "final_repetitive_flag";
    const ALTER_SCORE = "alternative_score";
    const ALTERNATIVE_SCORE = "final_alternative_flag";
    const STEP_SCORE = "stepped_score";
    const STEPPED_SCORE = "final_stepped_flag";

    // {
    //   respid: '9827',
    //   alternative_score: 0.75,
    //   repetitive_score: 0.375,
    //   stepped_score: 1,
    //   genai_score: 0,
    //   final_alternative_flag: true,
    //   final_repetitive_flag: false,
    //   final_stepped_flag: true,
    //   final_genai_flag: false
    // },

    const finalScoreObject = {};

    // Object.keys(tableMappedData).forEach((respondentId) => {
    //   const questionObjArray = tableMappedData[respondentId];

    //   let reqQuestionObject = {};

    //   Object.keys(questionObjArray).forEach((questionId) => {
    //     const questionObj = questionObjArray[questionId];

    //     if (questionObjArray[questionId].include === true) {
    //       const { include, ...filteredReqQuestionObject } = questionObjArray[questionId];
    //       reqQuestionObject[questionId] = filteredReqQuestionObject;

    //       if (questionObj[ALTERNATING_PATTERN_LABEL] !== undefined) reqQuestionObject[questionId][ALTERNATING_PATTERN_LABEL] = questionObj[ALTERNATING_PATTERN_LABEL] ? "True" : "False";
    //       if (questionObj[REPETITIVE_PATTERN_LABEL] !== undefined) reqQuestionObject[questionId][REPETITIVE_PATTERN_LABEL] = questionObj[REPETITIVE_PATTERN_LABEL] ? "True" : "False";
    //       if (questionObj[STEPPED_PATTERN_LABEL] !== undefined) reqQuestionObject[questionId][STEPPED_PATTERN_LABEL] = questionObj[STEPPED_PATTERN_LABEL] ? "True" : "False";
    //     }
    //   });

    //   if (reqQuestionObject && Object.keys(reqQuestionObject).length > 0) {
    //     finalTableMappedData[respondentId] = reqQuestionObject;
    //   }
    // });

    if (!scoreArray) return {};

    for (const response of scoreArray) {
      finalScoreObject[parseInt(response[RESPONDENT_ID])] = {
        alternative_score: Number(response[ALTER_SCORE]).toFixed(2),
        alternating_pattern: response[ALTERNATIVE_SCORE] ? 100 : 0,
        stepped_score: Number(response[STEP_SCORE]).toFixed(2),
        stepped_pattern: response[STEPPED_SCORE] ? 100 : 0,
        repetitive_score: Number(response[REPET_SCORE]).toFixed(2),
        repetitive_pattern: response[REPETITIVE_SCORE] ? 100 : 0,
      };
    }

    return finalScoreObject;
  } catch (error) {
    // todo show snackbar
    return {};
  }
}

export function flattenPatternRecognitionData(originalData) {
  const mappedData = [];

  Object.keys(originalData)?.forEach((respId) => {
    const dataEntry = { respId: respId };

    const questions = originalData[respId];

    Object.keys(questions)?.forEach((questionId) => {
      const questionData = questions?.[questionId];

      const mappedQuestion = {
        question_id: questionId,
        question_type: questionData?.question_type ?? "",
        repetitive_pattern_label: questionData?.repetitive_pattern_label ?? false,
        repetitive_score: questionData?.repetitive_score ?? 0,
        alternating_pattern_label: questionData?.alternating_pattern_label ?? false,
        alternating_score: questionData?.alternating_score ?? 0,
        stepped_pattern_label: questionData?.stepped_pattern_label ?? false,
        stepped_pattern_score: questionData?.stepped_pattern_score ?? 0,
        include: questionData?.include,
      };

      // Merge question data into the data entry
      const respondentRowObj = { respId: respId };
      Object.assign(respondentRowObj, mappedQuestion);
      mappedData.push(respondentRowObj);
    });
  });

  return mappedData;
}

export const flattenPatternRecognitionColumns = {
  RESP_ID: "respId",
  QUESTION_ID: "question_id",
  QUESTION_TYPE: "question_type",
  REPETITIVE_PATTERN_LABEL: "repetitive_pattern_label",
  REPETITIVE_SCORE: "repetitive_score",
  ALTERNATING_PATTERN_LABEL: "alternating_pattern_label",
  ALTERNATING_SCORE: "alternating_score",
  STEPPED_PATTERN_LABEL: "stepped_pattern_label",
  STEPPED_PATTERN_SCORE: "stepped_pattern_score",
  INCLUDE: "include",
};
