import { QUESTION_TYPES } from "../components/modals/QuestionLibrary/questionLibrary.constant";

import { encodeToBase64 } from "./helper";

const categoricalTypes = [QUESTION_TYPES.GRID, QUESTION_TYPES.SINGLE, QUESTION_TYPES.MULTI, QUESTION_TYPES.OPEN_TEXT, QUESTION_TYPES.OPEN_TEXT_LIST];
const numericalTypes = [QUESTION_TYPES.NUMERIC, QUESTION_TYPES.NUMERIC_LIST, QUESTION_TYPES.RANKING];

export function questionMetaConvertor(questionMeta) {
  const categoricalQuestions = ["IIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII"];
  const numericalQuestions = ["BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB"];

  Object.keys(questionMeta).forEach((questionId) => {
    if (questionId && questionId.length > 0) {
      if (categoricalTypes.includes(questionMeta[questionId].type)) {
        categoricalQuestions.push(questionId);
      }

      if (numericalTypes.includes(questionMeta[questionId].type)) {
        numericalQuestions.push(questionId);
      }
    }
  });

  return [categoricalQuestions, numericalQuestions];
}

export function optionsExpressionConvertor(expression, optionsMeta) {
  // Escape function to handle special characters in keys for RegExp
  function escapeRegExp(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  // Sort keys by length in descending order to handle longer keys first
  const sortedKeys = Object.keys(optionsMeta).sort((a, b) => b.length - a.length);

  // Create a regular expression to match any key in optionsMeta
  const keysRegex = new RegExp(sortedKeys.map(escapeRegExp).join("|"), "g");

  // Replace keys in the expression with their corresponding values from optionsMeta, wrapped in double quotes
  const result = expression.replace(keysRegex, (match) => {
    if (optionsMeta.hasOwnProperty(match) && optionsMeta?.[match] !== undefined && optionsMeta?.[match] !== null) {
      return `"${encodeToBase64(optionsMeta?.[match])}"`;
      // return `"${optionsMeta[match]}"`;
    }
    return match; // Return the original match if the key is not found in optionsMeta
  });

  return result;
}
