import React from "react";

import { QUESTION_TYPES } from "../../../modals/QuestionLibrary/questionLibrary.constant";

import QuestionTableSingleSelectRow from "./QuestionTableSingleSelectRow";

const QuestionTableRenderSubDetails = ({
  questionId,
  overallQuestionObj,
  originalQuestionObject,
  index,
  handleRadioChange,
  selectedRow,
  openIndexes,
  showHighlightedRows = true,
  radioOnFirstColumn = false,
}) => {
  function renderOptions() {
    if (originalQuestionObject?.type === QUESTION_TYPES.RANKING) {
      return (
        <>
          {originalQuestionObject?.options?.map((option, optionIndex) => {
            const isLastItem = optionIndex === originalQuestionObject?.options?.length - 1;

            return (
              <QuestionTableSingleSelectRow
                key={`${index}-${optionIndex}`}
                rowClassName={`subQuestionRow ${openIndexes.includes(index) && showHighlightedRows ? "question-table__highlighted-row" : ""} ${
                  isLastItem && showHighlightedRows ? "question-table__highlighted-row--last-row" : ""
                }`}
                selectedRow={selectedRow}
                displayObjId={option?.variable_id}
                displayObjLabel={option?.option_label}
                displayObjType={option?.type ?? originalQuestionObject?.type}
                rowMetaShowRadioBtn={true}
                rowMetaDataOptions={option?.options}
                rowMetaDataRadioInputAriaLabel={"Select row"}
                handleRadioChange={handleRadioChange}
                radioColumnIndex={radioOnFirstColumn ? 0 : 1}
              />
            );
          })}
        </>
      );
    } else {
      return (
        <>
          {originalQuestionObject?.sub_questions &&
            originalQuestionObject?.sub_questions.map((subQuestion, subIndex) => {
              const isLastItem = subIndex === originalQuestionObject?.sub_questions?.length - 1;

              return (
                <QuestionTableSingleSelectRow
                  key={`${index}-${subIndex}`}
                  rowClassName={`subQuestionRow ${openIndexes.includes(index) && showHighlightedRows ? "question-table__highlighted-row" : ""} ${
                    isLastItem && showHighlightedRows ? "question-table__highlighted-row--last-row" : ""
                  }`}
                  selectedRow={selectedRow}
                  displayObjId={subQuestion?.variable_id}
                  displayObjLabel={subQuestion?.sub_question_label}
                  displayObjType={subQuestion?.type ?? originalQuestionObject?.type}
                  rowMetaShowRadioBtn={true}
                  rowMetaDataOptions={subQuestion?.options}
                  rowMetaDataRadioInputAriaLabel={"Select row"}
                  handleRadioChange={handleRadioChange}
                  radioColumnIndex={radioOnFirstColumn ? 0 : 1}
                />
              );
            })}
        </>
      );
    }
  }

  function renderOtherQuestionsIfAvailable() {
    if (!overallQuestionObj) return null;

    // Filter out the current question ID
    const otherQuestionKeys = Object.keys(overallQuestionObj).filter((key) => key !== questionId && key !== "question_id") ?? [];

    // Render the current question row if the conditions are met
    const renderCurrentQuestionRow =
      otherQuestionKeys?.length > 0 && [QUESTION_TYPES.MULTI, QUESTION_TYPES.SINGLE, QUESTION_TYPES.NUMERIC].includes(originalQuestionObject?.type);

    return (
      <>
        {renderCurrentQuestionRow && (
          <QuestionTableSingleSelectRow
            key={`${index}-original-${0}`}
            rowClassName={`subQuestionRow ${openIndexes.includes(index) && showHighlightedRows ? "question-table__highlighted-row" : ""} `}
            selectedRow={selectedRow}
            displayObjId={questionId}
            displayObjLabel={originalQuestionObject?.question}
            displayObjType={originalQuestionObject?.type}
            rowMetaShowRadioBtn={true}
            rowMetaDataOptions={originalQuestionObject?.options}
            rowMetaDataRadioInputAriaLabel={`Select question ${index}`}
            handleRadioChange={handleRadioChange}
            radioColumnIndex={radioOnFirstColumn ? 0 : 1}
          />
        )}
        {otherQuestionKeys?.length > 0 &&
          otherQuestionKeys?.map((key, idx) => (
            <QuestionTableRenderSubDetails
              showHighlightedRows={showHighlightedRows}
              key={key}
              overallQuestionObj={null}
              originalQuestionObject={overallQuestionObj[key]}
              questionId={key}
              index={index}
              handleRadioChange={handleRadioChange}
              selectedRow={selectedRow}
              openIndexes={openIndexes}
              radioOnFirstColumn={radioOnFirstColumn}
            />
          ))}
      </>
    );
  }

  return (
    <>
      {renderOptions()}
      {renderOtherQuestionsIfAvailable()}
    </>
  );
};

export default QuestionTableRenderSubDetails;
