import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DocumentUpload } from "iconsax-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa6";

import { convertToKb, convertToMb, formatDate } from "../../assets/utils/utils";
import FileIconUploadSVG from "../../assets/images/FileIconUploadSVG";
import SelectDropdown from "../../components/SelectDropdown/SelectDropdown";
import TextField from "../../components/TextField/TextField";
import { ReactComponent as CloseIcon } from "../../assets/images/closeproject.svg";
import { ReactComponent as Merged } from "../../assets/images/merged.svg";
import {
  activateFile,
  getExistingPotentialJokers,
  getIsFileParsedStatus,
  getMergedFileStatus,
  removeRuleStatus,
  saveProjectdetails,
  updateLastViewedProject,
  updateProjectdetails,
  uploadFilesData,
} from "../../services/projectService";
import {
  ROUTES,
  SNACKBAR_TYPES,
  dataStatusTypeOptions,
  fileTypeOptions,
  objectiveTypeOptions,
  organizationOptions,
  required,
  selectType,
} from "../../assets/constants/constant";
import { createProjectStart, setSelectedFiles, showSelectedFilesSuccess } from "../../features/createProject/createProductSlice";
import FileUploadModal from "../../components/modals/FileUpload/FileUploadModal";
import { openSnackbar } from "../../features/snackbar/snackbarslice";
import SavedFileModal from "../../components/modals/SavedFileModal/SavedFileModal";
import SavedFilesSVG from "../../assets/images/SavedFilesSVG";

import "./ProjectDetails.css";
import { IoCloseCircle, IoCloseCircleOutline, IoTrashOutline } from "react-icons/io5";

import { closeLoader, openLoader } from "../../features/loader/loaderSlice";
import ButtonComponent from "../../components/Button/ButtonComponent";
import Addicon from "../../assets/images/AddPath.svg";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";
import PermanentRespondentModal from "../../components/modals/PermanentRespondentModal/PermanentRespondentModal";

// custom checkbox
function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root": {
      height: 34,
      minHeight: 34,
    },
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: "none",
      borderRadius: 3,
      boxShadow: "0px 0px 2px rgba(0,0,0,0.3)",
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#5E5ADB",
      borderColor: "#5E5ADB",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
      width: 8,
      height: 8,
      backgroundColor: "#1890ff",
      transform: "none",
      top: "39%",
      border: 0,
    },
  };
}
// styledDataGrid
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color: "#25282B",
  fontFamily: "Montserrat",
  WebkitFontSmoothing: "auto",
  letterSpacing: "0.2px",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: "#ffffff",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: 0,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid #E9EDF5",
  },
  "& .MuiDataGrid-cell": {
    color: "#25282B",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  ...customCheckbox(theme),
}));
// styledDataGrid
const ProjectDetails = () => {
  const [isRadioSelected, setIsRadioSelected] = useState(false);
  const { createProjectDetails, validateFiles } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState("");
  // const [openModal, setOpenModal] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("projectId");
  let cloneedProject = queryParams.get("enable_basic_details_edit");
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [upladedSuccess, setUploadedSuccess] = useState("pending");
  const [duplicateErrorMessage, setDuplicateErrorMessage] = useState("");
  const [rawErrorMessage, setRawErrorMessage] = useState("");
  const [dataErrorMessage, setDataErrorMessage] = useState("");
  const [waveApplicable, setWaveApplicable] = useState("N");
  const [isDisabled, setIsDisabled] = useState(true);
  const [testre, settestre] = useState(null);
  const [isAddFiles, setAddFiles] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [mergedFileStatus, setMergedFileStatus] = useState(false);
  const [showPermanentRespondentModal, setShowPermanentRespondentModal] = useState(false);
  const [initProjectDetailApiTrigger, setInitProjectDetailApiTrigger] = useState(false);

  const dataMapFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.id);
  const rawFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeRawDataFile?.id);
  const userInfo = useSelector((state) => state?.user?.userInfo ?? "");

  useEffect(() => {
    if (createProjectDetails?.files?.length > 0) {
      const tableRows =
        createProjectDetails?.files?.map((item, index) => ({
          ...item,
          id: item.rawDataId + item.mapDataId,
          key: item.rawDataId + item.mapDataId,
        })) || [];
      const selectRowsTemp = tableRows.filter((row) => {
        // Get the name of the active data map file
        const activeDataMapFileName = createProjectDetails?.selectedFiles?.activeDataMapFile?.name;
        // Get the name of the active raw data file
        const activeRawDataFileName = createProjectDetails?.selectedFiles?.activeRawDataFile?.name;
        if (row.dataMapName === activeDataMapFileName && row.rawDataName === activeRawDataFileName) {
        }
        // Check if both conditions are met for the current row
        return row.dataMapName === activeDataMapFileName && row.rawDataName === activeRawDataFileName;
      });
      setSelectedRow(selectRowsTemp[0]);
    }
  }, [createProjectDetails, createProjectDetails?.selectedFiles]);

  const [projectDetails, setProjectDetails] = useState({
    name: "",
    objective_type: null,
    client_name: "",
    organization_name: null,
    description: "",
    wave_applicable: "N",
  });

  const [fileType, setFileType] = useState({
    file_type: null,
    file_status: { label: "Flat", value: "Flat" }, // by default file status is flat
    select_type: null,
    wave: null,
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    objective_type: "",
    client_name: "",
    organization_name: "",
    wave_applicable: "",
  });
  const [savedProject, setSavedproject] = useState(false);
  const [rawDataFile, setRawDataFile] = useState(null);
  const [mapDataFile, setMapDataFile] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [totalCount, setTotalCount] = useState(0);
  // const [openModal1, setOpenModal] = useState(false);
  const [openSavedFileModal, setOpenSavedFileModal] = useState(false);
  const [projectId, setProjectId] = useState(null);

  const [potentialPermanentJokerList, setPotentialPermanentJokerList] = useState([]);
  const [menuPlacement, setMenuPlacement] = useState();

  const handleRadioChange = (event) => {
    setWaveApplicable(event.target.value);
    setIsRadioSelected(event.target.value === "Y");
    setProjectDetails((prevProjectDetail) => ({
      ...prevProjectDetail,
      wave_applicable: event.target.value,
    }));
  };

  const handleButtonClick = () => {
    dispatch(showSelectedFilesSuccess(false));
    setAddFiles(true);
  };
  const handleClose = () => {
    setAddFiles(false);
  };

  useEffect(() => {
    if (createProjectDetails?.selectedFiles?.activeRawDataFile?.name && createProjectDetails?.selectedFiles?.activeDataMapFile?.name) {
      const activeRawDataFile = createProjectDetails.selectedFiles.activeRawDataFile;
      const activeDataMapFile = createProjectDetails.selectedFiles.activeDataMapFile;

      const activeRow = createProjectDetails?.files?.find((file) => file.rawDataName === activeRawDataFile.name && file.dataMapName === activeDataMapFile.name);

      if (activeRow) {
        // setSelectedRow(activeRow);
      }
    }
  }, [createProjectDetails]);
  const columns = [
    {
      field: "selection",
      headerName: "",
      flex: 0.25,
      minWidth: 70,

      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => (
        <Radio
          type="radio"
          name="radio"
          //  value={params.row}
          checked={selectedRow?.id === params.row.id}
          onChange={() => {
            setSelectedRow(params.row);
            // handleRadioChange1(params.row);
          }}
        />
      ),
    },
    {
      field: "dataMapName",
      headerName: "Data Map File",
      flex: 0.9,
      minWidth: 220,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "rawDataName",
      headerName: "Survey File",
      flex: 0.9,
      minWidth: 220,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.5,
      minWidth: 150,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "error",
      headerName: "",
      flex: 0.3,
      minWidth: 100,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      align: "center",
      renderCell: (params) => {
        if (params?.row?.rawFileOverallStatus === "ERROR" || params?.row?.dataFileOverallStatus === "ERROR") {
          return <div className="create-project__error-column">Error</div>;
        } else {
          return <></>;
        }
      },
    },
    {
      field: "filetype",
      headerName: "File Type",
      flex: 0.5,
      minWidth: 150,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => <div className="dateIcon">{params.row.dataFileType}</div>,
    },
    {
      field: "wave",
      headerName: "Wave",
      flex: 0.5,
      minWidth: 120,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => <div className="dateIcon">{params.row.wave}</div>,
    },
    {
      field: "createdAt",
      headerName: "Uploaded On",
      flex: 0.85,
      minWidth: 180,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => <div className="dateIcon">{formatDate(params.row.createdAt)}</div>,
    },
  ];

  // This useEffect hook is used to create a new project.
  useEffect(() => {
    if (param1) {
      setProjectId(param1);
      dispatch(createProjectStart(param1));
      if (!cloneedProject) {
        setSavedproject(true);
      }
      setInitProjectDetailApiTrigger(true);
    }
  }, []);

  /**
   * Extracts relevant details from `createProjectDetails` and sets the `projectDetails` and `fileType` state variables accordingly.
   * This effect runs only when `createProjectDetails.data` changes.
   */
  useEffect(() => {
    if (param1) {
      const {
        data: { name, objective_type, client_name, organization_name, description, wave_applicable, project_lock_user_id },
      } = createProjectDetails;

      async function handleProjectLock() {
        if (initProjectDetailApiTrigger) {
          if (project_lock_user_id !== null && project_lock_user_id !== undefined && project_lock_user_id !== userInfo.id) {
            dispatch(openSnackbar({ message: "Project is already locked!", type: SNACKBAR_TYPES.ERROR }));
            navigate(ROUTES.HOME_PAGE);
          } else if (project_lock_user_id === null || project_lock_user_id === undefined) {
            try {
              // await lockProject(param1, userInfo.email, userInfo.id);
            } catch (error) {}
          }
        }
      }
      handleProjectLock();

      setIsRadioSelected(createProjectDetails?.data?.wave_applicable === "Y");
      setProjectDetails({
        name: name,
        objective_type: { label: objective_type, value: objective_type },
        client_name: client_name,
        organization_name: {
          label: organization_name,
          value: organization_name,
        },
        description: description,
        wave_applicable: waveApplicable,
      });

      setFileType({
        // file_type: createProjectDetails?.selectedFiles.activeRawDataFile
        //   ? {
        //       label:
        //         createProjectDetails?.selectedFiles.activeRawDataFile
        //           ?.data_file_type,
        //       value:
        //         createProjectDetails?.selectedFiles.activeRawDataFile
        //           ?.data_file_type,
        //     }
        //   : null,

        file_status: createProjectDetails?.selectedFiles.activeRawDataFile
          ? {
              label: createProjectDetails?.selectedFiles.activeRawDataFile?.data_status_type,
              value: createProjectDetails?.selectedFiles.activeRawDataFile?.data_status_type,
            }
          : null,
        // select_type: createProjectDetails?.selectedFiles.activeRawDataFile
        //   ? {
        //       label:
        //         createProjectDetails?.selectedFiles.activeRawDataFile?.wave,
        //       value:
        //         createProjectDetails?.selectedFiles.activeRawDataFile?.wave,
        //     }
        //   : null,

        // select_type: createProjectDetails?.selectedFiles.activeRawDataFile
        // ? {
        //   label:
        //     createProjectDetails?.selectedFiles.activeRawDataFile
        //       ?.wave,
        //   value:
        //     createProjectDetails?.selectedFiles.activeRawDataFile
        //       ?.wave,
        // }
        // : null,
        // select_type: createProjectDetails?.selectedFiles.activeRawDataFile
        // ? {
        //   label:
        //     createProjectDetails?.selectedFiles.activeRawDataFile
        //       ?.data_file_type,
        //   value:
        //     createProjectDetails?.selectedFiles.activeRawDataFile
        //       ?.data_file_type,
        // }
        // : null,
      });
    }
  }, [createProjectDetails.data]);

  /**
   * Prevents the default form submission behavior, validates the input,
   * and then saves the project details to the server if the input is valid.
   * Displays a success or error message to the user based on the response from the server.
   */

  const saveProjectDetails = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      const values = { ...projectDetails };
      values.objective_type = projectDetails.objective_type.value;
      values.organization_name = projectDetails.organization_name.value;
      values.wave_applicable = projectDetails.wave_applicable;
      try {
        dispatch(openLoader());
        const { data } = await saveProjectdetails(values);
        if (data?.name) {
          setProjectId(data.id);
          await updateLastViewedProject(data?.id); // todo:
          setSavedproject(true);
          dispatch(closeLoader());
          dispatch(
            openSnackbar({
              message: `${data?.name} project is created successfully`,
              type: SNACKBAR_TYPES.SUCCESS,
            })
          );
        }
      } catch (error) {
        dispatch(closeLoader());
        const { response } = error;
        if (response?.data?.name) {
          dispatch(openSnackbar({ message: response?.data?.name, type: SNACKBAR_TYPES.ERROR }));
        }
      }
    }
  };
  const handleBackButtonClick = () => {
    // showSelectedFilesSuccess
  };
  const updateProjectDetails = async (e) => {
    if (validateInput()) {
      const values = { ...projectDetails };
      values.objective_type = projectDetails.objective_type.value;
      values.organization_name = projectDetails.organization_name.value;
      try {
        const { data } = await updateProjectdetails(values, projectId);
        if (data?.name) {
          setProjectId(data.id);
          await updateLastViewedProject(data?.id); // todo:
          setSavedproject(true);
          cloneedProject = false;
          dispatch(closeLoader());
          dispatch(
            openSnackbar({
              message: `${data?.name} project is updated successfully`,
              type: SNACKBAR_TYPES.SUCCESS,
            })
          );
        }
      } catch (error) {}
    }
  };

  // This function validates the input fields for the project creation form
  const validateInput = () => {
    const errorObj = {};
    if (!projectDetails.name) {
      errorObj.name = required.value;
    }
    // if (!projectDetails.description) {
    //   errorObj.description = required.value;
    // }
    if (!projectDetails.client_name) {
      errorObj.client_name = required.value;
    }
    if (!projectDetails?.objective_type?.value) {
      errorObj.objective_type = required.value;
    }
    if (!projectDetails?.organization_name?.value) {
      errorObj.organization_name = required.value;
    }
    setFormErrors(errorObj);
    if (Object.keys(errorObj)?.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  // validate file types and upload files
  const uploadFiles = async (e) => {
    e.preventDefault();
    setopenModal(true);
    const uploadValue = {
      data_file_type: fileType?.file_type?.value?.toUpperCase(),
      data_status_type: fileType?.file_status?.value?.toUpperCase(),
      wave: isRadioSelected ? fileType?.select_type?.value : "NA",
      raw_data: rawDataFile,
      data_map: mapDataFile,
      // wave:
    };

    try {
      const { data } = await uploadFilesData(projectId, uploadValue);
      if (data?.status) {
        setProgress(100);
        setUploadedSuccess("success");
      }
    } catch (error) {
      // setDuplicateErrorMessage(errorMesage); TODO: If need to pass the message from BE

      const rawErrorMessageDuplicate = error?.response?.data?.message?.raw_file
        ? `${error?.response?.data?.message?.raw_file}`
        : error?.response?.data?.message?.error;
      const dataErrorMessageDuplicate = error?.response?.data?.message?.data_file
        ? `${error?.response?.data?.message?.data_file}`
        : error?.response?.data?.message?.error;

      setRawErrorMessage(rawErrorMessageDuplicate);
      setDataErrorMessage(dataErrorMessageDuplicate);
      // setDuplicateErrorMessage(errorMessageDuplicate);
      setUploadedSuccess("fail");

      // dispatch(
      //   openSnackbar({
      //     message: duplicateErrorMessage
      //       ? duplicateErrorMessage
      //       : `Server Connection Failed!!`,
      //     type: SNACKBAR_TYPES.ERROR,
      //   })
      // );
    }
  };

  // let combineFileSizeInMB = 0;
  // const [combineFileSizeInMB, setCombineFileSizeInMB] = useState(0);
  // const [dataMapFileSizeInMB, setDataMapFileSizeInMB] = useState(0);
  // const [rowDataFileSizeInMB, setRowDataFileSizeInMB] = useState(0);

  // const handleFileRawDataSelection = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const fileName = file.name;
  //     const rawFile = file.type;
  //     const fileSizeKB = convertToKb(file?.size);
  //     // const fileSizeMB = convertToMb(file?.size);
  //     setRowDataFileSizeInMB(convertToMb(file?.size));

  //     // Check combine file size not more then 5MB
  //     let sumOfFileSizeInMB = (+rowDataFileSizeInMB) + (+dataMapFileSizeInMB) + (+combineFileSizeInMB);
  //     setCombineFileSizeInMB(sumOfFileSizeInMB);

  //     if (rawFile !== "text/csv") {
  //       dispatch(
  //         openSnackbar({
  //           message: `${fileName} is not in csv format`,
  //           type: SNACKBAR_TYPES.ERROR,
  //         })
  //       );
  //     } else if (fileSizeKB < 1) {
  //       dispatch(
  //         openSnackbar({
  //           message: `${fileName} is empty`,
  //           type: SNACKBAR_TYPES.ERROR,
  //         })
  //       );
  //     } else if (rowDataFileSizeInMB > 4) {
  //       dispatch(
  //         openSnackbar({
  //           message: `${fileName} is too big`,
  //           type: SNACKBAR_TYPES.ERROR,
  //         })
  //       );
  //     } else if(combineFileSizeInMB > 15) {
  //       dispatch(
  //         openSnackbar({
  //           message: `Both file size is more then 5MB.`,
  //           type: SNACKBAR_TYPES.ERROR,
  //         })
  //       );
  //     } else if (createProjectDetails.files.length > 0) {
  //       const hasSameName = createProjectDetails.files.some(
  //         (file) => file.rawDataName == fileName
  //       );
  //       if (hasSameName) {
  //         dispatch(
  //           openSnackbar({
  //             message: `${fileName} name already exists, Please upload file with unique name!!`,
  //             type: SNACKBAR_TYPES.ERROR,
  //           })
  //         );
  //       } else {
  //         setRawDataFile(file);
  //       }
  //     } else {
  //       setRawDataFile(file);
  //     }
  //   }
  // };

  // const handleFileDataMapSelection = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const fileName = file.name;
  //     const dataFile = file.type;
  //     const fileSizeKB = convertToKb(file?.size);
  //     // const fileSizeMB = convertToMb(file?.size);
  //     // dataMapFileSizeInMB = convertToMb(file?.size);
  //     setDataMapFileSizeInMB(convertToMb(file?.size));

  //     // Check combine file size not more then 5MB
  //     let sumOfFileSizeInMB = (+rowDataFileSizeInMB) + (+dataMapFileSizeInMB) + (+combineFileSizeInMB);
  //     setCombineFileSizeInMB(sumOfFileSizeInMB);

  //     if (dataFile !== "text/csv") {
  //       dispatch(
  //         openSnackbar({
  //           message: `${fileName} is not in csv format`,
  //           type: SNACKBAR_TYPES.ERROR,
  //         })
  //       );
  //     } else if (fileSizeKB < 1) {
  //       dispatch(
  //         openSnackbar({
  //           message: `${fileName} is empty`,
  //           type: SNACKBAR_TYPES.ERROR,
  //         })
  //       );
  //     } else if (dataMapFileSizeInMB > 4) {
  //       dispatch(
  //         openSnackbar({
  //           message: `${fileName} is too big`,
  //           type: SNACKBAR_TYPES.ERROR,
  //         })
  //       );
  //     } else if(combineFileSizeInMB > 15) {
  //       dispatch(
  //         openSnackbar({
  //           message: `Both file size is more then 5MB.`,
  //           type: SNACKBAR_TYPES.ERROR,
  //         })
  //       );
  //     } else if (createProjectDetails.files.length > 0) {
  //       const hasSameName = createProjectDetails.files.some(
  //         (file) => file.dataMapName == fileName
  //       );
  //       if (hasSameName) {
  //         dispatch(
  //           openSnackbar({
  //             message: `${fileName} name already exists, Please upload file with unique name!!`,
  //             type: SNACKBAR_TYPES.ERROR,
  //           })
  //         );
  //       } else {
  //         setMapDataFile(file);
  //       }
  //     } else {
  //       setMapDataFile(file);
  //     }
  //   }
  // };

  const [dataMapFileSizeInMB, setDataMapFileSizeInMB] = useState(0);
  const [rowDataFileSizeInMB, setRowDataFileSizeInMB] = useState(0);

  const handleFileRawDataSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const rawFile = file.type;
      const fileSizeKB = convertToKb(file?.size);
      const fileSizeMB = convertToMb(file?.size);

      if (rawFile !== "text/csv") {
        dispatch(
          openSnackbar({
            message: `${fileName} is not in csv format`,
            type: SNACKBAR_TYPES.ERROR,
          })
        );
      } else if (fileSizeKB < 1) {
        dispatch(
          openSnackbar({
            message: `${fileName} is empty`,
            type: SNACKBAR_TYPES.ERROR,
          })
        );
      } else if (fileSizeMB > 5) {
        dispatch(
          openSnackbar({
            message: `${fileName} is too big`,
            type: SNACKBAR_TYPES.ERROR,
          })
        );
      } else {
        const combinedFileSizeInMB = +fileSizeMB + +dataMapFileSizeInMB;
        if (combinedFileSizeInMB >= 5) {
          dispatch(
            openSnackbar({
              message: `The combined file size exceeds 5MB.`,
              type: SNACKBAR_TYPES.ERROR,
            })
          );
        } else {
          setRowDataFileSizeInMB(fileSizeMB);

          if (createProjectDetails.files.length > 0) {
            const hasSameName = createProjectDetails.files.some((file) => file.rawDataName === fileName);
            if (hasSameName) {
              dispatch(
                openSnackbar({
                  message: `${fileName} name already exists, Please upload a file with a unique name!!`,
                  type: SNACKBAR_TYPES.ERROR,
                })
              );
            } else {
              setRawDataFile(file);
            }
          } else {
            setRawDataFile(file);
          }
        }
      }
    }
  };

  const handleFileDataMapSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const dataFile = file.type;
      const fileSizeKB = convertToKb(file?.size);
      const fileSizeMB = convertToMb(file?.size);

      if (dataFile !== "text/csv") {
        dispatch(
          openSnackbar({
            message: `${fileName} is not in csv format`,
            type: SNACKBAR_TYPES.ERROR,
          })
        );
      } else if (fileSizeKB < 1) {
        dispatch(
          openSnackbar({
            message: `${fileName} is empty`,
            type: SNACKBAR_TYPES.ERROR,
          })
        );
      } else if (fileSizeMB > 5) {
        dispatch(
          openSnackbar({
            message: `${fileName} is too big`,
            type: SNACKBAR_TYPES.ERROR,
          })
        );
      } else {
        const combinedFileSizeInMB = +rowDataFileSizeInMB + +fileSizeMB;
        if (combinedFileSizeInMB >= 5) {
          dispatch(
            openSnackbar({
              message: `The combined file size exceeds 5MB.`,
              type: SNACKBAR_TYPES.ERROR,
            })
          );
        } else {
          setDataMapFileSizeInMB(fileSizeMB);

          if (createProjectDetails.files.length > 0) {
            const hasSameName = createProjectDetails.files.some((file) => file.dataMapName === fileName);
            if (hasSameName) {
              dispatch(
                openSnackbar({
                  message: `${fileName} name already exists, Please upload a file with a unique name!!`,
                  type: SNACKBAR_TYPES.ERROR,
                })
              );
            } else {
              setMapDataFile(file);
            }
          } else {
            setMapDataFile(file);
          }
        }
      }
    }
  };

  // Saved file and opend saved file modal
  const handleSavedFiles = (e) => {
    e.preventDefault();
    setOpenSavedFileModal(true);
  };
  useEffect(() => {
    if (createProjectDetails?.selectedFiles?.activeRawDataFile?.name && createProjectDetails?.selectedFiles?.activeDataMapFile?.name) {
      // Set testre to a specific value if both files are selected
      settestre(createProjectDetails.selectedFiles.activeRawDataFile); // Assuming you want to set to activeRawDataFile
    }
  }, [createProjectDetails]);

  const columns1 = [
    {
      field: "selection",
      headerName: "",
      flex: 0.25,
      minWidth: 70,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => {
        const isSelected = params.row;
        return (
          <Radio
            type="radio"
            name="radio"
            value={params.row}
            checked={selectedRow?.id === params.row.id}
            onChange={() => {
              setSelectedRow(params.row);
              // handleChangeNext(params.row);
            }}
          />
        );
      },
    },
    {
      field: "dataMapName",
      textAlign: "center",
      headerName: "Data Map File",
      flex: 0.9,
      minWidth: 220,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "rawDataName",
      headerName: "Survey File",
      flex: 0.9,
      minWidth: 220,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.5,
      minWidth: 150,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
    },
    {
      field: "error",
      headerName: "",
      flex: 0.3,
      minWidth: 100,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      // align: "center",
      renderCell: (params) => {
        if (params?.row?.rawFileOverallStatus === "ERROR" || params?.row?.dataFileOverallStatus === "ERROR") {
          return <div className="create-project__error-column">Error</div>;
        } else {
          return <></>;
        }
      },
    },
    {
      field: "filetype",
      headerName: "File Type",
      flex: 0.5,
      minWidth: 150,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => <div className="dateIcon">{params.row.dataFileType}</div>,
    },
    {
      field: "wave",
      headerName: "Wave",
      flex: 0.5,
      minWidth: 120,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => <div className="dateIcon">{params.row.wave}</div>,
    },
    {
      field: "createdAt",
      textAlign: "center",
      headerName: "Uploaded On",
      flex: 0.85,
      minWidth: 180,
      headerClassName: "quant-app-theme--header",
      cellClassName: "quant-app-theme--cell",
      renderCell: (params) => <div className="dateIcon">{formatDate(params.row.createdAt)}</div>,
    },
  ];

  useEffect(() => {
    dispatch(showSelectedFilesSuccess(false));
  }, []);
  const getPotentialJokerRespondents = async (rawFileId) => {
    try {
      const response = await getExistingPotentialJokers(rawFileId, projectId);
      const potentialJokerList = response?.data?.data ?? [];

      setPotentialPermanentJokerList(potentialJokerList);
      return potentialJokerList;
    } catch (error) {
      dispatch(openSnackbar({ message: "Couldn't fetch existing jokers", type: SNACKBAR_TYPES.ERROR }));
    }
  };

  const handleStartClick = async (navigate, row) => {
    try {
      if (selectedRow?.rawFileOverallStatus === "ERROR" || selectedRow?.dataFileOverallStatus === "ERROR") {
        dispatch(
          openSnackbar({
            message: "This file can't be activated please activate/upload different file.",
            type: SNACKBAR_TYPES.ERROR,
          })
        );
        return;
      }

      if (row?.data?.active_data_map_file?.id === selectedRow?.mapDataId || row?.data?.active_raw_file?.id === selectedRow?.rawDataId) {
        dispatch(openLoader());
        try {
          const status = await getIsFileParsedStatus(rawFileId);

          if (!status) {
            const jokerList = await getPotentialJokerRespondents(row?.data?.active_raw_file?.id);
            if (jokerList.length > 0) {
              setShowPermanentRespondentModal(true);
              dispatch(closeLoader());
            } else {
              dispatch(closeLoader());
              navigate("/create-project/design-qc-rules/1");
            }
          } else {
            dispatch(closeLoader());
            navigate("/create-project/design-qc-rules/1");
          }

          return;
        } catch (error) {
          openSnackbar({
            message: "This file can't be activated please activate/upload different file.",
            type: SNACKBAR_TYPES.ERROR,
          });
          throw new Error("This file can't be activated please activate/upload different file.");
        } finally {
          dispatch(closeLoader());
        }
      }

      const reqObj = {
        raw_file_id: selectedRow.rawDataId,
        data_map_file_id: selectedRow.mapDataId,
      };

      try {
        dispatch(openLoader());
        await activateFile(projectId, reqObj);

        try {
          await removeRuleStatus(projectId);
        } catch (error) {
          console.error({ error });
        }

        const interval = 10 * 1000; // 10 seconds
        const duration = 3000 * 1000;

        const fetchData = async () => {
          try {
            dispatch(openLoader());

            const { data } = await getMergedFileStatus(projectId);

            if (data?.data_file_type?.status === "LOADED" || data?.active_raw_file?.status === "LOADED") {
              if (intervalId) clearInterval(intervalId); // Stop interval
              setMergedFileStatus(true);
              dispatch(createProjectStart(projectId));
              dispatch(closeLoader());

              const status = await getIsFileParsedStatus(rawFileId);
              if (!status) {
                const jokerList = await getPotentialJokerRespondents(data?.active_raw_file?.id);
                if (jokerList.length > 0) {
                  setShowPermanentRespondentModal(true);
                } else {
                  dispatch(
                    openSnackbar({
                      message: "File has been activated successfully",
                      type: SNACKBAR_TYPES.SUCCESS,
                    })
                  );
                  navigate("/create-project/design-qc-rules/1");
                }
              } else {
                dispatch(
                  openSnackbar({
                    message: "File has been activated successfully",
                    type: SNACKBAR_TYPES.SUCCESS,
                  })
                );
                navigate("/create-project/design-qc-rules/1");
              }
            } else if (data?.data_file_type?.status === "ERROR" || data?.active_raw_file?.status === "ERROR") {
              if (intervalId) clearInterval(intervalId); // Stop interval
              dispatch(
                openSnackbar({
                  message: "Error while activating the file",
                  type: SNACKBAR_TYPES.ERROR,
                })
              );
              setMergedFileStatus(false);
              dispatch(closeLoader());
            }
          } catch (error) {
            console.error("Error fetching merged file status:", error);
          }
        };

        fetchData();
        const intervalId = setInterval(fetchData, interval);
        setTimeout(() => {
          clearInterval(intervalId);
        }, duration);
      } catch (error) {
        dispatch(closeLoader());
        dispatch(
          openSnackbar({
            message: "File activation failed!!",
            type: SNACKBAR_TYPES.ERROR,
          })
        );
        console.error("Error during file activation:", error);
      }
    } catch (error) {
      console.error("Error during handleStartClick:", error);
    }
  };
  const fileslength = createProjectDetails?.files?.length;

  // const handlePageChange = (newOffset) => {
  //   setOffset(newOffset);
  // };

  // screen size tracker for dropdown
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1347 || window.innerHeight < 768) {
        setMenuPlacement("top");
      } else {
        setMenuPlacement();
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Initialize AttachmentsRows as an empty array
  let AttachmentsRows = [];

  // Populate AttachmentsRows only if createProjectDetails and files exist
  if (createProjectDetails?.files?.length > 0) {
    AttachmentsRows = createProjectDetails.files.map((item) => {
      const obj = {
        ...item,
        id: item.rawDataId + item.mapDataId,
        key: item.rawDataId + item.mapDataId,
      };
      createProjectDetails?.data?.files?.forEach((ele) => {
        if (ele.id === item.rawDataId) {
          obj.wave = ele.wave;
        }
      });
      return obj;
    });
  }
  // /2nd pagination
  const totalCount = AttachmentsRows?.length || 0;
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsPerPage,
    page: page,
  });

  const handlePageChange = (newOffset) => {
    setOffset(newOffset);
    setPaginationModel((prev) => ({
      ...prev,
      page: newOffset / rowsPerPage,
    }));
  };
  const handleFilterModelChange = (searchValue) => {
    setOffset(0);
    setPaginationModel((prev) => ({
      ...prev,
      page: 0,
    }));
  };

  const pagination_row = AttachmentsRows?.slice(offset, offset + rowsPerPage);
  // 2nd pagination
  return (
    <>
      <div className="child-container">
        <form className="container-fluid pt-3">
          <div className="card w-100 border-none">
            <span className="project-title-text">Basic Details</span>
            <div className="row mx-0">
              <div className="col-8 px-0">
                <div className="form-row w-100 d-flex flex-wrap form-row-split">
                  <div className="form-group">
                    <label htmlFor="inputEmail4" className="add_labels">
                      Project name
                    </label>
                    <TextField
                      type={"text"}
                      autoComplete="off"
                      className={`form-control` + ` ${formErrors.name && "error-border"}`}
                      placeholder={"Enter project name"}
                      name="name"
                      value={projectDetails.name}
                      onChange={(e) =>
                        setProjectDetails({
                          ...projectDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                      disabled={savedProject}
                    />
                    {formErrors.name && <p className="error-font text-danger">{formErrors.name}</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputPassword4" className="add_labels">
                      Objective type / study type{" "}
                    </label>
                    <SelectDropdown
                      options={objectiveTypeOptions}
                      value={projectDetails.objective_type}
                      onChange={(e) =>
                        setProjectDetails({
                          ...projectDetails,
                          objective_type: e,
                        })
                      }
                      disabled={savedProject}
                      className={formErrors.objective_type ? "error-border" : "seperator"}
                      placeholder="Select objective type"
                    />
                    {formErrors.objective_type && <p className="error-font text-danger">{formErrors.objective_type}</p>}
                  </div>

                  <div className="form-group pb-0">
                    <label htmlFor="inputEmail4" className="add_labels">
                      Client name
                    </label>
                    <TextField
                      type={"text"}
                      className={`form-control` + ` ${formErrors.name && "error-border"}`}
                      placeholder={"Enter client name"}
                      name="client_name"
                      value={projectDetails.client_name}
                      onChange={(e) =>
                        setProjectDetails({
                          ...projectDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                      disabled={savedProject}
                      autoComplete="off"
                    />
                    {formErrors.client_name && <p className="error-font text-danger">{formErrors.client_name}</p>}
                  </div>
                  <div className="form-group pb-0">
                    <label htmlFor="inputPassword4" className="add_labels">
                      Organisation
                    </label>
                    <SelectDropdown
                      options={organizationOptions}
                      value={projectDetails.organization_name}
                      onChange={(e) =>
                        setProjectDetails({
                          ...projectDetails,
                          organization_name: e,
                        })
                      }
                      className={formErrors.organization_name && "error-border"}
                      disabled={savedProject}
                      placeholder="Select organization"
                    />
                    {formErrors.organization_name && <p className="error-font text-danger">{formErrors.organization_name}</p>}
                  </div>
                </div>
              </div>
              <div className="col-4 px-0">
                <div className="w-100">
                  <div className="form-row">
                    <div className="form-group">
                      {/* <FormControl component="fieldset"> */}
                      <label className="add_labels">Do you want to select wave?</label>
                      <RadioGroup
                        row
                        aria-label="wave selection"
                        name="wave-radio-buttons-group"
                        value={isRadioSelected ? "Y" : "N"}
                        onChange={handleRadioChange}
                        disabled={savedProject}
                        sx={{ padding: "0px", "& .MuiSvgIcon-root": { fontSize: 20 } }}
                      >
                        <FormControlLabel
                          value="Y"
                          control={<Radio />}
                          disabled={savedProject}
                          label={<span className="wave_span">Yes, Enable Wave Input</span>}
                        />
                        <FormControlLabel
                          value="N"
                          disabled={savedProject}
                          control={<Radio />}
                          label={<span style={{ fontSize: "14px" }}>No, Disable Wave Input</span>}
                        />
                      </RadioGroup>
                      {/* </FormControl> */}
                      <div className="desc">
                        <label htmlFor="inputPassword4" className="add_labels">
                          Description
                        </label>
                        <textarea
                          className={
                            "form-control text-area-control "
                            // + (formErrors.description && "error-border")
                          }
                          name="description"
                          cols="5"
                          rows="5"
                          value={projectDetails.description}
                          onChange={(e) =>
                            setProjectDetails({
                              ...projectDetails,
                              [e.target.name]: e.target.value,
                            })
                          }
                          disabled={savedProject}
                          placeholder="Enter detailed project description "
                          style={{ height: "40px" }}
                        />
                        {
                          // formErrors.description && (
                          <p className="error-font text-danger">{formErrors.description}</p>
                          // )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!savedProject && (
            <div className="d-flex w-100 my-3 justify-content-end">
              <ButtonComponent
                variant="contained"
                onClick={cloneedProject ? updateProjectDetails : saveProjectDetails}
                btnType="primary"
                text="Save"
                className="btnPrimary"
              />
            </div>
          )}
          {fileslength > 0 && !isAddFiles && (savedProject || cloneedProject) && (
            <div>
              <div className="card w-100 border-none">
                <div className="w-100 h-100 position-relative">
                  <div className="d-flex justify-content-between" style={{ marginBottom: "2px" }}>
                    <h4 className="project-title-text">Attachments</h4>
                    <div
                      className="long"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <img src={Addicon} alt="add" className="add-file-icon" />
                      <ButtonComponent
                        onClick={handleButtonClick}
                        variant="contained"
                        btnType="primary"
                        text="Add File"
                        className="btnPrimaryOutline btnDisabled"
                        sx={{
                          height: "35.11px !important",
                        }}
                      />
                    </div>
                  </div>
                  <div className="file-datagrid">
                    <StyledDataGrid
                      rowHeight={48}
                      rows={pagination_row}
                      columns={columns1}
                      disableDensitySelector
                      // page={page}
                      paginationModel={paginationModel}
                      pageSizeOptions={[rowsPerPage]}
                      onFilterModelChange={handleFilterModelChange}
                      slots={{
                        toolbar: GridToolbarQuickFilter,
                        pagination: () => <PaginationComponent limit={rowsPerPage} offset={offset} totalCount={totalCount} onPageChange={handlePageChange} />,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      paginationMode="client"
                      checkboxSelection={false}
                      disableRowSelectionOnClick={true}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex w-100 my-3 gap-2 justify-content-end">
                {/* <ButtonComponent
                  onClick={() => navigate("/home")}
                  variant="outlined"
                  btnType="primary"
                  text="Back"
                  className="btnPrimaryOutline"
                /> */}
                <ButtonComponent
                  onClick={() => handleStartClick(navigate, createProjectDetails)}
                  variant="contained"
                  btnType="primary"
                  text="Next"
                  className="btnPrimary"
                  disabled={
                    !(createProjectDetails?.selectedFiles?.activeRawDataFile?.id && createProjectDetails?.selectedFiles?.activeDataMapFile?.id) &&
                    !(selectedRow?.id !== undefined && selectedRow?.id !== null)
                  }
                />
              </div>
            </div>
          )}

          {((fileslength == 0 && (savedProject || cloneedProject)) || (fileslength > 0 && isAddFiles && (savedProject || cloneedProject))) && (
            <>
              <div className="card w-100 border-none add_new_file">
                <div className="d-flex justify-content-between">
                  <h4 className="project-title-text">Add new files</h4>
                  {fileslength > 0 && isAddFiles && (savedProject || cloneedProject) && <CloseIcon onClick={handleClose} />}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="row px-0 mx-0 mb-3" style={{ width: "48%" }}>
                    <div className="form-row px-0 flex-wrap align-items-start form-row-split">
                      <div className="form-group1">
                        <label htmlFor="inputPassword4" className="add_labels">
                          Survey Status
                        </label>
                        <SelectDropdown
                          options={fileTypeOptions}
                          value={fileType?.file_type}
                          onChange={(e) => {
                            setFileType({ ...fileType, file_type: e });
                            dispatch(showSelectedFilesSuccess(false));
                          }}
                          className={"w-100 select-types"}
                        />
                      </div>
                      <div className="form-row px-0 flex-wrap align-items-start form-row-split">
                        <div className="form-group1">
                          <label htmlFor="inputPassword4" className="add_labels">
                            File Type
                          </label>
                          <SelectDropdown
                            options={dataStatusTypeOptions}
                            value={fileType.file_status}
                            onChange={(e) => {
                              setFileType({ ...fileType, file_status: e });
                              dispatch(showSelectedFilesSuccess(false));
                            }}
                            className={"w-100 select-types"}
                            position={menuPlacement}
                          />
                        </div>
                      </div>
                      {isRadioSelected && (
                        <div className="form-row px-0 flex-wrap align-items-start form-row-split">
                          <div className="form-group1">
                            <label htmlFor="inputPassword4" className="add_labels">
                              Select Type
                            </label>
                            <SelectDropdown
                              options={selectType}
                              value={fileType.select_type}
                              onChange={(e) => {
                                setFileType({ ...fileType, select_type: e });
                                dispatch(showSelectedFilesSuccess(false));
                              }}
                              className={"w-100 select-types"}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row px-0 mx-0 mb-0" style={{ width: "49%" }}>
                    {!createProjectDetails.showSelectedFiles && (
                      <div className="form-row px-0">
                        <div className="form-group col-md-12 p-0 pb-0">
                          <label htmlFor="inputPassword4" className="add_labels">
                            Upload files
                          </label>
                          <div
                            className={`uploadContainer-details d-flex  ${
                              isRadioSelected ? "upload_border" : "wave_upload_border"
                            } flex-column align-items-center text-center`}
                          >
                            <div className={`${isRadioSelected ? "" : ""} `}>
                              <div className={"flex gap-3 upload_completed"}>
                                {
                                  <span className="iconBg">
                                    <DocumentUpload size="22" color="#4f46e5" />{" "}
                                  </span>
                                }
                                {!rawDataFile && !mapDataFile && (
                                  <div>
                                    {!isRadioSelected ? (
                                      <>
                                        {" "}
                                        <p className="fs-14 mb-0 upload_content">
                                          Upload both files by clicking on the <span className="text-purple">"Add data Files"</span> Supported formats is CSV &
                                          MaxSize is <span className="text-purple">5MB.</span>
                                        </p>
                                      </>
                                    ) : (
                                      <p className={`fs-14 ${isRadioSelected ? " " : ""}`}>
                                        Upload both files by clicking on the <span className="text-purple">"Add data Files"</span> Supported formats is CSV &
                                        MaxSize is <span className="text-purple">5MB.</span>
                                      </p>
                                    )}
                                  </div>
                                )}
                                {(rawDataFile || mapDataFile) && (
                                  <div>
                                    <p className={`fs-14 upload_mandatory`}>Uploading both files is mandatory to proceed</p>
                                  </div>
                                )}
                              </div>

                              <div className="d-flex gap-2  justify-content-center">
                                <div>
                                  <input
                                    accept="text/csv"
                                    style={{ display: "none" }}
                                    id="contained-button-raw-file"
                                    type="file"
                                    key={Date.now()}
                                    onChange={(event) => handleFileRawDataSelection(event)}
                                    disabled={!fileType?.file_type?.value || !fileType?.file_status?.value}
                                  />

                                  {!rawDataFile && (
                                    <label htmlFor="contained-button-raw-file">
                                      <ButtonComponent
                                        btnType="teritory"
                                        component="span"
                                        text="Add Raw Data file"
                                        className="btnLightPurple"
                                        children={<FaPlus fontSize="small" className="addIconSize" color="#4F46E5" />}
                                        disabled={!fileType?.file_type?.value || !fileType?.file_status?.value}
                                      />
                                    </label>
                                  )}
                                  {rawDataFile && (
                                    <div className="selectedFile">
                                      <FileIconUploadSVG iconWidth="20" iconHeight="26" viewBox="0 0 26 52" />
                                      <span className="uploaded-filename w-[163px] white ">{rawDataFile.name}</span>
                                      <span
                                        onClick={() => {
                                          setRawDataFile(null);
                                          setRowDataFileSizeInMB(0);
                                        }}
                                      >
                                        <IoCloseCircleOutline width="16px" height="16px" color="#EF0707" fontSize={16} />
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <input
                                    accept="text/csv"
                                    style={{ display: "none" }}
                                    id="contained-button-map-file"
                                    type="file"
                                    onChange={(event) => handleFileDataMapSelection(event)}
                                    disabled={!fileType?.file_type?.value || !fileType?.file_status?.value}
                                    key={Date.now()}
                                  />
                                  {!mapDataFile && (
                                    <label htmlFor="contained-button-map-file">
                                      <ButtonComponent
                                        btnType="teritory"
                                        component="span"
                                        text="Add Data Map file"
                                        className="btnLightPurple"
                                        children={<FaPlus fontSize="small" className="addIconSize" color="#4F46E5" />}
                                        disabled={!fileType?.file_type?.value || !fileType?.file_status?.value}
                                      />
                                    </label>
                                  )}
                                  {mapDataFile && (
                                    <div className="selectedFile">
                                      <FileIconUploadSVG iconWidth="20" iconHeight="26" viewBox="0 0 26 52" />
                                      <span className="uploaded-filename">{mapDataFile?.name}</span>
                                      <span
                                        onClick={() => {
                                          setMapDataFile(null);
                                          setDataMapFileSizeInMB(0);
                                        }}
                                      >
                                        <IoCloseCircleOutline width="16px" height="16px" color="#EF0707" fontSize={16} />
                                      </span>
                                    </div>
                                  )}
                                </div>

                                {rawDataFile && mapDataFile && (
                                  <ButtonComponent
                                    className="file_upload_btn px-5"
                                    onClick={uploadFiles}
                                    // disabled={!rawDataFile || !mapDataFile}
                                    btnType="secondary"
                                    text="Upload"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {createProjectDetails.showSelectedFiles && (
                      <div className="form-row px-0">
                        <div className="form-group col-md-12 px-0">
                          <div className="d-flex justify-content-between">
                            <label className="mb-[6px]">Upload files</label>
                          </div>
                          <div
                            className={`uploadContainer-details d-flex  ${
                              isRadioSelected ? "upload_border" : "wave_upload_border"
                            } flex-column align-items-center text-center`}
                          >
                            <div className="col-md-12 px-0 d-flex justify-content-center mb-[12px]">
                              {!createProjectDetails.showSelectedError ? (
                                <span className="success-text">
                                  <Merged /> Merge file has been generated successfully.
                                </span>
                              ) : (
                                <span className="error-text">
                                  <IoCloseCircle fontSize="20" color="#EF0707" /> Error while processing the file.
                                </span>
                              )}
                            </div>
                            <div className="col-md-12 px-0 d-flex flex-nowrap gap-12">
                              <div className="file-preview-container">
                                <div className="uploadedContainerSucess d-flex flex-column align-items-start">
                                  <div className="d-flex gap-12 align-items-start">
                                    <SavedFilesSVG iconWidth="20" iconHeight="26" />{" "}
                                    <div className="d-flex flex-column gap-12">
                                      <span className="file-preview">{createProjectDetails?.selectedFiles?.activeRawDataFile?.name}</span>
                                      {/* <span className="text-success body-text font-medium">
                                        File validated successfully
                                      </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="file-preview-container">
                                <div className="uploadedContainerSucess d-flex flex-column align-items-start">
                                  <div className="d-flex gap-12 align-items-start">
                                    <SavedFilesSVG iconWidth="20" iconHeight="26" />{" "}
                                    <div className="d-flex flex-column gap-12">
                                      <span className="file-preview">{createProjectDetails?.selectedFiles?.activeDataMapFile?.name}</span>
                                      {/* <span className="text-success body-text font-medium">
                                        File validated successfully
                                      </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <span
                                  onClick={() => {
                                    dispatch(showSelectedFilesSuccess(false));
                                    dispatch(setSelectedFiles({}));
                                    setRawDataFile(null);
                                    setMapDataFile(null);
                                  }}
                                  className="trash-icon"
                                >
                                  <Tooltip title="Delete">
                                    <IoTrashOutline fontSize={18} color="#ef4444" />
                                  </Tooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {createProjectDetails?.filterFiles?.length > 0 && (
                  <div className="w-100 h-100">
                    <div className="d-flex justify-content-between" style={{ marginBottom: "2px", marginTop: "10px" }}>
                      <h4 className="project-title-text">Saved uploaded files</h4>
                      <div
                        className="long"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <img src={Addicon} alt="add" className="add-file-icon" />
                        <ButtonComponent
                          onClick={handleBackButtonClick}
                          variant="contained"
                          btnType="primary"
                          text="Add File"
                          disabled={isDisabled}
                          className="btnPrimaryOutline btnDisabled"
                          sx={{
                            height: "35.11px !important",
                          }}
                        />
                      </div>
                    </div>
                    <div className="file-datagrid">
                      <StyledDataGrid
                        sortingOrder={["desc", "asc"]}
                        rowHeight={48}
                        rows={
                          createProjectDetails?.files?.length > 0 &&
                          createProjectDetails?.files?.map((item, index) => {
                            const obj = {
                              ...item,
                              id: item.rawDataId + item.mapDataId,
                              key: item.rawDataId + item.mapDataId,
                            };
                            createProjectDetails?.data?.files?.map((ele, i) => {
                              if (ele.id === item.rawDataId) {
                                obj.wave = ele.wave;
                              }
                            });

                            return obj;
                          })
                        }
                        columns={columns}
                        disableDensitySelector
                        // slots={{
                        //   toolbar: GridToolbarQuickFilter,
                        // }}
                        // slotProps={{
                        //   toolbar: {
                        //     showQuickFilter: true,
                        //   },
                        // }}
                        checkboxSelection={false}
                        disableRowSelectionOnClick={true}
                        // sx={{
                        //   boxShadow: 3,
                        //   border: 0,
                        //   backgroundColor: "#fff",
                        //   borderColor: "primary.light",
                        //   "& .MuiDataGrid-cell:hover": {
                        //     color: "primary.main",
                        //   },
                        // }}
                        paginationModel={paginationModel}
                        pageSizeOptions={[rowsPerPage]}
                        onFilterModelChange={handleFilterModelChange}
                        slots={{
                          toolbar: GridToolbarQuickFilter,
                          pagination: () => <PaginationComponent limit={rowsPerPage} offset={offset} totalCount={totalCount} onPageChange={handlePageChange} />,
                        }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="d-flex w-100 my-3 gap-2 justify-content-end">
                <ButtonComponent
                  onClick={() => handleStartClick(navigate, createProjectDetails)}
                  variant="contained"
                  btnType="primary"
                  text="Next"
                  className="btnPrimary"
                  disabled={
                    !createProjectDetails?.selectedFiles?.activeRawDataFile?.name &&
                    !createProjectDetails?.selectedFiles?.activeDataMapFile?.name &&
                    !(selectedRow?.id !== undefined && selectedRow?.id !== null)
                  }
                />
              </div>
            </>
          )}
        </form>
      </div>
      <FileUploadModal
        rawDataFile={rawDataFile}
        mapDataFile={mapDataFile}
        openModal={openModal}
        setopenModal={setopenModal}
        progress={progress}
        setProgress={setProgress}
        setFileType={setFileType}
        upladedSuccess={upladedSuccess}
        setUploadedSuccess={setUploadedSuccess}
        duplicateErrorMessage={duplicateErrorMessage}
        setDuplicateErrorMessage={setDuplicateErrorMessage}
        rawErrorMessage={rawErrorMessage}
        setRawErrorMessage={setRawErrorMessage}
        dataErrorMessage={dataErrorMessage}
        setDataErrorMessage={setDataErrorMessage}
        projectDetails={projectDetails}
        projectId={projectId}
        fileType={fileType}
      />
      {showPermanentRespondentModal && potentialPermanentJokerList && (
        <PermanentRespondentModal open={showPermanentRespondentModal} potentialPermanentJokerList={potentialPermanentJokerList} />
      )}
      <SavedFileModal
        openModal={openSavedFileModal}
        setOpenModal={setOpenSavedFileModal}
        data={createProjectDetails?.files}
        setFileType={setFileType}
        fileType={fileType}
        projectDetails={projectDetails}
        projectId={projectId}
      />
    </>
  );
};

export default ProjectDetails;
