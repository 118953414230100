import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import StepperForm from "../StepperForm/StepperForm";

function CreateProjectSteps({ children }) {
  const { step } = useParams();

  useEffect(() => {}, [step]);
  return (
    <>
      <div className="container-fluid d-flex align-items-center justify-content-center stepperContainer">
        <StepperForm />
      </div>
      {children}
    </>
  );
}

export default CreateProjectSteps;
