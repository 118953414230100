import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "@uidotdev/usehooks";

import SwitchButton from "../../../../components/Switch/SwitchButton/SwitchButton.jsx";
import SelectDropdown from "../../../../components/SelectDropdown/SelectDropdown.jsx";
import ButtonComponent from "../../../../components/Button/ButtonComponent.jsx";
import RuleTable from "../../../../components/Tables/RuleTable/RuleTable.jsx";
import TextAreaComponent from "../../../../components/TextareaComponent/TextAreaComponent.jsx";
import useEvaluation from "../../../../hooks/useEvaluation.jsx";
import { getAllRulesByProjectId } from "../../../../services/projectService.js";
import WarningModal from "../../../../components/modals/WarningModal/WarningModal.jsx";
import { setIsRuleModified, setIsSwitchButtonPositionLeft } from "../../../../features/designQcRule/Rule/ruleSlice.js";
import { closeLoader, openLoader } from "../../../../features/loader/loaderSlice.js";
import { OwnerFilter } from "../../../../assets/constants/constant.js";
import { addAdverseTextRule, resetAllAdverseTextRules, startSaveAdverseRules } from "../../../../features/adverseEvent/RuleBuilder/RuleBuilder.js";
import SearchComponent from "../../../../components/SearchBox/SearchBox.jsx";
import Norule from "../../../../assets/images/Norule.svg";

function RuleBuilder() {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [showRuleButtons, setShowRuleButtons] = useState(true);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [accordionCount, setAdverseAccordionCount] = useState(1);
  const rules = useSelector((state) => state.rules.rules);
  const [adverseruleName, setRuleName] = useState(null);
  const [adverserule, setRule] = useState(null);
  const [noRule, setNoRule] = useState(false);
  const [isValidExpression, setExpression, evaluateExpression] = useEvaluation(adverserule);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [prevRuleName, setPrevRuleName] = useState(null);
  const [table_length, setTable_length] = useState();
  const isSwitchOnLeftPosition = useSelector((state) => state.rules.isSwitchButtonPositionLeft ?? false);
  const isRuleModified = useSelector((state) => state.rules.isRuleModified);
  const [accordions, setAccordions] = useState([{ adverseruleName: "", adverserule: "" }]);
  // Redux state selectors
  const textBoxValue = useSelector((state) => state.text.textRule?.adverserule);
  const textWithIconValue = useSelector((state) => state?.text?.textRules?.rule_name);
  const Adverse_rule_Rule_name = useSelector((state) => state?.adversetext?.textRules);
  const projectId = useSelector((state) => state?.createProjectDetails?.data?.id);
  const dataMapFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.name);
  const Rule_number = useSelector((state) => state?.adverseRule?.adverserules?.length);
  const rawFileName = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeRawDataFile?.name);
  const adverseRules = useSelector((state) => state.adverseRule.adverserules);
  const username = useSelector((state) => state?.user?.userInfo?.email) ?? "";

  const dataMapFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeDataMapFile?.id);

  const rawFileId = useSelector((state) => state?.createProjectDetails?.selectedFiles?.activeRawDataFile?.id);
  const DEBOUNCED_SEARCH_TIME = 700;
  const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCED_SEARCH_TIME);

  useEffect(() => {
    async function fetchUpdatedSearchTerm() {
      // await handleFetchKeywords(offset, debouncedSearchQuery);
      // setOffset(0);
    }

    fetchUpdatedSearchTerm();
  }, [debouncedSearchQuery]);
  React.useEffect(() => {
    if (adverserule !== null && prevRuleName !== adverserule) {
      setPrevRuleName(adverserule);
      setIsRuleModified(true);
    }
  }, [adverserule]);

  const creators = Array.from(new Set(adverseRules.map((adverseRules) => adverseRules.created_by))).map((owner) => ({
    label: owner,
    value: owner,
  }));
  const handleOwnerChange = (selectedOption) => {
    setSelectedOwner(selectedOption.value);
    setFilterApplied(true);
  };

  useEffect(() => {
    setRuleName(textWithIconValue);
    setRule(textBoxValue);
  }, [textWithIconValue, textBoxValue]);

  const handleAddRule = () => {
    dispatch(setIsSwitchButtonPositionLeft(true));
    dispatch(addAdverseTextRule());
  };

  // clear all rules
  const handleClearAll = () => {
    dispatch(resetAllAdverseTextRules());
  };

  const handleDropdownOptionSelect = (value) => {
    setSelectedOwner(value);
    setFilterApplied(value !== "");
  };

  if (isSwitchOnLeftPosition && showRuleButtons !== false) {
    setShowRuleButtons(false);
  }

  if (!isSwitchOnLeftPosition && showRuleButtons !== true) {
    setShowRuleButtons(true);
  }
  // handle save
  const handleSave = async () => {
    dispatch(startSaveAdverseRules());
    // let allValid = true;
    // let invalidMessage = "";
    // for (const ruleObj of Adverse_rule_Rule_name) {
    //   const { rule, rule_name } = ruleObj;
    //   const preProcessedResult = checkEvaluation(rule);
    //   if (!preProcessedResult) {
    //     allValid = false;
    //     invalidMessage = `Rule "${rule_name}" is invalid. Please enter a valid expression.`;
    //     break;
    //   }
    // }
    // const isEmptyRuleName = Adverse_rule_Rule_name.some((ruleObj) => !ruleObj.rule_name || ruleObj.rule_name.trim() === "");
    // const isEmptyRule = Adverse_rule_Rule_name.some((ruleObj) => !ruleObj.rule || ruleObj.rule.trim() === "");
    // if (isEmptyRuleName) {
    //   dispatch(
    //     openSnackbar({
    //       message: "Rule name is empty. Please fill adverserule name.",
    //       type: SNACKBAR_TYPES.ERROR,
    //     })
    //   );
    // } else if (isEmptyRule) {
    //   dispatch(
    //     openSnackbar({
    //       message: "Rule is empty. Please fill in the adverserule.",
    //       type: SNACKBAR_TYPES.ERROR,
    //     })
    //   );
    // } else if (!allValid) {
    //   dispatch(
    //     openSnackbar({
    //       message: "Rule expression is invalid. Please enter a valid expression.",
    //       type: SNACKBAR_TYPES.ERROR,
    //     })
    //   );
    // } else {
    //   try {
    //     dispatch(openLoader());
    //     const newRulesData = Adverse_rule_Rule_name.map((ruleObj) => ({
    //       id: ruleObj?.id ?? null,
    //       project_id: projectId,
    //       rule_name: ruleObj.rule_name.trim(),
    //       rule_type: "ADVERSE_EVENT",
    //       rule: ruleObj.rule.trim(),
    //       optionsMeta: ruleObj?.optionsMeta,
    //       questionsMeta: ruleObj?.questionsMeta,
    //       weight: "0",
    //       // rule_status: false,
    //       is_rule_active: false,
    //     }));

    //     const newRuleData = {
    //       rules: newRulesData,
    //       updated_by: username,
    //     };
    //     const response = await createRule(newRuleData);
    //     const ids = response?.data?.map((rule) => rule.id);
    //     dispatch(updateAdverseRuleIds({ ids }));
    //     dispatch(openSnackbar({ message: "Rule created successfully!", type: SNACKBAR_TYPES.SUCCESS }));
    //     // dispatch(resetEntireAdverseTextRules());
    //     dispatch(setIsAdverseRuleModified(false));
    //   } catch (error) {
    //     if (error.response.data.errorCode === "E011")
    //       dispatch(
    //         openSnackbar({
    //           message: "Duplicate rule name received from Joker Analysis or Adverse Event",
    //           type: SNACKBAR_TYPES.ERROR,
    //         })
    //       );
    //     else dispatch(openSnackbar({ message: error.response.data.message, type: SNACKBAR_TYPES.ERROR }));
    //   } finally {
    //     dispatch(closeLoader());
    //   }
    // }
  };
  useEffect(() => {
    if (Rule_number > 0) {
      setNoRule(false);
    } else {
      setNoRule(true);
    }
  }, [handleSave]);
  const handleRuleChange = (value) => {
    setExpression(value);
  };
  // Apply all rules
  const handleApplyAll = () => {
    dispatch(setIsSwitchButtonPositionLeft(true));
    dispatch(addAdverseTextRule());

    // -----Apply all logic------
    // try {
    //   dispatch(openLoader());
    //   // if (isRuleModified) {
    //   //   dispatch(openSnackbar({ message: "Please save adverserule", type: SNACKBAR_TYPES.ERROR }));
    //   //   const newSelected = adverseRules.map((rule) => rule.id);
    //   //   dispatch(setAdverseCheckedRulesInRuleTable(newSelected));
    //   //   dispatch(getAdverseRuleExecutionStart({ projectId: projectId, questionFileId: dataMapFileId, respondentFileId: rawFileId, username: username }));
    //   //   return;
    //   // }
    //   const newSelected = adverseRules.map((rule) => rule.id);
    //   dispatch(setAdverseCheckedRulesInRuleTable(newSelected));
    //   dispatch(
    //     getAdverseRuleExecutionStart({
    //       projectId: projectId,
    //       questionFileId: dataMapFileId,
    //       respondentFileId: rawFileId,
    //       username: username,
    //     })
    //   );
    //   // dispatch(openSnackbar({ message: "Rule executed", type: SNACKBAR_TYPES.SUCCESS }));
    // } catch (error) {
    //   dispatch(
    //     openSnackbar({ message: "Unable to apply all rules!", type: SNACKBAR_TYPES.SUCCESS })
    //   );
    // } finally {
    //   dispatch(closeLoader());
    // }
  };
  const handleSearchInputChange = async (event) => {
    const newSearchValue = event.target.value;
    setSearchQuery(newSearchValue);
  };
  React.useEffect(() => {
    const getRulesFunction = async () => {
      try {
        dispatch(openLoader());
        const ruleType = "ADVERSE_EVENT";
        const response = await getAllRulesByProjectId(projectId, ruleType);
        const table_length = response?.data?.items?.length;
        setTable_length(table_length);
      } catch (error) {
        // dispatch(openSnackbar({ message: "Failed to fetch rules", type: SNACKBAR_TYPES.ERROR }));
        console.error("Failed to fetch rules", error);
      } finally {
        dispatch(closeLoader());
      }
    };
    getRulesFunction();
  }, [isSwitchOnLeftPosition, adverseRules.length]);

  return (
    <>
      <div className="buttons">
        <SwitchButton />
        {showRuleButtons && table_length > 0 && (
          <div className="saved_btn_combine">
            <div className="ruletablesearch-unique">
              <SearchComponent value={searchQuery} onChange={handleSearchInputChange} className="open_text_search" width="244px" height="32px" />
            </div>
            <div className="owner_dropdown">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {OwnerFilter.Owner}
              </p>
              <SelectDropdown
                options={[{ label: "All Owners", value: "" }, ...creators]}
                value={selectedOwner}
                onChange={handleOwnerChange}
                className={"custom-select-dropdown"}
                placeholder={selectedOwner ? selectedOwner : "All Owners"}
              />
            </div>
            <ButtonComponent
              onClick={handleApplyAll}
              className="file_upload_btn"
              btnType="secondary"
              text="Add Rule"
              sx={{ width: "104px", height: "32px !important" }}
            />
          </div>
        )}
        {!showRuleButtons && (
          <div className="" style={{ marginLeft: "auto" }}>
            <ButtonComponent
              className=""
              btnType="outline"
              text="Clear all"
              onClick={handleClearAll}
              sx={{ width: "104px", marginRight: "10px", height: "32px !important" }}
            />
            <ButtonComponent
              className="file_upload_btn"
              btnType="secondary"
              text="Save"
              onClick={handleSave}
              sx={{ width: "80px", marginRight: "5px", height: "32px !important" }}
            />
            <ButtonComponent
              className="file_upload_btn"
              btnType="secondary"
              text="Add Rule"
              onClick={handleAddRule}
              sx={{ width: "140px", height: "32px !important" }}
            />
          </div>
        )}
      </div>
      <div className="table-rule">
        {!showRuleButtons && <TextAreaComponent onRuleChange={handleRuleChange} />}
        {showRuleButtons && table_length > 0 && (
          <RuleTable selectedOwner={selectedOwner} isRuleModified={isRuleModified} debouncedSearchQuery={debouncedSearchQuery} />
        )}
      </div>
      {showRuleButtons && table_length == 0 && (
        <div className="no-rule">
          <div className="image_no-rule">
            <img src={Norule} alt="No rule" />
          </div>
          <div>
            <p className="no-rule-p1">No saved rules</p>
            <p className="no-rule-p2">Click on add rule button</p>
            <p className="no-rule-p3"> to add a rule</p>
            <ButtonComponent
              className="btnPrimary"
              variant="contained"
              btnType="primary"
              text="Add Rule"
              onClick={handleAddRule}
              sx={{ width: "154px", height: "38px !important" }}
            />
          </div>
        </div>
      )}
      {showPopup && <WarningModal openModal={showPopup} setOpenModal={setShowPopup} headerText="Warning" titleText={popupMessage} />}
    </>
  );
}

export default RuleBuilder;
