export const Rule_Name = "Rule Name";
export const Owner = "Owner";
export const Created_on = "Created on";
export const Description = "Description";
export const Last_updated = "Last Updated";
export const Status = "Actions";
export const RAW_FILE = "Raw Data File";
export const DATA_MAP_FILE = "Data Map File";

export const RuleTableColumns = {
  RULE_NAME: "rule_name",
  RULE_DESCRIPTION: "rule",
  RULE_OWNER: "created_by",
  RULE_UPDATED: "updated_at",
  RULE_STATUS: "status",
  //   RAW_FILE:
};

export const AdverseRuleTable = {
  is_success: true,
  data: {
    items: [
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-28T07:49:27.954Z",
        updated_at: "2024-05-28T07:49:27.954Z",
        id: 58,
        project_id: 284,
        rule_name: "AdverseAdverselumiq_11",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYZ2SWRVGM7XJSW1RK5WKNWF",
        weight: "0.00",
        rule_status: false,
      },
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-26T10:53:18.349Z",
        updated_at: "2024-05-26T10:53:18.349Z",
        id: 44,
        project_id: 284,
        rule_name: "Adverselumiq_10",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYT8H2MGSE8G450ZZ743HK2Z",
        weight: "0.00",
        rule_status: false,
      },
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-26T10:53:12.129Z",
        updated_at: "2024-05-26T10:53:12.129Z",
        id: 43,
        project_id: 284,
        rule_name: "Adverselumiq_9",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYT8GWJ3VZWB4Q5ATB3SJGCH",
        weight: "0.00",
        rule_status: false,
      },
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-26T10:53:05.929Z",
        updated_at: "2024-05-26T10:53:05.929Z",
        id: 42,
        project_id: 284,
        rule_name: "Adverselumiq_8",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYT8GPGB862X0WHFX4XYG8E3",
        weight: "0.00",
        rule_status: false,
      },
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-26T10:53:01.959Z",
        updated_at: "2024-05-26T10:53:01.959Z",
        id: 41,
        project_id: 284,
        rule_name: "Adverselumiq_7",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYT8GJM97DMKPMQCCZV8PPGP",
        weight: "0.00",
        rule_status: false,
      },
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-26T10:52:56.741Z",
        updated_at: "2024-05-26T10:52:56.741Z",
        id: 40,
        project_id: 284,
        rule_name: "Adverselumiq_6",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYT8GDH86YRWVKBVB57W17YA",
        weight: "0.00",
        rule_status: false,
      },
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-26T10:52:50.689Z",
        updated_at: "2024-05-26T10:52:50.689Z",
        id: 39,
        project_id: 284,
        rule_name: "Adverselumiq_5",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYT8G7M4B779N5WA67TYXVVM",
        weight: "0.00",
        rule_status: false,
      },
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-26T10:52:46.466Z",
        updated_at: "2024-05-26T10:52:46.466Z",
        id: 38,
        project_id: 284,
        rule_name: "Adverselumiq_4",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYT8G3G51188V2RB8WA6091M",
        weight: "0.00",
        rule_status: false,
      },
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-26T10:52:43.178Z",
        updated_at: "2024-05-26T10:52:43.178Z",
        id: 37,
        project_id: 284,
        rule_name: "Adverselumiq_3",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYT8G09CZJG5CPAKDJRSCXXV",
        weight: "0.00",
        rule_status: false,
      },
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-26T10:52:39.890Z",
        updated_at: "2024-05-26T10:52:39.890Z",
        id: 36,
        project_id: 284,
        rule_name: "Adverselumiq_2",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYT8FX2MC71KX3RRB9G6TN5J",
        weight: "0.00",
        rule_status: false,
      },
      {
        created_by: "Adverseuser",
        updated_by: "user",
        created_at: "2024-05-26T10:52:36.137Z",
        updated_at: "2024-05-26T10:52:36.137Z",
        id: 35,
        project_id: 284,
        rule_name: "Adverselumiq_1",
        rule_type: "temp",
        rule: "2+2",
        rule_version: "01HYT8FSDHHZGP34FBASZ3M0C2",
        weight: "0.00",
        rule_status: false,
      },
    ],
    skip: 0,
    total: 11,
    totalRecords: null,
  },
  responseCode: 200,
};
