import React from "react";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as DeleteIcon } from "../../assets/images/deleteicon.svg";
import { ReactComponent as Replayicon } from "../../assets/images/replayicon.svg";
import "./TextBoxWithIcon.css";
import QuestionLibrary from "../modals/QuestionLibrary/QuestionLibrary";
import { setAccordionIndex, setOpenQuestionModal } from "../../features/designQcRule/questionlibrary/questionlibrarySlice";
import { deleteTextRule, resetTextRule, undoTextRule, updateTextRuleName } from "../../features/designQcRule/BuildRule/BuildRuleslice";
import { deleteRule } from "../../services/projectService";
import { setIsRuleedited } from "../../features/designQcRule/Rule/ruleSlice";
import { openSnackbar } from "../../features/snackbar/snackbarslice";
import { closeLoader, openLoader } from "../../features/loader/loaderSlice";
import { deleteAdverseTextRule, undoAdverseTextRule, updateAdverseTextRuleName } from "../../features/adverseEvent/RuleBuilder/RuleBuilder";
import CustomTooltip from "../Tooltip/Tooltip";
import { useRuleBuilderTextAreaProvider } from "../../context/RuleBuilderTextArea/ruleBuilderTextAreaProvider";
import { SNACKBAR_TYPES } from "../../assets/constants/constant";

const TextBoxWithIcon = ({ initialValue, onChange, setSelectedQuestionId, onDelete, onReset, index, isAdverseText }) => {
  const { editorRef, saveCaretPosition, restoreCaretPosition } = useRuleBuilderTextAreaProvider();

  const dispatch = useDispatch();
  const selectedHorizontalTab = useSelector((state) => state?.designQc?.selectedHorizontalTab);
  // const [value, setValue] = useState(initialValue);
  // const textRuleName = useSelector((state) => state?.text?.textRule_name);
  const openQuestionModal = useSelector((state) => state.questions.openQuestionModal);
  const adverseRuleName = useSelector((state) => state?.adversetext?.textRules[index]?.rule_name) || "";
  const textRuleName = useSelector((state) => state?.text?.textRules[index]?.rule_name) || "";
  const value = selectedHorizontalTab === "Adverse Events" && isAdverseText ? adverseRuleName : textRuleName;
  // const value = useSelector((state) => state?.text.textRules[index].rule_name) || "";
  const newIndex = JSON.parse(JSON.stringify(index));
  const isRuleEdit = useSelector((state) => state?.rules?.isRuleEdited);
  const accordionIndex = useSelector((state) => state.questions.accordionIndex);
  const textRules = useSelector((state) => state?.text?.textRules) || [];
  const adverseRules = useSelector((state) => state?.adversetext?.textRules) || [];
  const adverseRule = useSelector((state) => state?.adversetext?.textRules[0]?.rule) || "";
  const textRule = useSelector((state) => state?.text?.textRules[0]?.rule) || "";
  // const dispatch = useDispatch();
  const handleInputChange = (e, newIndex) => {
    const newValue = e.target.value;
    if (selectedHorizontalTab === "Adverse Events") {
      dispatch(updateAdverseTextRuleName({ newIndex, newValue }));
    } else {
      dispatch(updateTextRuleName({ newIndex, newValue }));
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleLibraryClicked = (e) => {
    if (!openQuestionModal) {
      dispatch(setOpenQuestionModal(true));
    }
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    saveCaretPosition();
    handleLibraryClicked(e);
    dispatch(setAccordionIndex(newIndex));
  };

  const handleReset = (e) => {
    if (selectedHorizontalTab === "Adverse Events") {
      e.stopPropagation();
      dispatch(undoAdverseTextRule({ newIndex1: index }));
    } else {
      e.stopPropagation();
      dispatch(undoTextRule({ index: index }));
    }
  };
  const edit_rule_id = useSelector((state) => state?.text?.textRules[0]?.id);
  const handleDelete = async (e) => {
    if (selectedHorizontalTab === "Adverse Events") {
      e.stopPropagation();
      dispatch(deleteAdverseTextRule({ index: index }));
    } else {
      e.stopPropagation();
      if (isRuleEdit) {
        try {
          dispatch(openLoader());
          await deleteRule(edit_rule_id);
          dispatch(resetTextRule({ index: 0 }));
          dispatch(closeLoader());
          dispatch(openSnackbar({ message: "Deleted rule successfully", type: SNACKBAR_TYPES.SUCCESS }));
        } catch (error) {
          console.error("Failed to delete rule:", error);
          dispatch(closeLoader());
          dispatch(openSnackbar({ message: "Failed to delete rule", type: SNACKBAR_TYPES.ERROR }));
          return;
        }
        dispatch(setIsRuleedited(false));
      }
      dispatch(deleteTextRule({ index: index }));
    }
  };
  return (
    <div className="Container-RuleName">
      <div className="minus-icon">
        <div className="Textbutton" onClick={stopPropagation}>
          {selectedHorizontalTab === "Adverse Events" ? (
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              autoComplete="off"
              // value={value}
              value={adverseRuleName}
              placeholder="Enter Rule Name"
              // value={textRuleName}
              onChange={(e) => handleInputChange(e, newIndex)}
              InputProps={{
                endAdornment: <EditIcon className="edit-icon" color="action" sx={{ width: "16px", height: "16px" }} />,
                sx: {
                  "& .MuiInputAdornment-root": {
                    marginLeft: 0,
                    width: "100%",
                    height: "38px",
                  },
                },
              }}
            />
          ) : (
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              autoComplete="off"
              // value={value}
              value={textRuleName}
              placeholder="Enter Rule Name"
              // value={textRuleName}
              onChange={(e) => handleInputChange(e, newIndex)}
              InputProps={{
                endAdornment: <EditIcon className="edit-icon" color="action" sx={{ width: "16px", height: "16px" }} />,
                sx: {
                  "& .MuiInputAdornment-root": {
                    marginLeft: 0,
                    width: "100%",
                    height: "38px",
                  },
                },
              }}
            />
          )}

          <div onClick={stopPropagation}>
            <Button className="primary-button" onClick={handleButtonClick}>
              + Add a Question
            </Button>
          </div>
        </div>
        {selectedHorizontalTab === "Adverse Events" ? (
          <>
            {(adverseRules.length > 1 || adverseRule.trim() != "") && (
              <div className="additionalbutton">
                <CustomTooltip title="Delete">
                  <button className="bg-white border-none" style={{ padding: "0px" }} onClick={handleDelete}>
                    <DeleteIcon className="additional-icon1" color="action" />
                  </button>
                </CustomTooltip>
                <CustomTooltip title="Undo">
                  <button className="bg-white border-none" onClick={handleReset}>
                    <Replayicon className="additional-icon" color="action" />
                  </button>
                </CustomTooltip>
              </div>
            )}
          </>
        ) : (
          <>
            {(textRules.length > 1 || textRule.trim() != "") && (
              <div className="additionalbutton">
                <CustomTooltip title="Delete">
                  <button className="bg-white border-none" style={{ padding: "0px" }} onClick={handleDelete}>
                    <DeleteIcon className="additional-icon1" color="action" />
                  </button>
                </CustomTooltip>
                <CustomTooltip title="Undo">
                  <button className="bg-white border-none" onClick={handleReset}>
                    <Replayicon className="additional-icon" color="action" />
                  </button>
                </CustomTooltip>
              </div>
            )}
          </>
        )}
      </div>
      {openQuestionModal && index == accordionIndex && (
        <div onClick={(e) => e.stopPropagation()}>
          <QuestionLibrary onSaveQuestionId={setSelectedQuestionId} openModal={openQuestionModal} />
        </div>
      )}
    </div>
  );
};

export default TextBoxWithIcon;
